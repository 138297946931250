import React from "react";
import { Button, Box } from "@material-ui/core";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiButton-root": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  buttonYes: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonNo: {
    border: "1 solid",
    borderColor: red[500],
    color: red[500],
  },
}));

const YesNoButtons = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Button
        variant="contained"
        className={classes.buttonYes}
        endIcon={<DoneRoundedIcon />}
        onClick={props.selectedApproved}
      >
        {props.positiveTitle}
      </Button>
      <Button
        variant="outlined"
        className={classes.buttonNo}
        endIcon={<CloseRoundedIcon />}
        onClick={props.selectedDeclined}
      >
        {props.negavtiveTitle}
      </Button>
    </Box>
  );
};
export default YesNoButtons;
