import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import {
  Grid,
  Typography,
  Collapse,
  FormControlLabel,
  Checkbox,
  Box,
  TextField,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
  },
}));

const FQLicense = ({ onCancel, onCompletion, fqEdit = null }) => {
  const [minLicenseYears, setMinLicenseYears] = React.useState(0);
  const [selectedLicense, setSelectedLicense] = React.useState("");
  const [isManualLicense, setIsManualLicense] = React.useState(false);
  const [isLicenseAMust, setIsLicenseAMust] = React.useState(false);
  const [isYouth, setIsYouth] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedLicense(fqEdit.licenseOn);
      setMinLicenseYears(fqEdit.minYearsLicense);
      setIsLicenseAMust(fqEdit.isLicenseAMust);
      setIsManualLicense(fqEdit.isVehicalManual);
      setIsYouth(fqEdit.isNotYouth);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  /*
  Single license Object: 
  {
      licenseId: AutoID
      licenseOn: string: license type,
      minYearsLicense: num,
      isLicenseAMust: boolean,
      isVehicalManual: boolean,
      isNotYouth: boolean
  }
  */

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["licenseOn"] = selectedLicense;
      existingObject["minYearsLicense"] = parseInt(minLicenseYears);
      existingObject["isLicenseAMust"] = isLicenseAMust;
      existingObject["isVehicalManual"] = isManualLicense;
      existingObject["isNotYouth"] = isYouth;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      licenseOn: selectedLicense,
      minYearsLicense: minLicenseYears,
      isLicenseAMust,
      isVehicalManual: isManualLicense,
      isNotYouth: isYouth,
    });
  };

  function clearForm() {
    //clean the form values:
    setMinLicenseYears(0);
    setIsLicenseAMust(false);
    setSelectedLicense("");
    setIsManualLicense(false);
    setIsYouth(false);
  }

  const handleCheckEnableQ = (value) => {
    if (!value) setMinLicenseYears(0);
  };
  const handleMinYearsLicense = (event) =>
    setMinLicenseYears(event.target.value);
  const handleSelectedLicense = (event) => {
    setSelectedLicense(event.target.value);
    if (event.target.value !== "רכב") {
      setIsYouth(false);
      setIsManualLicense(false);
    }
  };

  const handleIsLicenseIsMust = (event) =>
    setIsLicenseAMust(event.target.checked);

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          Select a license:
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="lincense"
            label="Required license"
            variant="filled"
            value={selectedLicense}
            onChange={handleSelectedLicense}
            fullWidth
            helperText='Type "car" for car license additional information'
          />
        </Grid>
        <Grid item xs={6}>
          <div className={classes.carLicense}>
            <Collapse in={selectedLicense.toLowerCase().startsWith("car")}>
              <FormControlLabel
                control={<Checkbox name="manualLicense" color="primary" />}
                label="Manual Gearbox"
                onClick={() => setIsManualLicense(true)}
              />
              <FormControlLabel
                control={<Checkbox name="manualLicense" color="primary" />}
                label="Not Youth driver"
                onClick={() => setIsYouth(true)}
              />
            </Collapse>
          </div>
        </Grid>

        <Grid item xs={6}>
          <QuestionValueInput
            disabled={selectedLicense === ""}
            question="Minimal years required for holding this license:"
            minYearsValue={handleMinYearsLicense}
            isAMustCheck={handleIsLicenseIsMust}
            value={minLicenseYears}
            isAmustValue={isLicenseAMust}
            checkEnalbeQ={handleCheckEnableQ}
          />
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="Done"
            negativeCaption="Cancel"
            positiveDisabled={selectedLicense === ""}
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FQLicense;
