import React from "react";
import {
  Paper,
  Typography,
  Grid,
  Slide,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Fade,
  TextField,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { JobDeclinedReasonsOptions } from "../../../config/jobsConfigData";
import YesNoButtons from "../../YesNoButtons/YesNoButtons";
import { useIntl, FormattedMessage } from "react-intl";

const styles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f2f2f2",
    height: "100vh",
    "& .MuiTypography-root": {
      color: grey[900],
      "& span": {
        fontWeight: 700,
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
}));

const JobDeclinedReasons = (props) => {
  const classes = styles();
  const theme = useTheme();
  const intl = useIntl();
  const [declinedOther, setDeclinedOther] = React.useState("");
  const [declinedReason, setDeclinedReason] = React.useState(
    JobDeclinedReasonsOptions.find((res) => res.id === "salary").id
  );

  const radioGroupRendering = JobDeclinedReasonsOptions.map((opt) => {
    return (
      <FormControlLabel
        value={opt.id}
        control={<Radio color="primary" />}
        label={opt.label}
        key={opt.id}
      />
    );
  });

  const handleReasonOtherChanage = (event) => {
    setDeclinedOther(event.target.value);
  };

  const handleChangeReason = (event) => {
    setDeclinedReason(event.target.value);
  };

  return (
    <Slide
      direction="up"
      timeout={500}
      in={props.declined}
      className={classes.root}
      mountOnEnter
      unmountOnExit
    >
      <Grid container>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="body1">
              <FormattedMessage
                id="publishScreen.thankYouComment"
                defaultMessage="Thank you for your answer, {fullname}."
                values={{ fullname: props.fullname }}
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                id="publishScreen.declineReasons.libiMessage"
                defaultMessage="In order to keep suggesting to you the best suitable jobs for you, I'll be happy if you can share the reason for declining this job:"
              />
            </Typography>
            <div style={{ marginTop: theme.spacing(2) }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <FormattedMessage
                    id="publishScreen.jobNotSuitalble"
                    defaultMessage="I don't like this job because:"
                  />
                </FormLabel>
                <RadioGroup
                  aria-label="reasons"
                  name="reasons"
                  value={declinedReason}
                  onChange={handleChangeReason}
                >
                  {radioGroupRendering}
                </RadioGroup>
              </FormControl>
              {declinedReason === "other" ? (
                <Fade in={true}>
                  <TextField
                    id="standard-multiline-flexible"
                    label={intl.formatMessage({
                      id: "publishScreen.declinedReasons.details",
                      defaultMessage: "Details (Optional)",
                    })}
                    multiline
                    rows={4}
                    value={declinedOther}
                    onChange={handleReasonOtherChanage}
                    fullWidth
                  />
                </Fade>
              ) : null}
            </div>
            <div style={{ marginTop: theme.spacing(2) }}>
              {declinedReason ? (
                <YesNoButtons
                  positiveTitle={intl.formatMessage({
                    id: "general.done",
                    defaultMessage: "Done",
                  })}
                  negavtiveTitle={intl.formatMessage({
                    id: "general.cancel",
                    defaultMessage: "Cancel",
                  })}
                  selectedDeclined={props.cancelDecline}
                  selectedApproved={() =>
                    props.answered(declinedReason, declinedOther)
                  }
                />
              ) : null}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Slide>
  );
};
export default JobDeclinedReasons;
