export function rebuildExperienceFQObject(obj) {
  let localExperienceFQObject = [];
  let experienceId = 0;
  let selectedProfession = "";
  let minYears = 0;
  let isAMust = false;

  const expReducedArray = obj.reduce((acc, item) => {
    const { fq_sub_type_id } = item;
    acc[fq_sub_type_id] = acc[fq_sub_type_id]
      ? [...acc[fq_sub_type_id], item]
      : [item];
    return acc;
  }, []);
  expReducedArray.map((fqSubType) => {
    selectedProfession = fqSubType.filter(
      (fq) => fq.fq_key === "selectedProfession"
    )[0].fq_value;
    experienceId = fqSubType.filter(
      (fq) => fq.fq_key === "selectedProfession"
    )[0].fq_sub_type_id;
    minYears = fqSubType.filter((fq) => fq.fq_key === "minYears")[0].fq_value;
    minYears = minYears ? parseInt(minYears) : 0;
    isAMust = parseInt(
      fqSubType.filter((fq) => fq.fq_key === "isAMust")[0].fq_value
    );
    localExperienceFQObject = [
      ...localExperienceFQObject,
      {
        experienceId,
        selectedProfession,
        minYears,
        isAMust,
      },
    ];
  });

  return localExperienceFQObject;
}

/*
  Single skills Object: 
  {
      skillId: id,
      skillName: string,
      minYearsSkill: num,
      isSkillAMust: boolean,
      knowledgeLevelRequired: string
  }
  */

export function rebuildTalentsFQObject(obj) {
  let talentsFQObject = [];
  let skillName = "";
  let skillId = -1;
  let isSkillAMust = false;
  let minSkillYears = 0;
  let knowledgeLevel = "";
  if (parseInt(Object.keys(obj).length)) {
    //find the count of skills:
    const count = obj.filter((item) => item.fq_key === "skillName").length;
    for (let i = 0; i < count; i++) {
      const single = obj.filter(
        (item) => parseInt(item.fq_sub_type_id) === i + 1
      );
      if (single.length) {
        single.map((skl) => {
          switch (skl.fq_key) {
            case "skillName":
              skillName = skl.fq_value;
              skillId = skl.fq_sub_type_id;
              break;
            case "knowledgeLevel":
              knowledgeLevel = skl.fq_value;
              break;
            case "minSkillYears":
              minSkillYears = parseInt(skl.fq_value);
              break;
            case "isSkillAMust":
              isSkillAMust = skl.fq_value === "true" ? true : false;
              break;
            default:
              break;
          }
        });
      }
      talentsFQObject = [
        ...talentsFQObject,
        {
          skillId: skillId,
          skillName: skillName,
          minYearsSkill: minSkillYears,
          isSkillAMust: isSkillAMust,
          knowledgeLevelRequired: knowledgeLevel,
        },
      ];
    }
  }
  return talentsFQObject;
}

/*
  Single license Object: 
  {
      licenseId: AutoID
      licenseOn: string: license type,
      minYearsLicense: num,
      isLicenseAMust: boolean,
      isVehicalManual: boolean,
      isNotYouth: boolean
  }
  */
export function rebuildLicensesFQObject(obj) {
  let licensesFQObject = [];
  let licenseOn = "";
  let licenseId = -1;
  let isLicenseAMust = false;
  let minYearsLicense = 0;
  let isVehicalManual = false;
  let isNotYouth = false;

  if (parseInt(Object.keys(obj).length)) {
    //find the count of skills:
    const count = obj.filter((item) => item.fq_key === "licenseOn").length;
    for (let i = 0; i < count; i++) {
      const single = obj.filter(
        (item) => parseInt(item.fq_sub_type_id) === i + 1
      );
      single.map((skl) => {
        switch (skl.fq_key) {
          case "licenseOn":
            licenseOn = skl.fq_value;
            licenseId = skl.fq_sub_type_id;
            break;
          case "minYearsLicense":
            minYearsLicense = parseInt(skl.fq_value);
            break;
          case "isLicenseAMust":
            isLicenseAMust = skl.fq_value === "true" ? true : false;
            break;
          case "isVehicalManual":
            isNotYouth = skl.fq_value === "true" ? true : false;
            break;
          default:
            break;
        }
      });
      licensesFQObject = [
        ...licensesFQObject,
        {
          licenseId,
          licenseOn,
          minYearsLicense,
          isLicenseAMust,
          isVehicalManual,
          isNotYouth,
        },
      ];
    }
  }
  return licensesFQObject;
}

/* Academic Degree Object  
  Academic Degree Object:
  degreeId: id
  selectedDegree = string,
  degreeType = string,
  isAMust = boolean
  */
export function rebuildAcademicDegreeFQObject(obj) {
  let selectedDegree = "";
  let degreeType = "";
  let isAMust = false;
  let degreeId = 0;

  if (parseInt(Object.keys(obj).length)) {
    //find the count of skills:
    obj.map((ad) => {
      switch (ad.fq_key) {
        case "selectedDegree":
          selectedDegree = ad.fq_value;
          degreeId = ad.fq_sub_type_id;
          break;
        case "degreeType":
          degreeType = ad.fq_value;
          break;
        case "isDegreeAMust":
          isAMust = parseInt(ad.fq_value) === 1 ? true : false;
          break;
        default:
          break;
      }
    });

    return [
      {
        degreeId,
        selectedDegree,
        degreeType,
        isAMust,
      },
    ];
  }
}

/* Certificate Object
  selectedCertificate = string,  
  isCertificateAMust = boolean

  */
export function rebuildCertificateFQObject(obj) {
  let selectedCertificate = "";
  let isCertificateAMust = false;
  let certificateId = 0;

  if (parseInt(Object.keys(obj).length)) {
    obj.map((ad) => {
      switch (ad.fq_key) {
        case "selectedCertificate":
          selectedCertificate = ad.fq_value;
          certificateId = ad.fq_sub_type_id;
          break;
        case "isCertificateAMust":
          isCertificateAMust = parseInt(ad.fq_value) === 1 ? true : false;
          break;
        default:
          break;
      }
    });
    return [
      {
        certificateId,
        selectedCertificate,
        isCertificateAMust,
      },
    ];
  }
}

/* Languages Object 
  languageId = number,
  languageName = string,
  languageLevel = string,
  languageIsAMust = bool,
  */
export function rebuildLanguagesFQObject(obj) {
  let localLanguagesFQObject = [];
  let languageId = 0;
  let languageName = "";
  let languageLevel = "";
  let languageIsAMust = false;

  const langReducedArray = obj.reduce((acc, item) => {
    const { fq_sub_type_id } = item;
    acc[fq_sub_type_id] = acc[fq_sub_type_id]
      ? [...acc[fq_sub_type_id], item]
      : [item];
    return acc;
  }, []);
  langReducedArray.map((fqSubType) => {
    languageName = fqSubType.filter((fq) => fq.fq_key === "languageName")[0]
      .fq_value;
    languageId = fqSubType.filter((fq) => fq.fq_key === "languageName")[0]
      .fq_sub_type_id;
    languageLevel = fqSubType.filter((fq) => fq.fq_key === "languageLevel")[0]
      .fq_value;
    languageIsAMust = parseInt(
      fqSubType.filter((fq) => fq.fq_key === "languageIsAMust")[0].fq_value
    );
    localLanguagesFQObject = [
      ...localLanguagesFQObject,
      {
        languageId,
        languageName,
        languageLevel,
        languageIsAMust,
      },
    ];
  });

  return localLanguagesFQObject;
}

export function rebuildDynamicsFQObject(obj) {
  let localDynamicFQObject = [];
  let dynamicId = 0;
  let qType = "";
  let displayQuestion = "";
  let passAnswer = "";
  let isAMust = false;
  obj.map((dynamicItem) => {
    displayQuestion = dynamicItem.dfq_display_question;
    dynamicId = parseInt(dynamicItem.dfq_question_subtype_id);
    passAnswer = dynamicItem.dfq_pass_answer;
    isAMust = parseInt(dynamicItem.dfq_questionIsAMust) === 1 ? true : false;
    qType = dynamicItem.dfq_question_type;
    localDynamicFQObject = [
      ...localDynamicFQObject,
      {
        dynamicId,
        displayQuestion,
        qType,
        isAMust,
        passAnswer,
      },
    ];
  });
  return localDynamicFQObject;
}
