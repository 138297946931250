import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Collapse,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import DashboardHeader from "../../../../../components/JobHeader/DashboardHeader";
import JobLink from "../../../../../components/JobLink/JobLink";
import DistributionsData from "../../../../../components/DistributionsData/DistributionsData";
import Alert from "@material-ui/lab/Alert";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import DropdownBox from "../../../../../components/DropdownBox/DropdownBox";
import JobDetailsViewStep1 from "../../../../../components/JobWizardDetails/JobWizardDetailsStep1";
import JobDetailsViewStep2 from "../../../../../components/JobWizardDetails/JobWizardDetailsStep2";
import JobDetailsViewStep3 from "../../../../../components/JobWizardDetails/JobWizardDetailsStep3";
import { makeStyles, useTheme } from "@material-ui/core/styles";
//import TitleValuePairPrint from "../../../../../components/TitleValuePairPrint/TitleValuePairPrint";
import ConfigurationListsContext from "../../../../../context/configurationListsContext";
import axios from "axios";
import Spinner from "../../../../../UI/Spinner/Spinner";
//import Title from "../../../../../components/Title/Title";
import AxiosErrorHandling from "../../../../../components/AxiosErrorHandling/AxiosErrorHandling";
import { jobTimeLimitOptions } from "../../../../../config/jobsConfigData";
import {
  MED_SMS_BALANCE,
  LOW_SMS_BALANCE,
  SMSTEMPLATES,
} from "../../../../../config/constants";
import {
  rebuildExperienceFQObject,
  rebuildTalentsFQObject,
  rebuildLicensesFQObject,
  rebuildAcademicDegreeFQObject,
  rebuildCertificateFQObject,
  rebuildLanguagesFQObject,
  rebuildDynamicsFQObject,
} from "../../../AddJob/FilterQuestions/RebuildFilterQuestions/RebuildFilterQuestions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),

    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    flex: 1,
    "& .MuiAlert-root": {
      maxWidth: 500,
    },
    "& .MuiFormControl-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& .MuiMenuItem-root": {},
  },
  button: { minWidth: 200 },

  itemsDashboard: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
  },
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexWrap: "warp",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const PublishJobPreview = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [job, setJob] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [jobChanged, setJobChanged] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);
  const [smsTemplate, setSmsTemplate] = React.useState(1);

  const contextData = React.useContext(ConfigurationListsContext);

  React.useEffect(() => {
    axios
      .get(`/wp-json/api/v2/job/${props.job.id}`)
      .then((res) => {
        setJob(res.data);
        setIsLoading(false);
      })
      .catch((errors) => {
        console.log("error", errors);
        setIsLoading(false);
        props.cancel();
      });
  }, []);

  const targetMenuItems = () => {
    let tmi = [];
    for (let i = 0; i < 30; i++) {
      tmi = [
        ...tmi,
        <MenuItem key={(i + 1).toString()} value={i + 1}>
          {(i + 1).toString()}
        </MenuItem>,
      ];
    }
    return tmi;
  };

  const handleJobTimeLimit = (event) => {
    const jobUpdate = { ...job };
    jobUpdate.job_time_limit = event.target.value;
    setJob(jobUpdate);
    setJobChanged(true);
  };

  const handleChangeTemplateSMS = (event) => {
    setSmsTemplate(event.target.value);
  };
  const handleRecruitmentTarget = (event) => {
    const jobUpdate = { ...job };
    jobUpdate.recruitment_target = event.target.value;
    setJob(jobUpdate);
    setJobChanged(true);
  };

  const handlePubllishJob = () => {
    //the following is not async function. It is being sent to the server for execution.
    axios.post(`/wp-json/api/v2/publishJob/${props.job.id}`, {
      template_id: smsTemplate,
      author: localStorage.getItem("userId"),
    });
    setTimeout(() => {
      props.confirm();
    }, 2000);
  };

  const handleSaveJobConfiguration = () => {
    setButtonLoading(true);
    axios
      .post(`/wp-json/api/v2/job_configuration/${props.job.id}`, {
        recruitmentTarget: job.recruitment_target,
        jobTimeLimit: job.job_time_limit,
      })
      .then((res) => {
        //success
        setButtonLoading(false);
        setShowSuccessAlert(true);
        setJobChanged(false);
      })
      .catch((err) => {
        //axios error
        setJobChanged(false);
        setError(err.response);
        setShowSuccessAlert(false);
        setButtonLoading(false);
      });
  };

  const handleCloseSuccessAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccessAlert(false);
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner open={isLoading} />
      ) : (
        <React.Fragment>
          {showSuccessAlert ? (
            <Snackbar
              open={showSuccessAlert}
              autoHideDuration={6000}
              onClose={handleCloseSuccessAlert}
            >
              <Alert
                onClose={handleCloseSuccessAlert}
                severity="success"
                variant="filled"
              >
                Settings saved successfully
              </Alert>
            </Snackbar>
          ) : null}
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Paper className={classes.root}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  lineHeight={"6.2"}
                >
                  {job !== null && <DashboardHeader job={props.job} />}
                  {props.job && (
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                    >
                      <JobLink jobId={job && job.id} />
                      <DistributionsData
                        totalDistributions={props.job.total_distributions}
                        lastPublished={props.job.acf.job_published_date}
                      />
                    </Box>
                  )}
                </Box>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <DropdownBox title="Job description">
                      <JobDetailsViewStep1
                        selectedCompany={job.company ? job.company.name : ""}
                        discreteCompany={job.discrete_company}
                        employmentType={job.employment_type}
                        jobTitle={job.title}
                        jobDescription={job.description}
                        jobReqs={job.job_requirements}
                      />
                    </DropdownBox>
                    <DropdownBox title="Job details">
                      <JobDetailsViewStep2
                        authorName={
                          contextData.users.find(
                            (user) => user.id === parseInt(job.author)
                          ).display_name
                        }
                        coordinatorDisplayName={job.coordinator_name}
                        professions={""}
                        jobExtent={job.job_extent.value}
                        jobArea={job.job_area.value}
                        jobCity={job.city}
                        salaryMin={parseInt(job.minimum_salary)}
                        salaryMax={parseInt(job.maximum_salary)}
                        discreteSalary={job.discrete_salary}
                        isOpenSalaryQuestion={job.open_salary_question}
                        openSalaryValueBy={job.open_salary_value_by}
                      />
                    </DropdownBox>
                    <DropdownBox title="Filter questions">
                      <JobDetailsViewStep3
                        style={{ paddingTop: theme.spacing(2) }}
                        experienceFilterQuestions={rebuildExperienceFQObject(
                          job.filter_questions.filter(
                            (fqItem) => fqItem.fq_type === "experience"
                          )
                        )}
                        talentsFilterQuestions={rebuildTalentsFQObject(
                          job.filter_questions.filter(
                            (fqItem) => fqItem.fq_type === "talents"
                          )
                        )}
                        licensesFilterQuestions={rebuildLicensesFQObject(
                          job.filter_questions.filter(
                            (fqItem) => fqItem.fq_type === "license"
                          )
                        )}
                        languagesFilterQuestions={rebuildLanguagesFQObject(
                          job.filter_questions.filter(
                            (fqItem) => fqItem.fq_type === "languages"
                          )
                        )}
                        academicDegreeFilterQuestions={rebuildAcademicDegreeFQObject(
                          job.filter_questions.filter(
                            (fqItem) => fqItem.fq_type === "academicDegree"
                          )
                        )}
                        certificateFilterQuestions={rebuildCertificateFQObject(
                          job.filter_questions.filter(
                            (fqItem) => fqItem.fq_type === "certificate"
                          )
                        )}
                        dynamicFilterQuestions={rebuildDynamicsFQObject(
                          job.dynamic_filter_questions
                        )}
                      />
                    </DropdownBox>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5">
                      Distribution settings
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Response time limit
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={
                          job.job_time_limit ? parseInt(job.job_time_limit) : 72
                        }
                        onChange={handleJobTimeLimit}
                        label="Limit job response time"
                        autoWidth
                      >
                        <MenuItem value={0}>
                          <em>No limit</em>
                        </MenuItem>
                        {jobTimeLimitOptions.map((jtl) => {
                          return (
                            <MenuItem key={jtl.label} value={jtl.value}>
                              {jtl.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="recruitmentTarget">
                        Recruitment targets
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={
                          job.job_recruitment_target
                            ? job.job_recruitment_target
                            : 1
                        }
                        onChange={handleRecruitmentTarget}
                        label="Recruitment targets"
                        autoWidth
                      >
                        <MenuItem value={0}>
                          <em>No target</em>
                        </MenuItem>
                        {targetMenuItems()}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Box>
                  <div style={{ display: "flex" }}>
                    <Collapse in={jobChanged}>
                      <div className={classes.wrapper}>
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          style={{
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1),
                          }}
                          onClick={handleSaveJobConfiguration}
                        >
                          Save settings
                        </Button>
                        {buttonLoading && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                          />
                        )}
                      </div>
                    </Collapse>
                  </div>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Paper className={classes.root}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h5">
                      SMS Template
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="smsTemplate">
                        SMS Template selection
                      </InputLabel>
                      <Select
                        labelId="smsTemplate"
                        id="simple-smsTemplate"
                        value={smsTemplate}
                        onChange={handleChangeTemplateSMS}
                        label="Select SMS template"
                        autoWidth
                      >
                        <MenuItem value={1}>Default Template</MenuItem>,
                        <MenuItem value={2}>SMS Greek</MenuItem>,
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">Template preview:</Typography>
                    <Typography
                      variant="caption"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {SMSTEMPLATES[smsTemplate - 1]}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
          {error && (
            <AxiosErrorHandling
              title="Error occured while saving settings"
              error={error}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={props.cancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  endIcon={<PublishRoundedIcon />}
                  onClick={handlePubllishJob}
                  style={{ marginLeft: theme.spacing(1) }}
                >
                  {job.published !== null && job.published !== ""
                    ? "Re-distribute"
                    : "Distribute"}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default PublishJobPreview;
