import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import BackgroundImage from "../../Assets/Images/libismart.jpg";
//import axios from "../../utils/axiosInstance";
import axios from "axios";
import Spinner from "../../UI/Spinner/Spinner";
import PackageJson from "../../../package.json";
import Version from "../../components/Version/Version";
import {
  ACCOUNT_REDIRECT_WEBSITE,
  ACCOUNT_DISPLAY_NAME,
} from "../../config/constants";
import BrandLogo from "../../Assets/Images/brand_logo.png";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href={ACCOUNT_REDIRECT_WEBSITE.toString()}>
        {ACCOUNT_DISPLAY_NAME}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    direction: "ltr",
  },
  image: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  const classes = useStyles();
  const [userLogin, setUserLogin] = React.useState("");
  const [userPassword, setUserPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errorType, setErrorType] = React.useState("");
  const [showAlert, setShowAlert] = React.useState(false);
  const [resetPasswordRequest, setResetPasswordRequest] = React.useState(false);
  const [confirmReset, setConfirmReset] = React.useState(false);

  const handleLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    //const credentials = `{username: ${userLogin}, password: ${userPassword}}`;
    const credentials = {
      username: userLogin,
      password: userPassword,
    };
    axios
      .post("/wp-json/jwt-auth/v1/token", credentials)
      .then((res) => {
        setLoading(false);
        if (undefined === res.data.token) {
          setError(true);
          return;
        }
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("userDisplayName", res.data.user_display_name);
        localStorage.setItem("userEmail", res.data.user_email);
        //setToken(res.data.token);
        setShowAlert(true);
        return axios.get("/wp-json/api/v2/user/", {
          params: { email: localStorage.getItem("userEmail") },
        });
      })
      .then((finalReq) => {
        localStorage.setItem("userId", finalReq.data.userId);
        localStorage.setItem("userImage", finalReq.data.profile_image);
        setLoading(false);
        window.location = PackageJson.homepage
          ? PackageJson.homepage + "/jobs"
          : "/jobs";
      })
      .catch((err) => {
        setLoading(false);
        setErrorType("login");
        setError(true);
        //setErrorMessage(err.data);
        setShowAlert(true);
      });
  };

  const handleResetPasswordRequest = async (email) => {
    setLoading(true);
    try {
      await axios.post("/wp-json/api/v2/reset_password", {
        email,
      });
      setLoading(false);
      setResetPasswordRequest(false);
      setConfirmReset(true);
      setError(false);
      setErrorType("");
    } catch (error) {
      setError(error);
      setErrorType("forgotPassword");
      setResetPasswordRequest(false);
      setConfirmReset(false);
      setLoading(false);
    }
  };

  const handleCancleResetPassword = () => {
    setResetPasswordRequest(false);
  };

  const handleForgotPassword = () => {
    setResetPasswordRequest(true);
  };
  return (
    <Grid container component="main" className={classes.root}>
      {loading ? <Spinner open={loading} /> : null}
      <CssBaseline />
      {resetPasswordRequest && (
        <ResetPasswordDialog
          open={resetPasswordRequest}
          onClose={handleCancleResetPassword}
          onConfirm={handleResetPasswordRequest}
        />
      )}
      {confirmReset && (
        <Snackbar
          open={confirmReset}
          autoHideDuration={6000}
          onClose={() => setConfirmReset(false)}
        >
          <Alert onClose={() => setConfirmReset(false)} severity="success">
            If email address is found, a reset link has sent
          </Alert>
        </Snackbar>
      )}
      {error && errorType === "forgotPassword" && (
        <Snackbar
          open={error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
        >
          <Alert onClose={() => setError(null)} severity="error">
            An eror occured during last request. Details:{" "}
            {error.response.data.code}
          </Alert>
        </Snackbar>
      )}
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Box my={2}>
            <img src={BrandLogo} alt="Brand Logo" />
          </Box>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleLogin}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={(userLogin.length > 1 && userLogin.length < 4) || error}
              fullWidth
              helperText={
                userLogin.length > 1 && userLogin.length < 4
                  ? "Username is too short"
                  : ""
              }
              onChange={(e) => setUserLogin(e.target.value)}
              id="email"
              label="Email Address or User Name"
              name="email"
              value={userLogin}
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              error={
                (userPassword.length > 1 && userPassword.length < 4) || error
              }
              onChange={(e) => setUserPassword(e.target.value)}
              fullWidth
              value={userPassword}
              helperText={
                userLogin.length > 1 && userLogin.length < 4
                  ? "Password is too short"
                  : ""
              }
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item>
                <Snackbar
                  open={showAlert}
                  onClose={() => setShowAlert(false)}
                  autoHideDuration={6000}
                >
                  <Alert severity={error ? "error" : "success"}>
                    {error
                      ? "Incorrect credentials. Please try again"
                      : "Successfull Login! You will be directed immediately"}
                  </Alert>
                </Snackbar>
                {/* {errorDetails && (
                  <Alert severity="error">
                    {errorDetails.response.data.code}
                  </Alert>
                )} */}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Link
                  variant="body2"
                  onClick={handleForgotPassword}
                  style={{ cursor: "pointer" }}
                >
                  Forgot password?
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
            <Box mt={5}>
              <Version />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

const ResetPasswordDialog = ({ open, onClose, onConfirm }) => {
  const emailInputRef = React.useRef(null);
  const handleEmailValue = () => {
    onConfirm(emailInputRef.current.value);
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog">
        <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address here. Password reset instructions
            will be send to this email address.
          </DialogContentText>
          <TextField
            autoFocus
            margin="normal"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            inputRef={emailInputRef}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEmailValue} color="primary">
            Get New Password
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
