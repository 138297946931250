import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default class Example extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width={"100%"} height={240}>
        <BarChart
          data={this.props.chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="CoordinatorName" reversed={true} />

          <YAxis />

          <Tooltip />
          <Legend />
          <Bar dataKey="total" fill="#AC92EC" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
