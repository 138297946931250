import React from "react";
import ConfigurationListsContext from "../../../context/configurationListsContext";
import axios from "axios";
import {
  AI_EXPERIENCES,
  AI_SKILLS,
  AI_LICENSES,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_SALARY_EXPECTATIONS,
} from "../../../config/constants";
import { RatingLabel, GenderList } from "../../../config/candidatesConfigData";
import Spinner from "../../../UI/Spinner/Spinner";
import MyModal from "../../../UI/MyModal/MyModal";
import AdditionalInformation from "./AdditionalInformation/AdditionalInformation";
import AIExperience from "./AdditionalInformation/AIExperience/AIExperience";
import AISkills from "./AdditionalInformation/AISkills/AISkills";
import AILicenses from "./AdditionalInformation/AILicenses/AILicenses";
import AIAcademicDegrees from "./AdditionalInformation/AIAcademiDegree/AIAcademiDegree";
import AICertificates from "./AdditionalInformation/AICertificates/AICertificates";
import AILanguages from "./AdditionalInformation/AILanguages/AILanguages";
import AISalaryExpectations from "./AdditionalInformation/AISalaryExpectations/AISalaryExpectations";
import Tags from "../../../components/Tags/Tags";
import SplitButton from "../../../components/SplitButton/SplitButton";
import SearchJob from "../../../components/SearchJob/SearchJob";
import AddCancelButton from "../../../components/AddCancelButtons/AddCancelButton";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Button,
  Box,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import AxiosErrorHandling from "../../../components/AxiosErrorHandling/AxiosErrorHandling";
import { MobilePrefixes } from "../../../config/jobsConfigData";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginTop: theme.spacing(2),
    },
    "& .MuiButton-endIcon": {
      display: "inherit",
      marginLeft: 8,
      marginRight: -4,
    },

    padding: theme.spacing(1),
  },
  layout: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  generalDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "30%",
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
}));

const AddNewCandidate = (props) => {
  const classes = useStyles();
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [selectedFamilyStatus, setSelectedFamilyStatus] = React.useState([]);
  const [candidateEmail, setCandidateEmail] = React.useState("");
  const [yearOfBirthList, setYearOfBirthList] = React.useState([]);
  const [externalId, setExternalId] = React.useState("");
  const [selectedYearOfBirth, setSelectedYearOfBirth] = React.useState("");
  const [selectedProfessions, setSelectedProfessions] = React.useState([]);
  const [selectedGender, setSelectedGender] = React.useState("female");
  const [selectedArea, setSelectedArea] = React.useState([]);
  const [ratingValue, setRatingValue] = React.useState(-1);
  const [mobilePrefix, setMobilePrefix] = React.useState("99");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [candidateDescription, setCandidateDescription] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [source, setSource] = React.useState("");
  const [city, setCity] = React.useState("");
  const [candidateTags, setCandidateTags] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [ratingValueHover, setRatingValueHover] = React.useState(-1);
  const [linkToJob, setLinkToJob] = React.useState(-1);
  const [error, setError] = React.useState(null);
  const [showLinkCandidateToJob, setShowLinkCandidateToJob] = React.useState(
    false
  );
  const [
    candidateIdentifierNumber,
    setCandidateIdentifierNumber,
  ] = React.useState("");
  //const [candidateId, setCandidateId] = React.useState(-1); //always -1 in add mode, or job id in edit mode
  const [isEditMode, setIsEditMode] = React.useState(false); //if this is true, we are in job edit mode
  const [
    additionalInformationModalType,
    setAdditionalInformationModalType,
  ] = React.useState("");
  const [aiExperienceObjectData, setAIExperienceObjectData] = React.useState(
    []
  );
  const [aiSkillsObjectData, setAISkillsObjectData] = React.useState([]);
  const [aiLicensesObjectData, setAILicensesObjectData] = React.useState([]);
  const [
    aiAcademicDegreesObjectData,
    setAIAcademicDegreesObjectData,
  ] = React.useState([]);
  const [
    aiCertificatesObjectData,
    setAICertificatesObjectData,
  ] = React.useState([]);
  const [aiLanguagesObjectData, setAILangagesObjectData] = React.useState([]);
  const [
    aiSalaryExpectationsObjectData,
    setAISalaryExpectationsObjectData,
  ] = React.useState([]);
  const theme = useTheme();
  const areasListContext = React.useContext(ConfigurationListsContext)
    .areasList;
  React.useEffect(() => {
    if (props.editMode > 0) {
      setIsEditMode(true);
      //setCandidateId(props.editMode);
      setAddress(props.candidateData[0].acf.address);
      setCity(props.candidateData[0].acf.city);
      setSource(props.candidateData[0].acf.source);
      setCandidateEmail(props.candidateData[0].acf.email);
      setExternalId(props.candidateData[0].acf.externalNumber);
      setSelectedFamilyStatus(
        props.candidateData[0].acf.family_status
          ? props.familyStatusList.filter(
              (fid) => fid.id === props.candidateData[0].acf.family_status
            )[0]
          : ""
      );
      setSelectedGender(
        props.candidateData[0].acf.gender
          ? props.candidateData[0].acf.gender.toLowerCase()
          : ""
      );
      setCandidateIdentifierNumber(props.candidateData[0].acf.identifierNumber);
      if (props.candidateData[0].acf.mobile) {
        const prefixNumber = props.candidateData[0].acf.mobile.split("-");
        setMobileNumber(prefixNumber[1]);
        setMobilePrefix(prefixNumber[0]);
      }

      setCandidateTags(props.candidateData[0].tags.map((tag) => tag.tag_name));
      setRatingValue(parseInt(props.candidateData[0].acf.rating));
      //source is missing:

      // let areas =
      //   props.candidateData[0].acf.workArea &&
      //   props.candidateData[0].acf.workArea.includes(",")
      //     ? props.candidateData[0].acf.workArea.split(",")
      //     : props.candidateData[0].acf.workArea;
      // //if not array - convert to array
      // if (typeof areas === "string") {
      //   areas === "" ? (areas = []) : (areas = [areas]);
      // }
      // //props.candidateData[0].acf.workArea.split(",");

      // if (areas) {
      //   const localAreas = areas.map((area) =>
      //     areasListContext.find((al) => parseInt(al.id) === parseInt(area))
      //   );
      //   setSelectedArea(localAreas);
      // }

      setCandidateDescription(props.candidateData[0].description);
      setSelectedYearOfBirth(
        props.candidateData[0].acf.yearOfBirth
          ? parseInt(props.candidateData[0].acf.yearOfBirth)
          : ""
      );
      setFirstName(
        props.candidateData[0].name.substring(
          0,
          props.candidateData[0].name.indexOf(" ")
        )
      );

      setLastName(
        props.candidateData[0].name.substring(
          props.candidateData[0].name.indexOf(" ") + 1,
          props.candidateData[0].name.length
        )
      );

      setSelectedProfessions(
        props.candidateData[0].professions
          ? props.candidateData[0].professions
          : ""
      );
      //missing profile Image upload
      //Get Additional Information:
      if (
        props.candidateData[0].candidatesAdditionalInformationUpdatedData &&
        props.candidateData[0].candidatesAdditionalInformationUpdatedData.length
      ) {
        const {
          candidatesAdditionalInformationUpdatedData,
        } = props.candidateData[0];
        candidatesAdditionalInformationUpdatedData.map((aiData) => {
          const keys = Object.keys(aiData)[0];
          const values = Object.values(aiData)[0];
          //
          switch (keys) {
            case AI_EXPERIENCES: {
              setAIExperienceObjectData(
                Object.values(values.typeBySubType).map((exp) => {
                  //build Experince Object:
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let isManagementRole = false;
                  exp.map((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "experienceName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "yearsExperience":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "isManagementRole":
                        isManagementRole =
                          parseInt(singleExp.ai_value) === 1 ? true : false;
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    experienceId: sId,
                    experienceName: sName,
                    yearsExperience: years,
                    isManagementRole: isManagementRole,
                  };
                })
              );
              break;
            }
            case AI_SKILLS: {
              setAISkillsObjectData(
                Object.values(values.typeBySubType).map((aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  let level = false;
                  aiItem.map((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "skillName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "yearsSkill":
                        years = parseInt(singleExp.ai_value);
                        break;
                      case "knowledgeLevel":
                        level = singleExp.ai_value;
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    skillId: sId,
                    skillName: sName,
                    yearsSkill: years,
                    knowledgeLevel: level,
                  };
                })
              );
              break;
            }
            case AI_ACADEMIC_DGREE: {
              setAIAcademicDegreesObjectData(
                Object.values(values.typeBySubType).map((aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let type = "";
                  let dOn = "";
                  aiItem.map((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "degreeName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "degreeType":
                        type = singleExp.ai_value;
                        break;
                      case "degreeOn":
                        dOn = singleExp.ai_value;
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    degreeId: sId,
                    degreeName: sName,
                    degreeType: type,
                    degreeOn: dOn,
                  };
                })
              );
              break;
            }
            case AI_CERTIFICATES: {
              setAICertificatesObjectData(
                Object.values(values.typeBySubType).map((aiItem) => {
                  let sId = 0;
                  let sName = "";
                  aiItem.map((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "certificateName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    certificateId: sId,
                    certificateName: sName,
                  };
                })
              );
              break;
            }

            case AI_LICENSES: {
              setAILicensesObjectData(
                Object.values(values.typeBySubType).map((aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let years = 0;
                  aiItem.map((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "licenseName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "licenseYears":
                        years = parseInt(singleExp.ai_value);
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    licenseId: sId,
                    licenseName: sName,
                    licenseYears: years,
                  };
                })
              );
              break;
            }
            case AI_LANGUAGES: {
              setAILangagesObjectData(
                Object.values(values.typeBySubType).map((aiItem) => {
                  let sId = 0;
                  let sName = "";
                  let level = false;

                  aiItem.map((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "languageName":
                        sName = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "languageLevel":
                        level = singleExp.ai_value;
                        break;
                      default:
                        break;
                    }
                  });
                  return {
                    languageId: sId,
                    languageName: sName,
                    languageLevel: level,
                  };
                })
              );
              break;
            }
            case AI_SALARY_EXPECTATIONS: {
              setAISalaryExpectationsObjectData(
                Object.values(values.typeBySubType).map((aiItem) => {
                  let sId = 0;
                  let sRole = "";
                  let sRange = [];
                  let sIsRange = false;
                  let sMeasure = "";

                  aiItem.map((singleExp) => {
                    switch (singleExp.ai_key) {
                      case "seForRole":
                        sRole = singleExp.ai_value;
                        sId = singleExp.ai_sub_type_id;
                        break;
                      case "seIsRange":
                        sIsRange = singleExp.ai_value === "true" ? true : false;
                        break;
                      case "seRange":
                        sRange = singleExp.ai_value.includes("-")
                          ? singleExp.ai_value.split("-")
                          : parseFloat(singleExp.ai_value);
                        break;
                      case "seMeasure":
                        sMeasure = singleExp.ai_value;
                        break;
                      default:
                        break;
                    }
                  });

                  return {
                    seId: sId,
                    seForRole: sRole,
                    seIsRange: sIsRange,
                    seRange: sRange,
                    seMeasure: sMeasure,
                  };
                })
              );
              break;
            }
            default:
              break;
          }
          //
        });
      }
    }

    // in case of edit mode - get the data from the server:
  }, [props.editMode, props.candidateAIData, areasListContext]);

  React.useEffect(() => {
    const uptoyear = new Date().getFullYear() - 10;
    let years = [];
    for (let i = 1950; i <= uptoyear; i++) {
      years = [...years, i];
    }
    setYearOfBirthList(years);
  }, []);

  const handleAdditionalInformationClick = (event, type) => {
    setAdditionalInformationModalType(type);
  };

  const handleAddNewtag = (tag) => {
    setCandidateTags([...candidateTags, tag.trim()]);
  };

  const handleRemoveTag = (tag) => {
    setCandidateTags(candidateTags.filter((t) => t !== tag));
  };

  const handleAdditionalInformationObject = (value) => {
    switch (additionalInformationModalType) {
      case AI_EXPERIENCES:
        setAIExperienceObjectData(value);
        break;
      case AI_SKILLS:
        setAISkillsObjectData(value);
        break;
      case AI_LICENSES:
        setAILicensesObjectData(value);
        break;
      case AI_ACADEMIC_DGREE:
        setAIAcademicDegreesObjectData(value);
        break;
      case AI_CERTIFICATES:
        setAICertificatesObjectData(value);
        break;
      case AI_LANGUAGES:
        setAILangagesObjectData(value);
        break;
      case AI_SALARY_EXPECTATIONS:
        setAISalaryExpectationsObjectData(value);
        break;
      default:
        break;
    }
    setAdditionalInformationModalType("");
  };

  const handleGroupSelect = (event, value) => {
    if (value.value === "doneAndLink") {
      setShowLinkCandidateToJob(true);
    } else {
      postNewCandidate();
    }
  };

  const postNewCandidate = async () => {
    setIsLoading(true);
    const candidateData = {
      title: `${firstName} ${lastName}`,
      fields: {
        first_name: firstName,
        last_name: lastName,
        email: candidateEmail,
        work_in_area: selectedArea.map((sa) => sa.id).join(", "),
        candidate_external_number: externalId,
        identifier_number: candidateIdentifierNumber,
        year_of_birth: selectedYearOfBirth,
        address: address,
        family_status: selectedFamilyStatus ? selectedFamilyStatus.id : "",
        telephone_main: `${mobilePrefix}-${mobileNumber}`,
        is_active: "active",
        city,
        source,
        gender: selectedGender,
        rating: ratingValue,
      },
      "jobs-sectors": selectedProfessions.map((prof) => prof.id).join(", "),
      content: candidateDescription,
      status: "publish",
    };
    const addOrUpdateUrl =
      props.editMode > 1
        ? `/wp-json/wp/v2/candidates/${props.editMode}`
        : "/wp-json/wp/v2/candidates";
    axios
      .post(addOrUpdateUrl, candidateData)
      .then((candidateRes) => {
        return axios
          .post("/wp-json/api/v2/updateCandidateAdditionalInformation", {
            candidateId: parseInt(candidateRes.data.id),
            candidateTags,
            aiExperienceObjectData,
            aiCertificatesObjectData,
            aiSkillsObjectData,
            aiLicensesObjectData,
            aiAcademicDegreesObjectData,
            aiLanguagesObjectData,
            aiSalaryExpectationsObjectData,
          })
          .then((rsAfter) => {
            if (linkToJob > -1) {
              return axios
                .post(`/wp-json/api/v2/link_multiples/${linkToJob}`, {
                  candidateIds: [parseInt(candidateRes.data.id)],
                  authorId: parseInt(localStorage.getItem("userId")),
                })
                .then(() => props.addedSuccessfully());
            }
            props.addedSuccessfully();
          })
          .catch((err) => {
            props.closeOnError(err, err.response);
          });
      })
      .catch((err) => {
        props.closeOnError(err, err.response);
      });
  };

  function filterQuestionModalDetect() {
    switch (additionalInformationModalType) {
      case AI_EXPERIENCES:
        return (
          <AIExperience
            professionsList={props.professionsList}
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiExperienceObjectData}
          />
        );
      case AI_SKILLS: {
        return (
          <AISkills
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiSkillsObjectData}
          />
        );
      }
      case AI_LICENSES: {
        return (
          <AILicenses
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiLicensesObjectData}
          />
        );
      }
      case AI_ACADEMIC_DGREE: {
        return (
          <AIAcademicDegrees
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiAcademicDegreesObjectData}
          />
        );
      }
      case AI_CERTIFICATES: {
        return (
          <AICertificates
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiCertificatesObjectData}
          />
        );
      }
      case AI_LANGUAGES: {
        return (
          <AILanguages
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiLanguagesObjectData}
          />
        );
      }
      case AI_SALARY_EXPECTATIONS: {
        return (
          <AISalaryExpectations
            candidateFullName={`${firstName} ${lastName}`}
            addAI={handleAdditionalInformationObject}
            closeModal={() => setAdditionalInformationModalType("")}
            aIValue={aiSalaryExpectationsObjectData}
          />
        );
      }

      default:
        break;
    }
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <Spinner open={isLoading} />
      ) : (
        <React.Fragment>
          <AdditionalInformation
            clicked={handleAdditionalInformationClick}
            experienceCount={Object.keys(aiExperienceObjectData).length}
            skillsCount={Object.keys(aiSkillsObjectData).length}
            licensesCount={Object.keys(aiLicensesObjectData).length}
            academicDegreeCount={
              Object.keys(aiAcademicDegreesObjectData).length
            }
            certificateCount={Object.keys(aiCertificatesObjectData).length}
            languagesCount={Object.keys(aiLanguagesObjectData).length}
            salaryExpectationsCount={
              Object.keys(aiSalaryExpectationsObjectData).length
            }
          />
          <div className={clsx(classes.root, classes.layout)}>
            <Grid container spacing={3}>
              {error && (
                <AxiosErrorHandling
                  title="An error occured during update"
                  error={error}
                />
              )}
              <Grid item lg={12}>
                <Paper className={classes.paper}>
                  <div className={classes.generalDetails}>
                    <Typography
                      variant="h5"
                      color="primary"
                      className={classes.typography}
                    >
                      {isEditMode
                        ? `Update ${firstName} ${lastName}`
                        : "Add Candidate"}
                    </Typography>
                    <div
                      className={classes.rating}
                      style={{ width: "30%", justifyContent: "flex-end" }}
                    >
                      {ratingValue !== null && (
                        <Box ml={2} style={{ marginLeft: theme.spacing(2) }}>
                          {
                            RatingLabel[
                              ratingValueHover !== -1
                                ? ratingValueHover
                                : ratingValue
                            ]
                          }
                        </Box>
                      )}
                      <Rating
                        name="hover-feedback"
                        value={ratingValue}
                        precision={1}
                        onChange={(event, newValue) => {
                          setRatingValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setRatingValueHover(newHover);
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={classes.layout}
                    style={{ marginTop: theme.spacing(2) }}
                  >
                    <Tags
                      AddTags={handleAddNewtag}
                      tags={candidateTags}
                      onChipDelete={handleRemoveTag}
                    />
                  </div>
                  <div
                    className={classes.layout}
                    style={{ position: "relative" }}
                  >
                    <TextField
                      required
                      label="First Name"
                      id="firstName"
                      margin="normal"
                      onBlur={(event) =>
                        setFirstName(event.target.value.trim())
                      }
                      variant="outlined"
                      value={firstName}
                      style={{ width: "30%" }}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                    <TextField
                      required
                      label="Last Name"
                      id="lastName"
                      onBlur={(event) => setLastName(event.target.value.trim())}
                      margin="normal"
                      variant="outlined"
                      style={{ width: "30%", marginLeft: theme.spacing(2) }}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <TextField
                      style={{ position: "absolute", right: 0 }}
                      label="External ID"
                      id="externalID"
                      margin="normal"
                      size="small"
                      variant="filled"
                      value={externalId}
                      onChange={(e) => setExternalId(e.target.value)}
                    />
                  </div>
                  <div className={classes.generalDetails}>
                    <TextField
                      label="Identifier Number"
                      id="candidateID"
                      margin="normal"
                      variant="outlined"
                      value={candidateIdentifierNumber}
                      style={{ width: "30%" }}
                      onChange={(e) =>
                        setCandidateIdentifierNumber(e.target.value)
                      }
                    />

                    <Autocomplete
                      options={props.familyStatusList}
                      id="familyStatus"
                      style={{ width: "30%" }}
                      getOptionLabel={(option) => option.value}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      value={
                        selectedFamilyStatus &&
                        selectedFamilyStatus !== null &&
                        Object.keys(selectedFamilyStatus).length
                          ? props.familyStatusList.filter(
                              (item) => selectedFamilyStatus.id === item.id
                            )[0]
                          : null
                      }
                      onChange={(e, value) => {
                        setSelectedFamilyStatus(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          label="Family Status"
                          variant="outlined"
                        />
                      )}
                    />
                    <Autocomplete
                      options={yearOfBirthList}
                      id="yearOfBirth"
                      style={{ width: "30%" }}
                      getOptionLabel={(option) => option.toString()}
                      value={selectedYearOfBirth}
                      onChange={(e, value) => {
                        setSelectedYearOfBirth(value);
                      }}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          label="Year of birth"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                  <div className={classes.generalDetails}>
                    <FormControl
                      variant="outlined"
                      style={{
                        width: "30%",
                        marginTop: theme.spacing(2),
                        textAlign: "left",
                      }}
                    >
                      <InputLabel htmlFor="outlined-adornment-email">
                        Email
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-email"
                        value={candidateEmail}
                        onChange={(e) => setCandidateEmail(e.target.value)}
                        startAdornment={
                          <InputAdornment position="start">@</InputAdornment>
                        }
                        labelWidth={60}
                      />
                    </FormControl>

                    <TextField
                      label="Address"
                      id="candidateAddressId"
                      margin="normal"
                      variant="outlined"
                      value={address ? address : ""}
                      style={{ width: "30%" }}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                    <TextField
                      label="City"
                      id="city"
                      margin="normal"
                      variant="outlined"
                      value={city}
                      style={{ width: "30%" }}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className={classes.layout}>
                    <div style={{ display: "flex" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        style={{
                          width: 80,
                          marginTop: theme.spacing(2),
                          marginLeft: 0,
                          marginRight: theme.spacing(1),
                        }}
                      >
                        <InputLabel id="demo-customized-select-label">
                          Prefix
                        </InputLabel>
                        <Select
                          labelId="mobileprefix"
                          id="mobileprefixlabel"
                          value={mobilePrefix}
                          onChange={(e) => setMobilePrefix(e.target.value)}
                          label="Prefix"
                        >
                          {MobilePrefixes.map((prefix) => (
                            <MenuItem key={prefix.label} value={prefix.label}>
                              {prefix.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        margin="normal"
                        label="Mobile"
                        variant="outlined"
                        helperText="Mobile number without prefix"
                        onChange={(e) => setMobileNumber(e.target.value)}
                        value={mobileNumber}
                        style={{ marginRight: theme.spacing(2) }}
                      />
                    </div>
                    <ConfigurationListsContext.Consumer>
                      {(context) => (
                        <Autocomplete
                          options={context.areasList.sort(
                            (a, b) => a.parent_id > b.parent_id
                          )}
                          getOptionLabel={(area) => area.value}
                          limitTags={2}
                          multiple
                          id="area"
                          style={{ flex: 1 }}
                          value={
                            selectedArea.length > 0
                              ? selectedArea.map(
                                  (sp) =>
                                    context.areasList.filter(
                                      (item) => item.id === sp.id
                                    )[0]
                                )
                              : []
                          }
                          onChange={(e, value) => setSelectedArea(value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              margin="normal"
                              value={
                                selectedArea !== null
                                  ? Object.values(selectedArea)
                                  : null
                              }
                              label="Working area"
                              variant="outlined"
                            />
                          )}
                        />
                      )}
                    </ConfigurationListsContext.Consumer>

                    <ConfigurationListsContext.Consumer>
                      {(context) => (
                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          limitTags={2}
                          options={context.professionsList.sort(
                            (a, b) => a.parent_id > b.parent_id
                          )}
                          //groupBy={(options) => options.parent_id}
                          getOptionLabel={(prof) => prof.name}
                          style={{ marginLeft: theme.spacing(2), flex: 1 }}
                          onChange={(e, value) => setSelectedProfessions(value)}
                          //value={Object.values(selectedProfessions)}
                          value={selectedProfessions.map(
                            (sp) =>
                              context.professionsList.filter(
                                (item) => item.id === sp.id
                              )[0]
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Relevant Professions"
                              placeholder="Professions"
                            />
                          )}
                        />
                      )}
                    </ConfigurationListsContext.Consumer>
                  </div>
                  <div className={classes.layout}>
                    <div style={{ width: "15%" }}>
                      <FormControl
                        component="fieldset"
                        style={{ margin: theme.spacing(2) }}
                        variant="outlined"
                      >
                        <FormLabel component="legend">Gender</FormLabel>
                        <RadioGroup
                          aria-label="Gender"
                          name="gender1"
                          value={selectedGender}
                          onChange={(e) => setSelectedGender(e.target.value)}
                        >
                          {Object.keys(GenderList).map((gender) => (
                            <FormControlLabel
                              key={gender}
                              value={gender}
                              control={<Radio color="primary" />}
                              label={GenderList[gender]}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <TextField
                      id="job-requirements"
                      label="More information"
                      multiline
                      style={{ flex: 1 }}
                      onChange={(e) => setCandidateDescription(e.target.value)}
                      rows={5}
                      InputLabelProps={{
                        shrink: candidateDescription !== "",
                      }}
                      defaultValue={candidateDescription}
                      variant="outlined"
                    />
                  </div>
                  <div style={{ marginTop: theme.spacing(2) }}>
                    <SplitButton
                      onSelect={handleGroupSelect}
                      options={[
                        {
                          label: isEditMode
                            ? "Update Candidate "
                            : "Add Candidate",
                          value: "done",
                        },
                        {
                          label: isEditMode ? "Update & Link " : "Add & Link",
                          value: "doneAndLink",
                        },
                      ]}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.cancel}
                      onClick={props.cancelNewCandidate}
                    >
                      Cancel
                    </Button>
                  </div>
                </Paper>
              </Grid>
            </Grid>
            <MyModal
              openModal={additionalInformationModalType !== ""}
              closeModal={() => setAdditionalInformationModalType("")}
            >
              {filterQuestionModalDetect()}
            </MyModal>
            <MyModal
              openModal={showLinkCandidateToJob}
              closeModal={() => setShowLinkCandidateToJob(false)}
            >
              <React.Fragment>
                <SearchJob
                  searchMode
                  linkToJobId={(id) => setLinkToJob(id)}
                  className={classes.topMargin}
                />
                <div>
                  <AddCancelButton
                    addCaption="Link Candidate"
                    cancelCaption="Cancel"
                    cancelClick={() => {
                      setLinkToJob(-1);
                      setShowLinkCandidateToJob(false);
                    }}
                    addClick={() => {
                      setShowLinkCandidateToJob(false);
                      postNewCandidate();
                    }}
                  />
                </div>
              </React.Fragment>
            </MyModal>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default AddNewCandidate;
