import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  useTheme,
  Button,
  Collapse,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #E4E4EA",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 4,
  },
}));

const DropdownBox = ({ title, ...props }) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const handleExpand = () => {
    setExpanded(!expanded);
  };
  return (
    <Box
      my={theme.spacing(2)}
      className={classes.root}
      px={theme.spacing(2)}
      py={theme.spacing(1)}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="body1" color="primary" style={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleExpand}>
          Show Details <ExpandMoreRoundedIcon />
        </Button>
      </Box>
      <Collapse
        in={expanded}
        style={{ marginBlock: expanded ? theme.spacing(2) : 0 }}
      >
        {props.children}
      </Collapse>
    </Box>
  );
};
export default DropdownBox;
