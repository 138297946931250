export const Primary = {
  main: "#4E2B7B",
  light: "#EBE3F5",
  dark: "#341D52",
  contrastText: "#fff",
};

export const Secondary = {
  main: "#3184C6",
  light: "#8ABBE2",
  dark: "#1B496E",
  contrastText: "#fff",
};

export const AppBarBackground = {
  main: "#fff",
};
