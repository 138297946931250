import React, { useRef } from "react";
import {
  Box,
  List,
  Avatar,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ProfileProgressLinear from "../../../../components/Profile Progress/ProfileProgress";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PeopleIcon from "@material-ui/icons/People";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import ProfileStregthContext from "../../../../context/profileStrengthContext";
import NavItem from "./NavItem";
import { FormattedMessage, useIntl } from "react-intl";
const items = [
  {
    href: "lobby",
    icon: DashboardIcon,
    title: "Dashboard",
    localizationId: "candidateProfile.menu.lobby",
  },
  {
    href: "aboutMe",
    icon: PeopleIcon,
    title: "About Me",
    localizationId: "candidateProfile.menu.aboutMe",
  },
  {
    href: "looking",
    icon: ShoppingCartIcon,
    title: "Looking For",
    localizationId: "candidateProfile.menu.looking",
  },
  {
    href: "settings",
    icon: SettingsRoundedIcon,
    title: "Settings",
    localizationId: "candidateProfile.menu.settings",
  },
];

const NavBarItems = ({
  onFileChange,
  profileImage,
  initials,
  profileFullName,
  onLogout,
  onMenuSelect,
  currentActive,
}) => {
  const [showEdit, setShowEdit] = React.useState(false);
  const hiddenFileInput = useRef(null);
  const intl = useIntl();
  const theme = useTheme();
  const handleSelectFile = (e) => {
    hiddenFileInput.current.click();
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={1}
        mt={6}
      >
        <Box
          onMouseOver={() => setShowEdit(true)}
          onMouseLeave={() => setShowEdit(false)}
          position="relative"
        >
          <React.Fragment>
            <input
              type="file"
              onChange={onFileChange}
              ref={hiddenFileInput}
              accept={".jpg,.gif, .png"}
              style={{ display: "none" }}
            />
            <Avatar src={profileImage}>{initials}</Avatar>

            {showEdit && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  borderRadius: "50%",
                  height: "calc(100% - 8px)",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  left: 0,
                  backgroundColor: "rgba(0,0,0,.2)",
                  zIndex: 10,
                }}
              >
                <IconButton aria-label="edit" onClick={handleSelectFile}>
                  <EditRoundedIcon color="secondary" fontSize="inherit" />
                </IconButton>
              </div>
            )}
          </React.Fragment>
        </Box>
        <Typography color="textPrimary" variant="h5">
          {profileFullName}
        </Typography>
        <Button color="secondary" onClick={onLogout}>
          <FormattedMessage id="app.logout" defaultMessage="Logout" />
        </Button>
        <div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ProfileStregthContext.Consumer>
            {(context) => {
              return (
                <ProfileProgressLinear
                  value={context.score}
                  indicator={context.indicator}
                />
              );
            }}
          </ProfileStregthContext.Consumer>
        </div>
      </Box>
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              style={{ marginBottom: theme.spacing(1) }}
              href={item.href}
              key={item.title}
              title={intl.formatMessage({
                id: item.localizationId,
                defaultMessage: item.title,
              })}
              icon={item.icon}
              onMenuSelect={onMenuSelect}
              currentActive={currentActive}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};
export default NavBarItems;
