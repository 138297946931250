import axios from "axios";
export const AreasList = {
  0: "All country",
  601: "South",
  602: "North",
  603: "East",
  604: "West",
};
export const JobExtentList = {
  fulltime: "Full Time",
  parttime: "Part Time",
  temporary: "Temporary Job",
  student: "Student",
};

export const CurrencySign = {
  name: "Euro",
  value: "&euro;",
  hex: "&#8364;",
  symbol: "€",
};

export const JobExtentListModified = [
  { id: 0, name: "Full Time", value: "fulltime" },
  { id: 1, name: "Part Time", value: "parttime" },
  { id: 2, name: "Temporary Job", value: "temporary" },
];

export const SkillLevels = [
  {
    id: 0,
    label: "None",
    value: "none",
    localizationId: "jobs.fq.levels.none",
  },
  {
    id: 1,
    label: "Basic",
    value: "basic",
    localizationId: "jobs.fq.levels.basic",
  },
  {
    id: 2,
    label: "Average",
    value: "average",
    localizationId: "jobs.fq.levels.average",
  },
  {
    id: 3,
    label: "Advanced",
    value: "advanced",
    localizationId: "jobs.fq.levels.advanced",
  },
  {
    id: 4,
    label: "Expert",
    value: "expert",
    localizationId: "jobs.fq.levels.expert",
  },
];

export function translateSkillLevel(value) {
  const index = SkillLevels.findIndex((level) => level.value === value);
  if (index >= 0) return SkillLevels[index].label;
  return "";
}

export const LanguageLevels = [
  {
    id: 0,
    label: "None",
    value: "none",
    localizationId: "jobQuestionnier.Language.0",
  },
  {
    id: 1,
    label: "Basic",
    value: "basic",
    localizationId: "jobQuestionnier.Language.1",
  },
  {
    id: 2,
    label: "Average",
    value: "average",
    localizationId: "jobQuestionnier.Language.2",
  },
  {
    id: 3,
    label: "Good",
    value: "good",
    localizationId: "jobQuestionnier.Language.3",
  },
  {
    id: 4,
    label: "Very Good",
    value: "veryGood",
    localizationId: "jobQuestionnier.Language.4",
  },
  {
    id: 5,
    label: "Mother Tongue",
    value: "motherTounge",
    localizationId: "jobQuestionnier.Language.5",
  },
];

export const JobActionsMenuOptions = [
  { id: "editJob", label: "Edit Job" },
  { id: "uploadCandidates", label: "Upload candidates" },
  { id: "duplicate", label: "Duplicate job" },
  { id: "archive", label: "Archive" },
  { id: "closeJob", label: "Close Job" },
  { id: "report", label: "View Report" },
];

export const JobDeclinedReasonsOptions = [
  { id: "salary", label: "Offered salary" },
  { id: "location", label: "Job location" },
  { id: "jobRequirements", label: "Job requirements" },
  { id: "employed", label: "Already employed" },
  { id: "other", label: "Other" },
];

export const MobilePrefixes = [
  { id: 1, label: "09" },
  { id: 2, label: "99" },
  { id: 3, label: "97" },
  { id: 4, label: "96" },
  { id: 5, label: "95" },
];

export const UnsubscribeReasons = [
  { id: "alreadyEmployed", label: "Already Employed" },
  { id: "signUp", label: "I don't remember signing up" },
  { id: "unqualifiedJobs", label: "The jobs offered are not qualified" },
  { id: "other", label: "Other" },
];

export const answersValues = [
  {
    id: 1,
    label: "No Experience",
    value: "0",
    localizationId: "jobQuestionnier.experienceValues.1",
  },
  {
    id: 2,
    label: "One year",
    value: "1",
    localizationId: "jobQuestionnier.experienceValues.2",
  },
  {
    id: 3,
    label: "Two years",
    value: "2",
    localizationId: "jobQuestionnier.experienceValues.3",
  },
  {
    id: 4,
    label: "3-4 years",
    value: "3-4",
    localizationId: "jobQuestionnier.experienceValues.4",
  },
  {
    id: 5,
    label: "5 years or more",
    value: "5+",
    localizationId: "jobQuestionnier.experienceValues.5",
  },
];

export const dateFilters = [
  { label: "All times", id: "alltimes" },
  { label: "Last 7 days", id: "lastSevenDays" },
  { label: "Last 14 days", id: "lastFourteenDays" },
  { label: "Beginning of month", id: "thisMonth" },
  { label: "Last Month", id: "lastMonth" },
  { label: "Beginning of year", id: "thisYear" },
];

export const Cities = [
  { id: 1, name: "Famagusta" },
  { id: 2, name: "Limassol" },
  { id: 3, name: "Larnaca" },
  { id: 4, name: "Nicosia" },
  { id: 5, name: "Paphos" },
];

export function translateLanguageLevel(level) {
  switch (level) {
    case "none":
      return "None";
    case "basic":
      return "Basic";
    case "average":
      return "Basic";
    case "good":
      return "Good";
    case "veryGood":
      return "Very Good";
    case "motherTounge":
      return "Monther Tongue";
    default:
      return "None";
  }
}

export const AddEventHandler = async (
  type,
  value,
  additionalInfo = "",
  isUpdateMode = false,
  candidateId,
  authorId
) => {
  if (type === "sendInvitation" && value) {
    axios.post(`/wp-json/api/v2/publishjob/${value}`, {
      author: authorId ? authorId : 1,
      single_id: candidateId,
    });
  }
  try {
    const res = await axios.post(
      `/wp-json/api/v2/candidate_events/${candidateId}`,
      {
        eventKey: type,
        eventValue: value,
        eventAuthor: authorId,
        additionalInfo: additionalInfo,
        isUpdateMode,
      }
    );
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export const DegreesList = [
  { value: "firstDegree", label: "First Degree" },
  { value: "secondDegree", label: "Second Degree" },
  { value: "doctor", label: "Doctor" },
  { value: "engineer", label: "Engineer" },
  { value: "practicalEngineer", label: "Practical Engineer" },
  { value: "technition", label: "Technician" },
];

export function translateDegreeAndType(degree) {
  switch (degree.trim()) {
    case "first":
    case "firstDegree":
      return "First Degree";
    case "second":
    case "secondDegree":
      return "Second Degree";
    case "doctor":
      return "Doctor";
    case "engineer":
      return "Engineer";
    case "practicalEngineer":
      return "Practical Engineer";
    case "technition":
      return "Technician";
    default:
      return "";
  }
}

export function reverseDegreeTranslation(value) {
  switch (value) {
    case "First Degree":
      return "firstDegree";
    case "Second Degree":
      return "secondDegree";
    case "Doctor":
      return "doctor";
    case "Engineer":
      return "engineer";
    case "Practical Engineer":
      return "practicalEngineer";
    case "Technician":
      return "technition";
    default:
      return "";
  }
}

export const tracerSources = [
  { label: "Facebook", id: 1 },
  { label: "Instagram", id: 2 },
  { label: "LinkedIn", id: 3 },
  { label: "Google", id: 4 },
  { label: "Other", id: 5 },
];

export const TabsList = [
  {
    id: "suitable",
    label: "Suitable Candidates",
    showBeforeDistribution: false,
  },
  { id: "rejected", label: "Auto Disqualified", showBeforeDistribution: false },
  { id: "all", label: "All Candidates", showBeforeDistribution: true },
  { id: "cvReferrals", label: "CV To Customer", showBeforeDistribution: false },
  {
    id: "responseRate",
    label: "Response Rate Stats",
    showBeforeDistribution: false,
  },
];

export const jobTimeLimitOptions = [
  {
    value: 3,
    label: "3 Hours",
  },
  {
    value: 6,
    label: "6 Hours",
  },
  {
    value: 12,
    label: "12 Hours",
  },
  {
    value: 24,
    label: "24 Hours",
  },
  {
    value: 48,
    label: "Two days",
  },
  {
    value: 72,
    label: "Three days",
  },
  {
    value: 168,
    label: "Week",
  },
  {
    value: 240,
    label: "Ten days",
  },
  {
    value: 336,
    label: "Two weeks",
  },
];

export const MySearchesHeadCells = [
  {
    id: "id",
    numeric: true,
    label: "#",
  },
  { id: "name", numeric: false, label: "Search Name" },
  { id: "createdAt", numeric: false, label: "Created at" },
  { id: "activities", numeric: false, label: "Actions" },
];

export const FilterBy = [
  { id: "all", label: "All Candidates" },
  { id: "watched", label: "Watched" },
  { id: "notSent", label: "Not Invited" },
  { id: "watchedNotResponded", label: "Watched Not Responded" },
  { id: "interested", label: "Interested" },
  { id: "suitable", label: "Suitables" },
  { id: "notInterested", label: "Not Interested" },
  { id: "autoRejected", label: "Auto Disqualified" },
  { id: "cvReferrals", label: "CV Referrals" },
  { id: "newSuitables", label: "New Suitables" },
  { id: "sendToInterview", label: "Interview Invitations" },
  { id: "recruited", label: "Recruited" },
  { id: "blocked", label: "Blocked" },
  { id: "unsubscribed", label: "Unsubscribed" },
];

export const JobsCacheRefresh = 600000; //10 minutes by default;
