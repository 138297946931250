import React from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { pink } from "@material-ui/core/colors";
import DeleteSweepRoundedIcon from "@material-ui/icons/DeleteSweepRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    margin: theme.spacing(1),
  },
  activeRow: {
    color: theme.palette.grey[700],
  },
  cvBox: {
    display: "flex",
    flex: 1,
    backgroundColor: pink[50],
    borderRadius: 30,
    padding: "4px 8px",
    color: theme.palette.secondary.main,
    "& .MuiIconButton-root": {
      color: theme.palette.secondary.main,
    },
    "& .MuiTypography-root": {
      fontSize: 14,
      overflow: "ellipsis",
    },
  },
}));

const CVRow = ({ id, description, link, remove, uploading, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="space-between"
        justifyContent="flex-start"
        className={classes.root}
        my={theme.spacing(2)}
        // px={theme.spacing(2)}
        // py={theme.spacing(1)}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.cvBox}>
              <div
                style={{
                  width: "calc(100% - 60px)",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography gutterBottom={false} variant="body1">
                  {!uploading ? (
                    description
                  ) : (
                    <FormattedMessage
                      id="candidateProfile.cv.uploadingWaiting"
                      defaultMessage="Uploading your CV..."
                    />
                  )}
                </Typography>
              </div>
              {!uploading ? (
                <Box
                  display="flex"
                  alignItems="center"
                  width={50}
                  justifyContent="space-between"
                >
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <IconButton
                      aria-label="download"
                      size="small"
                      //onClick={() => download(id)}
                    >
                      <VisibilityRoundedIcon fontSize="small" />
                    </IconButton>
                  </a>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => remove(id)}
                  >
                    <DeleteSweepRoundedIcon fontSize="small" />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  width={50}
                  justifyContent="flex-end"
                >
                  <CircularProgress color="secondary" size={20} />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default CVRow;
