import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography, useTheme } from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const DistributionReport = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(true);
  const [summery, setSummery] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(`wp-json/api/v2/distribution_report/${props.jobId}`)
      .then((res) => {
        setSummery(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, []);
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant="body1" color="textSecondary">
          Distributions Summary
        </Typography>
        {isLoading ? (
          <CircularProgress color="secondary" />
        ) : summery.length === 0 ? (
          <Typography variant="caption">
            No distribution history for this job
          </Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Total Candidates</TableCell>
                  <TableCell>Outgoing SMS</TableCell>
                  <TableCell>Outgoing Emails</TableCell>
                  <TableCell>WA Invitations</TableCell>
                  <TableCell>Distributed By</TableCell>
                  <TableCell>Distributed At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summery.map((row) => (
                  <TableRow key={row.distribution_id}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={`${props.location.pathname}/distribution/${row.distribution_id}`}
                        style={{ color: theme.palette.primary.main }}
                      >
                        {row.distribution_id}
                      </Link>
                    </TableCell>
                    <TableCell>{row.total_candidates}</TableCell>
                    <TableCell>{row.sms}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.wa}</TableCell>
                    <TableCell>{row.distributed_by}</TableCell>
                    <TableCell>{row.distribution_date}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
export default withRouter(DistributionReport);
