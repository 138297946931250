import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SingleProfileJob from "./SingleProfileJob";
import moment from "moment";
import JobRating from "./JobRating";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const isJobLive = (expiration) => {
  return moment().isAfter(expiration) ? false : true;
};

const CandidateJobs = ({ jobs, token, onJobRating }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h4">
            <FormattedMessage
              id="candidateProfile.lobby.liveJobs"
              defaultMessage="Live Jobs"
            />
          </Typography>
        </Grid>
        {jobs
          .filter(
            (isLive) =>
              isJobLive(isLive.expiration) &&
              isLive.published &&
              !isLive.answered_by_candidate
          )
          .map((job) => {
            return (
              <Grid item xs={12} md={6} lg={4} key={job.id}>
                <SingleProfileJob
                  jobId={job.id}
                  jobTitle={job.title}
                  descreteCompany={job.discrete_company}
                  company={job.company.name}
                  descreteSalary={job.discrete_salary}
                  minSalary={job.minimum_salary}
                  city={job.city}
                  jobExtent={job.job_extent.value}
                  published={job.published}
                  jobTimeLimit={job.job_time_limit}
                  jobExpiration={job.expiration}
                  serverTime={job.server_time}
                  isAnswered={job.answered_by_candidate}
                  jobToken={token}
                />
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <Typography color="primary" variant="h4">
            <FormattedMessage
              id="candidateProfile.lobby.historyJobs"
              defaultMessage="Jobs History"
            />
          </Typography>
        </Grid>

        {jobs
          .filter(
            (isLive) =>
              (!isJobLive(isLive.expiration) && isLive.published) ||
              isLive.answered_by_candidate
          )
          .map((job) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={job.id}>
                <SingleProfileJob
                  jobTitle={job.title}
                  jobId={job.id}
                  descreteCompany={job.discrete_company}
                  company={job.company.name}
                  descreteSalary={job.discrete_salary}
                  minSalary={job.minimum_salary}
                  city={job.city}
                  jobExtent={job.job_extent.value}
                  published={job.published}
                  jobTimeLimit={job.job_time_limit}
                  jobExpiration={job.expiration}
                  serverTime={job.server_time}
                  isAnswered={job.answered_by_candidate}
                />
                <Box my={theme.spacing(1)}>
                  <Typography variant="body1">
                    <FormattedMessage
                      id="candidateProfile.profile.suitableJobs"
                      defaultMessage="How was this job offer?"
                    />
                  </Typography>
                  <JobRating
                    jobId={job.id}
                    rating={job.job_candidate_rating}
                    onJobRating={(value) => onJobRating(value, job.id)}
                  />
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default CandidateJobs;
