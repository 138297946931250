import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
}));

export default function MySort() {
  const classes = useStyles();

  return (
    <div>
      <FormControl variant="filled" className={classes.formControl} fullWidth>
        <InputLabel id="demo-simple-select-filled-label">Order By:</InputLabel>
        <Select
          labelId="sort-by"
          id="sort-by"
          value={10}
          margin="dense"
          disableUnderline
          //onChange={handleChange}
        >
          <MenuItem value={10}>Recently Added</MenuItem>
          <MenuItem value={20}>Added First</MenuItem>
          <MenuItem value={30}>Added Date</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
