import React from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiSkeleton-root": {
      marginBottom: theme.spacing(1),
    },
  },
  skeletonItem: {
    display: "flex",
    justifyContent: "flex-start",
    marginRight: 40,
    paddingRight: 40,
    paddingLeft: 40,
  },
  skeletonBottom: {
    paddingRight: 40,
    paddingLeft: 40,
  },
}));

const CandidateSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.skeletonItem}>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <Skeleton variant="text" width={100} height={40} animation="pulse" />
      </div>
      <div classes={classes.skeletonBottom}>
        <Skeleton
          variant="rect"
          width={"100%"}
          height={100}
          animation="pulse"
        />
      </div>
      <div className={classes.skeletonItem}>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <Skeleton variant="text" width={100} height={40} animation="pulse" />
      </div>
      <div classes={classes.skeletonBottom}>
        <Skeleton
          variant="rect"
          width={"100%"}
          height={100}
          animation="pulse"
        />
      </div>
      <div className={classes.skeletonItem}>
        <Skeleton animation="wave" variant="circle" width={40} height={40} />
        <Skeleton variant="text" width={100} height={40} animation="pulse" />
      </div>
      <div classes={classes.skeletonBottom}>
        <Skeleton
          variant="rect"
          width={"100%"}
          height={100}
          animation="pulse"
        />
      </div>
    </div>
  );
};
export default CandidateSkeleton;
