import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Spinner from "../..//UI/Spinner/Spinner";
import axios from "axios"; //axios instance;
//import ThankYouUnSb from "../../Assets/Images/thank-you-unsbc.jpg";
import { grey, green } from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import AppBar from "../Frontend/AppBar/AppBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Typography,
  Box,
  Grid,
  Button,
  Collapse,
  Slide,
  FormControlLabel,
  Radio,
  Paper,
  FormControl,
  TextField,
  RadioGroup,
  FormLabel,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { UnsubscribeReasons } from "../../config/jobsConfigData";
import { ACCOUNT_CONTACT_EMAIL } from "../../config/constants";

const styles = (theme) => ({
  root: {
    backgroundColor: "#f2f2f2",
    maxWidth: 550,
    margin: "auto",
    height: "100vh",
    "& .MuiTypography-root": {
      color: grey[900],
      "& span": {
        fontWeight: 700,
      },
    },
    "& .MuiButton-iconSizeMedium": {
      marginRight: theme.spacing(2),
    },
  },
  mainBox: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
});

class Unsubscribe extends React.Component {
  state = {
    token: "",
    candidateId: -1,
    candidateFullname: "",
    authorized: false,
    isLoading: true,
    jobData: {},
    error: null,
  };

  componentDidMount() {
    //console.log("Starting param search", this.props.match.params.auth);
    axios
      .get(`/wp-json/api/v2/locateCandidate/${this.props.match.params.auth}`)
      .then((res) => {
        this.setState({
          candidateId: parseInt(res.data.id),
          isLoading: false,
          candidateFullname: res.data.fullname,
          token: this.props.match.params.auth,
        });
      })
      .catch((err) => {
        this.setState({ error: err.response, isLoading: false });
        console.log("Error", err.response);
      });
  }

  render() {
    const { classes } = this.props;
    const unauthorized = (
      <Typography variant="body1">
        Hello Guest,
        <br /> We were unable to locate your profile. <br />
        Please send us an email to{" "}
        <a href={`mailto:${ACCOUNT_CONTACT_EMAIL}`}>
          {ACCOUNT_CONTACT_EMAIL}
        </a>{" "}
        with your full details, and we will take care of this unsubscribe
        request
      </Typography>
    );
    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.root}>
          <AppBar />
          {this.state.isLoading ? (
            <Spinner open={this.state.isLoading} />
          ) : (
            <Box className={classes.mainBox}>
              {this.state.error ? (
                unauthorized
              ) : (
                <UnsubscribeScreen
                  fullname={this.state.candidateFullname}
                  candidateId={this.state.candidateId}
                  token={this.state.token}
                />
              )}
            </Box>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withRouter(Unsubscribe));

const screenStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const UnsubscribeScreen = (props) => {
  const classes = screenStyles();
  const [unsubscribeReason, setUnsubscribeReason] = React.useState("");
  const [other, setOther] = React.useState("");
  const [answered, setAnswered] = React.useState(false);
  const [isDuplicate, setIsDuplicate] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const usReasonsRadio = UnsubscribeReasons.map((opt) => {
    return (
      <FormControlLabel
        value={opt.id}
        control={<Radio color="primary" />}
        label={opt.label}
        key={opt.id}
      />
    );
  });

  const handleUnSbReasonChange = (event) => {
    setUnsubscribeReason(event.target.value);
  };

  const handleOtherChanage = (event) => {
    setOther(event.target.value);
  };

  const handleAddSubscribe = () => {
    setIsLoading(true);
    axios
      .post("/wp-json/api/v2/unsubscribe", {
        candidate_id: props.candidateId,
        token: props.token,
        reason: unsubscribeReason === "other" ? other : unsubscribeReason,
      })
      .then((res) => {
        setAnswered(true);
        setIsDuplicate(res.data === -1);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.code);
      });
  };

  const handleClose = () => {
    setError(null);
  };
  return (
    <Slide direction="up" timeout={500} in={true} mountOnEnter unmountOnExit>
      <Grid container>
        <Grid item xs={12}>
          {answered ? (
            <UnCompleted duplicate={isDuplicate} />
          ) : (
            <React.Fragment>
              <Paper className={classes.root}>
                <Typography variant="body1" gutterBottom>
                  Hi {props.fullname},
                </Typography>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Share with us why you're leaving:
                  </FormLabel>
                  <RadioGroup
                    aria-label="reasons"
                    name="reasons"
                    value={unsubscribeReason}
                    onChange={handleUnSbReasonChange}
                  >
                    {usReasonsRadio}
                  </RadioGroup>
                </FormControl>
                {unsubscribeReason === "other" ? (
                  <Collapse in={unsubscribeReason === "other"}>
                    <TextField
                      id="standard-multiline-flexible"
                      label="Details (Optional)"
                      placeholder="The unsubscribe reason is:"
                      multiline
                      rows={4}
                      onChange={handleOtherChanage}
                      fullWidth
                    />
                  </Collapse>
                ) : null}
                <Box>
                  <Typography variant="caption">
                    Click on unsubscribe will not allow us to offer you new
                    jobs.
                  </Typography>
                </Box>
                <div className={classes.buttons}>
                  <div className={classes.wrapper}>
                    <Button
                      variant="contained"
                      color="default"
                      className={classes.button}
                      endIcon={<DeleteIcon />}
                      onClick={handleAddSubscribe}
                      disabled={unsubscribeReason === ""}
                    >
                      Unsubscribe Me
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                  <Button onClick={() => setAnswered(true)} color="primary">
                    Cancel
                  </Button>
                </div>
              </Paper>
              <Snackbar open={error !== null} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                  Oh no! An error has occured. <br />
                  Error details: {error}
                </Alert>
              </Snackbar>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Slide>
  );
};

const UnCompleted = (props) => {
  return (
    <React.Fragment>
      <Slide direction="up" timeout={500} in={true} mountOnEnter unmountOnExit>
        <Grid container>
          <Grid item xs={12}>
            {props.duplicate ? (
              <Typography variant="h4" color="primary">
                You are already in unsubscribe list.
              </Typography>
            ) : (
              <Typography variant="h4" color="primary">
                Your answered has been recieved. Thank you!
              </Typography>
            )}

            <Typography variant="caption">You can close this page</Typography>
          </Grid>
        </Grid>
      </Slide>
    </React.Fragment>
  );
};
