import React from "react";
import axios from "axios";
import {
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
  Grid,
} from "@material-ui/core";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import { Alert, AlertTitle, TimelineDot } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { grey, green, red, yellow } from "@material-ui/core/colors";
import Title from "../Title/Title";
import { LOW_SMS_BALANCE, MED_SMS_BALANCE } from "../../config/constants";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {},
  pair: {},
  "& .MuiTimelineDot-defaultGrey": {
    backgroundColor: red[500],
  },
  grey: {
    color: grey[700],
    fontSize: 14,
  },
  progress: {
    position: "absolute",
    left: "calc(50% - 15px)",
    top: "calc(50% - 15px)",
    color: grey[500],
  },
}));

const RedDot = () => {
  return <TimelineDot style={{ backgroundColor: red[500] }} />;
};

const GreenDot = () => {
  return <TimelineDot style={{ backgroundColor: green[500] }} />;
};

const YellowDot = () => {
  return <TimelineDot style={{ backgroundColor: yellow[500] }} />;
};

function getDotStatus(value) {
  if (value > MED_SMS_BALANCE + 1) return <GreenDot />;
  else if (value > LOW_SMS_BALANCE) return <YellowDot />;
  else return <RedDot />;
}

const SMSServiceStatus = () => {
  const classes = useStyles();
  const [available, setAvialable] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalForPeriod, setTotalForPeriod] = React.useState({
    today: 0,
    last30days: 0,
  });
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    get_sms_balance_and_usage();
  }, []);

  const get_sms_balance_and_usage = () => {
    setIsLoading(true);
    const balanaceRequest = axios.get("/wp-json/api/v2/get_sms_balance");
    const usageRequest = axios.get("/wp-json/api/v2/SMS_report");
    axios
      .all([balanaceRequest, usageRequest])
      .then((responses) => {
        setIsLoading(false);
        setAvialable(parseInt(responses[0].data.sms));
        setTotalForPeriod({
          last30days: parseInt(responses[1].data.last30days),
          passed: parseInt(responses[1].data.passed),
          failed: parseInt(responses[1].data.failed),
        });
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response ? err.response : new Error("Unknown response"));
      });
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Title>SMS Service</Title>
        </Grid>
        <Grid item>{getDotStatus(available)}</Grid>
      </Grid>

      {isLoading ? (
        <CircularProgress className={classes.progress} size={20} />
      ) : (
        <Grid container direction="row" alignItems="center">
          <Grid item>
            <Typography variant="body1">
              Available SMS:{" "}
              <NumberFormat
                value={available}
                displayType={"text"}
                thousandSeparator={true}
              />
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title="Reload" aria-label="add">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={() => get_sms_balance_and_usage()}
              >
                <ReplayRoundedIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography varaint="caption" className={classes.grey}>
            SMS Sent successfully:{"   "}
            <NumberFormat
              value={totalForPeriod.passed}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
          <Typography varaint="caption" className={classes.grey}>
            SMS Failed:{"   "}
            <NumberFormat
              value={totalForPeriod.failed}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
          <Typography varaint="caption" className={classes.grey}>
            SMS Sent last 30 days: {"   "}
            <NumberFormat
              value={totalForPeriod.last30days}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
        </Grid>
      </Grid>

      {error !== null ? (
        <Alert severity="error">
          <AlertTitle>The Service is not available</AlertTitle>
          <Typography variant="body2">
            {error.code && error.statusText
              ? error.code + "/n" + error.statusText
              : "Error occured while fetching data"}
          </Typography>
        </Alert>
      ) : null}
    </React.Fragment>
  );
};
export default SMSServiceStatus;
