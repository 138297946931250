import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      marginLeft: theme.spacing(1),
    },
    "& .MuiButton-containedPrimary": {
      background: theme.palette.secondary.main,
    },
  },
}));

export default function YesNoDialog({
  open,
  onClose,
  title,
  description,
  positiveButtonLabel,
  onConfirm,
  negativeButtonLabel,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose} color="secondary">
            {negativeButtonLabel}
          </Button>
          <Button
            variant="contained"
            onClick={onConfirm}
            color="primary"
            autoFocus
          >
            {positiveButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
