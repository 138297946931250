import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { useIntl } from "react-intl";

const labels = {
  1: {
    id: "candidateProfile.profile.absolutelyNot",
    defaultMessage: "Absolutely not for me!",
  },
  2: {
    id: "candidateProfile.profile.notInDirection",
    defaultMessage: "Way Wrong",
  },
  3: {
    id: "candidateProfile.profile.OK",
    defaultMessage: "OK...",
  },
  4: {
    id: "candidateProfile.profile.interesting",
    defaultMessage: "Interesting...",
  },
  5: {
    id: "candidateProfile.profile.spotOn",
    defaultMessage: "Spot On!",
  },
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
  },
});

export default function JobRating({ rating, onJobRating, jobId }) {
  const [value, setValue] = React.useState(-1);
  const [hover, setHover] = React.useState(-1);
  const classes = useStyles();
  const intl = useIntl();

  React.useEffect(() => {
    if (rating && rating !== value) setValue(rating);
  }, [rating]);

  return (
    <div className={classes.root}>
      <Rating
        name={`job-feedback-${jobId}`}
        value={value}
        precision={1}
        onChange={(event, newValue) => {
          if (newValue !== value) {
            setValue(newValue);
            setHover(-1);
          }

          onJobRating(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      {value !== null && (
        // <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>
        <Box ml={2}>
          {value !== -1 && intl.formatMessage(labels[value])}
          {hover !== -1 && intl.formatMessage(labels[hover])}
        </Box>
      )}
    </div>
  );
}
