import React from "react";
import { Box, useTheme, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab/";
import { LangContext } from "../../Localization/LocalizationWrapper";
import greece from "../../Assets/Images/Languages/greece.jpg";
import gb from "../../Assets/Images/Languages/britain.jpg";
import axios from "axios";
import { FormattedMessage } from "react-intl";

const LanguageSwitcher = ({
  mt = 2,
  preferredLanguage,
  candidateId,
  skipSave = false,
}) => {
  const langContext = React.useContext(LangContext);
  const [saved, setSaved] = React.useState(false);
  React.useEffect(() => {
    //initial language:
    if (preferredLanguage && preferredLanguage !== "") {
      langContext.onLanguageChange(preferredLanguage);
    }
  }, [preferredLanguage]);
  const theme = useTheme();
  const handleChangeLanguage = (lang) => {
    //update the user preferences:
    if (!skipSave) {
      candidateId &&
        candidateId > -1 &&
        axios
          .post(
            `/wp-json/api/v2/my-profile/lobby/user_preferences/${candidateId}`,
            {
              language: lang,
            }
          )
          .then(() => {
            setSaved(true);
          });
    }
    langContext.onLanguageChange(lang);
  };
  return (
    <React.Fragment>
      <Box display="flex" alignItems="center" justifyContent="center" mt={mt}>
        <div
          onClick={() => handleChangeLanguage("en")}
          style={{ cursor: "pointer" }}
        >
          <img src={gb} alt="EN" />
        </div>
        <div
          onClick={() => handleChangeLanguage("gr")}
          style={{ marginLeft: theme.spacing(1), cursor: "pointer" }}
        >
          <img src={greece} alt="GR" />
        </div>
      </Box>
      <Snackbar
        open={saved}
        autoHideDuration={3000}
        onClose={() => setSaved(false)}
      >
        <Alert onClose={() => setSaved(false)} severity="success">
          <FormattedMessage
            id="common.settingsSaved"
            defaultMessage="Settings saved successfully"
          />
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

export default LanguageSwitcher;
