import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import DuplicateIcon from "../../Assets/CustomIcons/DuplicateIcon";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: 120,
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
    },
  },
  svgDuplicateIcon: {
    //color: "#FC6E00",
    color: theme.palette.secondary.main
  },
}));

export default function ConfirmationAutoDistribution({
  open,
  onClose,
  onConfirm,
  isAutoDistribute,
  jobId,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <Box
        py={theme.spacing(2)}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <DuplicateIcon fontSize="80" />
      </Box>
      <Box justifyContent="center" display="flex" alignItems="center">
        <Typography variant="h5" color="textPrimary" gutterBottom={true}>
          {`Automatic Distribution for job id ${jobId}`}
        </Typography>
      </Box>

      <DialogContent>
        <Box>
          {isAutoDistribute ? (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                {`Remove job ${jobId} from automatic distribution?`}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Confirmation on this message, will no longer send invitations to candidates automatically upon import
              </Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant="body1" color="textSecondary">
                {`Turn Automatic Distirbution for job id ${jobId}?`}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Automatic Distribution will send job invitation to every candidate imported to linked to this job id
              </Typography>
            </React.Fragment>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onConfirm(!isAutoDistribute)}
          color="primary"
        >
          Confirm
        </Button>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
