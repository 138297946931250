import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Collapse, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiAlert-icon": {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default function AxiosErrorHandling(props) {
  const classes = useStyles();
  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <div className={classes.root}>
      <Alert severity="error">
        <AlertTitle>
          <strong>{props.title}</strong>
        </AlertTitle>
        <Typography variant="body1">{props.error.data.code}</Typography>
        <Button variant="outlined" onClick={() => setShowDetails(!showDetails)}>
          {!showDetails ? "Full Details" : "Hide details"}
        </Button>
        <Collapse in={showDetails}>
          <Typography variant="body1">
            <strong>Error Details</strong>
          </Typography>
          <Typography variant="body1">
            Error code: {props.error.status}
          </Typography>
          <Typography variant="body1">
            Technical details: {props.error.data.code}
          </Typography>
          <Typography variant="body1">
            Message: {props.error.data.message}
          </Typography>
        </Collapse>
      </Alert>
    </div>
  );
}
