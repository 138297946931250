import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, Paper, Grid } from "@material-ui/core";
import { green, yellow, grey, blueGrey } from "@material-ui/core/colors";
import ThumbUpRoundedIcon from "@material-ui/icons/ThumbUpRounded";
import ThumbDownRoundedIcon from "@material-ui/icons/ThumbDownRounded";
import moment from "moment";
import {
  translateLanguageLevel,
  CurrencySign,
} from "../../config/jobsConfigData";
import {
  translateDegreeAndType,
  LanguageLevels,
} from "../../config/jobsConfigData";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";

function getCurrencySign() {
  return { __html: CurrencySign.hex };
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTimelineItem-missingOppositeContent:before": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      borderWidth: 1,
    },
  },
  paper: {
    padding: theme.spacing(2),
    "& .MuiSvgIcon-root": {
      color: grey[700],
    },
  },
  textHeader: {
    marginBottom: theme.spacing(2),
  },
  textTitle: {
    color: blueGrey[700],
    marginBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tlconent: {
    //minWidth: 350,
  },
  passIcon: {
    color: green[500],
  },
  failedIcon: {
    color: theme.palette.secondary.main,
  },
}));

function requiredQuestion() {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <PriorityHighRoundedIcon />{" "}
      <Typography variant="caption">Prerequisite Condition</Typography>
    </span>
  );
}

function generateQuestionReportLine(question, theme, classes) {
  let text = "";
  let candAnswer = "";
  switch (question.question_key) {
    case "location":
      if (question.answer_value === "no") text = "Candidate declined location";
      else if (question.answer_value === "maybe")
        text = "Candidate Is not sure about location";
      else text = "Candidate confirms location";

      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Location</strong>
            {requiredQuestion()}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            {text}
          </Typography>
          <Typography variant="body1">
            <strong>Required:</strong> {question.location.city}
          </Typography>
        </React.Fragment>
      );
    case "availability":
      const days = moment(question.answer_value, "MM/DD/YYYY").days();
      text =
        days < 2
          ? "Candidate is available immediately"
          : `Candidate is available within ${days} days`;
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Availability</strong>
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            {text}
          </Typography>
        </React.Fragment>
      );
    case "salary":
      if (question.salary_type.is_open) {
        if (question.pass_result === "passed")
          text = "Candidate salary expectations are within job's range";
        else text = "Candidate salary expectations exceeds job's range";
      } else {
        if (question.answer_value === "no")
          text = "Candidate declined salary terms";
        else if (question.answer_value === "yes")
          text = "Candidate approved salary terms";
        else text = "Candidate is not sure about salary terms";
      }

      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Salary</strong>
            {requiredQuestion()}
          </Typography>
          <Typography
            variant="body1"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {text}
          </Typography>
          <Typography variant="body1">
            <strong>Required:</strong> Between: {question.salary.minimum}{" "}
            <span dangerouslySetInnerHTML={getCurrencySign()}></span> and{" "}
            {question.salary.maximum}{" "}
            <span dangerouslySetInnerHTML={getCurrencySign()}></span>
          </Typography>
          {question.salary_type.is_open ? (
            <Typography variant="body1">
              <strong>Candidate Requirements:</strong> {question.answer_value}{" "}
              <span dangerouslySetInnerHTML={getCurrencySign()}></span> /{" "}
              {question.salary_type.salary_by === "hour" ? "Hour" : "Month"}
            </Typography>
          ) : null}
        </React.Fragment>
      );
    case "experience":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Experience</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            In Experience question on{" "}
            <strong>
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "selectedProfession"
                ).profession_translate
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>Required:</strong>{" "}
              {
                question.filterQuestion.find((fq) => fq.fq_key === "minYears")
                  .fq_value
              }{" "}
              experience years,
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              <strong>Candidate Answered:</strong> {question.answer_value} years
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "languages":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Language Proficiency</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            For language proficiency for{" "}
            <strong>
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "languageName"
                ).fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>Required level:</strong>{" "}
              {
                LanguageLevels.find(
                  (ll) =>
                    ll.value ===
                    question.filterQuestion.find(
                      (fq) => fq.fq_key === "languageLevel"
                    ).fq_value
                ).label
              }{" "}
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              <strong>Candidate Answered:</strong>{" "}
              {
                LanguageLevels.find((ll) => ll.value === question.answer_value)
                  .label
              }
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "skills":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Skills & Abilities</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            For skills for{" "}
            <strong>
              {
                question.filterQuestion.find((fq) => fq.fq_key === "skillName")
                  .fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>Knowledge level required:</strong>{" "}
              {translateLanguageLevel(
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "knowledgeLevel"
                ).fq_value
              )}{" "}
            </Typography>
            <Typography variant="body1" style={{ marginLeftt: 5 }}>
              <strong>Candidate answered:</strong>{" "}
              {translateLanguageLevel(question.answer_value)}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "licenses":
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Licenses</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            License required for{" "}
            <strong>
              {
                question.filterQuestion.find((fq) => fq.fq_key === "licenseOn")
                  .fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>Required :</strong>{" "}
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "minYearsLicense"
                ).fq_value
              }{" "}
              years of experience
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              <strong>Candidate answered:</strong> {question.answer_value} years
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "certificates":
      candAnswer = "Candidate has valid certificate";
      if (question.answer_value === "no")
        candAnswer = "Candidate does not have valid certificate";
      else if (question.answer_value === "maybe")
        candAnswer = "Candidate is not sure about having this certificate";
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Certifications</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            Required certificate in:{" "}
            <strong>
              {
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "selectedCertificate"
                ).fq_value
              }
            </strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              <strong>Candidate answered:</strong> {candAnswer}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "academicDegrees":
      candAnswer = "Candidate is holding a relevant degree";
      if (question.answer_value === "no")
        candAnswer = "Candidate is not holiding a relevant degree";
      else if (question.answer_value === "maybe")
        candAnswer = "Candidate is not sure about holding this degree";
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Academic Degrees</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            Required degree:{" "}
            <strong>
              {translateDegreeAndType(
                question.filterQuestion.find(
                  (fq) => fq.fq_key === "selectedDegree"
                ).fq_value
              )}
            </strong>{" "}
            of type{" "}
            <strong>
              {
                question.filterQuestion.find((fq) => fq.fq_key === "degreeType")
                  .fq_value
              }
            </strong>
          </Typography>

          <Grid alignItems="center" container direction="row">
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              <strong>Candidate answered:</strong> {candAnswer}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    case "dynamics":
      let dynamicAnswer = "Yes";
      if (question.answer_value === "no") dynamicAnswer = "no";
      else if (question.answer_value === "maybe")
        dynamicAnswer = "Candidate answered maybe";
      let passAnswerLabel = "Yes";
      if (
        question.filterQuestion.length &&
        question.filterQuestion[0].dfq_pass_answer === "no"
      )
        passAnswerLabel = "No";
      else passAnswerLabel = "Yes";
      return (
        <React.Fragment>
          <Typography variant="body1" className={classes.textTitle}>
            <strong>Dynamic Questions</strong>
            {parseInt(question.question_is_a_must) ? requiredQuestion() : null}
          </Typography>
          <Typography variant="body1" className={classes.textHeader}>
            For the question:
            <strong>{question.filterQuestion[0].dfq_display_question}</strong>
          </Typography>
          <Grid alignItems="center" container direction="row">
            <Typography variant="body1">
              <strong>Where a pass answer is :</strong> {passAnswerLabel}{" "}
            </Typography>
            <Typography variant="body1" style={{ marginLeft: 5 }}>
              <strong>Candidate answered:</strong> {dynamicAnswer}
            </Typography>
          </Grid>
        </React.Fragment>
      );
    default:
      return null;
  }
}

function setIcon(result, theme) {
  if (result === "passed")
    return <ThumbUpRoundedIcon style={{ color: green[500] }} />;
  else if (result === "failed")
    return (
      <ThumbDownRoundedIcon style={{ color: theme.palette.primary.main }} />
    );
  else return <PriorityHighRoundedIcon style={{ color: yellow[500] }} />;
}

const CandidateJobAnswersReport = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const renderQuestions = props.questions.map((question) => {
    return (
      <div className={classes.item} key={question.id}>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot variant="outlined">
              {setIcon(question.pass_result, theme)}
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent className={classes.tlconent}>
            <Paper elevation={1} className={classes.paper}>
              {generateQuestionReportLine(question, theme, classes)}
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </div>
    );
  });
  return (
    <React.Fragment>
      <Timeline className={classes.root}>{renderQuestions}</Timeline>
    </React.Fragment>
  );
};
export default CandidateJobAnswersReport;
