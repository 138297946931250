import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Primary } from "../../../config/colors";

const CandidatesButton = withStyles((theme) => ({
  root: {
    color: Primary.contrastText,
    backgroundColor: Primary.main,
    "&:hover": {
      backgroundColor: Primary.dark,
    },
  },
}))(Button);

export default CandidatesButton;
