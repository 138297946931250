import React from "react";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import {
  Box,
  TextField,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  FormControl,
  Radio,
} from "@material-ui/core";
import { setDisplayName } from "recompose";

const FQDynamic = ({ onCancel, onCompletion, fqEdit = null }) => {
  const [qType, setQType] = React.useState("YesNo");
  const [displayQuestion, setDisplayQuestion] = React.useState("");
  const [charsLeft, setCharsLeft] = React.useState(255);
  const [passAnswer, setPassAnswer] = React.useState("yes");
  const [isAMust, setIsAMust] = React.useState(false);

  React.useEffect(() => {
    if (fqEdit !== null) {
      setQType(fqEdit.qType);
      setDisplayQuestion(fqEdit.displayQuestion);
      setPassAnswer(fqEdit.passAnswer);
      setIsAMust(fqEdit.isAMust);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  /*
  Single Dynamic Object: 
  {
      dynamicId: AutoID
      qType: string: Question Type, default: YesNo
      displayQuestion: string, the full question to display
      passAnswer: string, the correct answer to pass 
      chars Left: num, number of characters left up to 255 of display question characters      
      isAMust: if true, answer must pass
  }
  */

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["displayQuestion"] = displayQuestion;
      existingObject["passAnswer"] = passAnswer;
      existingObject["isAMust"] = isAMust;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      qType: "YesNo",
      displayQuestion,
      passAnswer,
      isAMust,
    });
  };

  const clearForm = () => {
    //clean the form values:
    setDisplayQuestion("");
    setQType("YesNo");
    setPassAnswer("yes");
    setIsAMust(false);
    setCharsLeft(255);
  };

  const handleIsDynamicIsMust = (event) => setIsAMust(event.target.checked);
  const handleDisplayQuestionChange = (event) => {
    setDisplayQuestion(event.target.value);
    setCharsLeft(255 - event.target.value.length);
  };
  const handlePassAnswerChange = (event) => {
    setPassAnswer(event.target.value);
  };

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          Dynamic Question
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Question Type</FormLabel>
            <RadioGroup aria-label="qType" name="qType" value={qType}>
              <FormControlLabel
                value="YesNo"
                control={<Radio color="primary" />}
                label="Yes/No Question"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: 700, marginBottom: 15 }}
            color="textPrimary"
            variant="subtitle1"
          >
            The question (This will be displayed to the candidate)
          </Typography>
          <TextField
            id="openQuestion"
            label="Type the question to be displayed to the candidate"
            variant="filled"
            value={displayQuestion}
            onChange={handleDisplayQuestionChange}
            helperText={`Characters left: ${charsLeft}`}
            error={charsLeft <= 0}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset" onChange={handlePassAnswerChange}>
            <FormLabel component="legend">
              Correct answer for this question:
            </FormLabel>
            <RadioGroup
              aria-label="passAnswer"
              name="passAnswer"
              value={passAnswer}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isAMust}
                onClick={handleIsDynamicIsMust}
                name="requiredFQ"
                color="primary"
              />
            }
            label="This condition is prerequisite"
          />
        </Grid>
        <Grid xs={12} item>
          <CancelApproveButtons
            positiveCaption="Done"
            negativeCaption="Cancel"
            positiveDisabled={displayQuestion === "" || charsLeft <= 0}
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FQDynamic;
