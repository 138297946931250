import React from "react";
import {
  AI_EXPERIENCES,
  AI_SKILLS,
  AI_LICENSES,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_SALARY_EXPECTATIONS,
} from "../../../../config/constants";
import {
  Paper,
  Grid,
  Typography,
  Button,
  Collapse,
  FormControlLabel,
  Switch,
  Badge,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    "& .MuiButton-root": { minWidth: 140 },
  },

  layout: {
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: theme.spacing(3),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  buttons: {
    "& > .MuiButton-root": {
      minWidth: 140,
    },
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
  },
}));

const AdditionalInformatiion = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [showFilter, setShowFilter] = React.useState(true);
  return (
    <React.Fragment>
      <Grid container justify="flex-start">
        <Grid item xs={12}>
          <FormControlLabel
            style={{ margin: theme.spacing(1) }}
            label={
              showFilter ? "Hide More Information" : "Show More Information"
            }
            control={
              <Switch
                checked={showFilter}
                onChange={() => setShowFilter(!showFilter)}
                name="checkedB"
                color="secondary"
              />
            }
          />
        </Grid>
      </Grid>
      <Collapse in={showFilter}>
        <div className={clsx(classes.root, classes.layout)}>
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Paper className={classes.paper}>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.typography}
                  gutterBottom
                >
                  {"More information"}
                </Typography>
                <div className={classes.buttons}>
                  <Badge
                    color="secondary"
                    badgeContent={props.experienceCount}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="standard"
                    invisible={!props.experienceCount}
                  >
                    <Button
                      onClick={(event) => props.clicked(event, AI_EXPERIENCES)}
                      variant="outlined"
                      color="primary"
                      id="experience"
                    >
                      Experience
                    </Button>
                  </Badge>
                  <Badge
                    color="secondary"
                    badgeContent={props.skillsCount}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="standard"
                    invisible={!props.skillsCount}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(event) => props.clicked(event, AI_SKILLS)}
                    >
                      Skills & Abilities
                    </Button>
                  </Badge>
                  <Badge
                    color="secondary"
                    badgeContent={props.licensesCount}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="standard"
                    invisible={!props.licensesCount}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(event) => props.clicked(event, AI_LICENSES)}
                    >
                      Licenses
                    </Button>
                  </Badge>
                  <Badge
                    color="secondary"
                    badgeContent={props.academicDegreeCount}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="standard"
                    invisible={!props.academicDegreeCount}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(event) =>
                        props.clicked(event, AI_ACADEMIC_DGREE)
                      }
                    >
                      Academic Degrees
                    </Button>
                  </Badge>
                  <Badge
                    color="secondary"
                    badgeContent={props.certificateCount}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="standard"
                    invisible={!props.certificateCount}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(event) => props.clicked(event, AI_CERTIFICATES)}
                    >
                      Certifications
                    </Button>
                  </Badge>
                  <Badge
                    color="secondary"
                    badgeContent={props.languagesCount}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="standard"
                    invisible={!props.languagesCount}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(event) => props.clicked(event, AI_LANGUAGES)}
                    >
                      Languages
                    </Button>
                  </Badge>
                  <Badge
                    color="secondary"
                    badgeContent={props.salaryExpectationsCount}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="standard"
                    invisible={!props.salaryExpectationsCount}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={(event) =>
                        props.clicked(event, AI_SALARY_EXPECTATIONS)
                      }
                    >
                      Salary Expectations
                    </Button>
                  </Badge>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Collapse>
    </React.Fragment>
  );
};
export default AdditionalInformatiion;
