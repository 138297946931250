import React from "react";
import {
  Box,
  Divider,
  Grid,
  FormControlLabel,
  Switch,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiInputAdornment-positionStart": {
      minWidth: 200,
      "& .MuiTypography-root": {
        color: theme.palette.grey[600],
        fontWeight: 600,
      },
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  activeRow: {
    color: theme.palette.grey[700],
  },
}));

const CheckboxRow = ({ label, type, value, onCheck, noborder, ...rest }) => {
  const [checked, setChecked] = React.useState(false);
  const theme = useTheme();
  const classes = useStyles(theme);

  React.useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = () => {
    onCheck();
    //setChecked((prevValue) => !prevValue);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={classes.root}
        px={theme.spacing(2)}
        py={theme.spacing(1)}
      >
        <Grid container style={{ alignItems: "center" }}>
          <Grid item>
            {type === "checkbox" ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    name="checkedSettings"
                    color="secondary"
                  />
                }
                label={label}
              />
            ) : (
              <FormControlLabel
                control={
                  <Switch
                    checked={checked ? true : false}
                    onChange={handleChange}
                    name="checkedSettings"
                    color="secondary"
                  />
                }
                label={label}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {!noborder && <Divider light />}
    </React.Fragment>
  );
};

export default CheckboxRow;
