import React from "react";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Paper,
  Container,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import CheckboxRow from "../CandidateComponents/EditRow/CheckboxRow";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useIntl, FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Settings = ({ settings, dataUpdate, toggleDarkMode }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [allowJobs, setAllowJobs] = React.useState(true);
  const [isTouchedDarkMode, setIsTouchedDarkMode] = React.useState(false);
  const [allowMarketing, setAllowMarketing] = React.useState(false);
  const [remindMe, setRemindMe] = React.useState(true);
  const [reminderMonths, setReminderMonths] = React.useState(1);
  const theme = useTheme();
  const classes = useStyles(theme);
  const intl = useIntl();

  React.useEffect(() => {
    setAllowMarketing(settings.allowMarketing);
    setAllowJobs(settings.allowJobs);
  }, [settings]);

  const handleDataUpdate = () => {
    setIsTouchedDarkMode(false);
    dataUpdate({ allowJobs, allowMarketing, darkMode: settings.darkMode });
  };

  const handleUpdateDarkMode = () => {
    setIsTouchedDarkMode(true);
    toggleDarkMode();
  };

  //Check if original settings is different to localsettings
  const isEqual = () => {
    if (!settings) return false;
    if (
      settings.allowJobs === allowJobs &&
      settings.allowMarketing === allowMarketing &&
      !isTouchedDarkMode
    ) {
      return true;
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={theme.spacing(2)}
          >
            <Typography variant="h2">
              <FormattedMessage
                id="screenTitle.settings"
                defaultMessage="Settings"
              />
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDataUpdate}
              disabled={isEqual()}
              style={{ position: "relative" }}
            >
              <FormattedMessage id="general.update" defaultMessage="Update" />
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Box>

          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="primary">
              <FormattedMessage
                id="common.permissions"
                defaultMessage="Consents"
              />
            </Typography>
            <Paper>
              <CheckboxRow
                label={intl.formatMessage({
                  id: "candidateProfile.concents.marketing",
                  defaultMessage: "I agree to receive marketing communications",
                })}
                value={allowMarketing}
                type="checkbox"
                onCheck={() => setAllowMarketing((prev) => !prev)}
              />
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Paper>
              <CheckboxRow
                label={intl.formatMessage({
                  id: "candidateProfile.lookingForJob",
                  defaultMessage: "I am looking for a job",
                })}
                value={allowJobs}
                type="switch"
                onCheck={() => setAllowJobs((prev) => !prev)}
              />
              <Collapse in={!allowJobs} timeout={800}>
                <Box p={theme.spacing(2)} display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={remindMe}
                        onChange={() => setRemindMe((prevValue) => !prevValue)}
                        name="checkedB"
                        color="secondary"
                      />
                    }
                    label={intl.formatMessage({
                      id: "candidateProfile.offerMeJobDelay",
                      defaultMessage: "Offer me new jobs again within",
                    })}
                  />
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="reminder-select-label"
                      value={reminderMonths}
                      onChange={(event) =>
                        setReminderMonths(event.target.value)
                      }
                    >
                      <MenuItem value={1}>
                        <FormattedMessage
                          id="common.month"
                          defaultMessage="Month"
                        />
                      </MenuItem>
                      <MenuItem value={2}>
                        <FormattedMessage
                          id="common.twoMonths"
                          defaultMessage="Two Months"
                        />
                      </MenuItem>
                      <MenuItem value={3}>
                        <FormattedMessage
                          id="common.threeMonths"
                          defaultMessage="Three Months"
                        />
                      </MenuItem>
                      <MenuItem value={6}>
                        <FormattedMessage
                          id="common.sixMonths"
                          defaultMessage="Six Months"
                        />
                      </MenuItem>
                      <MenuItem value={12}>
                        <FormattedMessage
                          id="common.oneYear"
                          defaultMessage="One Year"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Typography
                  variant="body2"
                  style={{
                    margin: theme.spacing(2),
                    color: theme.palette.grey[500],
                  }}
                >
                  <FormattedMessage
                    id="candidateProfile.offerMeJobDelay.confirmation"
                    defaultMessage="I'll offer you new relevant jobs, in a period of your choice"
                  />
                </Typography>
              </Collapse>
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="primary">
              <FormattedMessage
                id="settings.display.title"
                defaultMessage="Display Settings"
              />
            </Typography>
            <Paper>
              <CheckboxRow
                label={intl.formatMessage({
                  id: "candidateProfile.settings.darkMode",
                  defaultMessage: "Dark Mode",
                })}
                value={settings.darkMode}
                type="switch"
                onCheck={handleUpdateDarkMode}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
