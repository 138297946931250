import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import { Primary } from "../../config/colors";
import packageJson from "../../../package.json";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: Primary.light,
    color: Primary.dark,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export default function ActivityTable({ data }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Job ID</StyledTableCell>
            <StyledTableCell>Job Title</StyledTableCell>
            <StyledTableCell>New Candidates</StyledTableCell>
            <StyledTableCell>Total Distributions</StyledTableCell>
            <StyledTableCell>Created at</StyledTableCell>
            <StyledTableCell>Created by</StyledTableCell>
            <StyledTableCell>Sent to Customer</StyledTableCell>
            <StyledTableCell>Recruited</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.ID}>
              <TableCell component="th" scope="row">
                <Link
                  href={`${
                    packageJson.homepage ? packageJson.homepage : ""
                  }/jobs/dashboard/${row.ID}`}
                  color="secondary"
                >
                  {row.ID}
                </Link>
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.newCands}</TableCell>
              <TableCell>{row.Distributions}</TableCell>
              <TableCell>{row.CreationDate}</TableCell>
              <TableCell>{row.CreatedBy}</TableCell>
              <TableCell>{row.CVReferrals}</TableCell>
              <TableCell>{row.SuccessRecruitments}</TableCell>
            </TableRow>
          ))}
          {data.length === 0 ? (
            <TableRow>
              <TableCell>No data found for this period</TableCell>
            </TableRow>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
