import React from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import CustomTags from "./customTag";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& .MuiTypography-root": {
      color: theme.palette.grey[600],
      fontWeight: 600,
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.main,
      "& svg": {
        color: theme.palette.primary.main,
      },
      "& .MuiChip-label": {
        lineHeight: 1.1,
      },
    },
  },

  activeRow: {
    color: theme.palette.grey[700],
  },
}));

const TagsRow = ({
  label,
  value,
  onSelect,
  onRemove,
  noborder,
  tagsList,
  listConfig,
  tagPlaceholder,
  ...rest
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  //   React.useEffect(() => {
  //     if (value) setTags(value);
  //   }, [value]);

  const handleOnChipSelect = (value) => {
    //setTags(value);
    onSelect(value);
  };

  const handleOnRemove = (value) => {
    onRemove(value);
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={classes.root}
        px={theme.spacing(2)}
        py={theme.spacing(1)}
      >
        <Grid container>
          <Grid item lg={2} sm={4} md={4}>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              height="100%"
            >
              <Typography variant="body1">{label}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={10} md={8} sm={8}>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              <CustomTags
                tagsList={tagsList}
                listConfig={listConfig}
                value={value}
                tagPlaceholder={tagPlaceholder}
                onRemove={handleOnRemove}
                onSelect={handleOnChipSelect}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!noborder && <Divider light />}
    </React.Fragment>
  );
};

export default TagsRow;
