import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useHistory } from "react-router-dom";
import { useTheme, withStyles } from "@material-ui/core";
import {
  MenuDashboardIcon,
  LinkToJobIcon,
  MenuJobsIcon,
  MenuLibiExploreIcon,
  MenuSettingsIcon,
  MenuCandidatesIcon,
} from "../../Assets/CustomIcons/CustomIconsList";
import { Primary } from "../../config/colors";

const StyleListItem = withStyles(() => ({
  root: {
    "&.Mui-selected": {
      backgroundColor: Primary.light,
      "& .MuiListItemIcon-root": {
        color: Primary.dark,
      },
      "& .MuiTypography-root": {
        color: Primary.dark,
      },
    },
    "&:hover": {
      backgroundColor: Primary.main,
      "& .MuiListItemIcon-root": {
        color: Primary.dark,
      },
      "& .MuiTypography-root": {
        color: Primary.contrastText,
      },
      "& .MuiListItemIcon-root": {
        color: Primary.contrastText,
      },
    },

    "& .MuiListItemIcon-root": {
      minWidth: 40,
    },
  },
}))(ListItem);

const MainMenuItems = (props) => {
  const history = useHistory();
  const theme = useTheme();

  const handleGoToJobs = () => {
    sessionStorage.removeItem("ljp");
    history.push("/jobs");
  };

  const mainListItems = (
    <div>
      <StyleListItem
        button
        selected={history.location.pathname === "/dashboard"}
        onClick={() => history.push("/dashboard")}
      >
        <ListItemIcon>
          <MenuDashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/candidates"}
        onClick={() => history.push("/candidates")}
      >
        <ListItemIcon>
          <MenuCandidatesIcon />
        </ListItemIcon>
        <ListItemText primary="Candidates" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/jobs"}
        onClick={handleGoToJobs}
      >
        <ListItemIcon>
          <MenuJobsIcon />
        </ListItemIcon>
        <ListItemText primary="Jobs" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/explore"}
        onClick={() => history.push("/explore")}
      >
        <ListItemIcon>
          <MenuLibiExploreIcon />
        </ListItemIcon>
        <ListItemText primary="Explore" />
      </StyleListItem>
      <StyleListItem
        button
        selected={history.location.pathname === "/settings"}
        onClick={() => history.push("/settings")}
      >
        <ListItemIcon>
          <MenuSettingsIcon />
        </ListItemIcon>
        <ListItemText primary="System Settings" />
      </StyleListItem>
    </div>
  );
  const secondaryListItems = (
    <div>
      <ListSubheader inset style={{ paddingLeft: theme.spacing(7) }}>
        Additional Information
      </ListSubheader>
      <StyleListItem
        button
        selected={props.screen === "activityReport"}
        onClick={() => history.push("/activityReport")}
      >
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Activity Report" />
      </StyleListItem>
      <StyleListItem
        button
        selected={props.screen === "tracers"}
        onClick={() => history.push("/tracers")}
        disabled
      >
        <ListItemIcon>
          <LinkToJobIcon />
        </ListItemIcon>
        <ListItemText primary="Tracers" />
      </StyleListItem>
    </div>
  );

  return props.list === "main" ? mainListItems : secondaryListItems;
};

export default MainMenuItems;
