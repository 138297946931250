import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Checkbox, Grid, Box, FormControlLabel } from "@material-ui/core";
import SuitbaleCandidateItem from "./SuitableItem";
import SuitablesSkeleton from "../../../../../UI/Skeletons/SuitablesSkeleton";
import Pagination from "../../../../../UI/Pagination/Pagination";
import axios from "axios";
import ErrorBoundary from "../../../../../components/ErrorBoundary/ErrorBoundary";
import MyAlert from "../../../../../UI/MyAlert/MyAlert";

function customArraySort(sortby = "time", arrayToSort) {
  if (sortby === "time") {
    return arrayToSort.sort(
      (prev, current) =>
        Date.parse(
          new Date(
            current.questionier_result.timestamp.split("/").reverse().join("-")
          )
        ) -
        Date.parse(
          new Date(
            prev.questionier_result.timestamp.split("/").reverse().join("-")
          )
        )
    );
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: 20,
    },
  },
  grid: {
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  },
}));

export default function SuitableCandidatesManager(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [suitableCandidates, setSuitableCandidates] = React.useState([]);
  const [showRejected, setShowRejected] = React.useState(false);
  const [totalRejected, setTotalRejected] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(1);
  const [totalResults, setTotalResults] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    getSuitables();
  }, [currentPage, showRejected]);

  const getSuitables = () => {
    setIsLoading(true);
    axios
      .get(`wp-json/api/v2/suitable_candidates/${props.jobId}`, {
        params: {
          page: currentPage,
          withRejected: showRejected,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setSuitableCandidates(res.status === 204 ? [] : res.data.suitables);
        setTotalPages(parseInt(res.headers["x-wp-totalpages"]));
        setTotalResults(parseInt(res.headers["x-wp-total"]));
        setTotalRejected(parseInt(res.data.totalRejected));
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err);
      });
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleRejectCandidate = async (candidateId, rejected = true) => {
    const localSuitableCandidates = [...suitableCandidates];
    const index = localSuitableCandidates.findIndex(
      (cand) => cand.id === candidateId
    );
    if (index >= 0) {
      let localSingle = { ...localSuitableCandidates[index] };
      if (localSingle.read_reject && localSingle.read_reject.rejected)
        localSingle.read_reject.rejected = rejected;
      else
        localSingle = {
          ...localSingle,
          read_reject: {
            rejected: rejected,
          },
        };
      localSuitableCandidates[index] = localSingle;

      setSuitableCandidates(localSuitableCandidates);

      await axios.post(
        `/wp-json/api/v2/suitable_candidate_reject/${props.jobId}`,
        {
          candidateId,
          reject_value: rejected,
        }
      );
    }
  };

  const handleReadCandidate = async (candidateId) => {
    const localSuitableCandidates = [...suitableCandidates];

    const index = localSuitableCandidates.findIndex(
      (cand) => cand.id === candidateId
    );
    if (index >= 0) {
      let localSingle = { ...localSuitableCandidates[index] };

      if (localSingle.read_reject && localSingle.read_reject.read)
        localSingle.read_reject.read = true;
      else
        localSingle = {
          ...localSingle,
          read_reject: {
            read: true,
            rejected: localSingle.read_reject
              ? localSingle.read_reject.rejected
              : false,
          },
        };
      localSuitableCandidates[index] = localSingle;

      setSuitableCandidates(localSuitableCandidates);
    }
    //update the state:
    try {
      await axios.post(
        `/wp-json/api/v2/suitable_candidate_read/${props.jobId}`,
        {
          candidateId,
          author: localStorage.getItem("userId"),
          read_value: true,
        }
      );
    } catch (e) {
      console.log("Error", e.response);
    }
  };

  const handleCVReferrals = (candId) => {
    //update the current state with CV Referal for this item
    const suitableTemp = suitableCandidates;
    const suitableItemIndex = suitableTemp.findIndex((si) => si.id === candId);
    if (suitableItemIndex > -1) {
      suitableTemp[suitableItemIndex].cv_referral = true;
    }
    setSuitableCandidates(suitableTemp);
    props.onCVReferral(candId);
  };

  const suiltableCandidatesRender = () => {
    let suitableList = suitableCandidates;

    const render = customArraySort("time", suitableList).map((cand) => {
      return (
        <ErrorBoundary key={cand.id}>
          <SuitbaleCandidateItem
            candidateData={cand}
            jobId={props.jobId}
            readCandidateClick={handleReadCandidate}
            rejectCandidateClick={handleRejectCandidate}
            onCVReferrals={handleCVReferrals}
          />
        </ErrorBoundary>
      );
    });
    return render;
  };

  return (
    <React.Fragment>
      <Grid container className={classes.grid}>
        {isLoading ? (
          <SuitablesSkeleton />
        ) : (
          <React.Fragment>
            <Box
              my={theme.spacing(1)}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showRejected}
                    onChange={(event) => setShowRejected(event.target.checked)}
                    name="showRejected"
                    color="primary"
                  />
                }
                label={`Show rejected suitables (${
                  !isLoading && totalRejected
                })`}
              />
              {suitableCandidates.length > 0 && (
                <Pagination
                  onChange={handlePageChange}
                  totalPages={totalPages}
                  total={totalResults}
                  totalLabel={"Total suitables:"}
                  currentPage={currentPage}
                />
              )}
            </Box>

            {suiltableCandidatesRender()}
            <Box display="flex" flex={1} justifyContent="flex-end" my={2}>
              {suitableCandidates.length > 0 && (
                <Pagination
                  onChange={handlePageChange}
                  totalPages={totalPages}
                  total={totalResults}
                  totalLabel={"Total suitables:"}
                  currentPage={currentPage}
                />
              )}
            </Box>
          </React.Fragment>
        )}
        {error && (
          <MyAlert
            showAlert={this.state.showAlert}
            title={this.state.errorMessage === "" ? "" : "An error occured"}
            message={
              this.state.errorMessage !== ""
                ? this.state.errorMessage
                : "Candidate has been added successfully!"
            }
            severity={this.state.errorMessage !== "" ? "error" : "success"}
            closeAlert={this.handleCloseMyAlert}
            errorInfo={this.state.errorMessage}
          />
        )}
      </Grid>
    </React.Fragment>
  );
}
