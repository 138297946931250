import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(2),
    },
    "& .MuiButton-root": {
      marginBottom: theme.spacing(2),
      borderRadius: 30,
      backgroundColor: "#fff",
      width: "100%",
      //borderColor: grey[800],
      //borderWidth: 1,
      boxSizing: "border-box",
      borderStyle: "solid",
    },
  },
  selectedButton: {
    borderColor: theme.palette.primary.main,
    borderWidth: 3,
  },
  notSelected: {
    borderColor: grey[800],
    borderWidth: 1,
    opacity: (props) => (props.indicateSelection.value > -1 ? 0.5 : 1),
  },
}));

const QuestionOption = (props) => {
  const classes = useStyles(props);
  let qValues = props.questionOptions.map((question) => {
    return (
      <Grid item xs={12} style={{ width: "100%" }} key={question.value}>
        <Button
          onClick={() => props.valueClick(question.value)}
          key={question.id}
          className={
            props.indicateSelection.value !== "" &&
            props.indicateSelection.value === question.value
              ? classes.selectedButton
              : classes.notSelected
          }
        >
          <FormattedMessage
            id={question.localizationId}
            defaultMessage={question.label}
          />
        </Button>
      </Grid>
    );
  });
  return (
    <React.Fragment>
      <div className={classes.root}>
        {props.questionTitle}
        <Grid container direction="column" justify="center" alignItems="center">
          {qValues}
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default QuestionOption;
