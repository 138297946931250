import React from "react";
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  Select,
  Checkbox,
  Collapse,
} from "@material-ui/core";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import { DEGREE_TYPES } from "../../../../../config/constants";
import { DegreesList } from "../../../../../config/jobsConfigData";
import DegreeTypeRadios from "../../../../../components/AdditionaInfoComponents/DegreeTypeRadios";

const AcademicDegree = ({ onCancel, onCompletion, fqEdit = null }) => {
  const [selectedDegree, setSelectedDegree] = React.useState("");
  const [degreeType, setDegreeType] = React.useState("");
  const [isDegreeIsMust, setIsDegreeIsMust] = React.useState(false);

  React.useEffect(() => {
    console.log();
    if (fqEdit !== null) {
      setSelectedDegree(fqEdit.selectedDegree);
      setDegreeType(fqEdit.degreeType);
      setIsDegreeIsMust(fqEdit.isDegreeAMust);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  /*Single Degree Object
  {
        selectedDegree: selectedDegree,
        degreeType: degreeType,
        isDegreeAMust: isDegreeIsMust,
      }
  */

  const clearForm = () => {
    //Clear the form:
    setSelectedDegree("");
    setDegreeType("");
    setIsDegreeIsMust(false);
  };

  const handleIsDegreeIsMust = (event) => {
    setIsDegreeIsMust(event.target.checked);
  };
  const handleDegreeTypeChange = (event) => {
    setDegreeType(event.target.value);
  };
  const handleChangeDegree = (event) => {
    setSelectedDegree(event.target.value);
    switch (event.target.value) {
      case "firstDegree":
        setDegreeType(DEGREE_TYPES.first[0]);
        break;
      case "secondDegree":
        setDegreeType(DEGREE_TYPES.first[1]);
        break;
      case "doctor":
        setDegreeType(DEGREE_TYPES.first[2]);
        setIsDegreeIsMust(false);
        break;
      default:
        break;
    }
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["selectedDegree"] = selectedDegree;
      existingObject["degreeType"] = degreeType;
      existingObject["isDegreeAMust"] = isDegreeIsMust;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      selectedDegree,
      degreeType: degreeType,
      isDegreeAMust: isDegreeIsMust,
    });
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">
              Add Academic Degree
            </InputLabel>
            <Select
              labelId="AcademicDegreeLabel"
              id="select-outlined"
              value={selectedDegree}
              onChange={handleChangeDegree}
              label="Academic Degree"
            >
              <MenuItem value="">
                <em>Without Degree</em>
              </MenuItem>
              {DegreesList.map((degree) => (
                <MenuItem key={degree.value} value={degree.value}>
                  {degree.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <Collapse in={selectedDegree !== ""}>
            <Typography
              style={{ fontWeight: 700, marginBottom: 15 }}
              color="textPrimary"
              variant="subtitle1"
            >
              Select degree type:
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="degreeTypeRadio"
                name="radioType"
                value={degreeType}
                onChange={handleDegreeTypeChange}
              >
                <DegreeTypeRadios degree={selectedDegree} />
              </RadioGroup>
            </FormControl>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            disabled={selectedDegree === ""}
            control={
              <Checkbox
                checked={isDegreeIsMust}
                onClick={handleIsDegreeIsMust}
                name="requiredFQ"
                color="primary"
              />
            }
            label="This condition is prerequisite"
          />
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="Done"
            negativeCaption="Cancel"
            positiveDisabled={selectedDegree === ""}
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default AcademicDegree;
