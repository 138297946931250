import React from "react";
import { withRouter } from "react-router-dom";
import { blueGrey, green } from "@material-ui/core/colors";
import {
  Paper,
  Typography,
  Button,
  Link,
  Grid,
  Avatar,
  Chip,
  Box,
  Divider,
  IconButton,
  useTheme,
  Tooltip,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import JobsMoreActions from "../../../../components/EntityMoreActions/EntitysMoreActions";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import JobFooter from "./JobFooter/JobFooter";
import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import AddIcon from "@material-ui/icons/Add";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FileCopyRoundedIcon from "@material-ui/icons/FileCopyRounded";
import { formatDistanceToNow } from "date-fns";
import ConfirmationAutoDistribution from "../../../../components/ConfirmationAutoDistribution/ConfirmationAutoDistribution";
import ResponseRateProgress from "../../../../components/ResponseRateProgress/ResponseRateProgress";
import ConfirmDuplicateJob from "../../../../components/DuplicateJobConfirmation/ConfirmDuplicateJob";

const useStyles = makeStyles((theme) => ({
  root: {
    //width: "100%",
  },
  paper: {
    //width: (props) => (props.viewType === "grid" ? "10%" : "100%"),
    width: "100%",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "& .MuiChip-root": {
      cursor: "pointer",
    },
    "& .MuiLink-root": {
      cursor: "pointer",
    },
  },

  title: {
    display: "flex",
  },
  jobDashboard: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  publishNotification: {
    marginRight: theme.spacing(2),
    color: green[500],
    display: "flex",
    alignItems: "center",
  },
  closedNotification: {
    marginRight: theme.spacing(2),
    color: blueGrey[700],
    display: "flex",
    alignItems: "center",
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(0),
    "& .MuiTypography-root": {
      color: "#A4A6B3",
    },
  },
  topRowInner: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginLeft: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
      marginRight: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
      marginTop: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
      marginBottom: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
    },
    "& :last-child": {
      marginRight: 0,
      marginLeft: 0,
    },

    "& .MuiTypography-h6:first-of-type": {
      marginLeft: 0,
    },
  },
}));

const linkStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiTypography-h4": {
      marginLeft: (props) =>
        props.orientation === "horizontal" ? theme.spacing(4) : 0,
      marginRight: (props) =>
        props.orientation === "horizontal" ? theme.spacing(1) : 0,
    },
  },
}));

const LabelValueDisplay = ({
  totalLink,
  label,
  spanValue,
  emphesizeValue = false,
  justify = "flex-start",
  orientation = "horizontal",
}) => {
  const classes = linkStyles({ orientation });
  return (
    <div
      className={classes.root}
      style={
        (justify
          ? { justifyContent: justify }
          : { justifyContent: "flex-start" },
        { flexDirection: orientation === "vertical" ? "column" : "row" })
      }
    >
      <Typography variant="h6" color={"textSecondary"}>
        {label}
      </Typography>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography
          display="inline"
          variant="h4"
          style={{ fontWeight: 400 }}
          color={emphesizeValue ? "primary" : "textPrimary"}
        >
          {totalLink}
        </Typography>
        {spanValue ? (
          <Typography component="span" color="textPrimary" variant="body1">
            ({spanValue}%)
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

const JobItem = ({ viewType = "list", ...props }) => {
  const theme = useTheme();
  const classes = useStyles({ ...props, viewType: viewType });
  const [confirmDuplicate, setConfirmDuplicate] = React.useState(false);
  const [confirmAutoDistribution, setConfirmAutoDistribution] = React.useState(
    false
  );

  const handleConfirmAutoDistribution = (event) => {
    setConfirmAutoDistribution(true);
  };

  const handleOnDialogAutoDistConfirmation = (setAutoDist) => {
    setConfirmAutoDistribution(false);
    props.onAutoDistribute(props.job.id, setAutoDist);
  };

  const handleConfirmDuplicate = (withFQ) => {
    setConfirmDuplicate(false);
    props.onDuplicate(props.job.id, withFQ);
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        {viewType === "list" ? (
          <React.Fragment>
            <Box className={classes.topRow}>
              <div className={classes.topRowInner}>
                <Avatar
                  alt=""
                  src={
                    props.job.author.profile_image
                      ? props.job.author.profile_image
                      : ""
                  }
                  style={{ width: 45, height: 45, margin: 0 }}
                />
                <Link
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  color="textPrimary"
                  onClick={() =>
                    props.history.push(
                      `/jobs/dashboard/${props.job.id}/?filterBy=newSuitables`
                    )
                  }
                >
                  <Chip
                    label={`New Candidates: ${
                      props.job.new_suitables
                        ? props.job.new_suitables.length
                        : 0
                    }`}
                    //onClick={handleClick}
                    icon={<PersonAddIcon />}
                  />
                </Link>
                <div>
                  <Typography
                    variant="body1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <SendRoundedIcon
                      color="secondary"
                      style={{
                        marginRight: 5,
                        transform: "scale(-1, 1)",
                        fontSize: "1rem",
                      }}
                    />{" "}
                    Total distributions: {props.job.total_distributions}
                  </Typography>{" "}
                </div>
                <Divider orientation="vertical" flexItem />
                <div>
                  <Typography
                    variant="body1"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <AccessTimeRoundedIcon
                      color="secondary"
                      style={{
                        marginRight: 5,
                        transform: "scale(-1, 1)",
                        fontSize: "1rem",
                      }}
                    />{" "}
                    Published:{" "}
                    {props.job.acf.job_published_date
                      ? formatDistanceToNow(
                          new Date(props.job.acf.job_published_date),
                          { addSuffix: true }
                        )
                      : "In Draft"}
                  </Typography>{" "}
                </div>
              </div>
              <div className={classes.topRowInner}>
                <Tooltip title="Duplicate Job">
                  <IconButton
                    aria-label="copy"
                    className={classes.margin}
                    size="small"
                    color="primary"
                    onClick={() => setConfirmDuplicate(true)}
                  >
                    <FileCopyRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit Job">
                  <IconButton
                    aria-label="edit"
                    className={classes.margin}
                    size="small"
                    color="primary"
                    onClick={(e) =>
                      props.menuItemClick(e, "editJob", props.job.id)
                    }
                  >
                    <EditRoundedIcon />
                  </IconButton>
                </Tooltip>
                <Box>
                  <Tooltip title="If set, after candidate import or candidate manual link to job, a job invitation will be sent">
                    <FormControlLabel
                      label="Automatic Distribution"
                      disabled={props.job.acf.status !== "active"}
                      control={
                        <Switch
                          checked={
                            props.job && props.job.is_auto_distribution
                              ? props.job.is_auto_distribution
                              : false
                          }
                          color="secondary"
                          onChange={handleConfirmAutoDistribution}
                          name="autDistribution"
                        />
                      }
                    />
                  </Tooltip>
                </Box>
                <Button
                  variant="outlined"
                  aria-label="add"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  Add Candidates
                </Button>
                <JobsMoreActions
                  menuItemClick={props.menuItemClick}
                  options={props.menuOptions}
                  jobId={props.job.id}
                  jobClosed={props.job.acf.status !== "active"}
                />
              </div>
            </Box>
            <Divider />
            <Grid container style={{ marginTop: theme.spacing(2) }}>
              <Grid item xs={12}>
                <div className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{ fontWeight: 700 }}
                  >
                    <Link
                      style={{
                        cursor: "pointer",
                        fontWeight: 700,
                        textDecoration: "none",
                      }}
                      color="textPrimary"
                      onClick={() =>
                        props.history.push(`/jobs/dashboard/${props.job.id}`)
                      }
                    >
                      {props.job.name ? props.job.name : ""}
                    </Link>
                  </Typography>
                </div>
                <div className={classes.topRowInner}>
                  <Typography variant="h6" color="textSecondary">
                    {props.job.acf.company.companyName
                      .replace("&#8221;", '"')
                      .replace("&#8211;", "-")
                      .replace("&#8217;", "'")}
                  </Typography>
                  <Divider orientation="vertical" flexItem />
                  <Typography variant="h6" color="textSecondary">
                    {props.job.acf.job_area}
                  </Typography>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              justify="space-between"
              alignItems="center"
              className={classes.jobDashboard}
            >
              <Grid item xs={2}>
                <LabelValueDisplay
                  label="Linked to job"
                  totalLink={props.job.total_assigned}
                />
              </Grid>
              <Grid item xs={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    variant="h6"
                    color={"textSecondary"}
                    style={{ marginLeft: theme.spacing(1) }}
                  >
                    Dist.Data
                  </Typography>

                  <ResponseRateProgress
                    maxWidth={400}
                    totalWatched={props.job.total_watched}
                    totalWatchedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              props.job.total_watched / props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                    totalInterested={props.job.total_interested}
                    totalInterestedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              props.job.total_interested /
                                props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                    totalWatchedNotResponded={
                      props.job.total_watched -
                      props.job.total_interested -
                      props.job.total_declined
                    }
                    totalWatchedNotRespondedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              (props.job.total_watched -
                                props.job.total_interested -
                                props.job.total_declined) /
                                props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                    totalNotInterested={props.job.total_declined}
                    totalNotInterestedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              props.job.total_declined /
                                props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Link
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                  }}
                  color="textPrimary"
                  onClick={() =>
                    props.history.push(`/jobs/dashboard/${props.job.id}`)
                  }
                >
                  <LabelValueDisplay
                    justify="center"
                    label="Suitables"
                    totalLink={`${
                      props.job.total_suitables
                        ? props.job.total_suitables.length
                        : 0
                    }`}
                    spanValue={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              props.job.total_suitables.length /
                                props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : null
                    }
                  />
                </Link>
              </Grid>
              <Grid item xs={3}>
                <LabelValueDisplay
                  justify="center"
                  label="Recruited"
                  totalLink={props.job.total_recruited}
                  emphesizeValue
                />
              </Grid>
            </Grid>

            <JobFooter
              status={props.job.acf.status}
              publishDate={props.job.post_modified}
              jobId={props.job.id}
              externalId={props.job.acf.job_external_id}
              coordinator={props.job.author.displayName}
              lastPublished={props.job.acf.job_published_date}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box
              mt={theme.spacing(0.5)}
              mb={theme.spacing(1.5)}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Chip
                label={`New Candidates: ${
                  props.job.new_suitables ? props.job.new_suitables.length : 0
                }`}
                icon={<PersonAddIcon />}
              />
              <FormControlLabel
                label="Automatic Distribution"
                control={
                  <Switch
                    checked={
                      props.job && props.job.is_auto_distribution
                        ? props.job.is_auto_distribution
                        : false
                    }
                    color="secondary"
                    onChange={handleConfirmAutoDistribution}
                    name="autDistribution"
                  />
                }
              />
            </Box>
            <Divider />
            <Box className={classes.topRow}>
              <div className={classes.topRowInner}>
                <Avatar
                  alt=""
                  src={
                    props.job.author.profile_image
                      ? props.job.author.profile_image
                      : ""
                  }
                  style={{ width: 45, height: 45, margin: 0 }}
                />
              </div>
              <div className={classes.topRowInner}>
                <IconButton
                  aria-label="copy"
                  className={classes.margin}
                  size="small"
                  color="primary"
                  onClick={() => setConfirmDuplicate(true)}
                >
                  <FileCopyRoundedIcon />
                </IconButton>
                <IconButton
                  aria-label="edit"
                  size="small"
                  color="primary"
                  onClick={(e) =>
                    props.menuItemClick(e, "editJob", props.job.id)
                  }
                >
                  <EditRoundedIcon />
                </IconButton>
                <Button
                  variant="outlined"
                  aria-label="add"
                  color="primary"
                  endIcon={<AddIcon />}
                >
                  Add Candidates
                </Button>
                <JobsMoreActions
                  menuItemClick={props.menuItemClick}
                  options={props.menuOptions}
                  jobId={props.job.id}
                  jobClosed={props.job.acf.status !== "active"}
                />
              </div>
            </Box>
            <Divider />
            <Box my={theme.spacing(1)} style={{ minHeight: 65 }}>
              <div className={classes.title}>
                <Typography
                  variant="h5"
                  color="primary"
                  style={{ fontWeight: 700 }}
                >
                  <Link
                    style={{
                      cursor: "pointer",
                      fontWeight: 700,
                      textDecoration: "none",
                    }}
                    color="textPrimary"
                    onClick={() =>
                      props.history.push(`/jobs/dashboard/${props.job.id}`)
                    }
                  >
                    {props.job.name ? props.job.name : ""}
                  </Link>
                </Typography>
              </div>
            </Box>

            <div className={classes.topRowInner}>
              <Typography variant="h6" color="textSecondary">
                {props.job.acf.company.companyName
                  .replace("&#8221;", '"')
                  .replace("&#8211;", "-")
                  .replace("&#8217;", "'")}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="h6" color="textSecondary">
                {props.job.acf.job_area}
              </Typography>
            </div>
            <Box className={classes.topRowInner} my={theme.spacing(2)}>
              <div>
                <Typography
                  variant="body1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#A4A6B3",
                  }}
                >
                  <SendRoundedIcon
                    color="secondary"
                    style={{
                      marginLeft: 5,
                      transform: "scale(-1, 1)",
                      fontSize: "1rem",
                    }}
                  />{" "}
                  Total distributions: {props.job.total_distributions}
                </Typography>{" "}
              </div>
              <Divider orientation="vertical" flexItem />
              <div>
                <Typography
                  variant="body1"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#A4A6B3",
                  }}
                >
                  <AccessTimeRoundedIcon
                    color="secondary"
                    style={{
                      marginRight: 5,
                      transform: "scale(-1, 1)",
                      fontSize: "1rem",
                    }}
                  />{" "}
                  {formatDistanceToNow(
                    new Date(props.job.acf.job_published_date),
                    { addSuffix: true }
                  )}
                </Typography>{" "}
              </div>
            </Box>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <LabelValueDisplay
                  label="Linked to job"
                  totalLink={props.job.total_assigned}
                  orientation="vertical"
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValueDisplay
                  justify="center"
                  label="Suitable"
                  orientation="vertical"
                  totalLink={`${props.job.total_suitables.length}`}
                  spanValue={
                    props.job.total_assigned > 0
                      ? (
                          parseFloat(
                            props.job.total_suitables.length /
                              props.job.total_assigned
                          ) * 100
                        ).toFixed(1)
                      : null
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LabelValueDisplay
                  justify="center"
                  label="Recruited"
                  totalLink={props.job.total_recruited}
                  orientation="vertical"
                  emphesizeValue
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection={"column"}
                >
                  <Typography
                    variant="h6"
                    color={"textSecondary"}
                    style={{ marginLeft: 0, marginBottom: theme.spacing(1) }}
                  >
                    Distribution Data
                  </Typography>
                  <ResponseRateProgress
                    maxWidth={400}
                    orientation="vertical"
                    totalWatched={props.job.total_watched}
                    totalWatchedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              props.job.total_watched / props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                    totalInterested={props.job.total_interested}
                    totalInterestedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              props.job.total_interested /
                                props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                    totalWatchedNotResponded={
                      props.job.total_watched -
                      props.job.total_interested -
                      props.job.total_declined
                    }
                    totalWatchedNotRespondedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              (props.job.total_watched -
                                props.job.total_interested -
                                props.job.total_declined) /
                                props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                    totalNotInterested={props.job.total_declined}
                    totalNotInterestedPercent={
                      props.job.total_assigned > 0
                        ? (
                            parseFloat(
                              props.job.total_declined /
                                props.job.total_assigned
                            ) * 100
                          ).toFixed(1)
                        : 0
                    }
                  />
                </Box>
              </Grid>

              <JobFooter
                status={props.job.acf.status}
                publishDate={props.job.post_modified}
                jobId={props.job.id}
                externalId={props.job.acf.job_external_id}
                coordinator={props.job.author.displayName}
                lastPublished={props.job.acf.job_published_date}
                orientation="vertical"
              />
            </Grid>
          </React.Fragment>
        )}
      </Paper>
      {confirmDuplicate && (
        <ConfirmDuplicateJob
          onClose={() => setConfirmDuplicate(false)}
          open={confirmDuplicate}
          onConfirm={handleConfirmDuplicate}
          jobId={props.job.id}
        />
      )}
      {confirmAutoDistribution && (
        <ConfirmationAutoDistribution
          onClose={() => setConfirmAutoDistribution(false)}
          open={confirmAutoDistribution}
          jobId={props.job.id}
          isAutoDistribute={props.job && props.job.is_auto_distribution}
          onConfirm={handleOnDialogAutoDistConfirmation}
        />
      )}
    </React.Fragment>
  );
};

export default withRouter(JobItem);
