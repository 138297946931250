import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Icon, Button } from "@material-ui/core";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import { SkillLevels } from "../../../../../config/jobsConfigData";
//import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));

const AISkills = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedSkill, setSelectedSkill] = React.useState("");
  const [yearsSkill, setYearsSkill] = React.useState(0);
  const [updatedChip, setUpdatedChip] = React.useState(-1);
  const [selectedKnowledgeLevel, setSelectedKnowledgeLevel] = React.useState(
    null
  );
  const [skillsAIObject, setSkillsAIObject] = React.useState([]);

  React.useEffect(() => {
    if (props.aIValue && Object.keys(props.aIValue).length) {
      const aiTempObject = props.aIValue.map((aiItem) => {
        return (
          [...skillsAIObject],
          {
            key: aiItem.skillId,
            skillId: aiItem.skillId,
            skillName: aiItem.skillName,
            yearsSkill: parseInt(aiItem.yearsSkill),
            knowledgeLevel: aiItem.knowledgeLevel,
          }
        );
      });
      setSkillsAIObject(aiTempObject);
    }
  }, [props.aIValue]);

  const handleSelectedSkill = (event) => {
    setSelectedSkill(event.target.value);
  };

  const buildAISkillObject = (event) => {
    props.addAI(skillsAIObject);
  };

  const handleYearsValue = (event) => {
    setYearsSkill(event.target.value);
  };

  const handleCheckEnableQ = (value) => {
    if (!value) setYearsSkill(0);
  };

  /*
  Single Skill Object: 
  {
      skillId: id,
      skillName: string,
      yearsSkill: num,      
  }
  */
  const handleAddSingleSkillAI = () => {
    skillsAIObject === null
      ? setSkillsAIObject([
          {
            skillId: skillsAIObject === null ? 1 : skillsAIObject.length + 1,
            skillName: selectedSkill,
            yearsSkill,
            knowledgeLevel: selectedKnowledgeLevel,
          },
        ])
      : setSkillsAIObject([
          ...skillsAIObject,
          {
            skillId: skillsAIObject.length + 1,
            skillName: selectedSkill,
            yearsSkill,
            knowledgeLevel: selectedKnowledgeLevel,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = skillsAIObject.find(
      (chip) => chip.skillId === updatedChip
    );

    //remove the selected chip from the array:
    const tempSkillAIObject = skillsAIObject.filter(
      (ai) => ai.skillId !== tempUpdatedChip.skillId
    );

    //update the form:
    setSkillsAIObject([
      ...tempSkillAIObject,
      {
        skillId: tempUpdatedChip.skillId,
        skillName: selectedSkill,
        yearsSkill: yearsSkill,
        knowledgeLevel: selectedKnowledgeLevel,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setYearsSkill(0);
    setUpdatedChip(-1);
    setSelectedSkill("");
    setSelectedKnowledgeLevel(null);
  };

  const handleRemoveSkill = (skillId) => {
    //locate index id
    const newSkillFQObject = skillsAIObject.filter(
      (exp) => exp.skillId !== skillId
    );
    setSkillsAIObject(newSkillFQObject);
    clearForm();
  };

  const handleKnowledgeLevelChange = (event) => {
    setSelectedKnowledgeLevel(event.target.value);
  };

  const handleReadChip = (sklId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleSklObj = skillsAIObject.find(
        (skill) => skill.skillId === sklId
      );
      setYearsSkill(singleSklObj.yearsSkill);
      setSelectedSkill(singleSklObj.skillName);
      setSelectedKnowledgeLevel(singleSklObj.knowledgeLevel);
      setUpdatedChip(sklId);
    }
  };

  const displayChips =
    skillsAIObject !== null
      ? skillsAIObject.map((skl) => {
          return (
            <Chip
              key={skl.skillId}
              icon={<FaceIcon />}
              label={skl.skillName}
              clickable
              color="primary"
              onClick={() => handleReadChip(skl.skillId)}
              onDelete={() => handleRemoveSkill(skl.skillId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== skl.skillId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            className={classes.modalTitle}
          >
            {props.candidateFullName !== "undefined" &&
            props.candidateFullName.length > 1
              ? `Add Skills for ${props.candidateFullName}`
              : "Add Skills"}
          </Typography>
          <Divider />
          <div>
            <Collapse in={skillsAIObject !== null}>
              <Typography variant="h5" className={classes.modalTitle}>
                Skills & Abilities
              </Typography>
              {displayChips}
              <Divider />
            </Collapse>
          </div>
          <Typography
            style={{ fontWeight: 700, marginBottom: theme.spacing(2) }}
            color="textPrimary"
            variant="subtitle1"
          >
            Add Skills
          </Typography>
          <TextField
            id="skill"
            label="Skills"
            variant="outlined"
            value={selectedSkill}
            onChange={handleSelectedSkill}
            style={{ width: "100%" }}
          />

          <QuestionValueInput
            question="Number of years of experience for this skill:"
            minYearsValue={handleYearsValue}
            disableVerificationQuestion
            value={yearsSkill}
            checkEnalbeQ={handleCheckEnableQ}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Level of knowledge in this skill
            </FormLabel>
            <RadioGroup
              aria-label="knowledgeLevel"
              name="knowledgeLevel"
              value={selectedKnowledgeLevel}
              onChange={handleKnowledgeLevelChange}
            >
              {SkillLevels.map((skill) => (
                <FormControlLabel
                  key={skill.value}
                  value={skill.value}
                  control={<Radio color="primary" />}
                  label={skill.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={selectedSkill === ""}
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              onClick={
                updatedChip <= 0 ? handleAddSingleSkillAI : updateSelectedChip
              } //add new Object with all values of this Component
            >
              {updatedChip > 0 ? "Update Skill" : "Add Skill"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                skillsAIObject === null ||
                skillsAIObject === "undefined" ||
                Object.keys(skillsAIObject).length <= 0 ||
                skillsAIObject.length <= 0
              }
              className={classes.button}
              endIcon={<Icon>done</Icon>}
              onClick={buildAISkillObject}
            >
              Done
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default React.memo(AISkills);
