import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import JobFooter from "../../Screens/Jobs/JobsList/JobListItem/JobFooter/JobFooter";

const JobHeader = (props) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="h4" gutterBottom color="textPrimary">
        {props.job.name}
      </Typography>
      <Typography variant="h6" color="textSecondary">
        {`${props.job.acf.company.companyName
          .replace("&#8221;", '"')
          .replace("&#8211;", "-")
          .replace("&#8217;", "'")} | ${props.job.acf.job_area}`}
      </Typography>
      <Box my={theme.spacing(2)}>
        <JobFooter
          status={props.job.acf.status}
          publishDate={props.job.post_modified}
          jobId={props.job.id}
          externalId={props.job.acf.job_external_id}
          justifyContent="flex-start"
          coordinator={props.job.acf.job_coordinator}
          autoDistribution={props.job.auto_distribution}
        />
      </Box>
    </Box>
  );
};
export default JobHeader;
