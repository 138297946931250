import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 120,
  },
}));

const WithDashboardPaper = (props) => {
  const classes = useStyles();

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Paper
      className={props.customHeight ? classes.paper : fixedHeightPaper}
      style={{
        position: "relative",
        overflow: "hidden",
        color: "#333",
        height: props.customHeight ? props.customHeight : null,
        direction: props.direction ? props.direction : "ltr",
      }}
    >
      {props.children}
    </Paper>
  );
};

export default WithDashboardPaper;
