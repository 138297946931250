import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { formatDistanceToNow } from "date-fns";

function returnlastModified(date) {
  return (
    <Typography variant="body1" size="small" color="textSecondary">
      {formatDistanceToNow(new Date(date), { addSuffix: true })}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const LastModified = (props) => {
  const classes = useStyles();

  return (
    <Typography
      color={"textPrimary"}
      variant="body1"
      component="div"
      className={classes.root}
    >
      {returnlastModified(props.toDate)}
    </Typography>
  );
};
export default LastModified;
