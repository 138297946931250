import React, { useContext } from "react";
import {
  Grid,
  Button,
  MenuItem,
  Typography,
  Collapse,
  Box,
  Checkbox,
  ListItemText,
  Container,
} from "@material-ui/core";
import { DashboardPeriod } from "../../config/dashboardConfigData";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import DashboardSkeleton from "../../UI/Skeletons/DashboardSkeleton";
import AxiosErrorHandling from "../../components/AxiosErrorHandling/AxiosErrorHandling";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import ActivityTable from "./ActivityTable";
import { Primary, Secondary } from "../../config/colors";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ConfigurationListsContext from "../../context/configurationListsContext";
import PeriodSelection from "../../components/PeriodSelection/PeriodSelection";
//import CoordinatorsDropdown from "../../components/CoordinatorsDropdown/CoordinatorsDropdown";
import CoordinatorsDropdownMultipleSelection from "../../components/CoordinatorsDropdown/CoordinatorsDropdownMultipleSelection";

const DeatiledActivityReport = (props) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(true);
  const [activityReport, setActivityReport] = React.useState([]);
  const [selectedPeriod, setSelectedPeriod] = React.useState("last7");
  const [reportPeriod, setReportPeriod] = React.useState({
    from: moment().subtract(7, "days").format("YYYY/MM/DD"),
    to: moment().format("YYYY/MM/DD"),
  });
  const [selectedCoordinator, setSelectedCoordinator] = React.useState([
    parseInt(localStorage.getItem("userId")),
  ]);
  const [customDateFrom, setCustomDateFrom] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const [customDateTo, setCustomDateTo] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const usersContext = useContext(ConfigurationListsContext);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    reportPeriod === null
      ? getDashboardData(
          moment().subtract(7, "days").format("YYYY/MM/DD"),
          moment().format("YYYY/MM/DD")
        )
      : getDashboardData(reportPeriod.from, reportPeriod.to);
  }, [selectedPeriod]);

  const handleChange = (value) => {
    setSelectedCoordinator(value);
  };

  const getDashboardData = (fromDate, toDate) => {
    setIsLoading(true);
    axios
      .get("/wp-json/api/v2/activity_report", {
        params: {
          from: fromDate ? moment(fromDate).format("YYYY/MM/DD") : null,
          to: toDate ? moment(toDate).format("YYYY/MM/DD") : null,
          authors: selectedCoordinator,
        },
      })
      .then((res) => {
        setActivityReport(res.data.activity_report);
        setIsLoading(false);
      })
      .catch((err) => {
        //since this is the first call, check for authetication error:
        setIsLoading(false);
        setError(err.response);
      });
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    let localPeriod = {
      from: moment().subtract(7, "days").format("YYYY/MM/DD"),
      to: moment().format("YYYY/MM/DD"),
    };
    switch (event.target.value) {
      case "today":
        localPeriod = {
          from: moment().format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "yesterday":
        localPeriod = {
          from: moment().subtract(1, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "last7":
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last28":
        localPeriod = {
          from: moment().subtract(28, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last90":
        localPeriod = {
          from: moment().subtract(90, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "thisYear":
        localPeriod = {
          from: moment(`${moment().year()}/1/1`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
          to: moment(`${moment().year()}/12/31`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
        };
        break;

      default:
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
    }
    setReportPeriod(localPeriod);
  };

  const handleCustomDateCollection = () => {
    getDashboardData(customDateFrom, customDateTo);
  };

  const handleCancelDateCollection = () => {
    setCustomDateFrom(moment().format("YYYY/MM/DD"));
    setCustomDateTo(moment().format("YYYY/MM/DD"));
    setSelectedPeriod("today");
  };

  return (
    <Container maxWidth="xl">
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className="test"
      >
        <Grid
          item
          xs={12}
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-end"
        >
          <PeriodSelection
            label="Period"
            selectedPeriod={selectedPeriod}
            onPeriodChange={handlePeriodChange}
          />
          <Box my={theme.spacing(2)}>
            <CoordinatorsDropdownMultipleSelection
              label={"Recruiter(s)"}
              selectedRecruiters={
                selectedCoordinator ? selectedCoordinator : ""
              }
              onRecruiterChange={handleChange}
              onClose={() =>
                getDashboardData(reportPeriod.from, reportPeriod.to)
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={selectedPeriod === "custom"}>
            <Box display="flex" alignItems="center">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-from"
                  label="Start Date"
                  minDate={moment().subtract(365, "days").format("YYYY/MM/DD")}
                  maxDate={moment().format("YYYY/MM/DD")}
                  value={customDateFrom}
                  onChange={(date) => setCustomDateFrom(date)}
                  KeyboardButtonProps={{
                    "aria-label": "fromDate",
                  }}
                  style={{ marginTop: 8, marginRight: theme.spacing(2) }}
                />
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="yyyy/MM/dd"
                  margin="normal"
                  id="date-picker-from"
                  label="Until"
                  minDate={moment(customDateFrom).format("YYYY/MM/DD")}
                  value={customDateTo}
                  onChange={(date) => setCustomDateTo(date)}
                  KeyboardButtonProps={{
                    "aria-label": "toDate",
                  }}
                  style={{ marginTop: 8, marginRight: theme.spacing(2) }}
                />
              </MuiPickersUtilsProvider>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCustomDateCollection}
                style={{ marginRight: theme.spacing(3) }}
              >
                Apply
              </Button>
              <Button onClick={handleCancelDateCollection}>Cancel</Button>
            </Box>
          </Collapse>
        </Grid>
        {isLoading ? (
          <DashboardSkeleton
            open={true}
            style={{ marginTop: theme.spacing(4) }}
          />
        ) : (
          <Grid item xs={12} style={{ marginTop: theme.spacing(4) }}>
            <Typography variant="h5" color="textPrimary">
              Activity Report
            </Typography>
            <Box mt={theme.spacing(2)}>
              <ActivityTable data={activityReport}></ActivityTable>
            </Box>
          </Grid>
        )}
        {error && (
          <AxiosErrorHandling
            title="Error occured in dashboard module"
            error={error}
          />
        )}
      </Grid>
    </Container>
  );
};
export default DeatiledActivityReport;
