import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { Primary } from "../../../config/colors";

const CandidateTextFieldFilled = withStyles({
  root: {
    "& label.Mui-focused": {
      color: Primary.main,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: Primary.main,
    },
  },
})(TextField);

export default CandidateTextFieldFilled;
