import React from "react";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Typography, useTheme } from "@material-ui/core";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
      fontSize: "1rem",
    },
    "& .MuiDivider-root": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    "& .MuiTypography-root": {
      color: "#A4A6B3",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

const JobFooter = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems={props.orientation === "vertical" ? "flex-start" : "center"}
      justifyContent={
        props.orientation === "vertical"
          ? "center"
          : props.justifyContent
          ? props.justifyContent
          : "space-between"
      }
      className={classes.root}
      flexDirection={props.orientation === "vertical" ? "column" : "row"}
    >
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={
          props.orientation === "vertical" ? "center" : "space-between"
        }
        mb={props.orientation === "vertical" ? theme.spacing(1) : 0}
      >
        {props.status === "active" ? (
          <TodayRoundedIcon color="secondary" />
        ) : (
          <CloseIcon
            style={{ color: red[500], marginRigh: 15, marginLeft: 15 }}
          />
        )}
        <Typography variant="body1" color="textSecondary">
          Created at {props.publishDate}{" "}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body1" color="textSecondary">
          Recruiter owner: {props.coordinator}{" "}
        </Typography>
        {props.justifyContent === "flex-start" && (
          <Divider orientation="vertical" flexItem />
        )}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" color="textSecondary">
          Job ID: {props.jobId}{" "}
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body1" color="textSecondary">
          External ID: {props.externalId}{" "}
        </Typography>
      </Box>
    </Box>
  );
};
export default JobFooter;
