import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../languages/en.json";
import Greek from "../languages/gr.json";
export const LangContext = React.createContext({
  locale: "en",
  onLanguageChange: () => {},
});

// //const local = navigator.language;
// const localeSwitcherEng = "en-US";
// let lang = English;

const LocalizationWrapper = (props) => {
  const [locale, setLocale] = useState("en-US");
  const [messages, setMessages] = useState(English);

  const onLanguageChangeHandler = (lang) => {
    switch (lang) {
      case "en":
        setLocale("en-US");
        setMessages(English);
        break;
      case "gr":
        setLocale("el-GR");
        setMessages(Greek);
        break;
      default:
        setLocale("en-US");
        setMessages(English);
        break;
    }
  };

  return (
    <LangContext.Provider
      value={{ locale, onLanguageChange: onLanguageChangeHandler }}
    >
      <IntlProvider
        messages={messages}
        locale={locale}
        defaultRichTextElements={{
          b: (chunks) => <b>{chunks}</b>,
          br: () => <br />,
          p: (chunks) => <p>{chunks}</p>,
        }}
      >
        {props.children}
      </IntlProvider>
    </LangContext.Provider>
  );
};

export default LocalizationWrapper;
