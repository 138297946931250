import React from "react";
import { Container, Backdrop, Paper, Button, Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import RegistrationStep1 from "./RegistrationStep1";
import RegistrationStep2 from "./RegistrationStep2";
import VerificationStage from "./VerificationStage";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import cookies from "js-cookie";
import axios from "axios";
import validator from "validator";
import RegistrationStep4 from "./RegistrationStep4";
import { useIntl, FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& .MuiPaper-root": {
      borderRadius: 10,
      padding: theme.spacing(5),
    },
  },
  hidden: {
    display: "none",
  },
  whiteIcon: {
    color: "#fff",
  },
}));

const Registration = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [open, setOpen] = React.useState(true);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [gender, setGender] = React.useState("other");
  const [mobile, setMobile] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [registeredUser, setRegisteredUser] = React.useState(null);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [selectedProfression, setSelectedProfession] = React.useState([]);
  const [selectedSector, setSelectedSector] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleContinueValidation = () => {
    switch (currentStep) {
      case 1:
        return firstName !== "" && lastName !== "";
      case 2:
        return validator.isMobilePhone(mobile) && validator.isEmail(email);
      case 4:
        //return <RegistrationStep4 />;

        break;
      default:
        return false;
    }
  };

  React.useEffect(() => {
    async function getRegistrationData() {
      // You can await here
      setIsLoading(true);
      //if user has reached this step, clear his login cookies, if exists
      cookies.remove("token");
      cookies.remove("cand_id");

      try {
        const res = await axios.get("/wp-json/api/v2/accept_registration", {
          params: {
            email,
            mobile,
            firstName,
            lastName,
            gender,
          },
        });
        setRegisteredUser(res.data);
        if (!res.data.exists) {
          //New candidate, set token cookie for login
          setCurrentStep((prev) => prev + 1);
          cookies.set("cand_id", res.data.profile.id, { expires: 3 });
          cookies.set("token", res.data.profile.first_login_token, {
            expires: 3,
          });
          props.history.push("/my-profile/lobby/");
        }

        setIsLoading(false);
      } catch (e) {
        console.log("err");
        setIsLoading(false);
      }
      // ...
    }
    if (currentStep === 3) {
      getRegistrationData();
    }
  }, [currentStep]);

  const handleNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const handleBack = () => {
    if (currentStep > 1) setCurrentStep((prev) => prev - 1);
  };

  const handleSetMobile = (value) => {
    setMobile(value);
  };

  const handleSetEmail = (value) => {
    setEmail(value);
  };

  const handleRedirectToLogin = () => {
    props.history.push("/my-profile/login");
  };

  const handleSectorSelection = (value) => {
    setSelectedSector(value);
  };

  const handleOnSectorRemove = (value) => {
    const newSectors = selectedSector.filter((item) => item.id !== value);
    setSelectedSector(newSectors);
  };

  const handleOnProfessionSelect = (value) => {
    setSelectedProfession(value);
  };

  const handleOnProfessionRemove = (value) => {
    const newProfessions = selectedProfression.filter(
      (item) => item.id !== value
    );
    setSelectedProfession(newProfessions);
  };

  const renderScreen = () => {
    switch (currentStep) {
      case 1:
        return (
          <RegistrationStep1
            firstName={firstName}
            lastName={lastName}
            gender={gender}
            onFirstNameChange={(event) => setFirstName(event.target.value)}
            onLastNameChange={(event) => setLastName(event.target.value)}
            onGenderChange={handleChangeGender}
          />
        );
      case 2:
        return (
          <RegistrationStep2
            firstName={firstName}
            female={gender === "female"}
            email={email}
            mobile={mobile}
            onMobileChange={handleSetMobile}
            onEmailChange={handleSetEmail}
          />
        );
      case 3:
        return (
          <VerificationStage
            message={intl.formatMessage({
              id: "candidateProfile.signUp.verifyExists",
              defaultMessage:
                "Hold on. Just checking if you already registered...",
            })}
            loading={isLoading}
            foundDuplicate={registeredUser && registeredUser.exists}
            foundDuplicateMessage={intl.formatMessage({
              id: "candidateProfile.signUp.alreadyExists",
              defaultMessage:
                "Wait! I think you're already registered to our system.",
            })}
            redirectLogin={handleRedirectToLogin}
          />
        );
      case 4:
        return (
          <RegistrationStep4
            onSectorSelect={handleSectorSelection}
            onSectorRemove={handleOnSectorRemove}
            sectorsValue={selectedSector}
            onProfessionSelect={handleOnProfessionSelect}
            onProfessionRemove={handleOnProfessionRemove}
          />
        );
      default:
        return (
          <RegistrationStep1
            firstName={firstName}
            lastName={lastName}
            gender={gender}
            onFirstNameChange={(event) => setFirstName(event.target.value)}
            onLastNameChange={(event) => setLastName(event.target.value)}
            onGenderChange={handleChangeGender}
          />
        );
    }
  };

  return (
    <React.Fragment>
      <Backdrop open={open}>
        <Container
          maxWidth={currentStep === 4 ? "md" : "sm"}
          className={classes.root}
        >
          <Paper elevation={0}>
            {renderScreen()}
            {currentStep !== 3 && (
              <Box
                display="flex"
                justifyContent={
                  currentStep === 1 ? "flex-end" : "space-between"
                }
                mt={theme.spacing(5)}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={currentStep === 1 ? classes.hidden : ""}
                  startIcon={<ChevronLeftRoundedIcon />}
                  onClick={handleBack}
                >
                  <FormattedMessage id="general.back" defaultMessage={"Back"} />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!handleContinueValidation()}
                  onClick={handleNextStep}
                  endIcon={<ChevronRightRoundedIcon />}
                >
                  <FormattedMessage
                    id="general.continue"
                    defaultMessage={"Continue"}
                  />
                </Button>
              </Box>
            )}
          </Paper>
          {currentStep > 3 && (
            <div
              style={{
                position: "absolute",
                top: -30,
                right: 0,
                cursor: "pointer",
              }}
            >
              <CancelRoundedIcon
                className={classes.whiteIcon}
                onClick={() => setOpen(false)}
              />
            </div>
          )}
        </Container>
      </Backdrop>
    </React.Fragment>
  );
};

export default withRouter(Registration);
