import React from "react";
import { useTheme } from "@material-ui/core/styles";
import TagsRow from "../../CandidateComponents/EditRow/tagsRow";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { useIntl, FormattedMessage } from "react-intl";

const RegistrationStep4 = (props) => {
  const [professions, setProfessionalList] = React.useState([]);
  const [sectors, setSectors] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const theme = useTheme();
  const intl = useIntl();
  React.useEffect(() => {
    async function getLists() {
      try {
        const rs = await axios.get("/wp-json/api/v2/areas_professions_list");
        setIsLoading(false);
        setProfessionalList(rs.data.sector_professions);
        setSectors(rs.data.sectors);
      } catch (e) {
        setIsLoading(false);
      }
    }
    getLists();
  }, []);
  return (
    <React.Fragment>
      <FormattedMessage
        id="candidateProfile.signUp.step4.jobLooking"
        defaultMessage="Tell me about the job your looking for"
      />
      <Typography
        variant="body1"
        align="center"
        color="textSecondary"
        style={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
      >
        <FormattedMessage
          id="candidateProfile.signUp.step4.helper"
          defaultMessage="You can update these details at any stage"
        />
      </Typography>

      <Box
        px={theme.spacing(2)}
        display="flex"
        align="center"
        flexDirection="column"
        m="auto"
      >
        <Box>
          {isLoading && <CircularProgress color="secondary" />}
          <TagsRow
            value={props.sectorsValue}
            label={intl.formatMessage({
              id: "candidateProfile.profile.myAreas",
              defaultMessage: "My Areas",
            })}
            tagsList={sectors}
            onSelect={(value) => props.onSectorSelect(value)}
            onRemove={(value) => props.onSectorRemove(value)}
            tagPlaceholder={intl.formatMessage({
              id: "candidateProfile.profile.myAreas.singular.placeholder",
              defaultMessage: "My Area",
            })}
          />
          <TagsRow
            value={props.selectedProfression}
            label={intl.formatMessage({
              id: "candidateProfile.profile.jobsLooking",
              defaultMessage: "The roles I'm looking",
            })}
            tagsList={professions}
            onSelect={(value) => props.onProfessionSelect(value)}
            onRemove={(value) => props.onProfessionSelect(value)}
            tagPlaceholder={intl.formatMessage({
              id: "candidateProfile.profile.jobsLooking.placeholder",
              defaultMessage: "Looking for...",
            })}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default RegistrationStep4;
