import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { dateFilters } from "../../config/jobsConfigData";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function SimpleListMenu(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Menu
        id="events-dates-filter"
        anchorEl={props.anchorEl}
        keepMounted
        open={Boolean(props.anchorEl)}
        onClose={props.close}
      >
        {dateFilters.map((option, index) => (
          <MenuItem
            key={option.id}
            selected={option.id === props.selected}
            onClick={() => props.menuItemSelected(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
