import React from "react";
import {
  Paper,
  Box,
  Tabs,
  Tab,
  IconButton,
  Avatar,
  Button,
  Typography,
  Link,
  Grid,
  Tooltip,
} from "@material-ui/core";
import FilterListSharpIcon from "@material-ui/icons/FilterListSharp";
import MyAlert from "../../../UI/MyAlert/MyAlert";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { getInitials } from "../../../config/candidatesConfigData";
//import axios from "../../../utils/axiosInstance";
import axios from "axios";
import blue from "@material-ui/core/colors/blue";
import clsx from "clsx";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import RateReviewIcon from "@material-ui/icons/RateReview";
import TimelineIcon from "@material-ui/icons/Timeline";
import EditIcon from "@material-ui/icons/Edit";
import EventsFilter from "./EventsFilter/EventsFilter";
import EventsList from "../../../components/EventsManager/EventsList";
import CandidateEvents from "./CandidateEvents/CandidateEvents";
import EventRow from "./CandidateEvents/EventRow";
import UnderJobContext from "../../../context/underJobContext";
import { CloseRounded, AddRounded } from "@material-ui/icons";
import DownloadCVIcon from "../../../Assets/CustomIcons/DownloadCV";

const handleDownloadCV = (candidateId) => {
  axios
    .post(
      `https://services.adamtotal.co.il/ADMBI/RequestFiles/clientno/1021/user/lynadmin/password/9PiuGwyPzQPAoCjQHdwO/candno/${candidateId}`,
      {},
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
      }
    )
    .then((res) => {
      const cvsRaw = res.data.map((file) => {
        return {
          fileContent: file.file_content,
          fileType: file.file_ext,
          fileName: file.file_name,
        };
      });
      const base64 = cvsRaw[0].fileContent;
      const link = document.createElement("a");
      link.href =
        "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
        base64;
      link.download = cvsRaw[0].fileName;
      link.click();
    })
    .catch((err) => {
      console.log(err);
    });
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  detailsBox: {
    display: "flex",
    flex: 1,
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    margin: theme.spacing(1),
    border: "1px solid #E4E4EA",
    borderRadius: "4px",
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  leftRoot: {
    padding: theme.spacing(2),
    minHeight: "70vh",
    "& .MuiIconButton-root": {
      marginLeft: theme.spacing(2),
    },
  },
  leftHeaderWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  leftHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
  },
  largeAvatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  generalFullDetails: {
    padding: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
  },
  iconLable: {
    minWidth: 230,
    lineHeight: 4,
    marginBottom: theme.spacing(2),

    "& .MuiSvgIcon-root": {
      marginRight: 15,
      color: blueGrey[700],
      alignItems: "center",
    },
  },
  buttons: {
    "& .MuiButton-root": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const SingleCandidateFullDetails = ({ onClose, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeTab, setActiveTab] = React.useState("timeline");
  const [activeMenuItem, setActiveMenuItem] = React.useState("");
  const [showFilter, setShowFilter] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [showEventsList, setShowEventsList] = React.useState(false);
  const [filter, setFilter] = React.useState({
    filterByEventType: "",
    filterByCurrentJob: false,
    filterByDate: "alltimes",
  });

  const professionsLabel = () => {
    if (props.candidateData.professions.length === 0) return "";

    let mainProfession = "";
    if (props.candidateData.professions.length === 1)
      mainProfession = props.candidateData.professions[0].name;

    let otherProfessions = "";
    for (let i = 1; i < props.candidateData.professions.length; i++)
      otherProfessions = props.candidateData.professions[i].name + ", ";

    return otherProfessions === "" ? (
      <Typography style={{ color: blueGrey[600] }} variant="h6">
        {mainProfession}
      </Typography>
    ) : (
      <React.Fragment>
        <Typography
          style={{ color: blueGrey[600] }}
          variant="h6"
          display="inline"
        >
          {props.candidateData.professions[0].name}
        </Typography>
        <Typography
          display="inline"
          style={{ color: blueGrey[600], fontSize: 16 }}
        >
          {` Also: ${otherProfessions}`}
        </Typography>
      </React.Fragment>
    );
  };
  const handleChangeActiveTab = (e, newTab) => {
    setActiveTab(newTab);
  };
  const displayTags = () => {
    let tags = "";
    if (props.candidateData.tags.length > 0) {
      tags = props.candidateData.tags.map((tag) => {
        return (
          <Typography key={tag.tag_id} display="inline">
            <Link
              style={{
                cursor: "pointer",
                color: blue[500],
                textDecoration: "none",
              }}
              onClick={() => props.tagSearch(tag.tag_name)}
            >
              {`#${tag.tag_name} `}
            </Link>
          </Typography>
        );
      });
    }

    return tags;
  };

  const handleFilterByEventType = (type) => {
    setFilter({
      filterByEventType: type,
      filterByCurrentJob: filter.filterByCurrentJob,
      filterByDate: filter.filterByDate,
    });
  };

  const handleFilterByDate = (dateItem) => {
    setFilter({
      filterByEventType: filter.filterByEventType,
      filterByCurrentJob: filter.filterByCurrentJob,
      filterByDate: dateItem.id,
    });
  };

  const handleFilterByCurrentJob = (event) => {
    setFilter({
      filterByEventType: filter.filterByEventType,
      filterByCurrentJob: event.target.checked,
      filterByDate: filter.filterByDate,
    });
  };

  const displayActiveTab = () => {
    switch (activeTab) {
      case "timeline": {
        return props.candidateData ? (
          <React.Fragment>
            {activeMenuItem ? (
              <EventRow
                eventType={activeMenuItem}
                draftMode
                authorImage={localStorage.getItem("userImage")}
                disableLastModified
                onAddEvent={handleAddEvent}
                cancelEvent={() => setActiveMenuItem("")}
              />
            ) : null}

            {props.candidateData.candidateEvents.length > 0 && (
              <UnderJobContext.Consumer>
                {(context) => (
                  <CandidateEvents
                    candidateData={props.candidateData.candidateEvents}
                    filterByType={filter.filterByEventType}
                    filter={filter}
                    onAddEvent={handleAddEvent}
                    showCurrentJobOnly={filter.filterByCurrentJob}
                    jobId={context.job ? parseInt(context.job.id) : null}
                    filterByDate={filter.filterByDate}
                    eventsOffset={(e) =>
                      props.eventsOffset(e, props.candidateData.id)
                    }
                  />
                )}
              </UnderJobContext.Consumer>
            )}
          </React.Fragment>
        ) : null;
      }

      default:
        break;
    }
  };

  // const handleMoreActionsMenuClick = (event, action) => {
  //   if (action === "editCandidate") props.editCandidate();
  //   setActiveMenuItem(action);
  // };

  const handleAddEvent = (
    type,
    value,
    additionalInfo = "",
    isUpdateMode = false
  ) => {
    setShowEventsList(false);
    props.eventAdded(
      {
        eventKey: type,
        eventValue: value,
        eventAuthor: localStorage.getItem("userId"),
        additionalInfo: additionalInfo,
        isUpdateMode,
      },
      props.candidateData.id
    );
  };

  const handleCloseMyAlert = (event, reason) => {
    //if (reason === "clickaway") return;
    setError(null);
  };

  return (
    <UnderJobContext.Consumer>
      {(context) => (
        <Paper className={clsx(classes.paper, classes.leftRoot)}>
          <Box
            style={{
              display: "flex",
              justifyContent: "wrap",
            }}
          >
            {props.candidateData && (
              <React.Fragment>
                <div style={{ padding: theme.spacing(2) }}>
                  <Avatar
                    alt={props.candidateData.name}
                    src={
                      props.candidateData.profile_image
                        ? props.candidateData.profile_image
                        : ""
                    }
                    className={classes.largeAvatar}
                  >
                    {getInitials(props.candidateData.name)}
                  </Avatar>
                </div>
                <div
                  style={{
                    marginRight: theme.spacing(2),
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      variant="h5"
                      style={{
                        cursor: "pointer",
                        fontWeight: 700,
                        paddingTop: theme.spacing(2),
                      }}
                      color="textPrimary"
                    >
                      {props.candidateData.name}
                    </Typography>
                    <Button
                      color="secondary"
                      endIcon={<CloseRounded color="secondary" />}
                      size="small"
                      onClick={onClose}
                    >
                      Close
                    </Button>
                  </Box>

                  {professionsLabel()}
                  <div>
                    <Typography
                      style={{ color: blueGrey[600] }}
                      variant="body1"
                    >
                      {`via ${props.candidateData.acf.source}`}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="caption" color="textSecondary">
                      Tags: {displayTags()}
                    </Typography>{" "}
                  </div>
                </div>
              </React.Fragment>
            )}
          </Box>
          <div className={classes.detailsBox}>
            {props.candidateData && (
              <GeneralDetailsBox
                data={props.candidateData}
                onEdit={props.onEdit}
              />
            )}
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            m={theme.spacing(2)}
          >
            <Box>
              <Tabs
                className={classes.tabs}
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChangeActiveTab}
              >
                <Tab
                  icon={<TimelineIcon />}
                  disabled={
                    props.candidateData === null ||
                    props.candidateData === "undefined"
                  }
                  value="timeline"
                  label="Timeline"
                />
                <Tab
                  icon={<RateReviewIcon />}
                  value="assetments"
                  label="Assesments"
                  disabled={
                    props.candidateData === null ||
                    props.candidateData === "undefined"
                  }
                />
              </Tabs>
            </Box>
            <Box className={classes.buttons}>
              <Button
                startIcon={<FilterListSharpIcon />}
                onClick={() => setShowFilter(!showFilter)}
                color="primary"
                variant="outlined"
              >
                {showFilter ? "Hide Filter Options" : "Filter Options"}
              </Button>
              {showFilter && (
                <EventsFilter
                  events={props.candidateData.candidateEvents}
                  showFilter={showFilter}
                  filter={filter}
                  filterByEvent={handleFilterByEventType}
                  getCurrentJob={handleFilterByCurrentJob}
                  getFilterByDate={handleFilterByDate}
                  currentJobOnly={filter.filterByCurrentJob}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddRounded />}
                onClick={() => setShowEventsList(true)}
              >
                Add Event
              </Button>
            </Box>
          </Box>
          {showEventsList && (
            <EventsList
              open={showEventsList}
              close={() => setShowEventsList(false)}
              onAddEvent={handleAddEvent}
            />
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid item xs={6}></Grid>
            </Grid>
            <Grid item xs={12}>
              <Box>{displayActiveTab}</Box>
            </Grid>
          </Grid>

          <MyAlert
            showAlert={error !== null}
            title={"An error occured while performing this operation"}
            message={error && error.data.message}
            severity={"error"}
            closeAlert={handleCloseMyAlert}
            errorInfo={error && error.data.code}
          />
        </Paper>
      )}
    </UnderJobContext.Consumer>
  );
};
export default SingleCandidateFullDetails;

export const GeneralDetailsBox = ({ onEdit, data, ...rest }) => {
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Typography variant="subtitle1">General Details</Typography>
      </Grid>
      <Grid item xs={4}>
        <GeneralDetailsKeyValue label="Phone" value={data.acf.mobile} />
        <GeneralDetailsKeyValue label="City" value={data.acf.city} />
        <GeneralDetailsKeyValue
          label="Identifier Number"
          value={data.acf.identifierNumber}
        />
        <GeneralDetailsKeyValue label="Gender" value={data.acf.gender} />
        <GeneralDetailsKeyValue
          label="Year of birth"
          value={data.acf.yearOfBirth}
        />
      </Grid>
      <Grid item xs={4}>
        <GeneralDetailsKeyValue label="Email" value={data.acf.email} />
        <GeneralDetailsKeyValue
          label="Family Status"
          value={data.acf.family_status}
        />
        <GeneralDetailsKeyValue label="Candidate ID" value={data.id} />
        <GeneralDetailsKeyValue
          label="External ID"
          value={data.acf.externalNumber}
        />
        <GeneralDetailsKeyValue label="Source" value={data.acf.source} />
      </Grid>
      <Grid item xs={2}>
        <Box display="flex" justifyContent="flex-end">
          <Tooltip arrow title="Download CV">
            <span>
              <IconButton
                onClick={() =>
                  handleDownloadCV(parseInt(data.acf.externalNumber))
                }
                disabled
              >
                <DownloadCVIcon
                  style={{
                    color: theme.palette.grey,
                  }}
                  strokeColor={theme.palette.grey}
                />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip arrow title="Edit Details">
            <IconButton onClick={onEdit}>
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </Grid>
    </Grid>
  );
};

export const GeneralDetailsKeyValue = ({ label, value }) => {
  const theme = useTheme();

  return (
    <Box
      mb={theme.spacing(1)}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Typography
        variant="body1"
        color="textPrimary"
        style={{ minWidth: 150, marginLeft: theme.spacing(2) }}
      >
        {label}
      </Typography>
      <Typography variant="body1" color="textSecondary">
        {value}
      </Typography>
    </Box>
  );
};
