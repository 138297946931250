import React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "@material-ui/core";

let EditIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M15.728 9.68608L14.314 8.27208L5 17.5861V19.0001H6.414L15.728 9.68608ZM17.142 8.27208L18.556 6.85808L17.142 5.44408L15.728 6.85808L17.142 8.27208ZM7.242 21.0001H3V16.7571L16.435 3.32208C16.6225 3.13461 16.8768 3.0293 17.142 3.0293C17.4072 3.0293 17.6615 3.13461 17.849 3.32208L20.678 6.15108C20.8655 6.33861 20.9708 6.59292 20.9708 6.85808C20.9708 7.12325 20.8655 7.37756 20.678 7.56508L7.243 21.0001H7.242Z" />
  </SvgIcon>
);
EditIcon = pure(EditIcon);
EditIcon.displayName = "EditIcon";
EditIcon.muiName = "SvgIcon";

export default EditIcon;
