import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import { Box, Typography, TextField, Grid } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import ConfigurationListsContext from "../../../../../context/configurationListsContext";

const FQExperience = ({ onCompletion, onCancel, fqEdit = null }) => {
  const [selectedProfession, setSelectedPropfession] = React.useState("");
  const [minYearsExperience, setMinYearsExperience] = React.useState(0);
  const [isExperienceIsMust, setIsExperienceIsMust] = React.useState(false);
  const { professionsList } = React.useContext(ConfigurationListsContext);
  React.useEffect(() => {
    if (fqEdit !== null) {
      const propfessions = professionsList.find(
        (item) => item.id === parseInt(fqEdit.selectedProfession)
      );
      setSelectedPropfession(propfessions);
      setMinYearsExperience(fqEdit.minYears);
      setIsExperienceIsMust(
        fqEdit.isAMust === 1 || fqEdit.isAMust ? true : false
      );
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  const handleMinYearsValue = (event) => {
    setMinYearsExperience(event.target.value);
  };

  const clearForm = () => {
    setSelectedPropfession("");
    setMinYearsExperience(0);
    setIsExperienceIsMust(false);
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["selectedProfession"] = parseInt(selectedProfession.id);
      existingObject["minYears"] = parseInt(minYearsExperience);
      existingObject["isAMust"] = isExperienceIsMust ? true : false;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      selectedProfession: parseInt(selectedProfession.id),
      minYears: parseInt(minYearsExperience),
      isAMust: isExperienceIsMust ? true : false,
    });
  };

  const handleChangeProfession = (event, value, reason) => {
    if (value !== null || reason !== "clear") setSelectedPropfession(value);
  };

  const handleIsExperienceIsMust = (event) => {
    setIsExperienceIsMust(event.target.checked);
  };

  const handleCheckEnableQ = (value) => {
    if (!value) setMinYearsExperience(0);
  };
  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          Select Profession:
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {
            <ConfigurationListsContext.Consumer>
              {(context) => (
                <Autocomplete
                  id="sectors"
                  key={selectedProfession}
                  options={context.professionsList}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={handleChangeProfession}
                  value={selectedProfession ? selectedProfession : null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      color="primary"
                      label="Select Profession"
                      variant="filled"
                    />
                  )}
                />
              )}
            </ConfigurationListsContext.Consumer>
          }
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <QuestionValueInput
            question="Minimal years of experience:"
            minYearsValue={handleMinYearsValue}
            isAMustCheck={handleIsExperienceIsMust}
            value={minYearsExperience}
            isAmustValue={isExperienceIsMust}
            checkEnalbeQ={handleCheckEnableQ}
          />
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
      <CancelApproveButtons
        positiveCaption="Done"
        negativeCaption="Cancel"
        positiveDisabled={selectedProfession === ""}
        onPositiveClick={handleOnCompletion}
        onNegativeClick={onCancel}
      />
    </React.Fragment>
  );
};
export default FQExperience;
