import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DashboardHeader from "../../../../components/JobHeader/DashboardHeader";
import Candidates from "../../../Candidates/Candidates";
//import PublishJobPreview from "./PublishJobPreview/PublishJobPreview";
import PublishJobPreview from "./PublishJobPreview/PublishJobPreview2";
import {
  FormControlLabel,
  Checkbox,
  Box,
  Grid,
  Snackbar,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import UnderJobContext from "../../../../context/underJobContext";
import SuitableCandidatesManager from "./SuitableCandidatesManagement/SuitableCanidatesManagement";
import DistributionReport from "../../../../Screens/Jobs/JobsList/JobDashboard/DistributionReport/DistributionReport";
import CandidateDashboardTabs from "./CandidateTabs";
import axios from "axios";
import AxiosErrorHandling from "../../../../components/AxiosErrorHandling/AxiosErrorHandling";
import JobStatistics from "./JobStatistics/JobStatistics";
import FilterCandidates from "../../../../components/FilterCandidatesInJobDrowdown/FilterCandidates";
import { withRouter } from "react-router-dom";
import ErrorBoundary from "../../../../components/ErrorBoundary/ErrorBoundary";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    "& .MuiPaper-root": {
      padding: theme.spacing(2),
    },
  },
  distributionReport: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(1),
  },
});

const TAB_SUITABLE_CANDIDATES = 0;
const TAB_DISQUALIFIED_CANDIDATES = 1;
const TAB_ALL_CANDIDATES = 2;
const TAB_CVREFERRALS_CANDIDATES = 3;
const TAB_STATISTICS_CANDIDATES = 4;

class JobDashbaord extends React.Component {
  state = {
    jobData: {},
    //candidateListIds: [],
    distributePreview: false,
    candidatesList: [],
    jobTimeLimit: 72,
    jobRecruitmentTarget: 1,
    filterCandidateList: [],
    showPublishConfirmAlert: false,
    totalCVReferrals: 0,
    showCandidatesListSelection: false,
    suitableCandidatesMode: false,
    showDistributionHistory: false,
    filterCandidateOption: "all",
    activeTab: 0,
    isLoading: true,
    error: null,
  };

  async componentDidMount() {
    const filterByParam = new URLSearchParams(this.props.location.search).get(
      "filterBy"
    );

    this.setState({
      totalCVReferrals: this.props.totalCVReferrals,
      filterCandidateOption: filterByParam ? filterByParam : "all",
      activeTab: filterByParam ? 2 : 0,
    });
    filterByParam && this.handleCandidatesFilterChange(filterByParam);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.searchFilter !== this.props.searchFilter &&
      this.state.activeTab !== TAB_ALL_CANDIDATES
    ) {
      this.setState({ activeTab: TAB_ALL_CANDIDATES });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.job !== state.jobData)
      return {
        jobData: props.job,
      };
    if (state.totalCVReferrals !== props.totalCVReferrals)
      return { totalCVReferrals: props.totalCVReferrals };
    if (state.distributePreview !== props.showDistributePreview) {
      return { distributePreview: props.showDistributePreview };
    }
    return null;
  }

  handleGetCandidates = (candidatesList) => {
    this.setState({ candidatesList });
  };

  //manage option sends the list to the management screen
  handleFilterClick = (filter, manage = false) => {
    this.setState({
      filterCandidateList: filter,
      suitableCandidatesMode: manage,
    });
  };
  handleConfirmPublishJob = () => {
    this.setState({
      showPublishConfirmAlert: true,
      distributePreview: false,
    });
    this.props.onPreviewDistribution(false);
  };
  handleShowCandidateListSelection = () => {
    this.setState({ showCandidatesListSelection: true });
  };

  handleChangeHistoryView = () => {
    this.setState((state, props) => {
      return { showDistributionHistory: !state.showDistributionHistory };
    });
  };

  handleTabChange = (event, newValue) => {
    this.setState({ activeTab: newValue });
  };

  handleCandidateRating = async (candidateId, value) => {
    try {
      axios.post(`/wp-json/api/v2/candidate_events/${candidateId}`, {
        eventKey: "addRating",
        eventValue: value,
        eventAuthor: localStorage.getItem("userId"),
        additionalInfo: "",
        isUpdateMode: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  handleCVReferrals = async (id) => {
    try {
      await axios.post(`/wp-json/api/v2/candidate_events/${id}`, {
        eventKey: "cvReferrals",
        eventValue: this.state.jobData.id,
        eventAuthor: parseInt(localStorage.getItem("userId")),
        additionalInfo: "",
        isUpdateMode: false,
      });
      //update state:
      const localStateJobData = this.state.jobData;
      if (!localStateJobData.total_cv_referrals.includes(id)) {
        localStateJobData.total_cv_referrals = [
          ...localStateJobData.total_cv_referrals,
          id,
        ];
        this.setState({
          jobData: localStateJobData,
        });
      }
    } catch (error) {
      this.setState({ error: error.response });
    }
  };

  buildNotResponsdedArray = () => {
    let notResponded = [...this.state.jobData.total_watched];
    this.state.jobData.total_declined &&
      this.state.jobData.total_declined.map((item) => {
        const index = notResponded.indexOf(item);
        if (index > -1) {
          notResponded.splice(index, 1);
        }
      });
    this.state.jobData.total_partially_passed &&
      this.state.jobData.total_partially_passed.map((item) => {
        const index = notResponded.indexOf(item);
        if (index > -1) {
          notResponded.splice(index, 1);
        }
      });
    this.state.jobData.total_passed &&
      this.state.jobData.total_passed.map((item) => {
        const index = notResponded.indexOf(item);
        if (index > -1) {
          notResponded.splice(index, 1);
        }
      });
    this.state.jobData.total_auto_disqualified &&
      this.state.jobData.total_auto_disqualified.map((item) => {
        const index = notResponded.indexOf(item);
        if (index > -1) {
          notResponded.splice(index, 1);
        }
      });
    return notResponded;
  };

  buildTotalInterested = () => {
    //Total Interested: declined + pass + partiallyPassd + autoDisqualified
    let totalInterested = []
      .concat(this.state.jobData.total_partially_passed)
      .concat(this.state.jobData.total_passed)
      .concat(this.state.jobData.total_auto_disqualified);

    return totalInterested;
  };

  buildTotalSuitables = () => {
    //Total Interested: declined + pass + partiallyPassd + autoDisqualified
    let totalSuitables = []
      .concat(this.state.jobData.total_partially_passed)
      .concat(this.state.jobData.total_passed);

    return totalSuitables;
  };

  buildNotHandledSuitables = () => {
    let notHandled = [
      ...this.state.jobData.total_partially_passed,
      ...this.state.jobData.total_passed,
    ];
    this.state.jobData.total_suitable_reject &&
      this.state.jobData.total_suitable_reject.map((item) => {
        const index = notHandled.indexOf(item);
        if (index > -1) notHandled.splice(index, 1);
      });

    this.state.jobData.total_cv_referrals &&
      this.state.jobData.total_cv_referrals.map((item) => {
        const index = notHandled.indexOf(item);
        if (index > -1) {
          notHandled.splice(index, 1);
        }
      });
    return notHandled;
  };

  handleCandidatesFilterChange = (filterOption) => {
    this.setState({ filterCandidateOption: filterOption });
    switch (filterOption) {
      case "all":
        this.setState({ filterCandidateList: [] });
        break;
      case "watched":
        this.setState({
          filterCandidateList: this.state.jobData.total_watched,
        });
        break;
      case "notSent":
        this.setState({
          filterCandidateList: this.state.jobData.total_not_sent,
        });
        break;
      case "watchedNotResponded":
        this.setState({
          filterCandidateList: this.buildNotResponsdedArray(),
        });
        break;
      case "interested":
        this.setState({
          filterCandidateList: this.buildTotalInterested(),
        });
        break;
      case "suitable":
        this.setState({
          //filterCandidateList: this.buildTotalSuitables(),
          activeTab: TAB_SUITABLE_CANDIDATES,
        });
        break;
      case "notInterested":
        this.setState({
          filterCandidateList: this.state.jobData.total_declined,
        });
        break;
      case "autoRejected":
        this.setState({
          //filterCandidateList: this.state.jobData.total_auto_disqualified,
          activeTab: TAB_DISQUALIFIED_CANDIDATES,
        });
        break;
      case "cvReferrals":
        this.setState({
          //filterCandidateList: this.state.jobData.total_cv_referrals,
          activeTab: TAB_CVREFERRALS_CANDIDATES,
        });
        break;
      case "sendToInterview":
        this.setState({
          filterCandidateList: this.state.jobData.total_interviews,
        });
        break;
      case "newSuitables":
        this.setState({
          filterCandidateList: this.buildNotHandledSuitables(),
        });
        break;
      case "recruited":
        this.setState({
          filterCandidateList: this.state.jobData.total_recruited,
        });
        break;
      case "blocked":
        break;
      case "unsubscribed":
        break;
      default:
        break;
    }
  };

  handleEventStateUpdate = (event, candidateId) => {
    this.props.onEventUpdate(event, candidateId);
  };

  handleTabRendering = () => {
    //if not published, active tab 0 is all candidates:
    const { theme } = this.props;
    if (!this.props.job.acf.job_published_date) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Candidates
              noGutter
              noHeader
              candidatesList={this.handleGetCandidates}
              job={this.props.job}
              disableDefaultSearch
              searchFilter={this.props.searchFilter}
              multipleSelection
            />
          </Grid>
        </Grid>
      );
    }
    switch (this.state.activeTab) {
      case TAB_SUITABLE_CANDIDATES:
        return (
          <SuitableCandidatesManager
            filterCandidatesByIds={this.state.filterCandidateList}
            jobId={this.props.job.id}
            onSuitableReject={this.handleSuitableRejected}
            onCandidateRating={this.handleCandidateRating}
            onCVReferral={this.handleCVReferrals}
          />
        );
      case TAB_DISQUALIFIED_CANDIDATES:
        return (
          <ErrorBoundary>
            <Candidates
              noGutter
              noHeader
              disableDefaultSearch
              candidatesList={this.handleGetCandidates}
              searchFilter={this.props.searchFilter}
              filterCandidatesByIds={this.state.jobData.total_auto_disqualified}
              job={this.props.job}
              multipleSelection
              onEventAction={this.handleEventStateUpdate}
            />
          </ErrorBoundary>
        );
      case TAB_ALL_CANDIDATES:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!(
                this.state.filterCandidateOption !== "all" &&
                this.state.filterCandidateList.length === 0
              ) && (
                <Candidates
                  noGutter
                  noHeader
                  disableCustomTabs
                  candidatesList={this.handleGetCandidates}
                  filterCandidatesByIds={
                    this.state.filterCandidateList.length > 0
                      ? this.state.filterCandidateList
                      : null
                  }
                  job={this.props.job}
                  disableDefaultSearch
                  searchFilter={this.props.searchFilter}
                  multipleSelection
                  onEventAction={this.handleEventStateUpdate}
                />
              )}
            </Grid>
          </Grid>
        );
      case TAB_CVREFERRALS_CANDIDATES:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Candidates
                noGutter
                noHeader
                candidatesList={this.handleGetCandidates}
                disableCustomTabs
                searchFilter={this.props.searchFilter}
                filterCandidatesByIds={this.state.jobData.total_cv_referrals}
                job={this.props.job}
                multipleSelection
                disableDefaultSearch
              />
            </Grid>
          </Grid>
        );
      case TAB_STATISTICS_CANDIDATES:
        return (
          <Box mt={theme.spacing(6)}>
            <JobStatistics jobData={this.props.job} />
          </Box>
        );

      default:
        break;
    }
  };

  render() {
    const { distributePreview, activeTab } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        {distributePreview ? (
          <PublishJobPreview
            job={this.props.job}
            candidates={this.state.candidatesList}
            professionsList={this.props.professionsList}
            confirm={this.handleConfirmPublishJob}
            cancel={() => this.props.onPreviewDistribution(false)}
          />
        ) : (
          <React.Fragment>
            <UnderJobContext.Provider value={{ job: this.state.jobData }}>
              <DashboardHeader job={this.props.job} />
              <div className={classes.root}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.showDistributionHistory}
                      onChange={this.handleChangeHistoryView}
                      name="checkedA"
                    />
                  }
                  label="Distributions History"
                />
                <Box>
                  {this.state.showDistributionHistory ? (
                    <DistributionReport jobId={this.props.job.id} />
                  ) : null}
                </Box>
              </div>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flex={1}
              >
                <CandidateDashboardTabs
                  value={activeTab}
                  onChange={this.handleTabChange}
                  isBeforeDistribution={!this.props.job.acf.job_published_date}
                  totalCandidates={this.state.jobData.total_assigned}
                  totalSuitable={
                    this.state.jobData.total_passed.length +
                    this.state.jobData.total_partially_passed.length
                  }
                  totalRejected={
                    this.state.jobData.total_auto_disqualified.length
                  }
                  totalCVReferrals={
                    this.state.jobData.total_cv_referrals.length
                  }
                />
                {this.state.activeTab === TAB_ALL_CANDIDATES && (
                  <FilterCandidates
                    onSelect={(e) =>
                      this.handleCandidatesFilterChange(e.target.value)
                    }
                    value={this.state.filterCandidateOption}
                  />
                )}
              </Box>

              {this.handleTabRendering()}
            </UnderJobContext.Provider>
          </React.Fragment>
        )}
        {this.state.error && (
          <AxiosErrorHandling
            title="Error occured in data update"
            error={this.state.error}
          />
        )}
        {this.state.showPublishConfirmAlert ? (
          <Snackbar
            open={this.state.showPublishConfirmAlert}
            autoHideDuration={6000}
            onClose={() =>
              this.setState({
                showPublishConfirmAlert: false,
                distributePreview: false,
              })
            }
          >
            <Alert
              severity="success"
              variant="filled"
              style={{ marginTop: 16 }}
              onClick={() => this.setState({ showPublishConfirmAlert: false })}
            >
              <AlertTitle>The job has sent for processing</AlertTitle>
              Distribution Report will be waiting when completed.
            </Alert>
          </Snackbar>
        ) : null}
      </React.Fragment>
    );
  }
}
export default withStyles(styles, { withTheme: true })(
  withRouter(JobDashbaord)
);
