import React from "react";
import {
  Box,
  Typography,
  Collapse,
  Button,
  IconButton,
  Divider,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Doughnut } from "react-chartjs-2";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ProfileStregthContext from "../../../context/profileStrengthContext";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import {
  AI_LICENSES,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_SALARY_EXPECTATIONS,
  AI_EXPERIENCES,
} from "../../../config/constants";
import { useIntl, FormattedMessage } from "react-intl";
import { green } from "@material-ui/core/colors";

const redColors = ["#FF9494", "#f2f2f2"];

const orangeColors = ["#BF7000", "#f2f2f2"];

const greenColors = [green[500], "#f2f2f2"];

const chartColors = [
  "#FF9494",
  "#FF8F39",
  "#4BB543",
  "#f2f2f2",
  "#336699",
  "#99CCFF",
  "#999933",
  "#666699",
  "#CC9933",
  "#006666",
  "#3399FF",
  "#993300",
  "#CCCC99",
  "#666666",
  "#FFCC66",
  "#6699CC",
  "#663366",
  "#9999CC",
  "#CCCCCC",
  "#669999",
  "#CCCC66",
  "#CC6600",
  "#9999FF",
  "#0066CC",
  "#99CCCC",
  "#999999",
  "#FFCC00",
  "#009999",
  "#99CC33",
  "#FF9900",
  "#999966",
  "#66CCCC",
  "#339966",
  "#CCCC33",
  "#003f5c",
  "#665191",
  "#a05195",
  "#d45087",
  "#2f4b7c",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
  "#EF6F6C",
  "#465775",
  "#56E39F",
  "#59C9A5",
  "#5B6C5D",
  "#0A2342",
  "#2CA58D",
  "#84BC9C",
  "#CBA328",
  "#F46197",
  "#DBCFB0",
  "#545775",
];

const options = {
  legend: {
    display: false,
    position: "center",
  },
  maintainAspectRatio: false,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
};

const chartLabels = [
  {
    id: "candidateProfile.profile.completed",
    defaultMessage: "Profile Completed",
  },
  {
    id: "common.profile.stillMissing",
    defaultMessage: "Still Missing",
  },
];

const translateMissingLabel = (label) => {
  console.log(label);
  switch (label) {
    case "firstName":
    case "lastName":
      return {
        id: "candidateProfile.aboutMe.personalDetails.fullName",
        defaultMessage: "Full Name",
      };
    case "email":
      return {
        id: "candidates.add.email",
        defaultMessage: "Email",
      };
    case "identifierNumber":
      return { id: "common.id", defaultMessage: "ID" };
    case "mobile":
      return {
        id: "candidates.add.mobileNumber",
        defaultMessage: "Mobile Number",
      };
    case "yearOfBirth":
      return {
        id: "candidates.add.yearOfBirth",
        defaultMessage: "Year Of Birth",
      };
    case "city":
      return {
        id: "candidates.add.city",
        defaultMessage: "City",
      };
    case "gender":
      return {
        id: "common.gender",
        defaultMessage: "Gender",
      };
    case "family_status":
      return {
        id: "candidates.add.familyStatus",
        defaultMessage: "Family Status",
      };
    case AI_EXPERIENCES:
      return {
        id: "jobs.fq.experiences",
        defaultMessage: "Experiences",
      };
    case AI_ACADEMIC_DGREE:
      return {
        id: "jobs.fq.academicDegree",
        defaultMessage: "Academic Degree",
      };
    case AI_LANGUAGES:
      return {
        id: "jobs.fq.languages",
        defaultMessage: "Languages",
      };
    case AI_CERTIFICATES:
      return {
        id: "jobs.fq.certificates",
        defaultMessage: "Certificates",
      };
    case AI_LICENSES:
      return {
        id: "jobs.fq.licenses",
        defaultMessage: "Licenses",
      };
    case AI_SALARY_EXPECTATIONS:
      return {
        id: "jobs.fq.salaryExpectations",
        defaultMessage: "Salary Expectations",
      };
    case "candidate_areas":
      return {
        id: "candidates.add.preferredJobArea",
        defaultMessage: "Preferred Job Area",
      };
    case "professions":
      return {
        id: "common.mainProfessions",
        defaultMessage: "Main Professions",
      };
    case "working_cities":
      return {
        id: "candidateProfile.profile.myAreas",
        defaultMessage: "My Areas",
      };
    case "job_extent":
      return {
        id: "common.jobType",
        defaultMessage: "Job Type",
      };
    case "cv":
      return {
        id: "candidateProfile.cvs",
        defaultMessage: "CVs",
      };

    default:
      break;
  }
};

const buildDataSet = (strength, indicator, translatedLabels) => {
  return {
    maintainAspectRatio: false,
    responsive: false,
    labels: translatedLabels,
    datasets: [
      {
        data: [parseInt(strength), 100 - parseInt(strength)],
        backgroundColor:
          indicator === "red"
            ? redColors
            : indicator === "yellow"
            ? orangeColors
            : greenColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };
};

const ProfileStength = ({ disableGraph = false, disableTitle = false }) => {
  const [expandMore, setExpandMore] = React.useState(false);
  const theme = useTheme();
  const intl = useIntl();

  return (
    <React.Fragment>
      {!disableTitle && (
        <Typography variant="h4" align="center">
          <FormattedMessage
            id="candidateProfile.profileStrenght.barTitle"
            defaultMessage="My profile Strenght"
          />
        </Typography>
      )}
      {!disableGraph && (
        <Box
          display="flex"
          width={1}
          justifyContent="center"
          alignItems="flex-start"
          position="relative"
          my={theme.spacing(2)}
        >
          <ProfileStregthContext.Consumer>
            {(context) => {
              return (
                <Doughnut
                  data={buildDataSet(context.score, context.indicator, [
                    intl.formatMessage(chartLabels[0]),
                    intl.formatMessage(chartLabels[1]),
                  ])}
                  options={options}
                />
              );
            }}
          </ProfileStregthContext.Consumer>
          <div id="legend" />
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        my={theme.spacing(2)}
      >
        <Button
          onClick={() => setExpandMore((prev) => !prev)}
          color="secondary"
          endIcon={
            expandMore ? (
              <ExpandLessRoundedIcon fontSize="small" />
            ) : (
              <ExpandMoreRoundedIcon fontSize="small" />
            )
          }
        >
          <FormattedMessage
            id="candidateProfile.profile.missing"
            defaultMessage="What is missing?"
          />
        </Button>
      </Box>
      <Collapse in={expandMore}>
        <ProfileStregthContext.Consumer>
          {(context) =>
            context.missing.map((miss) => {
              return (
                <React.Fragment key={miss}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="body1">
                      {intl.formatMessage(translateMissingLabel(miss))}
                    </Typography>
                    <IconButton>
                      <ChevronRightRoundedIcon fontSize="small" />
                    </IconButton>
                  </Box>
                  <Divider />
                </React.Fragment>
              );
            })
          }
        </ProfileStregthContext.Consumer>
      </Collapse>
    </React.Fragment>
  );
};
export default ProfileStength;
