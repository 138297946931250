import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import validator from "validator";
import { FormattedMessage, useIntl } from "react-intl";

import { Typography, Box, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: 0,
    },
  },
  hidden: {
    display: "none",
  },
  customTextMargin: {},
}));

const RegistrationStep2 = ({
  firstName,
  female,
  mobile,
  onMobileChange,
  email,
  onEmailChange,
}) => {
  const [emailTouched, setEmailTouched] = React.useState(false);
  const [mobileTouched, setMobileTouched] = React.useState(false);
  const [mobileValid, setMobileValid] = React.useState(true);
  const [emailValid, setEmailValid] = React.useState(true);
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();

  const mobileValidation = (event) => {
    setMobileTouched(true);
    const isValid = validator.isMobilePhone(event.target.value);
    setMobileValid(isValid);
    onMobileChange(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailTouched(true);
    onEmailChange(event.target.value);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h4" align="center">
          <FormattedMessage
            id="candidateProfile.signUp.step2.greeting"
            defaultMessage="Hi {firstName}, Nice to meet you! Tell me a little bit about yourself, so I can find you the perfect job."
            values={{ firstName: firstName, br: <br /> }}
          />
        </Typography>
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <FormattedMessage
            id="candidateProfile.signUp.step2.skipNotice"
            defaultMessage="Note that you can skip or get back at any time"
          />
        </Typography>
        <Box
          px={theme.spacing(2)}
          maxWidth={400}
          display="flex"
          align="center"
          flexDirection="column"
          m="auto"
        >
          <TextField
            id="firstName"
            label={intl.formatMessage({
              id: "candidates.add.mobileNumber",
              defaultMessage: "Mobile Number",
            })}
            helperText={
              mobileTouched && !mobileValid
                ? intl.formatMessage({
                    id: "candidateProfile.signUp.step2.error.invalidPhone",
                    defaultMessage: "Invalid mobile number",
                  })
                : intl.formatMessage({
                    id: "candidateProfile.signUp.step2.helper.sms",
                    defaultMessage:
                      "SMS will be used to log you into the system",
                  })
            }
            error={mobileTouched && !mobileValid}
            variant="filled"
            color="secondary"
            fullWidth
            value={mobile}
            onChange={mobileValidation}
            style={{ marginBottom: theme.spacing(2) }}
          />
          <TextField
            id="lastName"
            label={intl.formatMessage({
              id: "candidates.add.email",
              defaultMessage: "Email",
            })}
            variant="filled"
            color="secondary"
            helperText={
              !validator.isEmail(email)
                ? intl.formatMessage({
                    id: "candidateProfile.signUp.step2.error.invalidEmail",
                    defaultMessage: "Invalid email address",
                  })
                : ""
            }
            error={emailTouched && !validator.isEmail(email)}
            fullWidth
            value={email}
            onChange={handleEmailChange}
            style={{ marginBottom: theme.spacing(2) }}
          />
        </Box>
      </div>
    </React.Fragment>
  );
};
export default RegistrationStep2;
