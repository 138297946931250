import React from "react";
import {
  makeStyles,
  Slide,
  Paper,
  Typography,
  Modal,
  Container,
  Chip,
  Box,
  TextField,
  useTheme,
  Divider,
  Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
    padding: theme.spacing(2, 4, 3),
    "& .MuiPaper-root": {
      padding: theme.spacing(3),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1),
      // background: pink[50],
      // color: theme.palette.primary.main,
      // border: "none",
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "inline-block",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SaveSearch = ({ open, close, searchData, onSearch }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchName, setSearchName] = React.useState("");
  const [saveAsPublic, setSaveAsPublic] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChangePublicState = () => {
    setSaveAsPublic(!saveAsPublic);
  };

  const collectSearchData = () => {
    //save the search
    // const search = await axios.post("/save_explore_search", {
    //     name: searchName,
    //     isPublic: saveAsPublic,
    //     searchData,
    //   });
    onSearch({
      name: searchName,
      isPublic: saveAsPublic,
    });
  };

  return (
    <Modal
      open={open}
      onClose={close}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md">
        <div className={classes.root}>
          <Slide
            direction="down"
            in={open}
            mountOnEnter
            unmountOnExit
            onExit={close}
          >
            <Paper elevation={4}>
              <React.Fragment>
                <Typography variant="h4" paragraph>
                  Add to favourites
                </Typography>
                <Box my={theme.spacing(4)}>
                  {searchData.map((chip) => (
                    <Chip
                      key={chip}
                      variant="outlined"
                      icon={<DoneIcon />}
                      label={chip}
                    />
                  ))}
                </Box>
                <Box my={theme.spacing(4)}>
                  <TextField
                    variant="filled"
                    label="Search Name"
                    value={searchName}
                    fullWidth
                    onChange={(event) => setSearchName(event.target.value)}
                  />
                </Box>
                <Divider />
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  my={theme.spacing(4)}
                >
                  <Button variant="outlined" color="primary" onClick={close}>
                    Cancel
                  </Button>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={saveAsPublic}
                          onChange={handleChangePublicState}
                          name="savePublic"
                          color="primary"
                        />
                      }
                      label="Public Search"
                      style={{ marginLeft: theme.spacing(2) }}
                    />
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={collectSearchData}
                        disabled={isLoading}
                      >
                        Save
                      </Button>
                      {isLoading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  </div>
                </Box>
              </React.Fragment>
            </Paper>
          </Slide>
        </div>
      </Container>
    </Modal>
  );
};
export default SaveSearch;
