import React from "react";
import libiSmart from "../../../../Assets/Images/personal-profile-login.png";
import { Grid, Typography, Box } from "@material-ui/core";
import { useIntl, FormattedMessage } from "react-intl";

const Welcome = ({ personalName, profileFull }) => {
  const intl = useIntl();

  const DetectWelcomeMessage = () => {
    const hour = new Date().getHours();
    let message = "";
    switch (hour) {
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 10:
      case 11:
        message = intl.formatMessage({
          id: "common.goodMorning",
          defaultMessage: "Good morning",
        });
        break;
      case 12:
      case 13:
      case 14:
      case 15:
      case 16:
        message = intl.formatMessage({
          id: "common.goodAfternoon",
          defaultMessage: "Good afternoon",
        });
        break;
      case 17:
      case 18:
      case 19:
      case 20:
        message = intl.formatMessage({
          id: "common.goodEvening",
          defaultMessage: "Good evening",
        });
        break;
      default:
        message = intl.formatMessage({
          id: "common.goodNight",
          defaultMessage: "Good night",
        });
        break;
    }
    return message;
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={9} lg={10}>
        <Typography variant="h2">
          {DetectWelcomeMessage()}
          <FormattedMessage
            id="candidateProfile.lobby.welcome"
            defaultMessage="{firstName}, how are you?"
            values={{ firstName: personalName ? " " + personalName : "" }}
          />
        </Typography>
        <Box maxWidth={700} my={2}>
          <Typography variant="body1" style={{ fontWeight: 700 }}>
            {profileFull ? (
              <FormattedMessage
                id="candidateProfile.lobby.welcome.profileCompleted"
                defaultMessage="Your profile is fully updated, and this is great!"
              />
            ) : (
              <FormattedMessage
                id="candidateProfile.lobby.welcome.profileNotCompleted"
                defaultMessage="I noticed your profile is not yet completed"
              />
            )}
          </Typography>
          <Typography variant="body1" style={{ marginTop: 16 }}>
            <FormattedMessage
              id="candidateProfile.lobby.welcome.notice"
              defaultMessage="Important: As more details I have abuot you and the job you're looking for, the greater the chances I will locate the perfect job for you"
            />
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={3} lg={2} style={{ position: "relative" }}>
        <img src={libiSmart} alt="Libi Smart" />
      </Grid>
    </Grid>
  );
};

export default Welcome;
