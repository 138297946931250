import React from "react";
import {
  Input,
  Box,
  FormControl,
  InputAdornment,
  Divider,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import EndRowButton from "../EndRowButtons";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import validator from "validator";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(0.5),
      paddingTop: theme.spacing(0.5),
    },
    flexWrap: "wrap",
    "& .MuiInputAdornment-root": {
      [theme.breakpoints.up("xs")]: {
        minWidth: 200,
      },
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "& .MuiTypography-root": {
        color: theme.palette.grey[600],
        fontWeight: 600,
      },
    },
  },
  label: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: 600,
    color: "#636575",
    marginBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },

  activeRow: {
    color: theme.palette.grey[700],
  },
}));

const EditCandidateRow = ({
  label,
  value,
  update,
  type,
  validateType,
  list,
  noborder,
  ...rest
}) => {
  const [newValue, setNewValue] = React.useState("");
  const [inEditMode, setInEditMode] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [undoValue, setUndoValue] = React.useState("");
  //const [selectedMenuIndex, setSelectedMenuIndex] = React.useState(0);
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles(theme);

  React.useEffect(() => {
    setNewValue(value);
  }, [value]);

  const handleNewValueChange = (event) => {
    setNewValue(event.target.value);
    detectValidation(event.target.value);
  };

  const handleSetEditMode = (event) => {
    setUndoValue(newValue);
    setInEditMode(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    if (inEditMode) {
      setInEditMode(false);
      setAnchorEl(null);
      setNewValue(undoValue);
    }
  };

  const handleSave = () => {
    //save happened only if the newValue is different from the undoValue. Otherwise there were just click without a change
    if (undoValue !== newValue) {
      setUndoValue("");
      update(newValue);
    }
    setInEditMode(false);
  };

  const handleClose = (event) => {
    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return;
    }
    setInEditMode(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      inEditMode(false);
    }
  }

  const handleMenuItemClick = (event, index) => {
    //setSelectedMenuIndex(index);
    setInEditMode(false);
    update(list[index]);
  };

  const detectValidation = (value) => {
    switch (validateType) {
      case "email":
        if (!validator.isEmail(value)) {
          setError(
            intl.formatMessage({
              id: "candidateProfile.signUp.step2.error.invalidEmail",
              defaultMessage: "Invalid email address",
            })
          );
        } else {
          setError(null);
        }
        break;
      case "mobile": {
        if (!validator.isMobilePhone(value)) {
          setError(
            intl.formatMessage({
              id: "candidateProfile.signUp.step2.error.invalidPhone",
              defaultMessage: "Invalid mobile number",
            })
          );
        } else {
          setError(null);
        }
        break;
      }
      case "year": {
        if (value < 1950 || value > new Date().getFullYear() - 14) {
          setError(
            intl.formatMessage({
              id: "candidateProfile.error.yearOfBirth",
              defaultMessage: "Invalid year of birth",
            })
          );
        } else {
          setError(null);
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        className={classes.root}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={10} lg={11} md={8}>
              <Hidden smUp={true}>
                <div style={{ width: "100%" }}>
                  <Typography className={classes.label} align="left">
                    {label}
                  </Typography>
                </div>
              </Hidden>
              <FormControl
                fullWidth
                inputprops={{ disableUnderline: true }}
                disabled={!inEditMode}
                focused={inEditMode}
                error={error !== null}
              >
                <Input
                  disableUnderline
                  className={inEditMode ? classes.activeRow : null}
                  id="CandidateEditRow"
                  value={newValue ? newValue : ""}
                  onChange={handleNewValueChange}
                  type={type ? type : "string"}
                  startAdornment={
                    <InputAdornment position="start">{label}</InputAdornment>
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={2} lg={1} md={4}>
              <EndRowButton
                disabled={false}
                onEdit={inEditMode ? handleSave : handleSetEditMode}
                editMode={inEditMode}
              />
            </Grid>
          </Grid>
        </ClickAwayListener>
        <Popper
          open={inEditMode && list && list.length > 0 ? true : false}
          anchorEl={anchorEl}
          transition
          disablePortal
          placement="right"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="optionsList" onKeyDown={handleListKeyDown}>
                    {list.map((item, index) => {
                      return (
                        <MenuItem
                          onClick={(event) => handleMenuItemClick(event, index)}
                          key={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      {!noborder && <Divider light />}
    </React.Fragment>
  );
};

export default EditCandidateRow;
