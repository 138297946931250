import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: 70,
      height: 50,
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: 0,
    },
    "& .MuiFormControl-root": {
      margin: theme.spacing(1),
    },
    "& .MuiFilledInput-input": {
      paddingTop: 10,
    },
  },
  mainRow: {
    //marginTop: theme.spacing(1),
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
  },
}));

const QuestionValueInput = (props) => {
  const [enableQ, setEnableQ] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setEnableQ(props.value ? true : false);
  }, [props.value]);
  const handleEnableQ = () => {
    props.checkEnalbeQ(!enableQ);
    setEnableQ(!enableQ);
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <Box display="flex" alignItems="center">
          <Checkbox
            disabled={props.disabled}
            checked={enableQ}
            color="primary"
            onClick={handleEnableQ}
          />
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.typography}
          >
            {props.question}
          </Typography>
          <TextField
            id="minimumYears"
            type="number"
            value={props.value > 0 ? props.value : ""}
            onChange={props.minYearsValue}
            inputProps={{ min: 0, step: 1, max: 10 }}
            variant={"filled"}
            disabled={!enableQ}
            color="primary"
          />
        </Box>
        {props.disableVerificationQuestion ? null : (
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isAmustValue}
                onClick={props.isAMustCheck}
                name="requiredQ"
                color="primary"
              />
            }
            label={
              props.changeToManagementRole
                ? "This is a mamagement role"
                : "This condition is prerequisite"
            }
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default React.memo(QuestionValueInput);
