import React from "react";
import {
  Paper,
  Grid,
  Typography,
  Box,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { ChevronLeftOutlined } from "@material-ui/icons";
import TodayRoundedIcon from "@material-ui/icons/TodayRounded";
import { withRouter } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import axios from "axios";
import Spinner from "../../../../../UI/Skeletons/JobsSkeleton";
import MyPagination from "../../../../../UI/Pagination/Pagination";
import validator from "validator";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  divider: {
    "& .MuiDivider-root": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  paper: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));

const DetailedDistributionReport = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [metaData, setMetaData] = React.useState({});
  const [detailedData, setDetailedData] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [perPage, setPerPage] = React.useState(25);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [metaTotals, setMetaTotalts] = React.useState({});
  const distriubtionId = props.match.params.distributionId;
  const jobId = props.match.params.jobid;
  React.useEffect(() => {
    setIsLoading(true);
    axios
      .get(`wp-json/api/v2/detailed_distribution_report/${distriubtionId}`, {
        params: {
          page: currentPage,
          limit: perPage,
          jobId,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setMetaData(res.data.distribution_data);
        setDetailedData(res.data.detailed_dist_data);
        setMetaTotalts(res.data.totals);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response);
        setMetaData({});
        setDetailedData([]);
        setMetaTotalts({});
      });
  }, [currentPage, perPage]);

  const handleChangePage = (event, page) => {
    setCurrentPage(page - 1);
  };

  const handleBackToDashboard = () => {
    props.history.push(`/jobs/dashboard/${jobId}`);
  };

  const showPaginationAndTitle = (showTitle = true) => (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      mt={4}
    >
      {showTitle && (
        <Typography variant="subtitle1">Detailed Distribution</Typography>
      )}
      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
        <MyPagination
          total={metaTotals ? parseInt(metaTotals.total) : 0}
          totalLabel="Total Candidates"
          onChange={handleChangePage}
          reload={() => setCurrentPage(0)}
          totalPages={
            metaData ? Math.ceil(parseInt(metaTotals.total) / perPage) : 0
          }
          currentPage={currentPage + 1}
        />
        <Box style={{ minWidth: 100, marginRight: theme.spacing(2) }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">results</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={perPage}
              label="Show Total"
              onChange={(event) => setPerPage(event.target.value)}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );

  return (
    <React.Fragment>
      <Box my={theme.spacing(2)}>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Tooltip title="Back to job dashboard">
              <IconButton
                aria-label="ChevronLeft"
                onClick={handleBackToDashboard}
                size="medium"
              >
                <ChevronLeftOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </Box>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={1}></Grid>
        <Grid item xs={10}>
          <Typography variant="h4" paragraph>
            {metaData ? metaData.jobName : ""}
          </Typography>
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"center"}
            height={24}
            my={2}
            className={classes.divider}
          >
            <TodayRoundedIcon
              color="secondary"
              style={{ marginLeft: theme.spacing(1) }}
            />
            <Typography variant="body1" color="textSecondary">
              Distribution Date & Time:{" "}
              {metaData ? metaData.distributed_at : ""}
            </Typography>
            <Divider orientation="vertical" />
            <Typography variant="body1" color="textSecondary">
              Distribution Id: {metaData ? metaData.id : ""}
            </Typography>
            <Divider orientation="vertical" />
            <Typography variant="body1" color="textSecondary">
              Distributed by: {metaData ? metaData.distributedBy : ""}
            </Typography>
            <Divider orientation="vertical" />
            <Typography variant="body1" color="textSecondary">
              Job Id: {metaData ? metaData.JobId : jobId}
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="subtitle1">Distribution Totals</Typography>
          </Box>
          {isLoading ? (
            <Spinner open={isLoading} />
          ) : (
            <React.Fragment>
              <Paper className={classes.paper}>
                <Grid container spacing={1}>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      Total Candidates
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      SMS
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      WhatsApp
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      Failed
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      Distribution Date
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box my={2}>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      {Object.keys(metaTotals).length === 0
                        ? 0
                        : parseInt(metaTotals.total)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      {Object.keys(metaTotals).length === 0
                        ? 0
                        : parseInt(metaTotals.emails)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      {Object.keys(metaTotals).length === 0
                        ? 0
                        : parseInt(metaTotals.sms)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      {Object.keys(metaTotals).length === 0
                        ? 0
                        : parseInt(metaTotals.wa)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      {Object.keys(metaTotals).length === 0
                        ? 0
                        : parseInt(metaTotals.failed)}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body1" color="textSecondary">
                      {Object.keys(metaTotals).length === 0
                        ? 0
                        : new Date(metaData.distributed_at).toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              {showPaginationAndTitle}
              <Paper className={classes.paper}>
                {isLoading ? (
                  <Spinner open={isLoading} />
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={1}>
                      <Typography variant="body1" color="textSecondary">
                        Status
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" color="textSecondary">
                        Full Name
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" color="textSecondary">
                        Candidate Id
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body1" color="textSecondary">
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body1" color="textSecondary">
                        SMS
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" color="textSecondary">
                        WhatsApp
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body1" color="textSecondary">
                        Comments
                      </Typography>
                    </Grid>
                    {detailedData.map((candidateDistRow) => (
                      <DistributionItemRow
                        item={candidateDistRow}
                        key={candidateDistRow.candidateId}
                      />
                    ))}
                  </Grid>
                )}
              </Paper>
              {showPaginationAndTitle}
            </React.Fragment>
          )}
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </React.Fragment>
  );
};
export default withRouter(DetailedDistributionReport);

const DistributionItemRow = ({ item }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isFailed = () => {
    if (
      parseInt(item.sms) == 0 &&
      parseInt(item.email) == 0 &&
      (parseInt(item.wa) == 5 || parseInt(item.wa) == 0)
    )
      return true;
    return false;
  };

  const buildComments = () => {
    let message = "";
    if (isFailed()) {
      //If is valid phone, we tried wa first:
      if (validator.isMobilePhone(item.mobile_number)) {
        if (item.wa == "5") {
          if (!validator.isMobilePhone(item.mobile_number)) {
            message += `Message sent to ${item.mobile_number} failed`;
          }
        }
        if (item.wa == "0" && item.sms == "0") {
          message += `No message sent to ${item.mobile_number}. `;
        }
      }
      if (item.email == "0") {
        if (!validator.isEmail(item.email_address)) {
          message += "Invalid email address";
        }
      }
      return message;
    }
    //if we are here, sent successfully:
    if (item.mobile_number && validator.isMobilePhone(item.mobile_number)) {
      //sent via wa:
      if (item.wa == "1") return "WA message sent";
      if (item.wa == "2") return "WA message sent and accepted by candidated";
      if (item.wa == "3") return "WA message sent and declined";
      if (item.wa == "4") return "WA message sent and received successfully";
      if (item.wa == "6") return "WA message read by candidate";
      if (item.sms == "1") return "SMS message sent successfully";
    }
    if (item.sms == "0") return `SMS message to ${item.mobile_number} failed.`;
    if (item.email == "1") return `Email sent successfully`;
  };

  return (
    <React.Fragment>
      <Grid item xs={1}>
        <Typography variant="body1">
          {isFailed() ? (
            <ErrorOutlineRoundedIcon className={classes.error} />
          ) : (
            <DoneRoundedIcon className={classes.success} />
          )}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1">{item.candidate_name}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1">{item.candidateId}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body1">{item.email}</Typography>
      </Grid>
      <Grid item xs={1}>
        <Typography variant="body1">{item.sms}</Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1">N/A</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="smallText">{buildComments()}</Typography>
      </Grid>
    </React.Fragment>
  );
};
