export const GenderList = {
  male: "Male",
  female: "Female",
  none: "Other",
};

export const FamilyStatusList = {
  married: "Married",
  single: "Single",
  widow: "Widow",
  divorced: "Divorced",
  publiclyKnown: "Publicly Known",
  saperated: "Seperated",
};

export const AllEvents = [
  {
    id: "job_mail_sent",
    label: "Candidate invited to view job",
  },
  { id: "removeFromJob", label: "Remove job link" },
  { id: "link_to_job", label: "Candidate Linked to job" },
  { id: "addComment", label: "Add Comment" },
  { id: "addRating", label: "Add Rating" },
  {
    id: "candidate_declined_job",
    label: "Candidate declined job",
  },
  {
    id: "candidate_watched_job",
    label: "Candidate Watched job",
  },
  {
    id: "candidate_accepted_job",
    label: "Candidate interested in job",
  },
  {
    id: "sendInvitation",
    label: "Candidate invited to view job",
  },
  { id: "sendToInterview", label: "Candidate sent to interview" },
  {
    id: "recruitmentSucceeded",
    label: "Candidate Recruited Successfully",
  },
  {
    id: "advancedRecruitmentProcedures",
    label: "Candidate moved to Advanced Recruitment Procedures",
  },
];

export const MenuMoreOptions = [
  {
    id: "editCandidate",
    label: "Edit Details",
    viewBox: "0 0 36 36",
    viewable: false,
    icon:
      "M25.456 13.3722L22.628 10.5442L4 29.1722V32.0002H6.828L25.456 13.3722ZM28.284 10.5442L31.112 7.71617L28.284 4.88817L25.456 7.71617L28.284 10.5442ZM8.484 36.0002H0V27.5142L26.87 0.644167C27.2451 0.269225 27.7537 0.0585938 28.284 0.0585938C28.8143 0.0585938 29.3229 0.269225 29.698 0.644167L35.356 6.30217C35.7309 6.67722 35.9416 7.18584 35.9416 7.71617C35.9416 8.2465 35.7309 8.75511 35.356 9.13017L8.486 36.0002H8.484Z",
  },
  {
    id: "link_to_job",
    label: "Link to job",
    viewBox: "0 0 40 40",
    viewable: true,
    icon:
      "M22.1195 12.2205L24.9495 15.0505C26.2497 16.3505 27.2811 17.8939 27.9847 19.5926C28.6884 21.2912 29.0505 23.1118 29.0505 24.9505C29.0505 26.7891 28.6884 28.6097 27.9847 30.3084C27.2811 32.007 26.2497 33.5504 24.9495 34.8505L24.2415 35.5565C21.6159 38.1821 18.0548 39.6572 14.3415 39.6572C10.6283 39.6572 7.06718 38.1821 4.44153 35.5565C1.81589 32.9308 0.34082 29.3697 0.34082 25.6565C0.34082 21.9432 1.81589 18.3821 4.44153 15.7565L7.27153 18.5865C6.33627 19.5136 5.59334 20.6163 5.08539 21.8313C4.57744 23.0463 4.31447 24.3496 4.31159 25.6665C4.30871 26.9834 4.56596 28.2879 5.06859 29.5051C5.57121 30.7224 6.30931 31.8283 7.2405 32.7595C8.17169 33.6907 9.27764 34.4288 10.4949 34.9314C11.7121 35.434 13.0166 35.6913 14.3335 35.6884C15.6504 35.6855 16.9537 35.4226 18.1687 34.9146C19.3837 34.4067 20.4864 33.6637 21.4135 32.7285L22.1215 32.0205C23.9962 30.1452 25.0494 27.6021 25.0494 24.9505C25.0494 22.2988 23.9962 19.7557 22.1215 17.8805L19.2915 15.0505L22.1215 12.2225L22.1195 12.2205ZM35.5555 24.2425L32.7275 21.4145C33.6628 20.4874 34.4057 19.3847 34.9137 18.1697C35.4216 16.9547 35.6846 15.6513 35.6875 14.3344C35.6904 13.0175 35.4331 11.713 34.9305 10.4958C34.4279 9.27857 33.6898 8.17262 32.7586 7.24143C31.8274 6.31024 30.7214 5.57214 29.5042 5.06952C28.287 4.56689 26.9825 4.30964 25.6656 4.31252C24.3487 4.3154 23.0453 4.57837 21.8303 5.08632C20.6153 5.59427 19.5126 6.3372 18.5855 7.27246L17.8775 7.98046C16.0028 9.85574 14.9497 12.3988 14.9497 15.0505C14.9497 17.7021 16.0028 20.2452 17.8775 22.1205L20.7075 24.9505L17.8775 27.7785L15.0495 24.9505C13.7494 23.6504 12.718 22.107 12.0143 20.4084C11.3107 18.7097 10.9485 16.8891 10.9485 15.0505C10.9485 13.2118 11.3107 11.3912 12.0143 9.69257C12.718 7.99392 13.7494 6.4505 15.0495 5.15046L15.7575 4.44446C18.3832 1.81882 21.9443 0.34375 25.6575 0.34375C29.3708 0.34375 32.9319 1.81882 35.5575 4.44446C38.1832 7.07011 39.6582 10.6312 39.6582 14.3445C39.6582 18.0577 38.1832 21.6188 35.5575 24.2445L35.5555 24.2425Z",
  },
  {
    id: "addComment",
    label: "Add Comment",
    viewable: true,
    icon:
      "M7.29101 20.824L2.00001 22L3.17601 16.709C2.40154 15.2604 1.99754 13.6426 2.00001 12C2.00001 6.477 6.47701 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C10.3574 22.0025 8.73963 21.5985 7.29101 20.824ZM7.58101 18.711L8.23401 19.061C9.39256 19.6801 10.6864 20.0027 12 20C13.5823 20 15.129 19.5308 16.4446 18.6518C17.7602 17.7727 18.7855 16.5233 19.391 15.0615C19.9965 13.5997 20.155 11.9911 19.8463 10.4393C19.5376 8.88743 18.7757 7.46197 17.6569 6.34315C16.538 5.22433 15.1126 4.4624 13.5607 4.15372C12.0089 3.84504 10.4004 4.00346 8.93854 4.60896C7.47674 5.21447 6.22731 6.23984 5.34825 7.55544C4.4692 8.87103 4.00001 10.4177 4.00001 12C4.00001 13.334 4.32501 14.618 4.94001 15.766L5.28901 16.419L4.63401 19.366L7.58101 18.711Z",
    viewBox: "0 0 24 24",
  },
  {
    id: "removeFromJob",
    label: "Remove Job Link",
    viewBox: "0 0 40 40",
    viewable: true,
    icon:
      "M4 12H36V38C36 38.5304 35.7893 39.0391 35.4142 39.4142C35.0391 39.7893 34.5304 40 34 40H6C5.46957 40 4.96086 39.7893 4.58579 39.4142C4.21071 39.0391 4 38.5304 4 38V12ZM8 16V36H32V16H8ZM14 20H18V32H14V20ZM22 20H26V32H22V20ZM10 6V2C10 1.46957 10.2107 0.960859 10.5858 0.585786C10.9609 0.210714 11.4696 0 12 0H28C28.5304 0 29.0391 0.210714 29.4142 0.585786C29.7893 0.960859 30 1.46957 30 2V6H40V10H0V6H10ZM14 4V6H26V4H14Z",
  },
  {
    id: "sendInvitation",
    label: "Send Invitation",
    viewBox: "0 0 40 40",
    viewable: true,
    icon:
      "M10 8V2C10 1.46957 10.2107 0.960859 10.5858 0.585786C10.9609 0.210714 11.4696 0 12 0H28C28.5304 0 29.0391 0.210714 29.4142 0.585786C29.7893 0.960859 30 1.46957 30 2V8H38C38.5304 8 39.0391 8.21071 39.4142 8.58579C39.7893 8.96086 40 9.46957 40 10V38C40 38.5304 39.7893 39.0391 39.4142 39.4142C39.0391 39.7893 38.5304 40 38 40H2C1.46957 40 0.960859 39.7893 0.585786 39.4142C0.210714 39.0391 0 38.5304 0 38V10C0 9.46957 0.210714 8.96086 0.585786 8.58579C0.960859 8.21071 1.46957 8 2 8H10ZM4 30V36H36V30H4ZM4 26H36V12H4V26ZM14 4V8H26V4H14ZM18 20H22V24H18V20Z",
  },
  {
    id: "candidate_watched_job",
    label: "Candidate Watched Job",
    viewBox: "0 0 24 24",
    viewable: false,
    icon:
      "M12.0002 3C17.3922 3 21.8782 6.88 22.8192 12C21.8792 17.12 17.3922 21 12.0002 21C6.60815 21 2.12215 17.12 1.18115 12C2.12115 6.88 6.60815 3 12.0002 3ZM12.0002 19C14.0396 18.9996 16.0185 18.3068 17.613 17.0352C19.2075 15.7635 20.3231 13.9883 20.7772 12C20.3214 10.0133 19.2051 8.24 17.6108 6.97003C16.0165 5.70005 14.0385 5.00853 12.0002 5.00853C9.96185 5.00853 7.98384 5.70005 6.38953 6.97003C4.79521 8.24 3.67892 10.0133 3.22315 12C3.67725 13.9883 4.79283 15.7635 6.3873 17.0352C7.98177 18.3068 9.96068 18.9996 12.0002 19ZM12.0002 16.5C10.8067 16.5 9.66209 16.0259 8.81817 15.182C7.97426 14.3381 7.50015 13.1935 7.50015 12C7.50015 10.8065 7.97426 9.66193 8.81817 8.81802C9.66209 7.97411 10.8067 7.5 12.0002 7.5C13.1936 7.5 14.3382 7.97411 15.1821 8.81802C16.026 9.66193 16.5002 10.8065 16.5002 12C16.5002 13.1935 16.026 14.3381 15.1821 15.182C14.3382 16.0259 13.1936 16.5 12.0002 16.5ZM12.0002 14.5C12.6632 14.5 13.2991 14.2366 13.7679 13.7678C14.2368 13.2989 14.5002 12.663 14.5002 12C14.5002 11.337 14.2368 10.7011 13.7679 10.2322C13.2991 9.76339 12.6632 9.5 12.0002 9.5C11.3371 9.5 10.7012 9.76339 10.2324 10.2322C9.76355 10.7011 9.50015 11.337 9.50015 12C9.50015 12.663 9.76355 13.2989 10.2324 13.7678C10.7012 14.2366 11.3371 14.5 12.0002 14.5Z",
  },
  {
    id: "candidate_accepted_job",
    label: "Candidate interested in job",
    viewBox: "0 0 24 24",
    viewable: false,
    icon:
      "M11.602 13.76L13.014 15.172L21.48 6.706L22.894 8.12L13.014 18L6.65 11.636L8.064 10.222L10.189 12.347L11.602 13.759V13.76ZM11.604 10.932L16.556 5.979L17.966 7.389L13.014 12.342L11.604 10.932ZM8.777 16.587L7.364 18L1 11.636L2.414 10.222L3.827 11.635L3.826 11.636L8.777 16.587Z",
  },
  {
    id: "job_mail_sent",
    label: "Send Invitation",
    viewBox: "0 0 40 40",
    viewable: false,
    icon:
      "M10 8V2C10 1.46957 10.2107 0.960859 10.5858 0.585786C10.9609 0.210714 11.4696 0 12 0H28C28.5304 0 29.0391 0.210714 29.4142 0.585786C29.7893 0.960859 30 1.46957 30 2V8H38C38.5304 8 39.0391 8.21071 39.4142 8.58579C39.7893 8.96086 40 9.46957 40 10V38C40 38.5304 39.7893 39.0391 39.4142 39.4142C39.0391 39.7893 38.5304 40 38 40H2C1.46957 40 0.960859 39.7893 0.585786 39.4142C0.210714 39.0391 0 38.5304 0 38V10C0 9.46957 0.210714 8.96086 0.585786 8.58579C0.960859 8.21071 1.46957 8 2 8H10ZM4 30V36H36V30H4ZM4 26H36V12H4V26ZM14 4V8H26V4H14ZM18 20H22V24H18V20Z",
  },
  {
    id: "addPhoneInterview",
    label: "Add Phone Interview",
    viewBox: "0 0 36 36",
    viewable: true,
    icon:
      "M12.732 15.364C14.6087 18.661 17.339 21.3913 20.636 23.268L22.404 20.792C22.6883 20.3939 23.1087 20.1138 23.5856 20.0047C24.0625 19.8956 24.5629 19.9651 24.992 20.2C27.8206 21.7458 30.9444 22.6756 34.158 22.928C34.6596 22.9677 35.1277 23.1951 35.4691 23.5646C35.8105 23.9342 36.0001 24.4189 36 24.922V33.846C36.0001 34.3412 35.8165 34.8188 35.4847 35.1864C35.153 35.554 34.6966 35.7855 34.204 35.836C33.144 35.946 32.076 36 31 36C13.88 36 0 22.12 0 5C0 3.924 0.054 2.856 0.164 1.796C0.214509 1.30339 0.445969 0.847041 0.81358 0.515268C1.18119 0.183494 1.65881 -0.000106716 2.154 4.65345e-08H11.078C11.5811 -6.3037e-05 12.0658 0.189503 12.4354 0.530901C12.8049 0.8723 13.0323 1.34045 13.072 1.842C13.3244 5.05558 14.2542 8.17936 15.8 11.008C16.0349 11.4371 16.1044 11.9375 15.9953 12.4144C15.8862 12.8913 15.6061 13.3117 15.208 13.596L12.732 15.364ZM7.688 14.05L11.488 11.336C10.4096 9.00819 9.6707 6.53768 9.294 4H4.02C4.008 4.332 4.002 4.666 4.002 5C4 19.912 16.088 32 31 32C31.334 32 31.668 31.994 32 31.98V26.706C29.4623 26.3293 26.9918 25.5904 24.664 24.512L21.95 28.312C20.8573 27.8874 19.796 27.3862 18.774 26.812L18.658 26.746C14.7352 24.5134 11.4866 21.2648 9.254 17.342L9.188 17.226C8.61381 16.204 8.11257 15.1427 7.688 14.05Z",
  },
  {
    id: "addRating",
    label: "Add Rating",
    viewBox: "0 0 42 40",
    viewable: true,
    icon:
      "M21.0003 7.03863L17.9992 13.1196C17.1252 14.8905 15.4358 16.1179 13.4816 16.4018L6.77081 17.377L11.6268 22.1104C13.0409 23.4888 13.6862 25.4748 13.3523 27.4211L12.206 34.1048L18.2083 30.9492C19.9562 30.0302 22.0444 30.0302 23.7924 30.9492L29.7946 34.1048L28.6483 27.4211C28.3145 25.4748 28.9598 23.4888 30.3739 22.1104L35.2298 17.377L28.5191 16.4018C26.5648 16.1179 24.8754 14.8905 24.0015 13.1196L21.0003 7.03863ZM22.7938 1.6344C22.0602 0.147907 19.9405 0.14791 19.2068 1.6344L14.4122 11.3493C14.1209 11.9396 13.5578 12.3488 12.9064 12.4434L2.18529 14.0013C0.54485 14.2397 -0.110168 16.2556 1.07687 17.4127L8.83471 24.9747C9.30608 25.4342 9.52117 26.0962 9.4099 26.745L7.57852 37.4227C7.2983 39.0565 9.01317 40.3024 10.4804 39.5311L20.0696 34.4897C20.6523 34.1834 21.3484 34.1834 21.931 34.4897L31.5202 39.5311C32.9875 40.3024 34.7023 39.0565 34.4221 37.4227L32.5907 26.745C32.4795 26.0962 32.6946 25.4342 33.1659 24.9747L40.9238 17.4127C42.1108 16.2556 41.4558 14.2397 39.8153 14.0013L29.0943 12.4434C28.4429 12.3488 27.8797 11.9396 27.5884 11.3493L22.7938 1.6344Z",
  },
  {
    id: "unsubscribeRequest",
    label: "Unsubscribe",
    viewBox: "0 0 42 40",
    viewable: true,
    icon:
      "M36 8.476L20.144 22.676L4 8.432V32H18.14C18.3388 33.3866 18.7461 34.7351 19.348 36H2C1.46957 36 0.960859 35.7893 0.585786 35.4142C0.210714 35.0391 0 34.5304 0 34V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H38C38.5304 0 39.0391 0.210714 39.4142 0.585786C39.7893 0.960859 40 1.46957 40 2V18.51C38.7783 17.6571 37.4279 17.0055 36 16.58V8.476ZM35.002 4H5.022L20.122 17.324L35.004 4H35.002ZM29.414 35.416C30.5339 35.9518 31.7924 36.1271 33.0161 35.9176C34.2398 35.7081 35.3684 35.1241 36.2463 34.2463C37.1241 33.3684 37.7081 32.2398 37.9176 31.0161C38.1271 29.7924 37.9518 28.5339 37.416 27.414L29.414 35.414V35.416ZM26.584 32.586L34.586 24.586C33.4661 24.0502 32.2076 23.8749 30.9839 24.0844C29.7602 24.2939 28.6316 24.8779 27.7537 25.7557C26.8759 26.6336 26.2919 27.7622 26.0824 28.9859C25.8729 30.2095 26.0482 31.4681 26.584 32.588V32.586ZM32 40C29.3478 40 26.8043 38.9464 24.9289 37.0711C23.0536 35.1957 22 32.6522 22 30C22 27.3478 23.0536 24.8043 24.9289 22.9289C26.8043 21.0536 29.3478 20 32 20C34.6522 20 37.1957 21.0536 39.0711 22.9289C40.9464 24.8043 42 27.3478 42 30C42 32.6522 40.9464 35.1957 39.0711 37.0711C37.1957 38.9464 34.6522 40 32 40Z",
  },
  {
    id: "cvReferrals",
    label: "Send CV to customer",
    viewBox: "0 0 36 40",
    viewable: true,
    icon:
      "M36 12V37.986C36.0018 38.2486 35.9519 38.5091 35.8531 38.7524C35.7543 38.9958 35.6085 39.2173 35.4241 39.4043C35.2397 39.5913 35.0203 39.7402 34.7783 39.8424C34.5364 39.9446 34.2766 39.9982 34.014 40H1.986C1.45963 40 0.954782 39.791 0.582393 39.419C0.210003 39.047 0.000530084 38.5424 0 38.016V1.984C0 0.91 0.898 0 2.004 0H23.994L36 12ZM32 14H22V4H4V36H32V14ZM10 10H16V14H10V10ZM10 18H26V22H10V18ZM10 26H26V30H10V26Z",
  },
  {
    id: "sendToInterview",
    label: "Sent to job interview",
    viewBox: "0 0 39 44",
    viewable: true,
    icon:
      "M37.518 43.1838L0.594002 22.8758C0.437214 22.7895 0.306469 22.6626 0.215408 22.5086C0.124348 22.3545 0.0763092 22.1788 0.0763092 21.9998C0.0763092 21.8208 0.124348 21.6451 0.215408 21.491C0.306469 21.3369 0.437214 21.2101 0.594002 21.1238L37.518 0.815792C37.6703 0.732033 37.8417 0.689396 38.0155 0.692081C38.1892 0.694767 38.3593 0.742683 38.5088 0.831104C38.6584 0.919525 38.7824 1.04541 38.8685 1.19633C38.9547 1.34726 39 1.51802 39 1.69179L39 42.3078C39 42.4816 38.9547 42.6523 38.8685 42.8032C38.7824 42.9542 38.6584 43.0801 38.5088 43.1685C38.3593 43.2569 38.1892 43.3048 38.0155 43.3075C37.8417 43.3102 37.6703 43.2675 37.518 43.1838ZM35 19.9998L35 6.76579L7.3 21.9998L35 37.2338V23.9998H25L25 19.9998H35Z",
  },
  {
    id: "advancedRecruitmentProcedures",
    label: "Move to Advanced Recruitment Procedures",
    icon: "M18 16V32H14V16H0L16 0L32 16H18Z",
    viewBox: "0 0 32 18",
    viewable: true,
  },
  {
    id: "recruitmentSucceeded",
    label: "Candidate Recruited Successfully",
    viewable: true,
    icon:
      "M3 19V5.70002C2.99994 5.49474 3.06305 5.29442 3.18077 5.12625C3.29849 4.95809 3.4651 4.83022 3.658 4.76002L13.329 1.24402C13.4045 1.21652 13.4856 1.20765 13.5653 1.21815C13.645 1.22865 13.721 1.25822 13.7869 1.30434C13.8527 1.35046 13.9065 1.41178 13.9436 1.4831C13.9807 1.55441 14 1.63363 14 1.71402V6.66702L20.316 8.77202C20.5152 8.83837 20.6885 8.96573 20.8112 9.13607C20.934 9.3064 21.0001 9.51105 21 9.72102V19H23V21H1V19H3ZM5 19H12V3.85502L5 6.40102V19ZM19 19V10.442L14 8.77502V19H19Z",
    viewBox: "0 0 24 24",
  },
  {
    id: "sendEmail",
    label: "Send Email",
    viewable: true,
    icon:
      "M3 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3ZM20 7.238L12.072 14.338L4 7.216V19H20V7.238ZM4.511 5L12.061 11.662L19.502 5H4.511Z",
    viewBox: "0 0 24 24",
    disabled: true,
  },
  {
    id: "addReminder",
    label: "Add Reminder",
    viewable: true,
    icon:
      "M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM13 12H17V14H11V7H13V12Z",
    viewBox: "0 0 24 24",
    disabled: true,
  },
  // {
  //   id: "addComment",
  //   label: "הוסף חוות דעת",
  //   viewable: true,
  //   icon:
  //     "M11 7H13V17H11V7ZM15 11H17V17H15V11ZM7 13H9V17H7V13ZM15 4H5V20H19V8H15V4ZM3 2.992C3 2.444 3.447 2 3.999 2H16L21 7V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992Z",
  //   viewBox: "0 0 24 24",
  //   disabled: true,
  // },
  {
    id: "blockCandidate",
    label: "Block Candidate",
    viewable: true,
    icon:
      "M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM16.891 8.523L8.523 16.891C7.97638 16.5015 7.49849 16.0236 7.109 15.477L15.477 7.109C16.0236 7.49849 16.5015 7.97638 16.891 8.523Z",
    viewBox: "0 0 24 24",
    disabled: true,
  },
];

export function getInitials(name) {
  const parts = name.split(" ");
  return parts.length > 1
    ? `${parts[0].charAt(0)}${parts[1].charAt(0)}`
    : parts[0].charAt(0);
}

export function TranformAdditionalInformationData(aiObject) {
  const trasformedArrayRawObject = Object.values(aiObject)[1];
  const transformedArrayByAI = trasformedArrayRawObject.reduce(
    (aiItems, aiItem) => {
      const { ai_type } = aiItem;
      aiItems[ai_type] = aiItems[ai_type]
        ? [...aiItems[ai_type], aiItem]
        : [aiItem];
      return aiItems;
    },
    {}
  );
  //return transformedArrayByAI;

  const newVar = Object.keys(transformedArrayByAI).map((aiType) => {
    const aibySubTypeObject = {};

    const typeBySubType = transformedArrayByAI[aiType].reduce((acc, item) => {
      const { ai_sub_type_id } = item;

      acc[ai_sub_type_id] = acc[ai_sub_type_id]
        ? [...acc[ai_sub_type_id], item]
        : [item];
      return acc;
    }, {});
    aibySubTypeObject[aiType] = { ...aibySubTypeObject, typeBySubType };

    return aibySubTypeObject;
  });
  return newVar;
}

export const RatingLabel = {
  1: "Weak",
  2: "Below Average",
  3: "Good",
  4: "Very Good",
  5: "Excellent",
};

export const CandidateTabs = [
  { index: 0, id: "all", label: "All Candidates" },
  { index: 1, id: "blocked", label: "Blocked" },
  { index: 2, id: "unsubscribed", label: "Unsubscribed" },
];
