import React from "react";
import { DEGREE_TYPES } from "../../config/constants";
import { FormControlLabel, Radio } from "@material-ui/core";

const DegreeTypeRadios = ({ degree }) => {
  switch (degree) {
    case "firstDegree":
      return DEGREE_TYPES.first.map((dt) => (
        <FormControlLabel
          key={dt}
          value={dt}
          control={<Radio color="primary" />}
          label={dt}
        />
      ));
    case "secondDegree":
      return DEGREE_TYPES.second.map((dt) => (
        <FormControlLabel
          key={dt}
          value={dt}
          control={<Radio color="primary" />}
          label={dt}
        />
      ));

    case "doctor":
      return DEGREE_TYPES.doctor.map((dt) => (
        <FormControlLabel
          key={dt}
          value={dt}
          control={<Radio color="primary" />}
          label={dt}
        />
      ));
    default:
      return <p>Loading...</p>;
  }
};
export default DegreeTypeRadios;
