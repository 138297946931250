import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function JobsMoreActions(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuSelect = (event, option) => {
    //check special case of closed/reopen job:
    props.jobClosed
      ? props.menuItemClick(event, "jobReopen", props.jobId)
      : props.menuItemClick(event, option.id, props.jobId);
    setAnchorEl(null);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={props.disabled}
      >
        <MoreVertIcon color={props.color ? props.color : "inherit"} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {props.options.map((option) => (
          <MenuItem
            key={option.id}
            selected={option === "Pyxis"}
            onClick={(event) => handleMenuSelect(event, option)}
          >
            {props.jobClosed && option.id === "closeJob"
              ? "Reopen Job"
              : option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
