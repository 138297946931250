import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Icon, Button } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));

const AICertificates = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedCertificate, setSelectedCertificate] = React.useState("");
  const [updatedChip, setUpdatedChip] = React.useState(-1);
  const [certificatesAIObject, setCertificatesAIObject] = React.useState([]);

  React.useEffect(() => {
    if (props.aIValue && Object.keys(props.aIValue).length) {
      const aiTempObject = props.aIValue.map((aiItem) => {
        return (
          [...certificatesAIObject],
          {
            key: aiItem.certificateId,
            certificateId: aiItem.certificateId,
            certificateName: aiItem.certificateName,
          }
        );
      });
      setCertificatesAIObject(aiTempObject);
    }
  }, []);

  const handleSelectedCertificate = (event) => {
    setSelectedCertificate(event.target.value);
  };

  const buildAICertificateObject = () => {
    props.addAI(certificatesAIObject);
  };

  /*
  Single Certificate Object: 
  {
      certificateId: id,
      certificateName: string,    
  }
  */
  const handleAddSingleCertificateAI = () => {
    certificatesAIObject === null
      ? setCertificatesAIObject([
          {
            certificateId:
              certificatesAIObject === null
                ? 1
                : certificatesAIObject.length + 1,
            certificateName: selectedCertificate,
          },
        ])
      : setCertificatesAIObject([
          ...certificatesAIObject,
          {
            certificateId: certificatesAIObject.length + 1,
            certificateName: selectedCertificate,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = certificatesAIObject.find(
      (chip) => chip.certificateId === updatedChip
    );

    //remove the selected chip from the array:
    const tempCertificateAIObject = certificatesAIObject.filter(
      (ai) => ai.certificateId !== tempUpdatedChip.certificateId
    );

    //update the form:
    setCertificatesAIObject([
      ...tempCertificateAIObject,
      {
        certificateId: tempUpdatedChip.certificateId,
        certificateName: selectedCertificate,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setUpdatedChip(-1);
    setSelectedCertificate("");
  };

  const handleRemoveCertificate = (crtId) => {
    //locate index id
    const newCertificateAIbject = certificatesAIObject.filter(
      (crt) => crt.certificateId !== crtId
    );
    setCertificatesAIObject(newCertificateAIbject);
    clearForm();
  };

  const handleReadChip = (crtId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleCrtObj = certificatesAIObject.find(
        (crt) => crt.certificateId === crtId
      );
      setSelectedCertificate(singleCrtObj.certificateName);
      setUpdatedChip(crtId);
    }
  };

  const displayChips =
    certificatesAIObject !== null
      ? certificatesAIObject.map((crt) => {
          return (
            <Chip
              key={crt.certificateId}
              icon={<FaceIcon />}
              label={crt.certificateName}
              clickable
              color="primary"
              onClick={() => handleReadChip(crt.certificateId)}
              onDelete={() => handleRemoveCertificate(crt.certificateId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== crt.certificateId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            className={classes.modalTitle}
          >
            {props.candidateFullName !== "undefined" &&
            props.candidateFullName.length > 1
              ? `Add Certificate for ${props.candidateFullName}`
              : "Add Certificate"}
          </Typography>
          <Divider />
          <div>
            <Collapse in={certificatesAIObject !== null}>
              <Typography variant="h5" className={classes.modalTitle}>
                Candidate Certifications
              </Typography>
              {displayChips}
              <Divider />
            </Collapse>
          </div>
          <Typography
            style={{ fontWeight: 700, marginBottom: 15 }}
            color="textPrimary"
            variant="subtitle1"
          >
            Add Certificate:
          </Typography>
          <TextField
            id="certificateId"
            label="Certificate"
            variant="outlined"
            value={selectedCertificate}
            onChange={handleSelectedCertificate}
            style={{ width: "100%", marginBottom: theme.spacing(2) }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={selectedCertificate === ""}
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              onClick={
                updatedChip <= 0
                  ? handleAddSingleCertificateAI
                  : updateSelectedChip
              } //add new Object with all values of this Component
            >
              {updatedChip > 0 ? "Update Certificate" : "Add Certificate"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                certificatesAIObject === null ||
                certificatesAIObject === "undefined" ||
                Object.keys(certificatesAIObject).length <= 0 ||
                certificatesAIObject.length <= 0
              }
              className={classes.button}
              endIcon={<Icon>done</Icon>}
              onClick={buildAICertificateObject}
            >
              Done
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default React.memo(AICertificates);
