import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
//import Popover from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import gray from "@material-ui/core/colors/grey";
import AddIcon from "@material-ui/icons/Add";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    justifyContent: "flex-start",
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
      height: 32,
    },
  },
  tags: {
    alignItems: "center",
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

export default function Tags(props) {
  const classes = useStyles();
  const [addMode, setAddMode] = React.useState(false);

  const handleChangeMode = (event) => setAddMode(true);
  const handleAddTag = (event) => {
    setAddMode(false);
    props.AddTags(event.target.value);
  };

  const handleTagKeyPress = (event) => {
    if (
      event.keyCode === 32 ||
      (event.keyCode === 186 && event.key === ";") ||
      event.keyCode === 188 ||
      event.keyCode === 222
    ) {
      setAddMode(false);
      if (event.target.value.length) {
        props.AddTags(
          event.target.value.substring(0, event.target.value.length - 1)
        );
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.tags}>
        {props.tags.map((chip) => (
          <Chip
            label={`#${chip}`}
            size="small"
            key={chip}
            onDelete={() => props.onChipDelete(chip)}
          />
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={handleChangeMode}
          disableElevation={true}
          style={{ textTransform: "lowercase", color: gray[700] }}
          disabled={props.disabled}
        >
          Add Tags
        </Button>
        {addMode ? (
          <TextField
            id="standard-tag"
            onBlur={handleAddTag}
            onKeyUp={handleTagKeyPress}
            className={clsx(classes.margin, classes.textField)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">#</InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            style={{ margin: 0 }}
          />
        ) : null}
      </div>
    </div>
  );
}
