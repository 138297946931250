import React from "react";
import { Box, IconButton } from "@material-ui/core";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { red } from "@material-ui/core/colors";

const DeleteEditActionButtons = ({
  maxWidth,
  onRemove,
  onEdit,
  readOnlyMode = false,
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      maxWidth={maxWidth ? maxWidth : 100}
      alignItems="center"
    >
      <IconButton
        aria-label="edit"
        onClick={onEdit}
        color="secondary"
        disabled={readOnlyMode}
      >
        <EditRoundedIcon fontSize="small" />
      </IconButton>
      <IconButton
        aria-label="delete"
        onClick={onRemove}
        style={{ color: red[500] }}
        disabled={readOnlyMode}
      >
        <DeleteOutlineRoundedIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};
export default DeleteEditActionButtons;
