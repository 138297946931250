import React, { useEffect } from "react";
import { Link as RouterLink, useLocation, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { ACCOUNT_REDIRECT_WEBSITE } from "../../../../config/constants";
import { Drawer, Hidden, makeStyles } from "@material-ui/core";
import TopBar from "./TopBar";
import NavBarItems from "./NavbarItems";
import cookies from "js-cookie";
import LanguageSwitcher from "../../../../components/LanguageSwitcher/LanguageSwitcher";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    //backgroundColor: theme.palette.type === 'dark' ? '#212936' : '#fff'
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({
  onMenuSelect,
  currentActive,
  initials,
  onChangeProfileImage,
  candidateFullName,
  profileImage,
  preferredLanguage,
  candidateId,
  ...props
}) => {
  const classes = useStyles();
  const location = useLocation();
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  useEffect(() => {
    if (isMobileNavOpen) {
      setIsMobileNavOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // On file select (from the pop up)
  const onFileChange = (event) => {
    //console.log(URL.createObjectURL(event.target.files[0]));
    event.target.files.length && onChangeProfileImage(event.target.files[0]);
  };

  const handleLogout = () => {
    //clear cookies if not remember me.
    cookies.remove("cand_id");
    cookies.remove("token");
    window.location.href = ACCOUNT_REDIRECT_WEBSITE.toString();
  };

  const handleCloseMobileNav = () => {
    setIsMobileNavOpen(false);
  };

  const handleMenuSelectOnMobileMenu = (value) => {
    setIsMobileNavOpen(false);
    onMenuSelect(value);
  };

  return (
    <>
      <Hidden lgUp>
        <TopBar onMobileNavOpen={() => setIsMobileNavOpen(true)} />
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={handleCloseMobileNav}
          open={isMobileNavOpen}
          variant="temporary"
          transitionDuration={500}
        >
          <NavBarItems
            onFileChange={onFileChange}
            profileImage={profileImage}
            initials={initials}
            profileFullName={candidateFullName}
            onLogout={handleLogout}
            onMenuSelect={handleMenuSelectOnMobileMenu}
            currentActive={currentActive}
          />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <LanguageSwitcher
            preferredLanguage={preferredLanguage}
            candidateId={candidateId}
          />
          <NavBarItems
            onFileChange={onFileChange}
            profileImage={profileImage}
            initials={initials}
            profileFullName={candidateFullName}
            onLogout={handleLogout}
            onMenuSelect={onMenuSelect}
            currentActive={currentActive}
          />
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default withRouter(NavBar);
