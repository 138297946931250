import React from "react";
import { Box, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 90,
    height: 90,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderWidth: (props) => (props.indicateSelection ? 3 : 1),
    borderStyle: "solid",
    borderRadius: 4,
    boxSizing: "border-box",
    borderColor: (props) =>
      props.indicateSelection ? theme.palette.primary.main : grey[800],
    alignItems: "center",
    background: "#fff",
    "& .MuiTypography-root": {
      fontWeight: 600,
      opacity: (props) => (props.active ? 1 : 0.5),
    },
    "& img": {
      textAlign: "center",
      marginBottom: theme.spacing(1),
      opacity: (props) => (props.active ? 1 : 0.5),
    },
  },
}));

const AnswerBox = (props) => {
  const classes = useStyles(props);

  return (
    <Box
      className={classes.root}
      onClick={() => props.answerSelected(props.answerValue)}
    >
      <img
        src={require(`../../../../Assets/Images/${props.iconName}.png`)}
        alt={props.iconName}
      />
      <Typography varaint="body1" align="center">
        {props.answerLabel}
      </Typography>
    </Box>
  );
};
export default AnswerBox;
