import React from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiSkeleton-root": {
      marginBottom: theme.spacing(1),
    },
  },
}));

const JobsSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Skeleton variant="text" width={200} height={40} animation="pulse" />
      <Skeleton variant="rect" width={"100%"} height={200} animation="pulse" />
      <Skeleton variant="text" width={200} height={40} animation="pulse" />
      <Skeleton variant="rect" width={"100%"} height={300} animation="pulse" />
    </div>
  );
};
export default JobsSkeleton;
