import React from "react";
import { Box, IconButton, useTheme, Tooltip } from "@material-ui/core";
import { MenuMoreOptions } from "../../config/candidatesConfigData";
import EntityMoreActions from "../../components/EntityMoreActions/EntitysMoreActions";
import { DeleteIcon } from "../../Assets/CustomIcons/DeleteIcon";
import DownloadCVIcon from "../../Assets/CustomIcons/DownloadCV";
import { red } from "@material-ui/core/colors";

const SingleCandidateActions = ({ menuItemClick, downloadCV }) => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center">
      <IconButton>
        <DeleteIcon style={{ color: red[500] }} />
      </IconButton>
      <Tooltip title="Watch CV" arrow>
        <span>
          <IconButton onClick={downloadCV} disabled>
            <DownloadCVIcon
              style={{ color: theme.palette.primary.main }}
              strokeColor={theme.palette.primary.main}
            />
          </IconButton>
        </span>
      </Tooltip>
      <EntityMoreActions
        style={{ marginRight: 0 }}
        options={MenuMoreOptions}
        menuItemClick={menuItemClick}
      />
    </Box>
  );
};

export default SingleCandidateActions;
