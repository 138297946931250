import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Icon, Button, Box } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { DEGREE_TYPES } from "../../../../../config/constants";
import { DegreesList } from "../../../../../config/jobsConfigData";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import DegreeTypeRadios from "../../../../../components/AdditionaInfoComponents/DegreeTypeRadios";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginLeft: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    left: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
  formControl: {
    minWidth: 300,
    marginBottom: theme.spacing(2),
  },
}));

//return a label for the chip
function academicDegreeLabel(degree, type) {
  switch (degree) {
    case "firstDegree":
      return `First Degree - ${type}`;
    case "secondDegree":
      return `Second Degree - ${type}`;
    case "doctor":
      return `Doctor Degree - ${type}`;
    case "engineer":
      return `Engineer Degree - ${type}`;
    case "practicalEngineer":
      return `Practical Engineer`;
    case "practicalTechnition":
      return `Technician`;
    default:
      break;
  }
}

const AIAcademicDegree = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedDegree, setSelectedDegree] = React.useState("");
  const [degreeType, setDegreeType] = React.useState("");
  const [degreeOn, setDegreeOn] = React.useState("");
  const [academicDegreesAIObject, setAcademicDegreesAIObject] = React.useState(
    []
  );
  const [updatedChip, setUpdatedChip] = React.useState(-1);

  React.useEffect(() => {
    if (props.aIValue && Object.keys(props.aIValue).length) {
      const aiTempObject = props.aIValue.map((aiItem) => {
        return (
          [...academicDegreesAIObject],
          {
            key: aiItem.degreeId,
            degreeId: aiItem.degreeId,
            degreeName: aiItem.degreeName,
            degreeType: aiItem.degreeType,
            degreeOn: aiItem.degreeOn,
          }
        );
      });
      setAcademicDegreesAIObject(aiTempObject);
    }
  }, [props.aIValue]);

  const buildAIAcademicDegreesObject = () => {
    props.addAI(academicDegreesAIObject);
  };

  const handleAddSingleAcademicDegreeAI = () => {
    academicDegreesAIObject === null
      ? setAcademicDegreesAIObject([
          {
            degreeId:
              academicDegreesAIObject === null
                ? 1
                : academicDegreesAIObject.length + 1,
            degreeName: selectedDegree,
            degreeType,
            degreeOn,
          },
        ])
      : setAcademicDegreesAIObject([
          ...academicDegreesAIObject,
          {
            degreeId: academicDegreesAIObject.length + 1,
            degreeName: selectedDegree,
            degreeType,
            degreeOn,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = academicDegreesAIObject.find(
      (chip) => chip.degreeId === updatedChip
    );

    //remove the selected chip from the array:
    const tempDegreeAIObject = academicDegreesAIObject.filter(
      (ai) => ai.degreeId !== tempUpdatedChip.degreeId
    );

    //update the form:
    setAcademicDegreesAIObject([
      ...tempDegreeAIObject,
      {
        degreeId: tempUpdatedChip.degreeId,
        degreeName: selectedDegree,
        degreeType,
        degreeOn,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setSelectedDegree("");
    setDegreeType("");
    setDegreeOn("");
    setUpdatedChip(-1);
  };

  const handleRemoveDegree = (degreeId) => {
    //locate index id
    const newDegreeAIObject = academicDegreesAIObject.filter(
      (ad) => ad.degreeId !== degreeId
    );
    setAcademicDegreesAIObject(newDegreeAIObject);
    clearForm();
  };

  const handleDegreeTypeChange = (event) => {
    setDegreeType(event.target.value);
  };
  const handleChangeDegree = (event) => {
    setSelectedDegree(event.target.value);
    switch (event.target.value) {
      case "firstDegree":
        setDegreeType(DEGREE_TYPES.first[0]);
        break;
      case "secondDegree":
        setDegreeType(DEGREE_TYPES.first[1]);
        break;
      case "doctor":
        setDegreeType(DEGREE_TYPES.first[2]);
        break;
      case "":
        setDegreeType("");
        break;
      default:
        break;
    }
  };

  const handleReadChip = (adId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleDegreeObj = academicDegreesAIObject.find(
        (ad) => ad.degreeId === adId
      );
      setSelectedDegree(singleDegreeObj.degreeName);
      setDegreeType(singleDegreeObj.degreeType);
      setDegreeOn(singleDegreeObj.degreeOn);
      setUpdatedChip(adId);
    }
  };

  const displayChips =
    academicDegreesAIObject !== null
      ? academicDegreesAIObject.map((ad) => {
          return (
            <Chip
              key={ad.degreeId}
              icon={<FaceIcon />}
              label={academicDegreeLabel(ad.degreeName, ad.degreeType)}
              clickable
              color="primary"
              onClick={() => handleReadChip(ad.degreeId)}
              onDelete={() => handleRemoveDegree(ad.degreeId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== ad.degreeId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            className={classes.modalTitle}
          >
            {props.candidateFullName !== "undefined" &&
            props.candidateFullName.length > 1
              ? `Add Academic Degree for ${props.candidateFullName}`
              : "Add Academic Degree"}
          </Typography>
          <Divider />
          <div>
            <Collapse
              in={
                academicDegreesAIObject !== null &&
                Object.keys(academicDegreesAIObject).length > 0
              }
            >
              <Typography variant="h5" className={classes.modalTitle}>
                Candidate Academic Degrees
              </Typography>
              {displayChips}
              <Divider />
            </Collapse>
          </div>

          <Typography
            style={{ fontWeight: 700, marginBottom: 15 }}
            color="textPrimary"
            variant="subtitle1"
          >
            Select Degree
          </Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">
              Academic Degree
            </InputLabel>
            <Select
              labelId="AcademicDegreeLabel"
              id="select-outlined"
              value={selectedDegree}
              onChange={handleChangeDegree}
              label="Academic Degree"
            >
              <MenuItem value="">
                <em>Without Degree</em>
              </MenuItem>
              {DegreesList.map((degree) => (
                <MenuItem key={degree.value} value={degree.value}>
                  {degree.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Collapse in={selectedDegree !== ""}>
            <Typography
              style={{ fontWeight: 700, marginBottom: 15 }}
              color="textPrimary"
              variant="subtitle1"
            >
              Select Degree type:
            </Typography>
            <div
              style={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            >
              <TextField
                id="degreeOnField"
                label="Degree On:"
                variant="outlined"
                fullWidth
                value={degreeOn}
                onChange={(e) => setDegreeOn(e.target.value)}
              />
            </div>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="degreeTypeRadio"
                name="radioType"
                value={degreeType}
                onChange={handleDegreeTypeChange}
              >
                <DegreeTypeRadios degree={selectedDegree} />
              </RadioGroup>
            </FormControl>
          </Collapse>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={theme.spacing(2)}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={
                selectedDegree === "" || degreeType === "" || degreeOn === ""
              }
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              onClick={
                updatedChip <= 0
                  ? handleAddSingleAcademicDegreeAI
                  : updateSelectedChip
              } //add new Object with all values of this Component
            >
              {updatedChip > 0 ? "Update Degree" : "Add Degree"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                academicDegreesAIObject === null ||
                academicDegreesAIObject === "undefined" ||
                Object.keys(academicDegreesAIObject).length <= 0 ||
                academicDegreesAIObject.length <= 0
              }
              className={classes.button}
              endIcon={<Icon>done</Icon>}
              onClick={buildAIAcademicDegreesObject}
            >
              Done
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
export default React.memo(AIAcademicDegree);
