import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Icon, Button } from "@material-ui/core";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import MyTooltip from "../../../../../UI/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));

const AILicenses = (props) => {
  const classes = useStyles();
  const [selectedLicense, setSelectedLicense] = React.useState("");
  const [licenseYears, setLicenseYears] = React.useState(0);
  const [updatedChip, setUpdatedChip] = React.useState(-1);
  const [licensesAIObject, setLicensesAIObject] = React.useState([]);
  const theme = useTheme();

  React.useEffect(() => {
    if (props.aIValue && Object.keys(props.aIValue).length) {
      const aiTempObject = props.aIValue.map((aiItem) => {
        return (
          [...licensesAIObject],
          {
            key: aiItem.licenseId,
            licenseId: aiItem.licenseId,
            licenseName: aiItem.licenseName,
            licenseYears: parseInt(aiItem.licenseYears),
          }
        );
      });
      setLicensesAIObject(aiTempObject);
    }
  }, [props.aIValue]);

  const handleSelectedLicense = (event) => {
    setSelectedLicense(event.target.value);
  };

  const buildAILicensesObject = (event) => {
    props.addAI(licensesAIObject);
  };

  const handleLicenseYears = (event) => setLicenseYears(event.target.value);

  const handleCheckEnableQ = (value) => {
    if (!value) setLicenseYears(0);
  };

  /*
  Single License Object: 
  {
      licenseId: id,
      licenseName: string,
      licenseYears: num,      
  }
  */
  const handleAddSingleLicenseAI = () => {
    licensesAIObject === null
      ? setLicensesAIObject([
          {
            licenseId:
              licensesAIObject === null ? 1 : licensesAIObject.length + 1,
            licenseName: selectedLicense,
            licenseYears,
          },
        ])
      : setLicensesAIObject([
          ...licensesAIObject,
          {
            licenseId: licensesAIObject.length + 1,
            licenseName: selectedLicense,
            licenseYears,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = licensesAIObject.find(
      (chip) => chip.licenseId === updatedChip
    );

    //remove the selected chip from the array:
    const tempLicenseAIObject = licensesAIObject.filter(
      (ai) => ai.licenseId !== tempUpdatedChip.licenseId
    );

    //update the form:
    setLicensesAIObject([
      ...tempLicenseAIObject,
      {
        licenseId: tempUpdatedChip.licenseId,
        licenseName: selectedLicense,
        licenseYears,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setLicenseYears(0);
    setUpdatedChip(-1);
    setSelectedLicense("");
  };

  const handleRemoveLicense = (licenseId) => {
    //locate index id
    const newLicensesFQObject = licensesAIObject.filter(
      (license) => license.licenseId !== licenseId
    );
    setLicensesAIObject(newLicensesFQObject);
    clearForm();
  };

  const handleReadChip = (id) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleLicenseObj = licensesAIObject.find(
        (lc) => lc.licenseId === id
      );
      setLicenseYears(singleLicenseObj.licenseYears);
      setSelectedLicense(singleLicenseObj.licenseName);
      setUpdatedChip(id);
    }
  };

  const displayChips =
    licensesAIObject !== null
      ? licensesAIObject.map((lcn) => {
          return (
            <Chip
              key={lcn.licenseId}
              icon={<FaceIcon />}
              label={lcn.licenseName}
              clickable
              color="primary"
              onClick={() => handleReadChip(lcn.licenseId)}
              onDelete={() => handleRemoveLicense(lcn.licenseId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== lcn.licenseId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            className={classes.modalTitle}
          >
            {props.candidateFullName !== "undefined" &&
            props.candidateFullName.length > 1
              ? `Add License for ${props.candidateFullName}`
              : "Add License"}
          </Typography>
          <Divider />
          <div>
            <Collapse in={licensesAIObject !== null}>
              <Typography variant="h5" className={classes.modalTitle}>
                Candidate Licenses
              </Typography>
              {displayChips}
              <Divider />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: theme.spacing(1),
            }}
          >
            <Typography
              style={{ fontWeight: 700 }}
              color="textPrimary"
              variant="subtitle1"
            >
              Type the license name
            </Typography>
            <MyTooltip
              marginRight
              arrow
              caption="Type candidate's license name"
            />
          </div>

          <TextField
            id="lincense"
            label="License"
            variant="outlined"
            value={selectedLicense}
            onChange={handleSelectedLicense}
            style={{ width: "100%" }}
          />
          <QuestionValueInput
            disabled={selectedLicense === ""}
            question="Number of years holding this license:"
            minYearsValue={handleLicenseYears}
            disableVerificationQuestion
            value={licenseYears}
            checkEnalbeQ={handleCheckEnableQ}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={selectedLicense === ""}
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              onClick={
                updatedChip <= 0 ? handleAddSingleLicenseAI : updateSelectedChip
              } //add new Object with all values of this Component
            >
              {updatedChip > 0 ? "Update License" : "Add License"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                licensesAIObject === null ||
                licensesAIObject === "undefined" ||
                Object.keys(licensesAIObject).length <= 0 ||
                licensesAIObject.length <= 0
              }
              className={classes.button}
              endIcon={<Icon>done</Icon>}
              onClick={buildAILicensesObject}
            >
              Done
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default React.memo(AILicenses);
