import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Typography,
  Box,
  TextField,
  FormControlLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
} from "@material-ui/core";

const RegistrationStep1 = ({
  firstName,
  onFirstNameChange,
  lastName,
  onLastNameChange,
  gender,
  onGenderChange,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <React.Fragment>
      <Typography variant="h4" align="center">
        <FormattedMessage
          id="candidateProfile.signUp.step1.greeting"
          defaultMessage="Hello! I'm Libi, your smart agent and I'll find you the perfect job!"
        />
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="textSecondary"
        style={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
      >
        <FormattedMessage
          id="candidateProfile.signUp.step1.notKnown"
          defaultMessage="I've noticed we haven't met yet. Shall we make a quick introduction?"
        />
      </Typography>

      <Box
        px={theme.spacing(2)}
        maxWidth={400}
        display="flex"
        align="center"
        flexDirection="column"
        m="auto"
      >
        <Box>
          <TextField
            id="firstName"
            label={intl.formatMessage({
              id: "candidates.add.firstName",
              defaultMessage: "First Name",
            })}
            variant="filled"
            color="primary"
            fullWidth
            value={firstName}
            onChange={onFirstNameChange}
            style={{ marginBottom: theme.spacing(2) }}
          />
          <TextField
            id="lastName"
            label={intl.formatMessage({
              id: "candidates.add.lastName",
              defaultMessage: "Last Name",
            })}
            variant="filled"
            color="primary"
            fullWidth
            value={lastName}
            onChange={onLastNameChange}
            style={{ marginBottom: theme.spacing(2) }}
          />
        </Box>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <FormattedMessage id="common.gender" defaultMessage="Gender" />
          </FormLabel>
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={gender}
            onChange={onGenderChange}
          >
            <FormControlLabel
              value="male"
              control={<Radio color="primary" />}
              label={intl.formatMessage({
                id: "genders.options.male",
                defaultMessage: "Male",
              })}
            />
            <FormControlLabel
              value="female"
              control={<Radio color="primary" />}
              label={intl.formatMessage({
                id: "genders.options.female",
                defaultMessage: "Female",
              })}
            />
            <FormControlLabel
              value="other"
              control={<Radio color="primary" />}
              label={intl.formatMessage({
                id: "genders.options.other",
                defaultMessage: "Other",
              })}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </React.Fragment>
  );
};
export default RegistrationStep1;
