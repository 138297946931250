import React from "react";
import { Typography, Divider, Box, makeStyles } from "@material-ui/core";

import AccessTimeRoundedIcon from "@material-ui/icons/AccessTimeRounded";
import { ContinueIcon } from "../../Assets/CustomIcons/CustomIconsList";
import { formatDistanceToNow } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    "& > *": {
      marginLeft: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
      marginRight: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
      marginTop: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
      marginBottom: (props) =>
        props.viewType === "grid" ? theme.spacing(1) : theme.spacing(2),
    },
    "& :last-child": {
      marginRight: 0,
      marginLeft: 0,
    },

    "& .MuiTypography-h6:first-of-type": {
      marginLeft: 0,
    },
  },
}));

const DistirbutionsData = ({ totalDistributions, lastPublished }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <div>
        <Typography
          variant="body1"
          style={{
            display: "flex",
            alignItems: "center",
            color: "#A4A6B3",
          }}
        >
          <ContinueIcon
            color="secondary"
            style={{
              marginRight: 5,
              fontSize: "1rem",
            }}
          />{" "}
          Total Distributions: {totalDistributions}
        </Typography>{" "}
      </div>
      <Divider orientation="vertical" flexItem />
      <div>
        <Typography
          variant="body1"
          style={{
            display: "flex",
            alignItems: "center",
            color: "#A4A6B3",
          }}
        >
          <AccessTimeRoundedIcon
            color="secondary"
            style={{
              marginRight: 5,
              transform: "scale(-1, 1)",
              fontSize: "1rem",
            }}
          />
          {lastPublished
            ? `Last published: ${formatDistanceToNow(new Date(lastPublished), {
                addSuffix: true,
              })}`
            : "Draft"}
        </Typography>{" "}
      </div>
    </Box>
  );
};
export default DistirbutionsData;
