import React from "react";
import { Tooltip } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";

const buildStyle = (marginTop, marginLeft) => {
  return {
    marginTop: marginTop !== "" ? marginTop : "",
    marginLeft: marginLeft !== "" ? marginLeft : "",
  };
};

const MyTooltip = (props) => {
  const location = "left";
  return (
    <Tooltip
      placement={props.location ? props.location : location}
      arrow
      title={props.caption}
    >
      <Icon
        color="primary"
        style={{
          marginTop: buildStyle.marginTop,
          marginLeft: buildStyle.marginLeft,
        }}
      >
        info_circle
      </Icon>
    </Tooltip>
  );
};
export default MyTooltip;
