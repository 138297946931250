import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import AppsRoundedIcon from "@material-ui/icons/AppsRounded";
import ListRoundedIcon from "@material-ui/icons/ListRounded";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inactive: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.grey[500],
    },
  },
  active: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ViewType({ onChange, selectedView = "list" }) {
  const classes = useStyles();
  return (
    <ButtonGroup disableElevation variant="outlined">
      <Button
        className={selectedView === "list" ? classes.active : classes.inactive}
        onClick={() => onChange("list")}
      >
        {<ListRoundedIcon />}
      </Button>
      <Button
        className={selectedView === "grid" ? classes.active : classes.inactive}
        onClick={() => onChange("grid")}
      >
        {<AppsRoundedIcon />}
      </Button>
    </ButtonGroup>
  );
}
