import { withStyles } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
const StyledRating = withStyles({
  iconFilled: {
    color: "#8593FF",
  },
  iconHover: {
    color: "#8593FF",
  },
})(Rating);
export default StyledRating;
