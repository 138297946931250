import React, { useState, useRef } from "react";
import cookies from "js-cookie";
import { withRouter } from "react-router-dom";
import { makeStyles, Snackbar, CssBaseline } from "@material-ui/core";
import { TranformAdditionalInformationData } from "../../../../config/candidatesConfigData";
import CandidateNavBar from "./CandidateNavBar";
import DashboardElements from "./CandidateDashboardElements";
import Settings from "../../Settings/Settings";
import AboutMe from "./aboutMe";
import LookingFor from "./LookingFor";
import Spinner from "../../../../UI/Spinner/Spinner";
import axios from "axios";
import { Alert } from "@material-ui/lab";
import ProfileStregthContext from "../../../../context/profileStrengthContext";
import validator from "validator";
import {
  AI_EXPERIENCES,
  AI_LICENSES,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_SALARY_EXPECTATIONS,
} from "../../../../config/constants";
import ErrorBoundary from "../../../../components/ErrorBoundary/ErrorBoundary";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
    "& .MuiAvatar-root": {
      width: theme.spacing(7),
      height: theme.spacing(7),
      marginBottom: theme.spacing(1),
    },
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const isAIObjectFilled = (obj, aiKey) => {
  const objIndex = obj.findIndex((oi) => {
    return Object.keys(oi).findIndex((innerobj) => innerobj === aiKey);
  });
  try {
    return Object.values(obj[objIndex]).length > 0;
  } catch (e) {
    return false;
  }
};

const ProfileWeights = (field) => {
  switch (field) {
    case "firstName":
    case "lastName":
      return 2.5;
    case "email":
    case "identifierNumber":
    case "mobile":
      return 5; //total 20
    case "yearOfBirth":
    case "city":
    case "gender":
    case "family_status":
      return 3.75; //total 15
    case AI_ACADEMIC_DGREE:
    case AI_LANGUAGES:
    case AI_CERTIFICATES:
    case AI_LICENSES:
      return 5; //total 20
    case AI_SALARY_EXPECTATIONS:
      return 15; //total 10
    case "candidate_areas":
    case "professions":
      return 7.5;
    case "working_cities":
    case "job_extent":
    case "cv":
      return 5; //total 25

    default:
      return 0;
  }
};

const DashboardLayout = (props) => {
  const classes = useStyles();
  //const theme = useTheme();
  const candidateId = useRef(cookies.get("cand_id")).current;
  const [candidateData, setCandidateData] = useState(null);
  const [cvUploading, setCvUploading] = React.useState(false);
  const [currentScreen, setCurrentScreen] = useState("lobby");
  const [dataUpdating, setDataUpdading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profileImage, setProfileImage] = React.useState(null);
  const [updatedSuccessfull, setUpdatedSuccessfully] = React.useState(false);
  const [preferredLanguage, setPreferredLanguage] = React.useState("en");
  const [settings, setSettings] = React.useState({
    allowJobs: false,
    allowMarketing: false,
  });
  //const [darkMode, setDarkMode] = React.useState(false); //used saperately from settings to record specific changes in value and re-render
  const [profileStrength, setProfileStrength] = React.useState({
    totalScore: 0,
    missing: [],
  });
  const [error, setError] = useState(null);
  React.useEffect(() => {
    setCurrentScreen(
      props.match.url === "/my-profile/settings" ? "settings" : "lobby"
    );
    axios
      .get(`/wp-json/api/v2/my-profile/lobby/general_data/${candidateId}`)
      .then((res) => {
        const transformObject = {
          id: candidateId,
          additional_information: res.data.additional_information,
        };
        let candidatesAdditionalInformationUpdatedData = [];
        candidatesAdditionalInformationUpdatedData = TranformAdditionalInformationData(
          transformObject
        );

        const candData = res.data;
        setPreferredLanguage(res.data.preferred_language);
        candData.additional_information = candidatesAdditionalInformationUpdatedData.map(
          (aiData) => {
            const keys = Object.keys(aiData)[0];
            const values = Object.values(aiData)[0];

            switch (keys) {
              case AI_EXPERIENCES: {
                return {
                  experiences: Object.values(values.typeBySubType).map(
                    (exp) => {
                      //build Experince Object:
                      let sId = 0;
                      let sName = "";
                      let years = 0;
                      let isManagementRole = false;
                      exp.map((singleExp) => {
                        switch (singleExp.ai_key) {
                          case "experienceName":
                            sName = singleExp.ai_value;
                            sId = singleExp.ai_sub_type_id;
                            break;
                          case "yearsExperience":
                            years = parseInt(singleExp.ai_value);
                            break;
                          case "isManagementRole":
                            isManagementRole =
                              parseInt(singleExp.ai_value) === 1 ? true : false;
                            break;
                          default:
                            break;
                        }
                      });
                      return {
                        experienceId: sId,
                        experienceName: sName,
                        yearsExperience: years,
                        isManagementRole: isManagementRole,
                      };
                    }
                  ),
                };
              }
              case AI_ACADEMIC_DGREE: {
                return {
                  academicDegrees: Object.values(values.typeBySubType).map(
                    (aiItem) => {
                      let sId = 0;
                      let sName = "";
                      let type = "";
                      let dOn = "";
                      aiItem.map((singleExp) => {
                        switch (singleExp.ai_key) {
                          case "degreeName":
                            sName = singleExp.ai_value;
                            sId = singleExp.ai_sub_type_id;
                            break;
                          case "degreeType":
                            type = singleExp.ai_value;
                            break;
                          case "degreeOn":
                            dOn = singleExp.ai_value;
                            break;
                          default:
                            break;
                        }
                      });
                      return {
                        degreeId: parseInt(sId),
                        degreeName: sName,
                        degreeType: type,
                        degreeOn: dOn,
                      };
                    }
                  ),
                };
              }
              case AI_CERTIFICATES: {
                return {
                  certificates: Object.values(values.typeBySubType).map(
                    (aiItem) => {
                      let sId = 0;
                      let sName = "";
                      aiItem.map((singleExp) => {
                        switch (singleExp.ai_key) {
                          case "certificateName":
                            sName = singleExp.ai_value;
                            sId = singleExp.ai_sub_type_id;
                            break;
                          default:
                            break;
                        }
                      });
                      return {
                        certificateId: parseInt(sId),
                        certificateName: sName,
                      };
                    }
                  ),
                };
              }

              case AI_LICENSES: {
                return {
                  licenses: Object.values(values.typeBySubType).map(
                    (aiItem) => {
                      let sId = 0;
                      let sName = "";
                      let years = 0;
                      aiItem.map((singleExp) => {
                        switch (singleExp.ai_key) {
                          case "licenseName":
                            sName = singleExp.ai_value;
                            sId = singleExp.ai_sub_type_id;
                            break;
                          case "licenseYears":
                            years = parseInt(singleExp.ai_value);
                            break;
                          default:
                            break;
                        }
                      });
                      return {
                        licenseId: parseInt(sId),
                        licenseName: sName,
                        licenseYears: years,
                      };
                    }
                  ),
                };
              }
              case AI_LANGUAGES: {
                return {
                  languages: Object.values(values.typeBySubType).map(
                    (aiItem) => {
                      let sId = 0;
                      let sName = "";
                      let level = false;

                      aiItem.map((singleExp) => {
                        switch (singleExp.ai_key) {
                          case "languageName":
                            sName = singleExp.ai_value;
                            sId = singleExp.ai_sub_type_id;
                            break;
                          case "languageLevel":
                            level = singleExp.ai_value;
                            break;
                          default:
                            break;
                        }
                      });
                      return {
                        languageId: parseInt(sId),
                        languageName: sName,
                        languageLevel: level,
                      };
                    }
                  ),
                };
              }
              case AI_SALARY_EXPECTATIONS: {
                return {
                  salaryExpectations: Object.values(values.typeBySubType).map(
                    (aiItem) => {
                      let sId = 1;
                      let sRole = "";
                      let sRange = [];
                      let sIsRange = false;
                      let sMeasure = "";

                      aiItem.map((singleExp) => {
                        switch (singleExp.ai_key) {
                          case "seForRole":
                            sRole = singleExp.ai_value;
                            sId = singleExp.ai_sub_type_id;
                            break;
                          case "seIsRange":
                            sIsRange =
                              singleExp.ai_value === "true" ? true : false;
                            break;
                          case "seRange":
                            sRange = singleExp.ai_value.includes("-")
                              ? singleExp.ai_value.split("-")
                              : parseFloat(singleExp.ai_value);
                            break;
                          case "seMeasure":
                            sMeasure = singleExp.ai_value;
                            break;
                          default:
                            break;
                        }
                      });

                      return {
                        seId: sId,
                        seForRole: sRole,
                        seIsRange: sIsRange,
                        seRange: sRange,
                        seMeasure: sMeasure,
                      };
                    }
                  ),
                };
              }
              default:
                break;
            }
            //
          }
        );

        if (res.data.profile_image) setProfileImage(res.data.profile_image);

        setCandidateData(candData);

        setIsLoading(false);
        const localSettings = {
          allowJobs: candData.settings.allow_jobs
            ? candData.settings.allow_jobs
            : false,
          allowMarketing: candData.settings.allow_marketing
            ? candData.settings.allow_marketing
            : false,
          darkMode: candData.settings.dark_mode
            ? candData.settings.dark_mode
            : false,
        };
        setSettings(localSettings);
        //update darkmode app.js
        candData.settings.dark_mode &&
          props.onToggleDark(
            candData.settings.dark_mode ? candData.settings.dark_mode : false
          );
      })
      .catch((err) => {
        console.log(err);
        if (err.isAxiosError && err.response.status === 403) {
          //clear cookies:
          cookies.remove("token");
          cookies.remove("cand_id");
          props.history.push("/my-profile/login");
        }
        setIsLoading(false);
        setError(err);
      });
  }, [props.settings]);
  //returns an object with a form of:
  //{score: number, missing: [array of fields (string)]}
  const calcProfileStrength = () => {
    let totalScore = 0.0;
    let missing = [];
    !validator.isEmpty(candidateData.firstName)
      ? (totalScore += ProfileWeights("firstName"))
      : (missing = [...missing, "firstName"]);
    !validator.isEmpty(candidateData.lastName)
      ? (totalScore += ProfileWeights("lastName"))
      : (missing = [...missing, "lastName"]);
    validator.isEmail(candidateData.email)
      ? (totalScore += ProfileWeights("email"))
      : (missing = [...missing, "email"]);
    validator.isNumeric(
      candidateData.identifierNumber ? candidateData.identifierNumber : ""
    )
      ? (totalScore += ProfileWeights("identifierNumber"))
      : (missing = [...missing, "identifierNumber"]);
    !validator.isMobilePhone(candidateData.mobile, "he-IL")
      ? (totalScore += ProfileWeights("mobile"))
      : (missing = [...missing, "mobile"]);
    candidateData.yearOfBirth
      ? (totalScore += ProfileWeights("yearOfBirth"))
      : (missing = [...missing, "yearOfBirth"]);
    candidateData.city
      ? (totalScore += ProfileWeights("city"))
      : (missing = [...missing, "city"]);
    candidateData.gender
      ? (totalScore += ProfileWeights("gender"))
      : (missing = [...missing, "gender"]);
    candidateData.family_status
      ? (totalScore += ProfileWeights("family_status"))
      : (missing = [...missing, "family_status"]);
    candidateData.additional_information.forEach((aiObj) => {
      isAIObjectFilled(
        candidateData.additional_information,
        Object.keys(aiObj)[0]
      )
        ? (totalScore += ProfileWeights(Object.keys(aiObj)[0]))
        : (missing = [...missing, Object.keys(aiObj)[0]]);
    });

    candidateData.candidate_areas && candidateData.candidate_areas.length > 0
      ? (totalScore += ProfileWeights("candidate_areas"))
      : (missing = [...missing, "candidate_areas"]);

    candidateData.professions && candidateData.professions.length
      ? (totalScore += ProfileWeights("professions"))
      : (missing = [...missing, "professions"]);

    candidateData.working_cities && candidateData.working_cities.length
      ? (totalScore += ProfileWeights("working_cities"))
      : (missing = [...missing, "working_cities"]);

    candidateData.job_extent && candidateData.job_extent.length
      ? (totalScore += ProfileWeights("job_extent"))
      : (missing = [...missing, "job_extent"]);

    return {
      totalScore: Math.round(totalScore),
      missing,
    };
  };

  React.useEffect(() => {
    if (updatedSuccessfull || candidateData !== null) {
      setProfileStrength(calcProfileStrength());
    }
  }, [updatedSuccessfull, candidateData]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setUpdatedSuccessfully(false);
  };

  const handleActiveMode = (activemode) => {
    setCurrentScreen(activemode);
  };

  const handleRemove = async (type, value) => {
    let removeData = {
      type,
      subTypeId: value.id,
    };

    try {
      const res = await axios.delete(
        `/wp-json/api/v2/my-profile/lobby/update_profile_details/${candidateId}`,
        {
          data: { removeData },
        }
      );
      let updatedCandidateData = candidateData;
      if (parseInt(res.data) > 0) {
        //update the state:
        switch (type) {
          case "academic_degree":
            const aiAcademicDegreeIndex = updatedCandidateData.additional_information.findIndex(
              (aiType) => Object.keys(aiType)[0] === "academicDegrees"
            );

            if (aiAcademicDegreeIndex > -1) {
              const removeId = Object.values(
                updatedCandidateData.additional_information[
                  aiAcademicDegreeIndex
                ]
              )[0].findIndex((aiItem) => aiItem.degreeId === value.id);

              if (removeId > -1)
                Object.values(
                  updatedCandidateData.additional_information[
                    aiAcademicDegreeIndex
                  ]
                )[0].splice(removeId, 1);
            }
            break;
          case AI_CERTIFICATES:
            const aiCertificateIndex = updatedCandidateData.additional_information.findIndex(
              (aiType) => Object.keys(aiType)[0] === AI_CERTIFICATES
            );

            if (aiCertificateIndex > -1) {
              const removeId = Object.values(
                updatedCandidateData.additional_information[aiCertificateIndex]
              )[0].findIndex((aiItem) => aiItem.certificateId === value.id);

              if (removeId > -1)
                Object.values(
                  updatedCandidateData.additional_information[
                    aiCertificateIndex
                  ]
                )[0].splice(removeId, 1);
            }
            break;
          case AI_LICENSES:
            const aiLicenseIndex = updatedCandidateData.additional_information.findIndex(
              (aiType) => Object.keys(aiType)[0] === AI_LICENSES
            );

            if (aiLicenseIndex > -1) {
              const removeId = Object.values(
                updatedCandidateData.additional_information[aiLicenseIndex]
              )[0].findIndex((aiItem) => aiItem.licenseId === value.id);

              if (removeId > -1)
                Object.values(
                  updatedCandidateData.additional_information[aiLicenseIndex]
                )[0].splice(removeId, 1);
            }
            break;
          case AI_LANGUAGES:
            const aiLanguageIndex = updatedCandidateData.additional_information.findIndex(
              (aiType) => Object.keys(aiType)[0] === AI_LANGUAGES
            );

            if (aiLanguageIndex > -1) {
              const removeId = Object.values(
                updatedCandidateData.additional_information[aiLanguageIndex]
              )[0].findIndex((aiItem) => aiItem.languageId === value.id);

              if (removeId > -1)
                Object.values(
                  updatedCandidateData.additional_information[aiLanguageIndex]
                )[0].splice(removeId, 1);
            }
            break;
          case AI_SALARY_EXPECTATIONS:
            const aiSalaryExpectationsIndex = updatedCandidateData.additional_information.findIndex(
              (aiType) => Object.keys(aiType)[0] === AI_SALARY_EXPECTATIONS
            );
            if (aiSalaryExpectationsIndex > -1) {
              const removeId = Object.values(
                updatedCandidateData.additional_information[
                  aiSalaryExpectationsIndex
                ]
              )[0].findIndex((aiItem) => aiItem.seId === value.id);

              if (removeId > -1)
                Object.values(
                  updatedCandidateData.additional_information[
                    aiSalaryExpectationsIndex
                  ]
                )[0].splice(removeId, 1);
            }
            break;

          default:
            break;
        }
        setUpdatedSuccessfully(true);
        setCandidateData(updatedCandidateData);
      }
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  const handleDarkModeToggle = (newValue) => {
    props.onToggleDark(newValue);
  };

  const handleSettings = async (settings) => {
    //setIsLoading(true);

    try {
      axios.post(
        `/wp-json/api/v2/my-profile/lobby/update_settings/${candidateId}`,
        {
          settings,
        }
      );
      //setIsLoading(false);
      setUpdatedSuccessfully(true);
      setSettings({
        allowJobs: settings.allowJobs,
        allowMarketing: settings.allowMarketing,
        darkMode: props.isDarkMode,
      });
    } catch (e) {
      //setIsLoading(false);
      setError(e);
    }
  };

  const handleRateJob = async (rating, jobId) => {
    try {
      await axios.post(
        `/wp-json/api/v2/my-profile/lobby/job_rating/${candidateId}`,
        {
          jobId: jobId,
          jobRating: rating,
        }
      );
      setUpdatedSuccessfully(true);
    } catch (e) {
      setError(e);
    }
  };

  const handleCVUpload = async (file) => {
    setCvUploading(true);
    const formData = new FormData();
    formData.append("file", file);
    try {
      const newUpload = await axios.post(
        `/wp-json/api/v2/my-profile/lobby/upload_cv/${candidateId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const updatedData = candidateData;
      updatedData.cvs = [...updatedData.cvs, newUpload.data];
      setCandidateData(updatedData);
      setCvUploading(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setCvUploading(false);
    }
  };
  const handleDataUpdate = async (updates) => {
    setDataUpdading(true);
    try {
      const res = await axios.patch(
        `/wp-json/api/v2/my-profile/lobby/update_profile_details/${candidateId}`,
        {
          updateFields: updates,
        }
      );
      //update the state:
      let updateCandData = candidateData;
      updates.forEach((field) => {
        //find the field and update:
        const key = Object.keys(field)[0];
        const value = Object.values(field)[0];
        switch (key) {
          case "academic_degree":
            const academicDegressIndex = updateCandData.additional_information.findIndex(
              (ai_data) => Object.keys(ai_data)[0] === "academicDegrees"
            );
            if (academicDegressIndex > -1) {
              updateCandData.additional_information[academicDegressIndex] = {
                academicDegrees: value,
              };
            } else {
              //we need to create this new object:
              updateCandData.additional_information = [
                ...updateCandData.additional_information,
                {
                  academicDegrees: value,
                },
              ];
            }
            break;
          case "certificate":
            const certificateIndex = updateCandData.additional_information.findIndex(
              (ai_data) => Object.keys(ai_data)[0] === AI_CERTIFICATES
            );
            if (certificateIndex > -1) {
              updateCandData.additional_information[certificateIndex] = {
                certificates: value,
              };
            } else {
              //add new to candidateData
              updateCandData.additional_information = [
                ...updateCandData.additional_information,
                {
                  certificates: value,
                },
              ];
            }
            break;
          case "license":
            const licenseIndex = updateCandData.additional_information.findIndex(
              (ai_data) => Object.keys(ai_data)[0] === AI_LICENSES
            );
            if (licenseIndex > -1) {
              updateCandData.additional_information[licenseIndex] = {
                licenses: value,
              };
            } else {
              //add new to candidateData
              updateCandData.additional_information = [
                ...updateCandData.additional_information,
                {
                  licenses: value,
                },
              ];
            }
            break;
          case "language":
            const languageIndex = updateCandData.additional_information.findIndex(
              (ai_data) => Object.keys(ai_data)[0] === AI_LANGUAGES
            );
            if (languageIndex > -1) {
              updateCandData.additional_information[languageIndex] = {
                languages: value,
              };
            } else {
              //add new to candidateData
              updateCandData.additional_information = [
                ...updateCandData.additional_information,
                {
                  languages: value,
                },
              ];
            }
            break;
          case "salaryExpectation":
            const seIndex = updateCandData.additional_information.findIndex(
              (ai_data) => Object.keys(ai_data)[0] === AI_SALARY_EXPECTATIONS
            );
            if (seIndex > -1) {
              updateCandData.additional_information[seIndex] = {
                salaryExpectations: value,
              };
            } else {
              //add new to candidateData
              updateCandData.additional_information = [
                ...updateCandData.additional_information,
                {
                  salaryExpectations: value,
                },
              ];
            }

            break;
          case "my-areas":
            value.map((prof) => {
              const ind = candidateData.sectors.findIndex(
                (sector) => sector.id === prof.id
              );
              return candidateData.sectors[ind];
            });
            break;
          default:
            updateCandData[key] = value;
            break;
        }
      });
      setDataUpdading(false);
      setUpdatedSuccessfully(true);
      setCandidateData(updateCandData);
    } catch (e) {
      setDataUpdading(false);
      console.log(e);
      setError(e.response);
      setUpdatedSuccessfully(false);
    }
  };

  const handleOnChangeProfileImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const newUpload = await axios.post(
        `/wp-json/api/v2/my-profile/lobby/upload_profile_image/${candidateId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProfileImage(newUpload.data);
      setCvUploading(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setCvUploading(false);
    }
  };

  const handleRemoveCV = (cvId) => {
    const updatedCandidateData = candidateData;
    updatedCandidateData.cvs = updatedCandidateData.cvs.filter(
      (cv) => cv.id !== cvId
    );
    setCandidateData(updatedCandidateData);
    setUpdatedSuccessfully(true);
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "lobby":
        return (
          <ErrorBoundary>
            <DashboardElements
              onCVUpload={handleCVUpload}
              cvs={candidateData ? candidateData.cvs : []}
              removeCv={handleRemoveCV}
              cvUploading={cvUploading}
              personalName={candidateData.firstName}
              jobs={candidateData.jobs}
              token={candidateData.token}
              onJobRating={handleRateJob}
            />
          </ErrorBoundary>
        );
      case "settings":
        return (
          <Settings
            dataUpdate={handleSettings}
            settings={{ ...settings, darkMode: props.isDarkMode }}
            toggleDarkMode={handleDarkModeToggle}
          />
        );
      case "aboutMe":
        return (
          <React.Fragment>
            <AboutMe
              data={candidateData}
              candidateId={candidateId}
              dataUpdate={handleDataUpdate}
              dataRemove={handleRemove}
              //dataNew={handleNew}
            />
            <Snackbar
              open={updatedSuccessfull}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
            >
              <Alert onClose={handleCloseSnackbar} severity="success">
                <FormattedMessage
                  id="common.settingsSaved"
                  defaultMessage="Settings updated"
                />
              </Alert>
            </Snackbar>
            {error !== null && !isLoading && (
              <Alert onClose={() => setError(null)} severity="error">
                <FormattedMessage
                  id="candidateProfile.profile.settings.error"
                  defaultMessage="An error occured during update. Details: {message}. Error code: {code}"
                  values={{
                    message: error.response.data.message,
                    code: error.response.status,
                  }}
                />
              </Alert>
            )}
          </React.Fragment>
        );
      case "looking":
        return (
          <React.Fragment>
            <LookingFor
              data={candidateData}
              candidateId={candidateId}
              dataUpdate={handleDataUpdate}
              dataRemove={handleRemove}
              isUpdating={dataUpdating}
            />
          </React.Fragment>
        );

      default:
        return;
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ProfileStregthContext.Provider
        value={{
          score: profileStrength.totalScore,
          missing: profileStrength.missing,
          indicator:
            profileStrength.totalScore < 20
              ? "red"
              : profileStrength.totalScore < 70
              ? "yellow"
              : "green",
        }}
      >
        <CandidateNavBar
          currentActive={currentScreen}
          onMenuSelect={handleActiveMode}
          candidateFullName={`${candidateData ? candidateData.firstName : ""} ${
            candidateData ? candidateData.lastName : ""
          }`}
          profileImage={profileImage}
          onChangeProfileImage={(file) => handleOnChangeProfileImage(file)}
          initials={`${candidateData ? candidateData.firstName[0] : ""}${
            candidateData ? candidateData.lastName[0] : ""
          }`}
          candidateId={candidateId}
          preferredLanguage={preferredLanguage}
        />
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              {isLoading ? <Spinner open={isLoading} /> : renderScreen()}
              <Snackbar
                open={updatedSuccessfull}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
              >
                <Alert
                  onClose={handleCloseSnackbar}
                  variant="filled"
                  severity="success"
                >
                  <FormattedMessage
                    id="common.settingsSaved"
                    defaultMessage="Settings updated"
                  />
                </Alert>
              </Snackbar>
              {error !== null && !isLoading && (
                <Alert onClose={() => setError(null)} severity="error">
                  <FormattedMessage
                    id="candidateProfile.profile.settings.error"
                    defaultMessage="An error occured during update. Details: {message}. Error code: {code}"
                    values={{
                      message: error.response.data.message,
                      code: error.response.status,
                    }}
                  />
                </Alert>
              )}
            </div>
          </div>
        </div>
      </ProfileStregthContext.Provider>
    </div>
  );
};

export default withRouter(DashboardLayout);
