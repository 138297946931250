import React from "react";
import { Box, Tooltip, IconButton } from "@material-ui/core";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import ClearRoundedIcon from "@material-ui/icons/ClearRounded";
import DeleteSweepRoundedIcon from "@material-ui/icons/DeleteSweepRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import { green } from "@material-ui/core/colors";
import { useTheme } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

const EndRowButtons = ({
  disabled,
  onCancel = null,
  onEdit,
  onRemove = null,
  editMode,
}) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <Box display="flex" alignItems={"center"} justifyContent="flex-end">
      {onRemove ? (
        <Tooltip
          title={intl.formatMessage({
            id: "general.remove",
            defaultMessage: "Remove",
          })}
        >
          <IconButton
            aria-label="toggle-cancel"
            onClick={onRemove}
            disabled={disabled}
          >
            <DeleteSweepRoundedIcon
              fontSize="small"
              style={{ color: theme.palette.grey[500] }}
            />
          </IconButton>
        </Tooltip>
      ) : null}
      {onCancel && editMode ? (
        <Tooltip
          title={intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        >
          <IconButton
            aria-label="toggle-done"
            onClick={onCancel}
            disabled={disabled}
          >
            <ClearRoundedIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip
        title={
          editMode
            ? intl.formatMessage({ id: "general.done", defaultMessage: "Done" })
            : intl.formatMessage({ id: "general.edit", defaultMessage: "Edit" })
        }
      >
        <IconButton
          aria-label="toggle-done"
          onClick={onEdit}
          disabled={disabled}
        >
          {editMode ? (
            <DoneRoundedIcon fontSize="small" style={{ color: green[500] }} />
          ) : (
            <EditRoundedIcon fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
    </Box>
  );
};
export default EndRowButtons;
