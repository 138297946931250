import React from "react";
import TagsRow from "../../CandidateComponents/EditRow/tagsRow";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  Container,
  CircularProgress,
} from "@material-ui/core";
import {
  Cities,
  JobExtentListModified,
} from "../../../../config/jobsConfigData";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { FormattedMessage, useIntl } from "react-intl";

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: 16,
    },
    "& .MuiTypography-h6": {
      marginBottom: theme.spacing(1),
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LookingFor = ({ data, dataUpdate, dataRemove, isUpdating }) => {
  const theme = useTheme();
  const classes = styles();
  const intl = useIntl();
  const [areaTags, setAreaTags] = React.useState([]);
  const [workingCitiesTags, setWorkingCitiesTags] = React.useState([]);
  const [professionTags, setProfessionTags] = React.useState([]);
  const [fieldsUpdated, setFieldsUpdated] = React.useState([]);
  const [jobExtentTags, setJobExtentTags] = React.useState([]);

  React.useEffect(() => {
    if (data.candidate_areas) {
      //candidate_areas is array of sectors:
      const localSecotrs = data.candidate_areas.map((sector) => {
        const seIndex = data.sectors.findIndex(
          (sectorList) => sectorList.id === sector
        );
        return data.sectors[seIndex];
      });
      setAreaTags(localSecotrs);
    }
    if (data.professions) {
      setProfessionTags(data.professions);
    }
    if (data.working_cities) {
      const localCities = data.working_cities.map((city) => {
        const cityIndex = Cities.findIndex((ci) => ci.id === city);
        return Cities[cityIndex];
      });
      setWorkingCitiesTags(localCities);
    }
    if (data.job_extent) {
      const localExtents = data.job_extent.map((extent) => {
        const jeIndex = JobExtentListModified.findIndex(
          (ext) => ext.id === extent
        );
        return JobExtentListModified[jeIndex];
      });
      setJobExtentTags(localExtents);
    }
  }, [data]);

  const handeUpdateDetails = async () => {
    //generate field=value pairs for server update:
    let updates = fieldsUpdated.map((field) => {
      switch (field) {
        case "my-areas":
          return {
            [field]: areaTags.map((tag) => {
              return parseInt(tag.id);
            }),
          };
        case "my-roles":
          return {
            [field]: professionTags.map((tag) => {
              return parseInt(tag.id);
            }),
          };
        case "working-cities":
          return {
            [field]: workingCitiesTags.map((tag) => {
              return parseInt(tag.id);
            }),
          };
        case "job-extent":
          return {
            [field]: jobExtentTags.map((tag) => {
              return parseInt(tag.id);
            }),
          };
        default:
          break;
      }
    });
    dataUpdate(updates);
  };

  const handleOnRemove = (field, value) => {
    if (!fieldsUpdated.includes(field)) {
      setFieldsUpdated([...fieldsUpdated, field]);
    }

    switch (field) {
      case "my-areas":
        setAreaTags((prev) => prev.filter((tag) => tag.id !== value));
        break;
      case "my-roles":
        setProfessionTags((prev) => prev.filter((tag) => tag.id !== value));
        break;
      case "working-cities":
        setWorkingCitiesTags((prev) => prev.filter((tag) => tag.id !== value));
        break;
      case "job-extent":
        setJobExtentTags((prev) => prev.filter((tag) => tag.id !== value));
        break;
      default:
        break;
    }
    dataRemove(field, value);
  };

  const handleOnSelect = (field, value) => {
    if (!fieldsUpdated.includes(field)) {
      setFieldsUpdated([...fieldsUpdated, field]);
    }
    switch (field) {
      case "my-areas":
        setAreaTags(value);
        break;
      case "my-roles":
        setProfessionTags(value);
        break;
      case "working-cities":
        setWorkingCitiesTags(value);
        break;
      case "job-extent":
        setJobExtentTags(value);
        break;
      default:
        break;
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid container className={classes.root}>
        <Grid item xl={12} xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={theme.spacing(2)}
          >
            <Typography variant="h2">
              <FormattedMessage
                id="candidateProfile.looking.myJobRequirements"
                defaultMessage="My Job Requirements"
              />
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handeUpdateDetails}
              disabled={fieldsUpdated.length === 0}
              style={{ position: "relative" }}
            >
              <FormattedMessage id="general.update" defaultMessage="Update" />
              {isUpdating && (
                <CircularProgress
                  color="inherit"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="candidateProfile.looking.menu"
                defaultMessage="Categories & Roles"
              />
            </Typography>
            <Paper>
              <TagsRow
                value={areaTags}
                label={intl.formatMessage({
                  id: "candidateProfile.profile.myAreas",
                  defaultMessage: "My Areas",
                })}
                tagsList={data.sectors}
                onSelect={(value) => handleOnSelect("my-areas", value)}
                onRemove={(value) => handleOnRemove("my-areas", value)}
                tagPlaceholder={intl.formatMessage({
                  id: "candidateProfile.profile.myAreas.singular.placeholder",
                  defaultMessage: "My Area...",
                })}
              />
              <TagsRow
                value={professionTags}
                label={intl.formatMessage({
                  id: "candidateProfile.profile.jobsLooking",
                  defaultMessage: "The positions I'm looking",
                })}
                tagsList={data.sector_professions}
                onSelect={(value) => handleOnSelect("my-roles", value)}
                onRemove={(value) => handleOnRemove("my-roles", value)}
                tagPlaceholder={intl.formatMessage({
                  id: "candidateProfile.profile.jobsLooking.placeholder",
                  defaultMessage: "Looking for...",
                })}
              />
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="candidateProfile.looking.jobDetail"
                defaultMessage="Job Details"
              />
            </Typography>
            <Paper>
              <TagsRow
                value={workingCitiesTags}
                label={intl.formatMessage({
                  id: "candidateProfile.looking.workInArea",
                  defaultMessage: "The area I want to work in",
                })}
                tagsList={Cities}
                onSelect={(value) => handleOnSelect("working-cities", value)}
                onRemove={(value) => handleOnRemove("working-cities", value)}
                tagPlaceholder={intl.formatMessage({
                  id: "candidateProfile.looking.workInArea.placeholder",
                  defaultMessage: "Area or city...",
                })}
              />
              <TagsRow
                value={jobExtentTags}
                label={intl.formatMessage({
                  id: "common.jobType",
                  defaultMessage: "Job Type",
                })}
                tagsList={JobExtentListModified}
                onSelect={(value) => handleOnSelect("job-extent", value)}
                onRemove={(value) => handleOnRemove("job-extent", value)}
                tagPlaceholder={intl.formatMessage({
                  id: "candidateProfile.looking.jobInterested.placeholder",
                  defaultMessage: "Interested in...",
                })}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default LookingFor;
