import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Icon, Button } from "@material-ui/core";
import { LanguageLevels } from "../../../../../config/jobsConfigData";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));

const AILanguages = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedLanguage, setSelectedLanguage] = React.useState("");
  const [updatedChip, setUpdatedChip] = React.useState(-1);
  const [languageLevel, setLanguageLevel] = React.useState("");
  const [languagesAIObject, setLanguagesAIObject] = React.useState([]);

  React.useEffect(() => {
    if (props.aIValue && Object.keys(props.aIValue).length) {
      const aiTempObject = props.aIValue.map((aiItem) => {
        return (
          [...languagesAIObject],
          {
            key: aiItem.languageId,
            languageId: aiItem.languageId,
            languageName: aiItem.languageName,
            languageLevel: aiItem.languageLevel,
          }
        );
      });
      setLanguagesAIObject(aiTempObject);
    }
  }, []);

  const handleSelectedLanguage = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const detectMotherTounge = () => {
    if (languagesAIObject.length === 0) setLanguageLevel("motherTounge");
  };

  const buildAILanguageObject = (event) => {
    props.addAI(languagesAIObject);
  };
  /*
  Single Language Object: 
  {
      langueageId: id,
      langueageName: string, 
      languageLevel: string,     
  }
  */
  const handleAddSingleLanugageAI = () => {
    Object.keys(languagesAIObject).length === 0
      ? setLanguagesAIObject([
          {
            languageId: languagesAIObject.length + 1,
            languageName: selectedLanguage,
            languageLevel,
          },
        ])
      : setLanguagesAIObject([
          ...languagesAIObject,
          {
            languageId: languagesAIObject.length + 1,
            languageName: selectedLanguage,
            languageLevel,
          },
        ]);
    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = languagesAIObject.find(
      (chip) => chip.languageId === updatedChip
    );

    //remove the selected chip from the array:
    const tempLanguageAIObject = languagesAIObject.filter(
      (ai) => ai.languageId !== tempUpdatedChip.languageId
    );

    //update the form:
    setLanguagesAIObject([
      ...tempLanguageAIObject,
      {
        languageId: tempUpdatedChip.languageId,
        languageName: selectedLanguage,
        languageLevel,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setUpdatedChip(-1);
    setSelectedLanguage("");
    setLanguageLevel("");
  };

  const handleRemoveLanguage = (langId) => {
    //locate index id
    const newLanguageAIObject = languagesAIObject.filter(
      (lng) => lng.languageId !== langId
    );
    setLanguagesAIObject(newLanguageAIObject);
    clearForm();
  };

  const handleKnowledgeLevelChange = (event) => {
    setLanguageLevel(event.target.value);
  };

  const handleReadChip = (langId) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleLngObj = languagesAIObject.find(
        (lang) => lang.languageId === langId
      );

      setSelectedLanguage(singleLngObj.languageName);
      setLanguageLevel(singleLngObj.languageLevel);
      setUpdatedChip(langId);
    }
  };

  const displayChips =
    languagesAIObject !== null && Object.keys(languagesAIObject).length > 0
      ? languagesAIObject.map((lang) => {
          return (
            <Chip
              key={lang.languageId}
              icon={<FaceIcon />}
              label={lang.languageName}
              clickable
              color="primary"
              onClick={() => handleReadChip(lang.languageId)}
              onDelete={() => handleRemoveLanguage(lang.languageId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== lang.languageId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  const displayOption = LanguageLevels.map((lo) => {
    return (
      <FormControlLabel
        key={lo.value}
        value={lo.value}
        control={<Radio color="primary" />}
        label={lo.label}
      />
    );
  });

  return (
    <Container maxWidth="lg" className={classes.root}>
      <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            className={classes.modalTitle}
          >
            {props.candidateFullName !== "undefined" &&
            props.candidateFullName.length > 1
              ? `Add language proficiency for ${props.candidateFullName}`
              : "Add language proficiency"}
          </Typography>
          <Divider />
          <div>
            <Collapse in={languagesAIObject !== null}>
              <Typography variant="h5" className={classes.modalTitle}>
                Language Proficiency
              </Typography>
              {displayChips}
              <Divider />
            </Collapse>
          </div>
          <Typography
            style={{ fontWeight: 700, marginBottom: 15 }}
            color="textPrimary"
            variant="subtitle1"
          >
            Add language
          </Typography>
          <TextField
            id="language"
            label="The language"
            variant="outlined"
            value={selectedLanguage}
            onChange={handleSelectedLanguage}
            onBlur={detectMotherTounge}
            style={{ width: "100%" }}
          />
          <div
            style={{
              marginTop: theme.spacing(2),
              marginRight: theme.spacing(1),
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">
                Knowledge level for this language
              </FormLabel>
              <RadioGroup
                aria-label="knowledgeLevel"
                name="knowledgeLevel"
                value={languageLevel}
                onChange={handleKnowledgeLevelChange}
              >
                {displayOption}
              </RadioGroup>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={selectedLanguage === ""}
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              onClick={
                updatedChip <= 0
                  ? handleAddSingleLanugageAI
                  : updateSelectedChip
              } //add new Object with all values of this Component
            >
              {updatedChip > 0 ? "Update Language" : "Add Language"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                Object.keys(languagesAIObject).length <= 0 ||
                languagesAIObject.length <= 0
              }
              className={classes.button}
              endIcon={<Icon>done</Icon>}
              onClick={buildAILanguageObject}
            >
              Done
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default React.memo(AILanguages);
