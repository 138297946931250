import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: theme.spacing(1),
    "& .MuiPaper-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiSkeleton-root": {
      marginBottom: theme.spacing(1),
    },
  },
}));

const SuitalbleSkeleton = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Skeleton variant="circle" width={30} height={30} animation="pulse" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="rect" height={100} animation="pulse" />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="circle" width={30} height={30} animation="pulse" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="rect" height={100} animation="pulse" />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="circle" width={30} height={30} animation="pulse" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="rect" height={100} animation="pulse" />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="circle" width={30} height={30} animation="pulse" />
        </Grid>
        <Grid item xs={11}>
          <Skeleton variant="rect" height={100} animation="pulse" />
        </Grid>
      </Grid>
    </div>
  );
};
export default SuitalbleSkeleton;
