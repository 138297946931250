import React from "react";
import {
  IconButton,
  Divider,
  Grid,
  Typography,
  Tooltip,
} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import { makeStyles } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(2),
      "& span": {
        fontWeight: 700,
        marginRight: theme.spacing(1),
      },
    },
  },
  iconValue: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
}));
const FullJobDetails = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Tooltip title="Back">
            <IconButton
              aria-label="delete"
              onClick={props.back}
              className={classes.iconValue}
            >
              <ArrowForwardIosRoundedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
        <Divider />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Typography variant="body1">
            <span>
              <FormattedMessage
                id="common.jobDescription"
                defaultMessage="Job Description"
              />
              :
            </span>
            {props.jobData.description}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Typography variant="body1">
            <span>
              <FormattedMessage
                id="common.jobRequirements"
                defaultMessage="Job Requirements"
              />
              :
            </span>
            {props.jobData.job_requirements}
          </Typography>
        </Grid>
      </div>
    </React.Fragment>
  );
};
export default FullJobDetails;
