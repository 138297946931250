import React from "react";
import {
  Button,
  Collapse,
  Typography,
  Grid,
  Tooltip,
  IconButton,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CancelPresentationRoundedIcon from "@material-ui/icons/CancelPresentationRounded";
import AddCancelButton from "../AddCancelButtons/AddCancelButton";
import AxiosErrorHandling from "../../components/AxiosErrorHandling/AxiosErrorHandling";
//import axiosInstance from "../../utils/axiosInstance";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {},
  rowMargin: {
    marginTop: theme.spacing(2),
  },
  buttonDone: {
    color: green[500],
    borderColor: green[500],

    "&:hover": {
      color: green[700],
      borderColor: green[700],
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CandidateInterview = (props) => {
  const [displayInterview, setDisplayInterview] = React.useState(false);
  const [interviewStatus, setInterviewStatus] = React.useState("");
  const [interviewReason, setInterviewReason] = React.useState("");
  const [declineMode, setDeclineMode] = React.useState(false);
  const [isUpdading, setIsUpdating] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [declinedBy, setDeclinedBy] = React.useState("declined_by_employer");
  const classes = useStyles();

  React.useEffect(() => {
    if (
      props.interview.interview_status &&
      props.interview.interview_status !== ""
    )
      setInterviewStatus(props.interview.interview_status);
    setInterviewReason(props.interview.interview_reason);
    /*if (
      props.interview.interview_status &&
      props.interview.interview_status !== "" &&
      props.interview.interview_status !== "done"
    )
      setDeclineMode(true);
      */
    setDeclinedBy(props.interview.interview_status);
  }, [props.interview]);

  const handleDeclineInterview = () => {
    setIsUpdating(true);
    //axiosInstance.defaults.headers.post["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    //axiosInstance.defaults.headers.post["Content-Type"] = "application/json; charset=UTF-8";
    axios
      .post(`/wp-json/api/v2/interviewStatus/${parseInt(props.interview.id)}`, {
        interview_status: declinedBy,
        interview_reason: interviewReason,
      })
      .then((res) => {
        setIsUpdating(false);
        setInterviewStatus(declinedBy);
        setDeclineMode(false);
      })
      .catch((err) => {
        setIsUpdating(false);
        setInterviewStatus("");
        setDeclineMode(false);
        //console.log("Error:", err.response);
        setError(err.response);
      });
  };

  const handleInterviewDone = () => {
    //axios post - interview done

    setIsUpdating(true);

    axios
      .post(`/wp-json/api/v2/interviewStatus/${parseInt(props.interview.id)}`, {
        interview_status: "done",
        interview_reason: "",
      })
      .then((res) => {
        console.log(res.data);
        setIsUpdating(false);
        setInterviewStatus("done");
      })
      .catch((err) => {
        setIsUpdating(false);
        setInterviewStatus("");
        console.log("Error:", err.response);
      });
  };

  const handleDeclineMode = () => {
    setDeclineMode(true);
  };

  const handleStatusChange = (event) => {
    setDeclinedBy(event.target.value);
  };

  const handleCancelInterview = (event) => {
    setDeclineMode("");
    setInterviewReason("");
    setDeclinedBy("declined_by_employer");
  };

  let interviewActions = (
    <div className={classes.rowMargin}>
      <Grid container alignItems="center" justify="flex-start" spacing={1}>
        <Grid item>
          <span className={classes.wrapper}>
            <Button
              color="primary"
              onClick={handleInterviewDone}
              variant="outlined"
              endIcon={<CheckRoundedIcon />}
              className={interviewStatus === "done" ? classes.buttonDone : ""}
            >
              Interview Conducted
            </Button>
            {isUpdading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </span>
        </Grid>
        <Grid item>
          <span className={classes.wrapper}>
            <Button
              color="primary"
              variant="outlined"
              endIcon={<CancelPresentationRoundedIcon />}
              onClick={handleDeclineMode}
            >
              Cancel Interview
            </Button>
          </span>
        </Grid>
      </Grid>
    </div>
  );

  if (interviewStatus !== "" && interviewStatus && interviewReason !== null) {
    const interviewDetails =
      interviewStatus === "done"
        ? "Interview Conducted"
        : "Interview Cancelled";
    let interviewReasonText = "";
    if (interviewStatus === "declined_by_candidate") {
      interviewReasonText = `Interview cancelled by candidate. Cancellation reason: ${interviewReason}`;
    } else if (interviewStatus === "declined_by_employer") {
      interviewReasonText = `Interview cancelled by employer. Cancellation reason: ${interviewReason}`;
    } else {
      interviewReasonText = "";
    }
    interviewActions = (
      <div className={classes.rowMargin}>
        <Typography variant="body1">{interviewDetails}</Typography>
        <Typography variant="body1">{interviewReasonText}</Typography>
      </div>
    );
  }
  if (declineMode) {
    interviewActions = (
      <div className={classes.rowMargin}>
        <Grid container alignItems="center" justify="flex-start" spacing={3}>
          <Grid item>
            <FormControl component="fieldset">
              <FormLabel component="legend">Cancel By:</FormLabel>
              <RadioGroup
                aria-label="canceledBy"
                name="canceledBy"
                value={interviewStatus}
                onChange={handleStatusChange}
              >
                <FormControlLabel
                  value="declined_by_candidate"
                  control={<Radio color="primary" />}
                  checked={declinedBy === "declined_by_candidate"}
                  label="Candidate"
                />
                <FormControlLabel
                  value="declined_by_employer"
                  control={<Radio color="primary" />}
                  checked={declinedBy === "declined_by_employer"}
                  label="Employer"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <TextField
              id="standard-multiline-static"
              label="Cancellation reason"
              multiline
              fullWidth
              onChange={(event) => setInterviewReason(event.target.value)}
              rows={4}
              value={interviewReason ? interviewReason : ""}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.rowMargin}>
              <AddCancelButton
                cancelCaption="Cancel"
                addCaption="Update"
                cancelClick={handleCancelInterview}
                addClick={handleDeclineInterview}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Button
            color="primary"
            onClick={(event) => setDisplayInterview(!displayInterview)}
          >
            Interview Details
          </Button>
          <Tooltip title="Edit Interview date" aria-label="edit">
            <span>
              <IconButton
                disabled={!displayInterview}
                onClick={props.editEvent}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
        <Collapse in={displayInterview} style={{ flex: 1 }}>
          <div className={classes.rowMargin}>
            <Typography variant="body1">
              Interview Date:{" "}
              {new Date(props.interview.interview_date).toLocaleString()}
            </Typography>
          </div>
          <div className={classes.rowMargin}>
            <Typography variant="body1">
              Interviewer: {props.interview.interviewer}
            </Typography>
          </div>
          <div className={classes.rowMargin}>
            <Typography variant="body1">
              Interview Location: {props.interview.interview_address}
            </Typography>
          </div>
          {interviewActions}
        </Collapse>
        {error && (
          <AxiosErrorHandling
            error={error}
            title="Oh no! An error occured during interview update. "
          />
        )}
      </Grid>
    </React.Fragment>
  );
};
export default React.memo(CandidateInterview);
