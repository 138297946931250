import React from "react";
import {
  Checkbox,
  Grid,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  FormControlLabel,
} from "@material-ui/core";
import { LanguageLevels } from "../../../../../config/jobsConfigData";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
const FQLanguages = ({ onCancel, onCompletion, fqEdit = null }) => {
  const [selectedLanguage, setSelectedLanguage] = React.useState("");
  const [languageLevel, setLanguageLevel] = React.useState("average");
  const [languageIsAMust, setLanguageIsAMust] = React.useState(false);

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedLanguage(fqEdit.languageName);
      setLanguageLevel(fqEdit.languageLevel);
      setLanguageIsAMust(
        parseInt(fqEdit.langueageIsAMust) === 1 || fqEdit.langueageIsAMust
          ? true
          : false
      );
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  const handleCheckIsAMust = (event) => {
    setLanguageIsAMust(event.target.checked);
  };

  const handleSelectedLanguage = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["langueageName"] = selectedLanguage;
      existingObject["languageLevel"] = languageLevel;
      existingObject["langueageIsAMust"] = languageIsAMust;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      languageName: selectedLanguage,
      languageLevel: languageLevel,
      langueageIsAMust: languageIsAMust,
    });
  };

  const clearForm = () => {
    //clean the form values:
    setSelectedLanguage("");
    setLanguageLevel("average");
    setLanguageIsAMust(false);
  };

  const handleKnowledgeLevelChange = (event) => {
    setLanguageLevel(event.target.value);
  };

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          Language to add:
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="language"
            label="The language"
            variant="filled"
            value={selectedLanguage}
            onChange={handleSelectedLanguage}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Level of Languages proficiency required
            </FormLabel>
            <RadioGroup
              aria-label="knowledgeLevel"
              name="knowledgeLevel"
              value={languageLevel}
              onChange={handleKnowledgeLevelChange}
            >
              {LanguageLevels.map((language) => (
                <FormControlLabel
                  key={language.value}
                  value={language.value}
                  control={
                    <Radio
                      checked={languageLevel === language.value}
                      color="primary"
                    />
                  }
                  label={language.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={languageIsAMust}
                onClick={handleCheckIsAMust}
                name="requiredQ"
                color="primary"
              />
            }
            label="This condition is prerequisite"
          />
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="Done"
            negativeCaption="Cancel"
            positiveDisabled={selectedLanguage === ""}
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default React.memo(FQLanguages);
