import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import AnswerBox from "./AnswerBox/AnswerBox";
import ContinueBackButtons from "../ContinueBackButtons/ContinueBackButtons";
import QuestionierCompleted from "./QuestionierCompleted";
import QuestionOption from "./QuestionOptions/QuestionOption";
import NumberFormat from "react-number-format";
import {
  answersValues,
  translateDegreeAndType,
  SkillLevels,
  LanguageLevels,
  CurrencySign,
} from "../../../config/jobsConfigData";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Slide,
  Grid,
  LinearProgress,
  Typography,
  FormControlLabel,
  Radio,
  TextField,
  Box,
} from "@material-ui/core";
import { MAX_HOURLY_RATE } from "../../../config/constants";
import { FormattedMessage, useIntl } from "react-intl";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      suffix={` ${CurrencySign.symbol}`} //need to fix this
      allowNegative={false}
      decimalSeparator={"."}
      inputMode="numeric"
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      // isNumericString
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiInputAdornment-root": {
      color: grey[500],
      marginRight: theme.spacing(2),
    },
  },
  progress: {
    margin: theme.spacing(2),
    "& .MuiLinearProgress-root": {
      height: 8,
      borderRadius: 4,
    },
  },
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
  question: {
    marginTop: theme.spacing(2),
  },
}));

const JobQuestionier = (props) => {
  //props.jobData.filter_questions.count
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const [totalSteps, setTotalSteps] = React.useState(3);
  const [completed, setCompleted] = React.useState(false);
  const [progressStep, setProgressStep] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState(1);
  const [currentAnswered, setCurrentAnswered] = React.useState(false);
  const [candidateQAnswers, setCandidateQAnswers] = React.useState([]);
  const [salaryExpectationsValue, setSalaryExpectationsValue] = React.useState(
    0
  );
  const [immedidateAvailability, setImmediateAvailability] = React.useState(
    true
  );
  //initial is up sliding, then: right - for progress sliding, left for back sliding
  const [availabilityDate, setAvailabilityDate] = React.useState(
    new Date().toLocaleDateString()
  );

  React.useEffect(() => {
    let steps = 3;
    let localCandidateAnswers = [];
    props.filterQuestions.map((fq) => {
      const key = Object.keys(fq)[0]; //guarenteed one key
      steps += fq[key].length;
      //now we can build the filterQuestions object

      fq[key].map((fqItem) => {
        const newObject = {
          key: key,
          value: fqItem,
        };
        localCandidateAnswers = [...localCandidateAnswers, newObject];
      });
    });
    setCandidateQAnswers(localCandidateAnswers);
    setTotalSteps(steps);
    setProgressStep(Math.ceil((1 / steps) * 100));
  }, [props.filterQuestions]);

  const handleSelectAvailability = (date) => {
    setAvailabilityDate(date.toLocaleDateString());
    handleAnswerSelected(date.toLocaleDateString(), "availability", true);
  };

  const handleSalaryExpectationsChange = (event) => {
    setSalaryExpectationsValue(parseFloat(event.target.value));
    handleAnswerSelected(parseFloat(event.target.value), "salary", false);
  };

  const getSalaryQuestion = (min, max, isOpenQuestion, openQuestionValueBy) => {
    let str = "";
    if (isOpenQuestion) {
      str =
        openQuestionValueBy === "month"
          ? intl.formatMessage(
              {
                id: "publishScreen.questionier.salaryExpectionsFullMonth",
                defaultMessage:
                  "What are your salary expectations for a fulltime job <b>({currency}/{month}</b>",
              },
              {
                currency: CurrencySign.symbol,
                month: intl.formatMessage({
                  id: "common.month",
                  defaultMessage: "Month",
                }),
              }
            )
          : intl.formatMessage(
              {
                id: "publishScreen.questionier.salaryExpectionsHour",
                defaultMessage:
                  "What are your salary expectations on hourly basis <b>({currency}/{hour}</b>",
              },
              {
                currency: CurrencySign.symbol,
                hour: intl.formatMessage({
                  id: "common.hour",
                  defaultMessage: "Hour",
                }),
              }
            );
    } else {
      if (min === max) {
        if (max < MAX_HOURLY_RATE)
          str = intl.formatMessage(
            {
              id: "publishScreen.questionier.salary",
              defaultMessage:
                "The offered salary for this job is {salary}{currency}/{period}, Is it suits you?",
            },
            {
              salary: min,
              currency: CurrencySign.symbol,
              period: intl.formatMessage({
                id: "common.hour",
                defaultMessage: "Hour",
              }),
            }
          );
        else
          str = intl.formatMessage(
            {
              id: "publishScreen.questionier.salary",
              defaultMessage:
                "The offered salary for this job is {salary}{currency}/{period}, Is it suits you?",
            },
            {
              salary: min,
              currency: CurrencySign.symbol,
              period: intl.formatMessage({
                id: "common.month",
                defaultMessage: "Month",
              }),
            }
          );
      } else {
        if ((max || min) < MAX_HOURLY_RATE)
          str = intl.formatMessage(
            {
              id: "publishScreen.questionier.salary.range",
              defaultMessage:
                "The offered salary for this job is between <b>{salaryMin}{currency} to {salaryMax}{currency}/{period}</b>, Is it suits you?",
            },
            {
              salaryMin: min,
              salaryMax: max,
              currency: CurrencySign.symbol,
              period: intl.formatMessage({
                id: "common.hour",
                defaultMessage: "Hour",
              }),
            }
          );
        str = intl.formatMessage(
          {
            id: "publishScreen.questionier.salary.range",
            defaultMessage:
              "The offered salary for this job is between {salaryMin}{currency} to {salaryMax}{currency}/{period}, Is it suits you?",
          },
          {
            salaryMin: min,
            salaryMax: max,
            currency: CurrencySign.symbol,
            period: intl.formatMessage({
              id: "common.month",
              defaultMessage: "Month",
            }),
          }
        );
      }
    }

    return str;
  };

  const verifyContinue = () => {
    if (currentStep === 1) return true;

    if (currentStep === 2 && candidateQAnswers.length === 1) return true;
    if (currentStep === totalSteps) return false;
    if (!currentAnswered) return true;
    return false;
  };

  const progressToNextStep = () => {
    const localCurrentStep = currentStep;
    setCurrentStep(localCurrentStep + 1);
    setCurrentAnswered(false);
    // update the progress bar:

    if (localCurrentStep + 1 > totalSteps) {
      //Completed!
      setProgressStep(100);
      setCompleted(true);
      //return the answers fo publishJos
      props.candidateAnswers(candidateQAnswers);
      return;
    }
    //special case: in last step, we automatically selecting a default value for availability: immediate
    if (localCurrentStep + 1 === totalSteps) {
      handleAnswerSelected(
        new Date().toLocaleDateString(),
        "availability",
        true
      );
    }
    setProgressStep(Math.ceil((localCurrentStep / totalSteps) * 100));
  };

  const handleAnswerSelected = (value, qType = "", finalStep = false) => {
    //add or update answer:
    let candidateAnswer = {
      questionType: qType,
      questionValue: value,
    };
    if (currentStep > 2 && !finalStep) {
      candidateAnswer = {
        value,
      };
    }
    //check if we already have an answer of this type:

    let index = -1;
    if (currentStep < 3 || finalStep) {
      index = candidateQAnswers.findIndex(
        (fqItem) => fqItem.questionType === qType
      );
    } else if (currentStep > 2 && !finalStep) {
      //we need to look for a qType format of:
      //key: expereince/skills/certifications.... ~
      //idName: experienceId, skilllId, certificicateId... ~
      //idValue: the value of the id
      //(index = locateCandidateIndex(qType, candidateQAnswers));
      index = parseInt(qType);
    }

    if (index > -1) {
      const updatedCandidateQAnswers = [...candidateQAnswers];
      if (currentStep < 3 || finalStep) {
        updatedCandidateQAnswers.splice(index, 1);
        setCandidateQAnswers(updatedCandidateQAnswers.concat(candidateAnswer));
      } else {
        //we have located the index, now check if we have an answer
        if (updatedCandidateQAnswers[index].answer) {
          updatedCandidateQAnswers[index].answer = value;
          setCandidateQAnswers(updatedCandidateQAnswers);
        } else {
          //add the object
          const answer = {
            ...updatedCandidateQAnswers[index],
            candidateAnswer,
          };
          updatedCandidateQAnswers[index] = answer;
          setCandidateQAnswers(updatedCandidateQAnswers);
        }
      }
    } else {
      //new element

      if (currentStep < 3 || finalStep) {
        const newQanwer = candidateQAnswers.concat(candidateAnswer);
        setCandidateQAnswers(newQanwer);
      }
    }
    if (currentStep > 1 || finalStep) setCurrentAnswered(true);
    if (currentStep === 1) progressToNextStep();
  };

  const handleAvailabilityMode = (event) => {
    console.log("Availability", event.target.value);
    setImmediateAvailability(event.target.value !== "onDate");
    if (event.target.value === "onDate")
      handleAnswerSelected(availabilityDate, "availability", true);
  };

  const handleContinue = () => {
    progressToNextStep();
  };

  const handleBack = () => {
    const localCurrentStep = currentStep;
    if (localCurrentStep) {
      setCurrentStep(localCurrentStep - 1);
      setCurrentAnswered(true);
      // update the progress bar:
      setProgressStep(Math.ceil(((localCurrentStep - 1) / totalSteps) * 100));
    }
  };

  let currentRender = (
    <React.Fragment>
      <Typography variant="body1">
        <FormattedMessage
          id="publishScreen.questionier.openningMessage"
          defaultMessage="Just before I'm sending your application, I have few questions for you:"
        />
      </Typography>
      <Typography
        variant="body1"
        style={{ marginTop: theme.spacing(2) }}
        className={classes.question}
      >
        {getSalaryQuestion(
          props.jobData.minimum_salary,
          props.jobData.maximum_salary,
          props.jobData.open_salary_question,
          props.jobData.open_salary_value_by
        )}
      </Typography>
      {props.jobData.open_salary_question ? (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Grid item xs={6}>
            <TextField
              id="standard-basic"
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
              label={intl.formatMessage({
                id: "jobs.fq.salaryExpectations",
                defaultMessage: "Salary Expectations",
              })}
              value={salaryExpectationsValue > 0 ? salaryExpectationsValue : ""}
              onChange={handleSalaryExpectationsChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="body1"
              style={{
                paddingTop: theme.spacing(2),
                paddingRight: theme.spacing(1),
              }}
            >
              {props.jobData.open_salary_value_by === "month" ? (
                <FormattedMessage
                  id="publishScreen.questionier.salaryExpectionsPeriod"
                  defaultMessage="{currency}/{period}"
                  values={{
                    currency: CurrencySign.symbol,
                    period: intl.formatMessage({
                      id: "common.month",
                      defaultMessage: "Month",
                    }),
                  }}
                />
              ) : (
                <FormattedMessage
                  id="publishScreen.questionier.salaryExpectionsPeriod"
                  defaultMessage="{currency}/{period}"
                  values={{
                    currency: CurrencySign.symbol,
                    period: intl.formatMessage({
                      id: "common.hour",
                      defaultMessage: "Hour",
                    }),
                  }}
                />
              )}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container alignContent="space-between">
          <Grid item xs={4}>
            <AnswerBox
              active
              answerSelected={(value) => handleAnswerSelected(value, "salary")}
              indicateSelection={
                candidateQAnswers.findIndex(
                  (fqItem) =>
                    fqItem.questionType === "salary" &&
                    fqItem.questionValue === "yes"
                ) > -1
              }
              iconName={"happyFace"}
              answerValue="yes"
              answerLabel={intl.formatMessage({
                id: "general.yes",
                defaultMessage: "Yes",
              })}
            />
          </Grid>
          <Grid item xs={4}>
            <AnswerBox
              active
              answerSelected={(value) => handleAnswerSelected(value, "salary")}
              indicateSelection={
                candidateQAnswers.findIndex(
                  (fqItem) =>
                    fqItem.questionType === "salary" &&
                    fqItem.questionValue === "no"
                ) > -1
              }
              iconName={"disappointedFace"}
              answerValue="no"
              answerLabel={intl.formatMessage({
                id: "general.no",
                defaultMessage: "No",
              })}
            />
          </Grid>
          <Grid item xs={4}>
            <AnswerBox
              active
              answerSelected={(value) => handleAnswerSelected(value, "salary")}
              indicateSelection={
                candidateQAnswers.findIndex(
                  (fqItem) =>
                    fqItem.questionType === "salary" &&
                    fqItem.questionValue === "maybe"
                ) > -1
              }
              iconName={"confusedFace"}
              answerValue="maybe"
              answerLabel={intl.formatMessage({
                id: "general.maybe",
                defaultMessage: "Maybe",
              })}
            />
          </Grid>
        </Grid>
      )}

      <Typography
        variant="body1"
        style={{ marginTop: theme.spacing(2) }}
        className={classes.question}
      >
        <FormattedMessage
          id="publishScreen.questionier.locationConfirmation"
          defaultMessage="Is the job in <b>{city}</b> suits you?"
          values={{ city: props.jobData.city }}
        />
      </Typography>
      <Grid container alignContent="space-between">
        <Grid item xs={4}>
          <AnswerBox
            active
            answerSelected={(value) => handleAnswerSelected(value, "location")}
            iconName={"happyFace"}
            answerValue="yes"
            indicateSelection={
              candidateQAnswers.findIndex(
                (fqItem) =>
                  fqItem.questionType === "location" &&
                  fqItem.questionValue === "yes"
              ) > -1
            }
            answerLabel={intl.formatMessage({
              id: "general.yes",
              defaultMessage: "Yes",
            })}
          />
        </Grid>
        <Grid item xs={4}>
          <AnswerBox
            active
            answerSelected={(value) => handleAnswerSelected(value, "location")}
            iconName={"disappointedFace"}
            answerValue="no"
            answerLabel={intl.formatMessage({
              id: "general.no",
              defaultMessage: "No",
            })}
            indicateSelection={
              candidateQAnswers.findIndex(
                (fqItem) =>
                  fqItem.questionType === "location" &&
                  fqItem.questionValue === "no"
              ) > -1
            }
          />
        </Grid>
        <Grid item xs={4}>
          <AnswerBox
            active
            answerSelected={(value) => handleAnswerSelected(value, "location")}
            indicateSelection={
              candidateQAnswers.findIndex(
                (fqItem) =>
                  fqItem.questionType === "location" &&
                  fqItem.questionValue === "maybe"
              ) > -1
            }
            iconName={"thinkingFace"}
            answerValue="maybe"
            answerLabel={intl.formatMessage({
              id: "general.maybe",
              defaultMessage: "Maybe",
            })}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
  if (currentStep > 2 && currentStep < totalSteps) {
    const fqObject = Object.values(candidateQAnswers)[currentStep - 3]; //-3: 2 initial questions, another 1 for zero based array
    switch (fqObject.key) {
      case "experience":
        currentRender = (
          <QuestionOption
            questionTitle={
              <Typography
                variant="body1"
                style={{ marginTop: theme.spacing(2) }}
                className={classes.question}
              >
                <FormattedMessage
                  id="publishScreen.questionier.experience"
                  defaultMessage="How many years of experience do you have in <b>{value}</b>?"
                  values={{
                    value: props.professionsTranslation.find(
                      (pt) =>
                        parseInt(pt.id) ===
                        parseInt(fqObject.value.selectedProfession)
                    ).name,
                  }}
                />
              </Typography>
            }
            questionOptions={answersValues}
            indicateSelection={
              candidateQAnswers[currentStep - 3].candidateAnswer
                ? candidateQAnswers[currentStep - 3].candidateAnswer
                : ""
            }
            valueClick={(value) =>
              handleAnswerSelected(value, [currentStep - 3])
            }
          />
        );
        break;
      case "academicDegrees":
        currentRender = (
          <React.Fragment>
            <Typography
              variant="body1"
              style={{ marginTop: theme.spacing(2) }}
              className={classes.question}
            >
              <FormattedMessage
                id="jobPreview.holdDegreeLong"
                defaultMessage="Do you hold title {title}, of type {type}?"
                values={{
                  title: translateDegreeAndType(fqObject.value.selectedDegree),
                  type: fqObject.value.degreeType,
                }}
              />
            </Typography>
            <Grid container alignContent="space-between">
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? candidateQAnswers.findIndex(
                          (fqItem) =>
                            fqItem.key === "academicDegrees" &&
                            fqItem.candidateAnswer.value === "yes"
                        ) > -1
                      : false
                  }
                  iconName={"happyFace"}
                  answerValue="yes"
                  answerLabel={intl.formatMessage({
                    id: "general.yes",
                    defaultMessage: "Yes",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? candidateQAnswers.findIndex(
                          (fqItem) =>
                            fqItem.key === "academicDegrees" &&
                            fqItem.candidateAnswer.value === "no"
                        ) > -1
                      : false
                  }
                  iconName={"disappointedFace"}
                  answerValue="no"
                  answerLabel={intl.formatMessage({
                    id: "general.no",
                    defaultMessage: "No",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? candidateQAnswers.findIndex(
                          (fqItem) =>
                            fqItem.key === "academicDegrees" &&
                            fqItem.candidateAnswer.value === "maybe"
                        ) > -1
                      : false
                  }
                  iconName={"confusedFace"}
                  answerValue="maybe"
                  answerLabel={intl.formatMessage({
                    id: "general.maybe",
                    defaultMessage: "Maybe",
                  })}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
        break;
      case "dynamics":
        currentRender = (
          <React.Fragment>
            <Typography
              variant="body1"
              style={{ marginTop: theme.spacing(2) }}
              className={classes.question}
            >
              {fqObject.value.displayQuestion}
            </Typography>
            <Grid container alignContent="space-between">
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? fqObject.candidateAnswer.value === "yes"
                      : false
                  }
                  iconName={"happyFace"}
                  answerValue="yes"
                  answerLabel={intl.formatMessage({
                    id: "general.yes",
                    defaultMessage: "Yes",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? fqObject.candidateAnswer.value === "no"
                      : false
                  }
                  iconName={"disappointedFace"}
                  answerValue="no"
                  answerLabel={intl.formatMessage({
                    id: "general.no",
                    defaultMessage: "No",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? fqObject.candidateAnswer.value === "maybe"
                      : false
                  }
                  iconName={"confusedFace"}
                  answerValue="maybe"
                  answerLabel={intl.formatMessage({
                    id: "general.maybe",
                    defaultMessage: "Maybe",
                  })}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
        break;
      case "licenses":
        currentRender = (
          <QuestionOption
            questionTitle={
              <Typography
                variant="body1"
                style={{ marginTop: theme.spacing(2) }}
                className={classes.question}
              >
                <FormattedMessage
                  id="publishScreen.questionier.license"
                  defaultMessage="How many years do you have a license for <b>{value}</b>?"
                  values={{ value: fqObject.value.licenseOn }}
                />
              </Typography>
            }
            questionOptions={answersValues}
            indicateSelection={
              candidateQAnswers[currentStep - 3].candidateAnswer
                ? candidateQAnswers[currentStep - 3].candidateAnswer
                : ""
            }
            valueClick={(value) =>
              handleAnswerSelected(value, [currentStep - 3])
            }
          />
        );
        break;
      case "skills":
        currentRender = (
          <QuestionOption
            questionTitle={
              <Typography
                variant="body1"
                style={{ marginTop: theme.spacing(2) }}
                className={classes.question}
              >
                <FormattedMessage
                  id="jobPreview.knowledgeLevel"
                  defaultMessage="What is your knowledge level in <b>{value}</b>"
                  values={{ value: fqObject.value.skillName }}
                />
              </Typography>
            }
            questionOptions={SkillLevels}
            indicateSelection={
              candidateQAnswers[currentStep - 3].candidateAnswer
                ? candidateQAnswers[currentStep - 3].candidateAnswer
                : ""
            }
            valueClick={(value) =>
              handleAnswerSelected(value, [currentStep - 3])
            }
          />
        );
        break;
      case "certificates":
        currentRender = (
          <React.Fragment>
            <Typography
              variant="body1"
              style={{ marginTop: theme.spacing(2) }}
              className={classes.question}
            >
              <FormattedMessage
                id="jobPreview.haveCertificate"
                defaultMessage="Do you have certificate of {cert}"
                values={{ cert: fqObject.value.selectedCertificate }}
              />
              ?
            </Typography>
            <Grid container alignContent="space-between">
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? candidateQAnswers.findIndex(
                          (fqItem) =>
                            fqItem.key === "certificates" &&
                            fqItem.candidateAnswer.value === "yes"
                        ) > -1
                      : false
                  }
                  iconName={"happyFace"}
                  answerValue="yes"
                  answerLabel={intl.formatMessage({
                    id: "general.yes",
                    defaultMessage: "Yes",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? candidateQAnswers.findIndex(
                          (fqItem) =>
                            fqItem.key === "certificates" &&
                            fqItem.candidateAnswer.value === "no"
                        ) > -1
                      : false
                  }
                  iconName={"disappointedFace"}
                  answerValue="no"
                  answerLabel={intl.formatMessage({
                    id: "general.no",
                    defaultMessage: "No",
                  })}
                />
              </Grid>
              <Grid item xs={4}>
                <AnswerBox
                  active
                  answerSelected={(value) =>
                    handleAnswerSelected(value, [currentStep - 3])
                  }
                  indicateSelection={
                    fqObject.candidateAnswer
                      ? candidateQAnswers.findIndex(
                          (fqItem) =>
                            fqItem.key === "certificates" &&
                            fqItem.candidateAnswer.value === "maybe"
                        ) > -1
                      : false
                  }
                  iconName={"confusedFace"}
                  answerValue="maybe"
                  answerLabel={intl.formatMessage({
                    id: "general.maybe",
                    defaultMessage: "Maybe",
                  })}
                />
              </Grid>
            </Grid>
          </React.Fragment>
        );
        break;
      case "languages":
        currentRender = (
          <QuestionOption
            questionTitle={
              <Typography
                variant="body1"
                style={{ marginTop: theme.spacing(2) }}
                className={classes.question}
              >
                <FormattedMessage
                  id="jobPreview.languageknowledgeLevel"
                  defaultMessage="What is your knowledge level in the language of {lang}"
                  values={{ lang: fqObject.value.languageName }}
                />
              </Typography>
            }
            questionOptions={LanguageLevels}
            indicateSelection={
              candidateQAnswers[currentStep - 3].candidateAnswer
                ? candidateQAnswers[currentStep - 3].candidateAnswer
                : ""
            }
            valueClick={(value) =>
              handleAnswerSelected(value, [currentStep - 3])
            }
          />
        );
        break;
      default:
        break;
    }
  } else if (currentStep === totalSteps) {
    currentRender = (
      <React.Fragment>
        <Typography variant="body1">
          <FormattedMessage
            id="publishScreen.questionier.lastQuestion"
            defaultMessage="And just one more question before I'm sending your application:"
          />
        </Typography>
        <Typography
          variant="body1"
          style={{ marginTop: theme.spacing(2) }}
          className={classes.question}
        >
          <FormattedMessage
            id="publishScreen.questionier.availability"
            defaultMessage="Your availability for this job is:"
          />
        </Typography>
        <Grid container alignContent="space-between">
          <Grid item xs={6}>
            <FormControlLabel
              value="immediate"
              control={<Radio color="primary" />}
              label={intl.formatMessage({
                id: "publishScreen.questionier.availability.immediatly",
                defaultMessage: "Available Immediatly",
              })}
              labelPlacement="end"
              checked={immedidateAvailability}
              onClick={handleAvailabilityMode}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              value="onDate"
              control={<Radio color="primary" />}
              label={intl.formatMessage({
                id: "publishScreen.questionier.availability.onDate",
                defaultMessage: "Available on date:",
              })}
              labelPlacement="end"
              checked={!immedidateAvailability}
              onClick={handleAvailabilityMode}
            />
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disabled={immedidateAvailability}
                  margin="normal"
                  id="date-picker-dialog"
                  label={intl.formatMessage({
                    id: "publishScreen.questionier.availability.onDate.label",
                    defaultMessage: "I'll be available on:",
                  })}
                  format="dd/MM/yyyy"
                  value={availabilityDate}
                  onChange={handleSelectAvailability}
                  minDate={new Date()} //maxDate
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  //for the third question onward, use the following component

  return (
    <React.Fragment>
      {!completed ? (
        <Slide
          direction={"up"}
          timeout={500}
          in={true}
          className={classes.root}
          mountOnEnter
          unmountOnExit
        >
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.progress}>
                <LinearProgress
                  variant="determinate"
                  color="secondary"
                  value={progressStep}
                />
              </div>
              <Box className={classes.paper}>
                {currentRender}
                <ContinueBackButtons
                  disableContinue={verifyContinue()}
                  continueLabel={
                    currentStep === totalSteps
                      ? intl.formatMessage({
                          id: "general.finish",
                          defaultMessage: "Finish",
                        })
                      : intl.formatMessage({
                          id: "general.continue",
                          defaultMessage: "Continue",
                        })
                  }
                  disableBack={currentStep < 3}
                  continue={handleContinue}
                  back={handleBack}
                />
              </Box>
            </Grid>
          </Grid>
        </Slide>
      ) : (
        <QuestionierCompleted fullname={props.candidateFullname} />
      )}
    </React.Fragment>
  );
};
export default JobQuestionier;
