/* eslint-disable no-use-before-define */
import React from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { useTheme } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { Chip } from "@material-ui/core";

//import styled from "styled-components";
import { styled } from "@material-ui/core/styles";

const InputWrapperTheme = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: 4,
  position: "relative",
  padding: 1,
  display: "flex",
  flexWrap: "wrap",
  "&:hover": {
    borderColor: "#40a9ff",
  },
  "& input": {
    fontSize: 14,
    height: 30,

    backgroundColor: theme.palette.type
      ? theme.palette.background.paper
      : "#fff",
    boxSizing: "border-box",
    padding: "4px 6px",
    width: 0,
    minWidth: 30,
    flexGrow: 1,
    border: 0,
    margin: 0,
    outline: 0,
  },
}));

const ListboxTheme = styled("ul")(({ theme }) => ({
  width: 200,
  top: 40,
  margin: "2px 0 0",
  padding: 0,
  position: "absolute",
  listStyle: "none",
  backgroundColor:
    theme.palette.type === "dark" ? theme.palette.background.paper : "#fff",
  overflow: "auto",
  maxHeight: 250,
  borderRadius: 4,
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
  zIndex: 1,
  "& li": {
    padding: "5px 12px",
    display: "flex",
    color: theme.palette.type === "dark" ? "#fff" : theme.palette.primary.main,
    "& span": {
      flexGrow: 1,
    },
    "& svg": {
      color: "transparent",
    },
  },
  "& li[aria-selected=true]": {
    backgroundColor: theme.palette.primary.light,
    color: "#f50057",
    fontWeight: 600,
    "& svg": {
      color: "#f50057",
    },
  },
  "& li[data-focus=true]": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    cursor: "pointer",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function CustomizedHook(props) {
  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    setAnchorEl,
  } = useAutocomplete({
    multiple: true,
    options: props.tagsList ? props.tagsList : [],
    value: props.value,
    filterSelectedOptions: true,
    freeSolo: props.solo ? props.solo : false,
    getOptionLabel: (option) => option.name,
    getOptionSelected: (option, value) => option.id === value.id,
    onChange: (event, newValue) => {
      props.onSelect(newValue);
    },
  });
  const theme = useTheme();
  const handleRemoveTag = (chip) => {
    props.onRemove(chip);
  };

  return (
    <div
      style={{
        width: "100%",
        position: "relative",
      }}
    >
      <div {...getRootProps()}>
        <InputWrapperTheme
          ref={setAnchorEl}
          //className={theme.palette.type ? classes.darkInput : null}
          style={{
            backgroundColor:
              theme.palette.type === "dark"
                ? theme.palette.background.paper
                : "",
          }}
        >
          {value.map((option, index) => (
            <Chip
              key={option.name}
              label={option.name}
              {...getTagProps({ index })}
              onDelete={() => handleRemoveTag(option.id)}
            />
          ))}

          <input {...getInputProps()} placeholder={props.tagPlaceholder} />
        </InputWrapperTheme>
      </div>
      {groupedOptions.length > 0 ? (
        <ListboxTheme {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>
              <span>{option.name}</span>
              <AddIcon fontSize="small" />
            </li>
          ))}
        </ListboxTheme>
      ) : null}
    </div>
  );
}
