import React from "react";
import {
  Select,
  Box,
  FormControl,
  Divider,
  MenuItem,
  Button,
  Grid,
  Input,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import {
  AI_LICENSES,
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_SALARY_EXPECTATIONS,
} from "../../../../config/constants";
import { LanguageLevels } from "../../../../config/jobsConfigData";
import YesNoDialog from "../../../../components/DialogYesNo/DialogYesNo";
import EndRowButton from "../EndRowButtons";
import { CurrencySign } from "../../../../config/jobsConfigData";
import { useIntl, FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiFormControl-root": {
      minWidth: 100,
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  activeRow: {
    color: theme.palette.grey[700],
  },
  divider: {
    position: "absolute",
    right: 0,
    top: -8,
    height: "calc(100% + 16px)",
  },
  selectLabel: {
    width: 150,
  },
}));

//This function will return the message id and default message object
const translateConfirmMessage = (rowType) => {
  switch (rowType) {
    case AI_ACADEMIC_DGREE:
      return {
        id: "candidateProfile.degree.remove",
        defaultMessage: "Remove title {title} from Academic Degrees list?",
      };
    case AI_CERTIFICATES:
      return {
        id: "candidateProfile.certificate.remove",
        defaultMessage: "Remove certificate {cert} from Certifications list?",
      };
    case AI_LICENSES:
      return {
        id: "candidateProfile.license.remove",
        defaultMessage: "Remove certificate {license} from Licenses list?",
      };
    case AI_LANGUAGES:
      return {
        id: "candidateProfile.language.remove",
        defaultMessage: "Remove language {lang} from Languages list?",
      };
    case AI_SALARY_EXPECTATIONS:
      return {
        id: "candidateProfile.salaryExpectations.remove",
        defaultMessage: "Remove salary expectations?",
      };
    default:
      break;
  }
};

const AddingCandidateRow = ({
  label,
  editLabel,
  value,
  update,
  remove,
  type,
  list,
  noborder,
  placeholder,
  emptyMode,
  rowType,
  ...rest
}) => {
  const [addValue, setAddValue] = React.useState(null);
  const [inEditMode, setInEditMode] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [confirmRequest, setConfirmRequest] = React.useState(false);
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles(theme);

  const handleChangeSelect = (event) => {
    if (addValue) {
      const currValue = addValue;
      if (rowType === AI_LICENSES || rowType === AI_LANGUAGES) {
        setAddValue({
          id: currValue.id,
          type: currValue.value,
          value: event.target.value,
        });
      } else {
        setAddValue({
          id: currValue.id,
          type: event.target.value,
          value: currValue.value,
        });
      }
    } else {
      //create new:
      if (rowType === AI_LICENSES || rowType === AI_LANGUAGES)
        setAddValue({ id: -1, value: event.target.value, type: "" });
      else setAddValue({ id: -1, type: event.target.value, value: "" });
    }
  };

  const handleTextChange = (e) => {
    if (addValue) {
      const currValue = addValue;

      if (rowType === AI_LICENSES || rowType === AI_LANGUAGES) {
        setAddValue({
          id: currValue.id,
          type: e.target.value,
          value: currValue.value,
        });
      } else {
        setAddValue({
          id: currValue.id,
          type: currValue.type ? currValue.type : "",
          value: e.target.value,
        });
      }
    } else {
      if (rowType === AI_LICENSES || rowType === AI_LANGUAGES)
        setAddValue({ id: -1, value: "", type: e.target.value });
      if (rowType === AI_SALARY_EXPECTATIONS)
        setAddValue({
          id: -1,
          type: "global",
          value: parseInt(e.target.value),
        });
      else setAddValue({ id: -1, type: "", value: e.target.value });
    }
  };

  const handleAdditionMode = () => {
    setInEditMode((prevValue) => setInEditMode(!prevValue));
  };

  const handleSetEditMode = () => {
    setInEditMode(true);
  };

  const handleClickAway = (event) => {
    if (inEditMode) {
      setInEditMode(false);
      setError(null);
      setAddValue(null);
    }
  };

  const handleSave = () => {
    setInEditMode(false);
    setError(null);
    update(addValue);
    setAddValue(null);
  };

  const handleCancel = () => {
    setInEditMode(false);
    setError(false);
    //setAddValue(null);
  };

  const handleRemove = () => {
    setConfirmRequest(false);
    remove(addValue);
  };

  React.useEffect(() => {
    if (value) {
      setAddValue(value);
    }
    return function cleanup() {
      //console.log("Cleaning Up")
      setInEditMode(false);
    };
  }, [value]);

  const detectRowType = () => {
    switch (rowType) {
      case AI_ACADEMIC_DGREE:
        return (
          <React.Fragment>
            <Grid item lg={2} sm={4}>
              <div style={{ position: "relative" }}>
                <Box display="flex" alignItems={"center"} minHeight={44}>
                  <FormControl
                    className={classes.formControl}
                    disabled={!inEditMode}
                  >
                    <Select
                      disableUnderline
                      labelId="select"
                      id="select"
                      value={
                        addValue && addValue.type ? addValue.type : "initial"
                      }
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value="initial" disabled>
                        <FormattedMessage
                          id="general.select"
                          defaultMessage="Select"
                        />
                        :
                      </MenuItem>
                      {list &&
                        list.map((item) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
                <Divider
                  orientation="vertical"
                  light
                  variant="fullWidth"
                  className={classes.divider}
                />
              </div>
            </Grid>
            <Grid item lg={7} sm={4}>
              <Box
                display="flex"
                alignItems={"center"}
                minHeight={44}
                pl={theme.spacing(2)}
              >
                <FormControl
                  fullWidth
                  inputprops={{ disableUnderline: true }}
                  disabled={!inEditMode}
                  focused={inEditMode}
                  error={error !== null}
                >
                  <Input
                    disableUnderline
                    className={inEditMode ? classes.activeRow : null}
                    id="CandidateEditRow"
                    value={addValue && addValue.value ? addValue.value : ""}
                    onChange={handleTextChange}
                    type={type ? type : "string"}
                    placeholder={placeholder ? placeholder : ""}
                  />
                </FormControl>
              </Box>
            </Grid>
          </React.Fragment>
        );
      case AI_CERTIFICATES:
        return (
          <React.Fragment>
            <Grid item lg={9} sm={6}>
              <Box display="flex" alignItems={"center"} minHeight={44}>
                <FormControl
                  fullWidth
                  inputprops={{ disableUnderline: true }}
                  disabled={!inEditMode}
                  focused={inEditMode}
                  error={error !== null}
                >
                  <Input
                    disableUnderline
                    className={inEditMode ? classes.activeRow : null}
                    id="CandidateEditRow"
                    value={addValue && addValue.value ? addValue.value : ""}
                    onChange={handleTextChange}
                    type={type ? type : "string"}
                    placeholder={placeholder ? placeholder : ""}
                  />
                </FormControl>
              </Box>
            </Grid>
          </React.Fragment>
        );
      case AI_LICENSES:
        return (
          <React.Fragment>
            <Grid item lg={2} sm={4}>
              <div style={{ position: "relative" }}>
                <Box display="flex" alignItems={"center"} minHeight={44}>
                  <YearsSelectionDropdown
                    disabled={!inEditMode}
                    onChange={handleChangeSelect}
                    value={
                      addValue && addValue.value ? addValue.value : "initial"
                    }
                  />
                </Box>
                <Divider
                  orientation="vertical"
                  light
                  variant="fullWidth"
                  className={classes.divider}
                />
              </div>
            </Grid>
            <Grid item lg={7} sm={4}>
              <Box
                display="flex"
                alignItems={"center"}
                minHeight={44}
                pl={theme.spacing(2)}
              >
                <FormControl
                  fullWidth
                  inputprops={{ disableUnderline: true }}
                  disabled={!inEditMode}
                  focused={inEditMode}
                  error={error !== null}
                >
                  <Input
                    disableUnderline
                    className={inEditMode ? classes.activeRow : null}
                    id="CandidateEditRow"
                    value={addValue && addValue.type ? addValue.type : ""}
                    onChange={handleTextChange}
                    type={type ? type : "string"}
                    placeholder={placeholder ? placeholder : ""}
                  />
                </FormControl>
              </Box>
            </Grid>
          </React.Fragment>
        );
      case AI_LANGUAGES:
        return (
          <React.Fragment>
            <Grid item lg={2} sm={4}>
              <div style={{ position: "relative" }}>
                <Box display="flex" alignItems={"center"} minHeight={44}>
                  <FormControl
                    className={classes.formControl}
                    disabled={!inEditMode}
                  >
                    <Select
                      disableUnderline
                      labelId="select"
                      id="select"
                      value={
                        addValue && addValue.value ? addValue.value : "initial"
                      }
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value="initial" disabled>
                        <FormattedMessage
                          id="candidateProfile.language.myLevel"
                          defaultMessage="My Level in this language"
                        />
                      </MenuItem>
                      {LanguageLevels.map((lang) => {
                        return (
                          <MenuItem key={lang.value} value={lang.value}>
                            <FormattedMessage
                              id={`jobs.fq.levels.${lang.value}`}
                              defaultMessage={lang.label}
                            />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Divider
                  orientation="vertical"
                  light
                  variant="fullWidth"
                  className={classes.divider}
                />
              </div>
            </Grid>
            <Grid item lg={7} sm={4}>
              <Box
                display="flex"
                alignItems={"center"}
                minHeight={44}
                pl={theme.spacing(2)}
              >
                <FormControl
                  fullWidth
                  inputprops={{ disableUnderline: true }}
                  disabled={!inEditMode}
                  focused={inEditMode}
                  error={error !== null}
                >
                  <Input
                    disableUnderline
                    className={inEditMode ? classes.activeRow : null}
                    id="CandidateEditRow"
                    value={addValue && addValue.type ? addValue.type : ""}
                    onChange={handleTextChange}
                    type={type ? type : "string"}
                    placeholder={placeholder ? placeholder : ""}
                  />
                </FormControl>
              </Box>
            </Grid>
          </React.Fragment>
        );
      case AI_SALARY_EXPECTATIONS:
        return (
          <React.Fragment>
            <Grid item lg={2} sm={4}>
              <div style={{ position: "relative" }}>
                <Box display="flex" alignItems={"center"} minHeight={44}>
                  <FormControl
                    className={classes.formControl}
                    disabled={!inEditMode}
                  >
                    <Select
                      disableUnderline
                      labelId="select"
                      id="select"
                      value={
                        addValue && addValue.type ? addValue.type : "global"
                      }
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value={"global"}>
                        <FormattedMessage
                          id="jobs.fq.salaryExpectations.paymentType.global"
                          defaultMessage="Global salary"
                        />
                      </MenuItem>
                      <MenuItem value={"hour"}>
                        <FormattedMessage
                          id="jobs.fq.salaryExpectations.paymentType.hour"
                          defaultMessage="Salary per hour"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Divider
                  orientation="vertical"
                  light
                  variant="fullWidth"
                  className={classes.divider}
                />
              </div>
            </Grid>
            <Grid item lg={7} sm={4}>
              <Box
                display="flex"
                alignItems={"center"}
                minHeight={44}
                pl={theme.spacing(2)}
              >
                <FormControl
                  fullWidth
                  inputprops={{ disableUnderline: true }}
                  disabled={!inEditMode}
                  focused={inEditMode}
                  error={error !== null}
                >
                  <Input
                    disableUnderline
                    className={inEditMode ? classes.activeRow : null}
                    id="CandidateEditRow"
                    min={0}
                    max={100000}
                    step={100}
                    style={inEditMode ? { maxWidth: 100 } : null}
                    value={
                      inEditMode
                        ? addValue && addValue.value
                          ? addValue.value
                          : 0
                        : addValue && addValue.value
                        ? intl.formatMessage(
                            {
                              id: "candidateProfile.salaryExpectations.profile",
                              defaultMessage:
                                "At least {value} {currency}/{period}",
                            },
                            {
                              value: addValue.value,
                              currency: CurrencySign.symbol,
                              period:
                                addValue.type === "global"
                                  ? intl.formatMessage({
                                      id: "common.month",
                                      defaultMessage: "Month",
                                    })
                                  : intl.formatMessage({
                                      id: "common.hour",
                                      defaultMessage: "Hour",
                                    }),
                            }
                          )
                        : ""
                    }
                    type={inEditMode ? "number" : "string"}
                    onChange={handleTextChange}
                    placeholder={placeholder ? placeholder : ""}
                  />
                </FormControl>
              </Box>
            </Grid>
          </React.Fragment>
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Box className={classes.root} px={theme.spacing(2)} py={theme.spacing(1)}>
        <Grid item lg={2} sm={4}>
          <Button
            disabled={addValue ? true : false}
            startIcon={addValue || inEditMode ? null : <AddRoundedIcon />}
            onClick={handleAdditionMode}
            style={{ minHeight: 44 }}
          >
            {addValue || inEditMode ? editLabel : label}
          </Button>
        </Grid>
        {inEditMode || addValue ? (
          detectRowType()
        ) : (
          <Grid item lg={9} xs={false}></Grid>
        )}
        <Grid item xs={12} lg={1} md={2} sm={4}>
          {!emptyMode || inEditMode ? (
            <EndRowButton
              disabled={error !== null || value === null}
              onCancel={handleCancel}
              onEdit={inEditMode ? handleSave : handleSetEditMode}
              //onRemove={handleRemove}
              onRemove={() => setConfirmRequest(true)}
              editMode={inEditMode}
            />
          ) : null}
        </Grid>
      </Box>
      {!noborder && <Divider light />}
      {confirmRequest && (
        <YesNoDialog
          open={confirmRequest}
          onClose={() => setConfirmRequest(false)}
          title={intl.formatMessage({
            id: "common.removeItemConfirmation",
            defaultMessage: "Item removal confirmation",
          })}
          description={intl.formatMessage(
            translateConfirmMessage(rowType, addValue)
          )}
          positiveButtonLabel={intl.formatMessage({
            id: "general.yes",
            defaultMessage: "Yes",
          })}
          onConfirm={handleRemove}
          negativeButtonLabel={intl.formatMessage({
            id: "general.cancel",
            defaultMessage: "Cancel",
          })}
        />
      )}
    </React.Fragment>
  );
};

export default AddingCandidateRow;

const YearsSelectionDropdown = ({ disabled, onChange, value }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      <Select
        disableUnderline
        labelId="select"
        id="select"
        value={value}
        onChange={onChange}
      >
        <MenuItem value="initial" disabled>
          <FormattedMessage
            id="candidateProfile.license.profile"
            defaultMessage="Holding license for"
          />
        </MenuItem>
        <MenuItem value={1}>
          <FormattedMessage
            id="common.list.upOne"
            defaultMessage="Up to 1 Year"
          />
        </MenuItem>
        <MenuItem value={2}>
          <FormattedMessage id="common.list.upTwo" defaultMessage="Two Years" />
        </MenuItem>
        <MenuItem value={3}>
          <FormattedMessage
            id="common.list.upThree"
            defaultMessage="Three Years"
          />
        </MenuItem>
        <MenuItem value={4}>
          <FormattedMessage
            id="common.list.upFour"
            defaultMessage="Four Years"
          />
        </MenuItem>
        <MenuItem value={5}>
          <FormattedMessage
            id="common.list.upFive"
            defaultMessage="Five Years"
          />
        </MenuItem>
        <MenuItem value={6}>
          <FormattedMessage id="common.list.upSix" defaultMessage="Six Years" />
        </MenuItem>
        <MenuItem value={7}>
          <FormattedMessage
            id="common.list.upSeven"
            defaultMessage="Seven Years"
          />
        </MenuItem>
        <MenuItem value={8}>
          <FormattedMessage
            id="common.list.upEight"
            defaultMessage="Eight Years"
          />
        </MenuItem>
        <MenuItem value={9}>
          <FormattedMessage
            id="common.list.upNine"
            defaultMessage="Nine Years"
          />
        </MenuItem>
        <MenuItem value={10}>
          <FormattedMessage id="common.list.upTen" defaultMessage="10+ Years" />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
