import React from "react";
import {
  Checkbox,
  Avatar,
  Divider,
  Typography,
  Link,
  Grid,
  Box,
  Collapse,
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { Primary } from "../../config/colors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import validator from "validator";
import StarIcon from "../../Assets/CustomIcons/StarIcon";
import LastModified from "../../components/LastModified/LastModified";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import { getInitials } from "../../config/candidatesConfigData";
import SingleCandidateActions from "./SingleCandidateActions";
import StyledRating from "../../components/StyledRating/StyledRating";
import EventRow from "./SingleCandidateFull/CandidateEvents/EventRow";
import axios from "axios";
import Spinner from "../../UI/Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  active: {
    "& > div": {
      backgroundColor: Primary.light,
    },
  },
}));

const removeCharsFromMobile = (mobile) => {
  return mobile && mobile.replace("-", "");
};

const SingleCandidate = ({ shortVersion, isActive, ...props }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeMenuItem, setActiveMenuItem] = React.useState("");

  const increaseCacheTotalAssignedValue = (cachedJobs, jobId) => {
    const jobIndex = cachedJobs.findIndex((job) => job.id === jobId);
    if (jobIndex > -1) {
      const newJobTotalAssigned = cachedJobs[jobIndex];
      newJobTotalAssigned.total_assigned =
        cachedJobs[jobIndex].total_assigned + 1;
      cachedJobs[jobIndex] = newJobTotalAssigned;
      return cachedJobs;
    }
    return -1;
  };

  const handleMoreActionsMenuClick = (event, actionId, actionValue) => {
    setActiveMenuItem(actionId);
  };

  const handleDownloadCV = () => {
    setIsLoading(true);
    axios
      .post(
        `https://services.adamtotal.co.il/ADMBI/RequestFiles/clientno/1021/user/lynadmin/password/9PiuGwyPzQPAoCjQHdwO/candno/${parseInt(
          props.candidateData.acf.externalNumber
        )}`,
        {},
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
            "Access-Control-Allow-Headers":
              "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
          },
        }
      )
      .then((res) => {
        const cvsRaw = res.data.map((file) => {
          return {
            fileContent: file.file_content,
            fileType: file.file_ext,
            fileName: file.file_name,
          };
        });
        const base64 = cvsRaw[0].fileContent;
        const link = document.createElement("a");
        link.href =
          "data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64," +
          base64;
        link.download = cvsRaw[0].fileName;
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleAddEvent = (
    type,
    value,
    additionalInfo = "",
    isUpdateMode = false
  ) => {
    setActiveMenuItem("");
    props.eventAdded(
      {
        eventKey: type,
        eventValue: value,
        eventAuthor: localStorage.getItem("userId"),
        additionalInfo: additionalInfo,
        isUpdateMode,
      },
      props.candidateData.id
    );
  };

  return (
    <React.Fragment>
      <Spinner open={isLoading} />
      <Grid container spacing={2} justify="flex-start" alignItems="center">
        <Grid
          item
          xs={shortVersion ? 12 : 2}
          className={isActive ? classes.active : ""}
        >
          <div className={classes.row}>
            <Checkbox
              checked={isActive}
              name="checkCandidate"
              onClick={(event) => props.check(event, props.candidateData.id)}
              color="primary"
            />
            <Avatar
              style={{
                marginRight: theme.spacing(1),
                width: theme.spacing(4),
                height: theme.spacing(4),
                fontSize: "1rem",
              }}
              alt={props.candidateData.name}
              src={
                props.candidateData && props.candidateData.profile_image
                  ? props.candidateData.profile_image
                  : ""
              }
            >
              {" "}
              {getInitials(props.candidateData.name)}
            </Avatar>
            <Typography variant="subtitle1">
              <Link
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                color="textPrimary"
                onClick={(e) => props.selectId(e, props.candidateData.id)}
              >
                {props.candidateData.name}
              </Link>
            </Typography>
          </div>
        </Grid>
        {!shortVersion && (
          <React.Fragment>
            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.acf.city}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.professions[0]
                  ? props.candidateData.professions[0].name
                  : ""}
              </Typography>
            </Grid>

            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.acf.externalNumber}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.id}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1" color="textPrimary">
                  {props.candidateData.acf.mobile}{" "}
                </Typography>
                {props.candidateData.acf.mobile &&
                  !validator.isMobilePhone(
                    removeCharsFromMobile(props.candidateData.acf.mobile)
                  ) && <ErrorOutlineRoundedIcon style={{ color: red[500] }} />}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="body1" color="textPrimary">
                {props.candidateData.acf.source}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <StyledRating
                value={
                  props.candidateData.acf.rating
                    ? parseFloat(props.candidateData.acf.rating)
                    : -1
                }
                precision={props.ratingPrecisionHalf ? 0.5 : 1}
                readOnly
                icon={<StarIcon strokeColor={"#8593FF"} />}
              />
            </Grid>
            <Grid item xs={2}>
              <LastModified toDate={props.candidateData.post_modified} />
            </Grid>
            <Grid item xs={1}>
              <SingleCandidateActions
                menuItemClick={handleMoreActionsMenuClick}
                downloadCV={handleDownloadCV}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      {activeMenuItem && (
        <Collapse in={true}>
          <Box p={theme.spacing(2)}>
            <EventRow
              eventType={activeMenuItem}
              draftMode
              authorImage={localStorage.getItem("userImage")}
              disableLastModified
              onAddEvent={handleAddEvent}
              cancelEvent={() => setActiveMenuItem("")}
            />
          </Box>
        </Collapse>
      )}
      <Divider />
    </React.Fragment>
  );
};
export default SingleCandidate;
