import React from "react";
import { Grid, Typography, Box, Divider, Button } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CVRow from "../../CandidateComponents/CVRow";
import { useTheme } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const UploadCV = ({ candidateCVs, onCVUpload, removeCv, cvUploading }) => {
  const hiddenFileInput = React.useRef(null);
  const theme = useTheme();

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    event.target.files.length && onCVUpload(event.target.files[0]);
  };

  const handleRemoveCV = (cvId) => {
    removeCv(cvId);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          <FormattedMessage
            id="candidateProfile.myCV"
            defaultMessage="My CVs"
          />
        </Typography>
        {!candidateCVs || candidateCVs.length === 0 ? (
          <Box my={theme.spacing(2)}>
            <Typography align="center" color="textSecondary" variant="body1">
              <FormattedMessage
                id="candidateProfile.cv.notUploaded"
                defaultMessage="No CV uploaded"
              />
            </Typography>
            {cvUploading && <CVRow uploading={true} />}
          </Box>
        ) : (
          <Box my={theme.spacing(2)}>
            {candidateCVs.map((singleCv) => {
              return (
                <CVRow
                  key={singleCv ? singleCv.id.toString() : []}
                  id={singleCv.id}
                  description={singleCv.description}
                  link={singleCv.url}
                  remove={handleRemoveCV}
                />
              );
            })}
            {cvUploading && <CVRow uploading={true} />}
          </Box>
        )}
        <Divider my={theme.spacing(1)} />
        <Box mt={theme.spacing(2)} align="center" textAlign="center">
          <input
            type="file"
            onChange={onFileChange}
            ref={hiddenFileInput}
            accept={".doc,.docx, .pdf"}
            style={{ display: "none" }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={(e) => hiddenFileInput.current.click()}
            endIcon={<CloudUploadIcon />}
            fullWidth
          >
            <FormattedMessage
              id="candidateProfile.cv.upload"
              defaultMessage="Upload CV"
            />
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default UploadCV;
