import React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "@material-ui/core";

export let DeleteIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M4 8H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V8ZM6 10V20H18V10H6ZM9 12H11V18H9V12ZM13 12H15V18H13V12ZM7 5V3C7 2.73478 7.10536 2.48043 7.29289 2.29289C7.48043 2.10536 7.73478 2 8 2H16C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V5H22V7H2V5H7ZM9 4V5H15V4H9Z" />
  </SvgIcon>
);
DeleteIcon = pure(DeleteIcon);
DeleteIcon.displayName = "DeleteIcon";
DeleteIcon.muiName = "SvgIcon";
