import React from "react";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import {
  Grid,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Box,
} from "@material-ui/core";

const FQCertificate = ({ onCancel, onCompletion, fqEdit = null }) => {
  const [selectedCertificate, setSelectedCertificate] = React.useState("");
  const [isCertificateIsMust, setIsCertificateIsMust] = React.useState(false);

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedCertificate(fqEdit.selectedCertificate);
      setIsCertificateIsMust(
        fqEdit.isCertificateAMust === 1 || fqEdit.isCertificateAMust
          ? true
          : false
      );
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  const handleSelectedCertificate = (event) => {
    setSelectedCertificate(event.target.value);
  };

  const handleIsCertificateIsMust = (event) => {
    setIsCertificateIsMust(event.target.checked);
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["selectedCertificate"] = selectedCertificate;
      existingObject["isCertificateAMust"] = isCertificateIsMust;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      selectedCertificate,
      isCertificateAMust: isCertificateIsMust,
    });
  };

  /*
  Certificate Object:
  selectedCertificate = string,  
  isCertificateAMust = boolean
  */

  const clearForm = () => {
    setSelectedCertificate("");
    setIsCertificateIsMust(false);
  };

  return (
    <React.Fragment>
      <Box>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
        >
          Add Certificate
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="certificate"
            label="Certificate"
            variant="filled"
            value={selectedCertificate}
            onChange={handleSelectedCertificate}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isCertificateIsMust}
                onClick={handleIsCertificateIsMust}
                name="requiredFQ"
                color="primary"
              />
            }
            label="This condition is prerequisite"
          />
        </Grid>
        <Grid item xs={12}>
          <CancelApproveButtons
            positiveCaption="Done"
            negativeCaption="Cancel"
            positiveDisabled={selectedCertificate === ""}
            onPositiveClick={handleOnCompletion}
            onNegativeClick={onCancel}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FQCertificate;
