import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { Button } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    "& .MuiButton-endIcon": {
      marginRight: 0,
    },
    "& .MuiButton-iconSizeMedium": { marginRight: 0 },
  },
  buttonContinue: {
    // "&:hover": {
    //   //you want this to be the same as the backgroundColor above
    //   backgroundColor: indigo[900],
    //   color: "#fff",
    // },
    // "& .MuiButton-endIcon": { marginRight: 0 },
    // backgroundColor: indigo[900],
    // color: "#fff",
    borderRadius: 30,
  },
}));

const ContinueBackButtons = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.root}>
      <Button
        className={classes.buttonBack}
        startIcon={<ChevronLeftRoundedIcon fontSize="small" />}
        disabled={props.disableBack}
        onClick={props.back}
      >
        <FormattedMessage id="general.back" defaultMessage="Back" />
      </Button>
      <Button
        variant="contained"
        className={classes.buttonContinue}
        onClick={props.continue}
        disabled={props.disableContinue}
        endIcon={<ChevronRightRoundedIcon fontSize="small" />}
        color="primary"
      >
        {props.continueLabel
          ? props.continueLabel
          : intl.formatMessage({
              id: "general.continue",
              defaultMessage: "Continue",
            })}
      </Button>
    </div>
  );
};
export default ContinueBackButtons;
