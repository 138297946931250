import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { LinearProgress, Typography, Box } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiLinearProgress-root": {
      height: 10,
      borderRadius: 5,
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
      "& .MuiLinearProgress-bar": {
        backgroundColor: (props) =>
          props.indicator === "red"
            ? red[300]
            : props.indicator === "yellow"
            ? "#BF7000"
            : green[300],
      },
    },
  },
}));

const ProfileProgressLinear = (props) => {
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      <Box
        display="flex"
        width={1}
        justifyContent="space-between"
        alignItems="flex-start"
        position="relative"
      >
        <Typography color="textPrimary" variant="body2">
          <FormattedMessage
            id="candidateProfile.profileStrenght.barTitle"
            defaultMessage="Logout"
          />
          :
        </Typography>

        <Typography color="textSecondary" variant="body2">
          {props.value}%
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        color="primary"
        value={props.value}
      />
    </div>
  );
};

export default ProfileProgressLinear;
