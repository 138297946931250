import React, { Component } from "react";
import { CSVReader } from "react-papaparse";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Spinner from "../../UI/Spinner/Spinner";
import {
  Typography,
  Paper,
  Grid,
  Container,
  Button,
  Fade,
  FormControlLabel,
  Switch,
  LinearProgress,
  Icon,
  Snackbar,
  Box,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { trasformMobileToLocalFormat } from "../../config/constants";
import ConfigurationListsContext from "../../context/configurationListsContext";

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alert: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
});

class CSVReader4 extends Component {
  state = {
    usersUpload: null,
    usersUploadTransform: null,
    isError: false,
    showLog: false,
    uploading: false,
    isLoading: false,
    usersError: [],
    duplicateUsers: [],
    error: null,
    showAlert: false,
    transformedApplied: false, //will be changed after clicking on import
  };
  static contextType = ConfigurationListsContext;

  parseProfessionId(professionStr) {
    //return console.log(this.context.professionsList);

    if (
      professionStr === "" ||
      professionStr === undefined ||
      professionStr === null
    )
      return "";

    const profession = this.context.professionsList.find(
      (ps) => ps.name === professionStr.toString()
    );
    if (profession && profession.id) return profession.id;

    return "";
  }

  calcImported = () => {
    return this.state.usersUpload.length - this.state.duplicateUsers.length;
  };
  handleOnDrop = (data) => {
    //before savign, check we can read the data:
    //return console.log("propf", this.parseProfessionId("Accounting2"));
    let sanitizedData = [];
    data.forEach((row) => {
      if (!row.errors && !row.errors.length) {
        return row;
      }
      sanitizedData.push(row.data);
    });
    if (!sanitizedData || sanitizedData.length === 0) {
      this.setState({ isError: true });
      return;
    }
    this.setState({ usersUpload: sanitizedData, isLoading: true });
    const verifyDuplicates = data;
    let localDuplicates = [];
    localDuplicates = verifyDuplicates.map((user) => {
      const keys = Object.keys(user.data);
      const loop = Object.values(user.data);
      //locate: email, mobile, fullname, externalId
      try {
        const emailIndex = keys.findIndex((cell) => cell === "Email");
        const mobileIndex = keys.findIndex((cell) => cell === "Mobile");
        const firstNameIndex = keys.findIndex((cell) => cell === "First Name");
        const lastNameIndex = keys.findIndex((cell) => cell === "Surname");
        const firstNamePart =
          firstNameIndex > -1 && loop[firstNameIndex]
            ? loop[firstNameIndex].trim()
            : "";
        const lastNamePart =
          lastNameIndex > -1 && loop[lastNameIndex]
            ? loop[lastNameIndex].trim()
            : "";
        const externalIdIndex = keys.findIndex((cell) => cell === "ID");
        return {
          mobile:
            mobileIndex > -1
              ? trasformMobileToLocalFormat(loop[mobileIndex])
              : "",
          //id: identifierNumber > -1 ? loop[identifierNumber] : "",
          email:
            emailIndex > -1 && loop[emailIndex] ? loop[emailIndex].trim() : "",
          fullname: `${firstNamePart} ${lastNamePart}`,
          externalId:
            externalIdIndex > -1 && loop[externalIdIndex]
              ? loop[externalIdIndex].toString().trim()
              : "",
        };
      } catch (err) {
        console.log("Error in ", user, err);
      }
    });
    axios
      .post(
        "/wp-json/api/v2/validate_duplicatation",
        {
          candidates: localDuplicates,
        },
        { timeout: 400000 }
      )
      .then((res) =>
        this.setState({ duplicateUsers: res.data, isLoading: false })
      )
      .catch((err) => {
        this.setState({ error: err, isLoading: false });
      });
  };

  handleOnError = (err, file, inputElem, reason) => {
    this.setState({
      isError: true,
      error: err,
      uploading: false,
      usersUploadTransform: null,
      transformedApplied: false,
    });
  };

  handleOnRemoveFile = (data) => {
    this.setState({
      usersUpload: [],
      duplicateUsers: [],
      showAlert: false,
      usersUploadTransform: null,
      transformedApplied: false,
    }); //null
  };

  startDataTransform = () => {
    this.setState({ uploading: true });
    const usersToCandidates = this.state.usersUpload;
    const verifyDuplicates = this.state.duplicateUsers;
    let candidateIds = [];
    let usersToUpload = [];
    //const progressStep = parseFloat(1 / usersToCandidates.length) * 100;
    //let candidatesList = [];
    usersToCandidates.forEach((user) => {
      const loop = Object.values(user);
      const keys = Object.keys(user);
      const emailIndex = keys.findIndex((cell) => cell === "Email");
      let mobileIndex = keys.findIndex((cell) => cell === "Mobile");
      const mobileData =
        mobileIndex > -1 ? trasformMobileToLocalFormat(loop[mobileIndex]) : -1;
      const firstNameIndex = keys.findIndex((cell) => cell === "First Name");
      const lastNameIndex = keys.findIndex((cell) => cell === "Surname");
      const externalIdIndex = keys.findIndex((cell) => cell === "ID");
      const availableOnIndex = keys.findIndex((cell) => cell === "Permanent");
      //const yearOfBirthIndex = keys.findIndex((cell) => cell === "AGE");

      const job1Index = keys.findIndex((cell) => cell === "Discipline");
      const roleIndexForComments = keys.findIndex((cell) => cell === "Role");
      const ratingIndex = keys.findIndex((cell) => cell === "Rating");
      const townIndexForComments = keys.findIndex((cell) => cell === "Town");
      const IndustryIndexForComments = keys.findIndex(
        (cell) => cell === "Industry Sector"
      );
      const cityIndex = keys.findIndex((cell) => cell === "Sub-Location");
      const genderIndex = keys.findIndex((cell) => cell === "Gender");
      let newCandidate = {};
      newCandidate = {
        firstName: firstNameIndex > -1 ? loop[firstNameIndex].trim() : "",
        lastName: lastNameIndex > -1 ? loop[lastNameIndex].trim() : "",
        mobile: mobileData,
        externalNumber:
          externalIdIndex > -1 && loop[externalIdIndex]
            ? loop[externalIdIndex].toString().trim()
            : "",
        city: cityIndex > -1 && loop[cityIndex] ? loop[cityIndex].trim() : "",
        gender:
          genderIndex > -1 && loop[genderIndex] ? loop[genderIndex].trim() : "",
        rating:
          loop[ratingIndex] > -1 && loop[ratingIndex]
            ? parseInt(loop[ratingIndex])
            : "",
        email:
          emailIndex > -1 && loop[emailIndex] ? loop[emailIndex].trim() : "",
        description: `More data imported: \n Town: ${
          loop[townIndexForComments]
        },\n Available on: ${
          availableOnIndex > -1 ? loop[availableOnIndex] : ""
        }, \n Role: ${loop[roleIndexForComments]}, 
        \n Industry: ${loop[IndustryIndexForComments]}`,
        profession:
          job1Index > -1 ? this.parseProfessionId(loop[job1Index]) : "",
      };
      const isDuplicated =
        verifyDuplicates.length &&
        verifyDuplicates.find(
          (dup) =>
            dup.field_value === newCandidate.mobile ||
            dup.field_value === newCandidate.email ||
            dup.field_value === newCandidate.externalNumber
        );
      if (!isDuplicated) {
        const candidateData = {
          title: `${newCandidate.firstName} ${newCandidate.lastName}`,
          fields: {
            first_name: newCandidate.firstName,
            last_name: newCandidate.lastName,
            email: newCandidate.email,
            candidate_external_number: newCandidate.externalNumber,
            telephone_main: newCandidate.mobile,
            is_active: "active",
            city: newCandidate.city,
            source: "Firefish Import",
            gender: newCandidate.gender,
            rating: newCandidate.rating,
          },
          "jobs-sectors": [newCandidate.profession],
          content: newCandidate.description,
          status: "publish",
        };
        const additionalInformation = {
          aiLanguagesObjectData: newCandidate.languages,
          correlateToJobId: this.props.match.params.jobid, //
          author: parseInt(localStorage.getItem("userId")), //used to get current user id
        };
        usersToUpload.push({
          candidateData,
          additionalInformation,
        });
      }
    });

    if (usersToUpload.length) {
      axios
        .post("/wp-json/api/v2/upload_candidates", {
          usersToUpload: usersToUpload,
        })
        .then((res) => {
          candidateIds = res.data.map((newCand) => newCand.candidateId);
          this.setState({
            uploading: false,
            showAlert: true,
            transformedApplied: true,
            usersUploadTransform: res.data,
          });
          //link the duplicates:
          this.state.duplicateUsers.forEach((cand) => {
            candidateIds.push(cand.candidate_id);
          });
          return axios
            .post(
              `/wp-json/api/v2/link_multiples/${this.props.match.params.jobid}`,
              {
                candidateIds: candidateIds,
                authorId: localStorage.getItem("userId"),
              }
            )
            .then((res) => {
              this.setState({
                uploading: false,
                showAlert: true,
                transformedApplied: true,
              });
            })
            .catch((err) => {
              this.setState({ error: err, uploading: false });
            });
        })
        .catch((err) =>
          this.setState({
            error: err,
            uploading: false,
            transformedApplied: false,
          })
        );
    } else {
      //just link the dupicates:
      //link the duplicates:
      candidateIds = this.state.duplicateUsers.map((cand) => {
        return cand.candidate_id;
      });
      axios
        .post(
          `/wp-json/api/v2/link_multiples/${this.props.match.params.jobid}`,
          {
            candidateIds: candidateIds,
            authorId: localStorage.getItem("userId"),
          }
        )
        .then((res) => {
          this.setState({
            usersUploadTransform: res.data,
            uploading: false,
            showAlert: true,
          });
        })
        .catch((err) => {
          this.setState({ error: err, uploading: false });
        });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showAlert: false });
  };

  render() {
    const { classes } = this.props;
    const displayDuplicates = this.state.duplicateUsers.length ? (
      <Fade in={this.state.duplicateUsers.length > 0}>
        <Paper className={classes.paper}>
          <div>
            <Typography variant="body1">
              <strong>
                Candidates not imported: Already exists in system{" "}
              </strong>{" "}
              (
              {this.state.duplicateUsers.length
                ? this.state.duplicateUsers.length
                : 0}{" "}
              candidates)
            </Typography>
          </div>
          <div className={classes.alert}>
            {this.state.duplicateUsers.map((candidate, index) => {
              return (
                <Alert
                  severity="warning"
                  variant="outlined"
                  key={candidate.candidate_id + "_" + index.toString()}
                >
                  <AlertTitle>
                    Candidate Name: {candidate.candidate_name}
                  </AlertTitle>
                  <Typography variant="body2" color="textSecondary">
                    {`Identification field: ${candidate.identified_field}, identified by value: ${candidate.field_value}`}
                    <br />
                    {`Candidate ID: ${candidate.candidate_id}`}
                  </Typography>
                </Alert>
              );
            })}
          </div>
        </Paper>
      </Fade>
    ) : null;
    const reportView =
      this.state.showLog && this.state.usersUploadTransform ? (
        <Fade in={this.state.showLog}>
          <Paper className={classes.paper}>
            <Box my={2}>
              <Typography variant="h5">Job import log</Typography>
            </Box>
            <div className={classes.alert}>
              {this.state.usersUploadTransform.map((candidate, index) => {
                return (
                  <Alert
                    severity={candidate.type}
                    key={
                      candidate.candidateId
                        ? candidate.candidateId
                        : `${candidate.candidate.title}_${index}`
                    }
                  >
                    <AlertTitle>{candidate.fullname}</AlertTitle>
                    {`external ID ${candidate.candidateId} from ${candidate.city}`}
                  </Alert>
                );
              })}
            </div>
          </Paper>
        </Fade>
      ) : null;
    return (
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xl={12}>
            <Paper className={classes.paper}>
              <Typography variant="h5" gutterBottom>
                Upload candidates to Job ID {this.props.match.params.jobid}
              </Typography>
              <CSVReader
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                addRemoveButton={true}
                onRemoveFile={this.handleOnRemoveFile}
                progressBarColor={blueGrey[500]}
                config={{
                  delimiter: "", // auto-detect
                  newline: "", // auto-detect
                  quoteChar: "'",
                  escapeChar: '"',
                  header: true,
                  dynamicTyping: true,
                  encoding: "ISO-8859-7",
                  error: undefined,
                  skipEmptyLines: true,
                  delimitersToGuess: [",", "	", "|", ";"],
                }}
              >
                <span>Click or drag & drop a CSV file here</span>
              </CSVReader>
              {this.state.usersUpload ? (
                <React.Fragment>
                  <div className={classes.container}>
                    <div className={classes.alert}>
                      <Alert severity="info">
                        {`${this.state.usersUpload.length} read successfully`}
                      </Alert>
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{ marginTop: 16 }}
                      startIcon={<CloudUploadIcon />}
                      onClick={this.startDataTransform}
                      disabled={
                        this.state.usersUpload === null ||
                        !this.state.usersUpload.length ||
                        this.state.usersUploadTransform !== null ||
                        this.state.uploading ||
                        this.state.error
                      }
                    >
                      Import Candidates
                    </Button>
                  </div>
                </React.Fragment>
              ) : null}
            </Paper>
            <div className={classes.container}>
              {this.state.uploading && (
                <Paper className={classes.paper}>
                  {this.state.uploading ? (
                    <Typography variant="body1">
                      Based on the amount of data, this action may take a while.
                    </Typography>
                  ) : null}
                  <LinearProgress
                    color="secondary"
                    variant={
                      this.state.uploading ? "indeterminate" : "determinate"
                    }
                    value={this.state.uploading ? null : 0}
                    style={{
                      marginBottom: 16,
                      marginTop: 16,
                    }}
                  />
                </Paper>
              )}
            </div>
            {this.state.duplicateUsers ? displayDuplicates : null}
            {this.state.usersUploadTransform ? (
              <React.Fragment>
                <div className={classes.container}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.showLog}
                        onChange={(event) =>
                          this.setState({
                            showLog: event.target.checked,
                          })
                        }
                        name="viewReport"
                        color="secondary"
                      />
                    }
                    label="Show Import log"
                  />
                </div>

                <div className={classes.container}>{reportView}</div>
              </React.Fragment>
            ) : null}
            {this.state.isLoading ? (
              <Spinner open={this.state.isLoading} />
            ) : null}
            {this.state.showAlert ? (
              <Snackbar
                open={this.state.showAlert}
                autoHideDuration={6000}
                onClose={this.handleClose}
              >
                <Alert
                  variant="filled"
                  onClose={this.handleClose}
                  severity="success"
                >
                  {this.calcImported().toString()} Successfully imported
                </Alert>
              </Snackbar>
            ) : null}
            <div className={classes.container}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<Icon>add</Icon>}
                //onClick={this.props.endUpload}
                disabled={!this.state.transformedApplied}
                onClick={() =>
                  this.props.history.push(
                    `/jobs/dashboard/${this.props.match.params.jobid}`
                  )
                }
              >
                Finish
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.cancel}
                onClick={() => this.props.history.push("/jobs/")}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    );
  }
}
export default withRouter(withStyles(styles)(CSVReader4));
