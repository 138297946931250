import React from "react";
import {
  TextField,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  useTheme,
} from "@material-ui/core";
import QuestionValueInput from "../../../../../components/QuestionValueInput";
import { Grid, Typography } from "@material-ui/core";
import CancelApproveButtons from "../../../../../components/CancelApproveButtons/CancelApproveButtons";
import { SkillLevels } from "../../../../../config/jobsConfigData";

const FQTalents = ({ onCompletion, onCancel, fqEdit = null }) => {
  const theme = useTheme();
  const [minYearsSkill, setMinYearsSkill] = React.useState(0);
  const [isSkillIsMust, setIsSkillIsMust] = React.useState(false);
  const [selectedSkill, setSelectedSkill] = React.useState("");
  const [selectedKnowledgeLevel, setSelectedKnowledgeLevel] = React.useState(
    "average"
  );

  React.useEffect(() => {
    if (fqEdit !== null) {
      setSelectedSkill(fqEdit.skillName);
      setMinYearsSkill(parseInt(fqEdit.minYearsSkill));
      setIsSkillIsMust(fqEdit.isAMust);
      setSelectedKnowledgeLevel(fqEdit.knowledgeLevelRequired);
    }
    return function cleanup() {
      clearForm();
    };
  }, [fqEdit]);

  const handleMinYearsSkill = (event) => {
    setMinYearsSkill(event.target.value);
  };

  const handleIsSkillIsMust = (event) => {
    setIsSkillIsMust(event.target.checked);
  };

  const handleKnowledgeLevelChange = (event) => {
    setSelectedKnowledgeLevel(event.target.value);
  };
  const handleCheckEnableQ = (value) => {
    if (!value) setMinYearsSkill(0);
  };
  const handleSelectedSkill = (event) => {
    setSelectedSkill(event.target.value);
  };

  const handleOnCompletion = () => {
    clearForm();
    //if edit, return with the id:
    if (fqEdit !== null) {
      const existingObject = fqEdit;
      existingObject["skillName"] = selectedSkill;
      existingObject["minYearsSkill"] = parseInt(minYearsSkill);
      existingObject["isAMust"] = isSkillIsMust;
      existingObject["knowledgeLevelRequired"] = selectedKnowledgeLevel;
      onCompletion(existingObject);
      return;
    }
    //if we are here - this is a new object
    onCompletion({
      skillName: selectedSkill,
      minYearsSkill: minYearsSkill,
      isAMust: isSkillIsMust,
      knowledgeLevelRequired: selectedKnowledgeLevel,
    });
  };

  /*
  Single skills Object: 
  {
      skillId: id,
      skillName: string,
      minYearsSkill: num,
      isSkillAMust: boolean,
      knowledgeLevelRequired: string
  }
  */

  function clearForm() {
    setMinYearsSkill(0);
    setIsSkillIsMust(false);
    setSelectedSkill("");
    setSelectedKnowledgeLevel("average");
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          style={{ fontWeight: 700, marginBottom: 15 }}
          color="textPrimary"
          variant="body1"
          gutterBottom={false}
        >
          Add Skills:
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="skill"
          label="Skills"
          variant="filled"
          color="primary"
          value={selectedSkill}
          onChange={handleSelectedSkill}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}></Grid>

      <Grid item xs={6}>
        <QuestionValueInput
          disabled={selectedSkill === ""}
          question="Minimal years required for this skill:"
          minYearsValue={handleMinYearsSkill}
          isAMustCheck={handleIsSkillIsMust}
          value={minYearsSkill}
          isAmustValue={isSkillIsMust}
          checkEnalbeQ={handleCheckEnableQ}
        />
        <div
          style={{
            marginLeft: theme.spacing(1),
          }}
        >
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Level of knowledge required for this skill
            </FormLabel>
            <RadioGroup
              aria-label="knowledgeLevel"
              name="knowledgeLevel"
              value={selectedKnowledgeLevel}
              onChange={handleKnowledgeLevelChange}
            >
              {SkillLevels.map((skill) => (
                <FormControlLabel
                  key={skill.value}
                  value={skill.value}
                  control={
                    <Radio
                      color="primary"
                      checked={selectedKnowledgeLevel === skill.value}
                    />
                  }
                  label={skill.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12}>
        <CancelApproveButtons
          positiveCaption="Done"
          negativeCaption="Cancel"
          positiveDisabled={selectedSkill === ""}
          onPositiveClick={handleOnCompletion}
          onNegativeClick={onCancel}
        />
      </Grid>
    </Grid>
  );
};
export default FQTalents;
