import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import {
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Typography,
} from "@material-ui/core";
import DuplicateIcon from "../../Assets/CustomIcons/DuplicateIcon";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiButton-root": {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      minWidth: 120,
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
    },
  },
  svgDuplicateIcon: {
    color: "#FC6E00",
  },
}));

export default function ConfirmDuplicateJob({
  open,
  onClose,
  onConfirm,
  jobId,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [duplicateFQ, setDuplicateFQ] = React.useState(false);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >
      <Box
        py={theme.spacing(2)}
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        <DuplicateIcon fontSize="80" />
      </Box>
      <Box justifyContent="center" display="flex" alignItems="center">
        <Typography variant="h5" color="textPrimary" gutterBottom={true}>
          {`Duplicate Job ${jobId}`}
        </Typography>
      </Box>

      <DialogContent>
        <Typography variant="body1" color="textSecondary">
          {`Duplicate Job ID ${jobId} with filter questions'?`}
        </Typography>
        <RadioGroup
          aria-label="duplicate"
          name="duplicateFQ"
          value={duplicateFQ}
          onChange={(event) =>
            setDuplicateFQ(event.target.value === "true" ? true : false)
          }
        >
          <FormControlLabel
            value={false}
            control={<Radio checked={!duplicateFQ} color="primary" />}
            label="Without filter questions"
          />
          <FormControlLabel
            value={true}
            control={<Radio color="primary" checked={duplicateFQ} />}
            label="With filter questions"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onConfirm(duplicateFQ)}
          color="primary"
        >
          Duplicate
        </Button>
        <Button variant="outlined" onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
