import React from "react";
//THIS COMMENT IS BRAnched from main. should appear soley in all merged version
import { JobExtentList, AreasList } from "./config/jobsConfigData";
import DeatiledActivityReport from "./Screens/Dashboard/DeatiledActivityReport";
import HLDashboard from "./Screens/Dashboard/HighLevelStatsDashboard";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import Login from "./Screens/Login/Login";
import Jobs from "./Screens/Jobs/Jobs";
import Candidates from "./Screens/Candidates/Candidates";
import axios from "axios";
import "./App.css";
import PublishJobs from "./publishJobs/PublishJobs.js";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";
import WithMenuContainer from "./hoc/WithMenuContainer";
import Settings from "./Screens/Settings/MainSettings/Settings";
import ConfigurationListsContext from "./context/configurationListsContext";
import { Primary, Secondary } from "./config/colors";
import CandidateLogin from "./Screens/ProfilesManagement/CandidateProfile/Login/Login";
import CandidateDashboard from "./Screens/ProfilesManagement/CandidateProfile/Dashboard/CandidateDashboard";
import Registration from "./Screens/ProfilesManagement/CandidateProfile/Registration/Registration";
import CssBaseline from "@material-ui/core/CssBaseline";
//import TracerCandidate from "./Screens/Tracers/TracerCandidate";
import cookies from "js-cookie";
//import Tracers from "./Screens/Tracers/Tracers";
import LibiExplore from "./Screens/Explore/LibiExplore";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import DetailedDistributionReport from "./Screens/Jobs/JobsList/JobDashboard/DetailedDistributionReport/DetailedDistributionReport";

//axios.defaults.baseURL = "http://localhost/libi-backoffice-sm/";
//axios.defaults.baseURL = "https://libitest-backoffice.connvisor.com/";
axios.defaults.baseURL = "https://sm-backoffice.libi.ai/";

axios.defaults.timeout = 80000;
axios.defaults.headers["Content-Type"] = "application/json; charset=UTF-8";

axios.interceptors.request.use(
  (request) => {
    if (
      request.url !== "/wp-json/jwt-auth/v1/token" &&
      !request.url.includes("/already_answered/") &&
      !request.url.includes("/candidateAuthentication/") &&
      !request.url.includes("/api/v2/job/") &&
      !request.url.includes("/api/v2/questionier_answers/") &&
      !request.url.includes("wp-json/api/v2/declinedJob/") &&
      !request.url.includes("/api/v2/unsubscribe") &&
      !request.url.includes("/api/v2/locateCandidate/") &&
      !request.url.includes("/api/v2/generate_login_passcode") &&
      !request.url.includes("/api/v2/generate_login_token") &&
      !request.url.includes("/api/v2/accept_registration") &&
      !request.url.includes("/api/v2/areas_professions_list") &&
      !request.url.includes("/api/v2/trace/") &&
      !request.url.includes("/api/v2/upload_trace_cv") &&
      !request.url.includes("/api/v2/send_trace") &&
      !request.url.includes("/api/v2/reset_password")
    ) {
      //check if this a candidate domain. all candidate domains must include /my-profile/lobby
      if (
        request.url.includes("/my-profile/lobby") ||
        request.url.includes("/my-profile/settings")
      ) {
        request.headers["Authorization"] = `Bearer ${cookies.get("token")}`;
      } else {
        request.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
    }

    console.log("request", request);
    return request;
  },
  (error) => {
    console.log("request error", error);
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    console.log("response", response);
    return response;
  },
  (error) => {
    console.log("response error", error.response);

    if (
      (error.response && error.response.data.code === "jwt_auth_bad_iss") ||
      error.response.data.code === "jwt_auth_invalid_token" ||
      error.response.data.data.status === 401
    ) {
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const theme = createMuiTheme({
  typography: {
    fontFamily: "Lato, sans-serif",
    h4: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: "1rem",
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.7",
    },
    body2: {
      fontSize: "1.1rem",
      fontWeight: 400,
    },
    smallText: {
      fontSize: "0.75rem",
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: Primary.main,
      light: Primary.light,
      dark: Primary.dark,
      contrastText: "#fff",
    },
    secondary: {
      main: Secondary.main,
      light: Secondary.light,
      dark: Secondary.dark,
      contrastText: "#fff",
    },
    success: {
      main: "#0BD03B",
      light: "#EDF9F0",
      dark: "#06681E",
    },
    text: {
      primary: "#363740",
      secondary: "#636575",
    },
    background: {
      default: "#F5F6F8",
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "#F5F6F8",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#A4A6B3",
      },
    },
    MuiChip: {
      root: {
        backgroundColor: Primary.light,
        color: Primary.main,
        "& .MuiSvgIcon-root": {
          color: Primary.main,
        },
        size: "medium",
      },
    },
  },
  spacing: (factor) => `${0.5 * factor}rem`,
});

function App() {
  const [professionsList, setProfessionalList] = React.useState([]);
  const [customersList, setCustomerList] = React.useState([]);
  const [jobExtentList, setJobExtentList] = React.useState([]);
  const [areasList, setAreasList] = React.useState([]);
  const [isDarkMode, setIsDarkMode] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    //load config files:
    if (window.location.pathname.includes("/my-profile/")) {
      //get token or redirect to login
      if (window.location.pathname.includes("/my-profile/lobby")) {
        const token = cookies.get("token");
        if (!token) window.location.href = "/my-profile/login";
      }
      return;
    }
    if (window.location.pathname.includes("/publishjob/")) return;
    if (
      !window.location.pathname.includes("/login") &&
      !window.location.pathname.includes("/tracer/") &&
      !window.location.pathname.includes("/publishJob")
    ) {
      const companiesRequest = axios.get("/wp-json/api/v2/companies");
      const professionsRequest = axios.get(
        "/wp-json/api/v2/all_terms/jobs-sectors"
      );
      const usersRequest = axios.get("/wp-json/api/v2/users");

      axios
        .all([companiesRequest, professionsRequest, usersRequest])
        .then((responses) => {
          setCustomerList(responses[0].data);
          setProfessionalList(responses[1].data);
          setUsers(responses[2].data);
          setAreasList(
            Object.keys(AreasList).map((item) => {
              return { id: item, value: AreasList[item] };
            })
          );
          setJobExtentList(
            Object.keys(JobExtentList).map((item) => {
              return { id: item, value: JobExtentList[item] };
            })
          );
        })
        .catch((err) => {
          setError(err.response);
        });
    }
  }, []);

  const createCustomTheme = () => {
    return !isDarkMode
      ? createMuiTheme({
          typography: {
            fontFamily: "Lato, sans-serif",
            h2: {
              fontSize: "3rem",
              [theme.breakpoints.down("md")]: {
                fontSize: "2rem",
              },
            },
            h3: {
              fontSize: "2rem",
            },
            h4: {
              fontSize: "1.5rem",
            },
          },
          spacing: (factor) => `${0.5 * factor}rem`,
          palette: {
            type: "light",
            primary: {
              main: Primary.main,
              light: Primary.light,
              dark: Primary.dark,
              contrastText: "#fff",
            },
            secondary: {
              main: Secondary.main,
              light: Secondary.light,
              dark: Secondary.dark,
              contrastText: "#fff",
            },
            success: {
              main: "#0BD03B",
              light: "#EDF9F0",
              dark: "#06681E",
            },
            text: {
              primary: "#363740",
            },
          },
        })
      : createMuiTheme({
          typography: {
            fontFamily: "Lato, sans-serif",
            h2: {
              fontSize: "3rem",
            },
            h3: {
              fontSize: "2rem",
            },
            h4: {
              fontSize: "1.5rem",
            },
          },
          spacing: (factor) => `${0.5 * factor}rem`,
          palette: {
            type: "dark",
            background: {
              paper: "#212936",
              default: "#2B3648",
            },
          },
        });
  };

  const handleToggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  return (
    <React.Fragment>
      <ConfigurationListsContext.Provider
        value={{
          professionsList,
          customersList,
          jobExtentList,
          areasList,
          users,
        }}
      >
        <Router>
          <Switch>
            <ThemeProvider theme={theme}>
              <Route exact path="/login">
                <Login />
              </Route>
              <CssBaseline />

              <Route exact path="/dashboard">
                <WithMenuContainer screenTitle="Dashboard">
                  <HLDashboard />
                </WithMenuContainer>
              </Route>
              <Route exact path="/activityReport">
                <WithMenuContainer screenTitle="Activity Report">
                  <DeatiledActivityReport />
                </WithMenuContainer>
              </Route>

              <Route exact path="/settings">
                <WithMenuContainer screenTitle="System Settings">
                  <Settings />
                </WithMenuContainer>
              </Route>
              <Route exact path="/explore">
                <WithMenuContainer screenTitle="Explore">
                  <LibiExplore />
                </WithMenuContainer>
              </Route>

              <Route exact path="/candidates">
                <WithMenuContainer screenTitle="Candidates">
                  <Candidates />
                </WithMenuContainer>
              </Route>
              <Route exact path="/jobs/dashboard/:jobid">
                <WithMenuContainer screenTitle="Manage Jobs">
                  <ErrorBoundary>
                    <Jobs innerpath="dashboard" />
                  </ErrorBoundary>
                </WithMenuContainer>
              </Route>
              <Route
                exact
                path="/jobs/dashboard/:jobid/distribution/:distributionId"
              >
                <WithMenuContainer screenTitle="ניהול משרות">
                  <ErrorBoundary>
                    <DetailedDistributionReport />
                  </ErrorBoundary>
                </WithMenuContainer>
              </Route>
              <Route exact path="/jobs">
                <WithMenuContainer screenTitle="Manage Jobs">
                  <Jobs innerpath="jobs" />
                </WithMenuContainer>
              </Route>
              <Route exact path="/jobs/addNew">
                <WithMenuContainer screenTitle="Manage Jobs">
                  <Jobs innerpath="AddNewJob" />
                </WithMenuContainer>
              </Route>
              <Route exact path="/jobs/drafts">
                <WithMenuContainer screenTitle="Manage Jobs">
                  <Jobs innerpath="drafts" />
                </WithMenuContainer>
              </Route>
              <Route exact path="/jobs/uploads/:jobid">
                <WithMenuContainer screenTitle="Manage Jobs">
                  <Jobs innerpath="uploads" />
                </WithMenuContainer>
              </Route>
              {/* <Route exact path="/tracers">
                <WithMenuContainer screenTitle="Manage Jobs">
                  <Tracers />
                </WithMenuContainer>
              </Route>
              <Route exact path="/tracer/:traceid">
                <TracerCandidate />
              </Route> */}
              <Route path="/publishJob/:id" exact>
                <PublishJobs />
              </Route>
              <Route path="/unsubscribe/:auth" exact>
                <Unsubscribe />
              </Route>
              <Route path="/" exact>
                <WithMenuContainer screenTitle="Manage Jobs">
                  <Jobs innerpath="jobs" />
                </WithMenuContainer>
              </Route>
            </ThemeProvider>
          </Switch>
          <Switch>
            <ThemeProvider theme={createCustomTheme()}>
              <CssBaseline />

              <Route exact path="/my-profile/login">
                <CandidateLogin />
              </Route>
              <Route exact path="/my-profile/lobby">
                <CandidateDashboard
                  onToggleDark={handleToggleDarkMode}
                  isDarkMode={isDarkMode}
                />
              </Route>
              <Route exact path="/my-profile/settings">
                <CandidateDashboard
                  onToggleDark={handleToggleDarkMode}
                  isDarkMode={isDarkMode}
                />
              </Route>
              <Route exact path="/my-profile/signup">
                <Registration />
              </Route>
            </ThemeProvider>
          </Switch>
        </Router>
      </ConfigurationListsContext.Provider>
    </React.Fragment>
  );
}

export default App;
