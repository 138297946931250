import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Button, Typography, Chip, Box } from "@material-ui/core";
import LocationOnRoundedIcon from "@material-ui/icons/LocationOnRounded";
import DefaultJobImage from "../../../../Assets/Images/defaultJobImage.png";
import Countdown from "../../../../components/Countdown/Countdown";
import moment from "moment";
import packagejson from "../../../../../package.json";
import { CurrencySign } from "../../../../config/jobsConfigData";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      padding: "0 !important",
      borderRadius: 10,
      overflow: "hidden",
    },
    "& .MuiButton-root": {
      borderRadius: 8,
    },
    "& .Mui-disabled": {
      backgroundColor: theme.palette.grey[400],
    },
  },
  rating: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
  media: {
    height: 150,
    width: "100%",
    marginBottom: theme.spacing(1),
  },
  title: {
    fontSize: 18,
    lineHeight: 1.3,
    textOverflow: "ellipsis",
  },
}));

export default function SingleProfileJob({
  jobId,
  jobImageSrc,
  jobTitle,
  descreteCompany,
  company,
  descreteSalary,
  minSalary,
  city,
  jobExtent,
  published,
  jobTimeLimit,
  jobExpiration,
  isAnswered,
  jobToken,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  const isJobAliveAndNotAnswered = () => {
    return moment().isBefore(jobExpiration) && !isAnswered;
  };
  const buttonCaption = () => {
    if (isAnswered && isAnswered === "candidate_accepted_job")
      return intl.formatMessage({
        id: "candidateProfile.profile.singleJob.alreadyApplied",
        defaultMessage: "You have already applied for this job",
      });
    if (isAnswered && isAnswered === "candidate_declined_job")
      return intl.formatMessage({
        id: "candidateProfile.profile.singleJob.rejectedJob",
        defaultMessage: "You have declined for this job",
      });

    return intl.formatMessage({ id: "general.apply", defaultMessage: "Apply" });
  };

  const handleJoinJobRequest = () => {
    const href = `${
      packagejson.homepage ? packagejson.homepage : "http://localhost:3000"
    }/publishjob/${jobId}/?auth=${jobToken}`;
    window.open(href, "_blank");
  };

  return (
    <div className={classes.root}>
      <Paper>
        <img
          src={jobImageSrc ? jobImageSrc : DefaultJobImage}
          alt="Default Job"
          className={classes.media}
        />
        <Box px={theme.spacing(2)} pb={theme.spacing(2)}>
          <div style={{ height: 70 }}>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.title}
              gutterBottom={false}
            >
              {jobTitle}
            </Typography>
            <Typography
              align="center"
              variant="subtitle2"
              style={{ marginBottom: theme.spacing(1) }}
            >
              {descreteCompany ? "" : company}
            </Typography>
          </div>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={theme.spacing(2)}
          >
            <Chip label={jobExtent} />
            <Chip icon={<LocationOnRoundedIcon />} label={city} />
            <Chip label={`${CurrencySign.symbol} ${minSalary}`} />
          </Box>
          <Countdown
            publishDate={published}
            jobDuration={parseInt(jobTimeLimit)}
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            style={{ marginTop: theme.spacing(2) }}
            disabled={!isJobAliveAndNotAnswered()}
            onClick={handleJoinJobRequest}
          >
            {buttonCaption()}
          </Button>
        </Box>
      </Paper>
    </div>
  );
}
