import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import ItMobile from "../Assets/Images/IT-MOBILE.jpg";
import PersonPinCircleRoundedIcon from "@material-ui/icons/PersonPinCircleRounded";
import WorkOutlineRoundedIcon from "@material-ui/icons/WorkOutlineRounded";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Spinner from "../UI/Spinner/Spinner";
import axios from "axios"; //axios instance;
import JobDeclinedReasons from "../components/Frontend/JobDeclinedReasons/JobDeclinedReasons";
import FullJobDetails from "../components/Frontend/FullJobDetails/FullJobDetails";
import BusinessIcon from "@material-ui/icons/Business";
import { CurrencySymbolIcon } from "../Assets/CustomIcons/CustomIconsList";
import {
  mobileVendor,
  mobileModel,
  osName,
  browserName,
  deviceType,
} from "react-device-detect";
import JobQuestionier from "../components/Frontend/JobQuestionier/JobQuestionier";
import moment from "moment";
import QuestionierCompleted from "../components/Frontend/JobQuestionier/QuestionierCompleted";
import AppBar from "../components/Frontend/AppBar/AppBar";
import {
  Typography,
  Box,
  Grid,
  Button,
  Divider,
  Container,
  Chip,
  Fade,
  Slide,
} from "@material-ui/core";
import { blueGrey } from "@material-ui/core/colors";
import { grey, green, red } from "@material-ui/core/colors";
import Countdown from "../components/Countdown/Countdown";
import {
  rebuildExperienceFQObject,
  rebuildTalentsFQObject,
  rebuildLicensesFQObject,
  rebuildAcademicDegreeFQObject,
  rebuildCertificateFQObject,
  rebuildLanguagesFQObject,
  rebuildDynamicsFQObject,
} from "../Screens/Jobs/AddJob/FilterQuestions/RebuildFilterQuestions/RebuildFilterQuestions";
import { ACCOUNT_REDIRECT_WEBSITE } from "../config/constants";
import { FormattedMessage } from "react-intl";

const styles = (theme) => ({
  root: {
    backgroundColor: "#f2f2f2",
    maxWidth: 550,
    margin: "auto",
    height: "100vh",
    "& .MuiTypography-root": {
      color: grey[900],
      "& span": {
        fontWeight: 700,
      },
    },
    "& .MuiButton-iconSizeMedium": {
      marginRight: theme.spacing(2),
    },
  },
  moreJobs: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  content: {
    padding: theme.spacing(2),
  },
  meta: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiChip-root": {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(2),
      marginBottom: theme.spacing(1),
      // backgroundColor: indigo[900],
      // color: "#fff",
      // "& .MuiChip-icon": {
      //   color: "#fff",
      // },
    },
  },
  iconData: {
    display: "flex",
    flexWrap: "wrap",
    "& svg": {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  buttons: {
    "& svg": {
      marginRight: theme.spacing(1),
    },
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonYes: {
    backgroundColor: green[500],
    color: "#fff",
    "& .MuiButton-endIcon": {
      margin: 0,
      "& .MuiSvgIcon-root": {
        marginLeft: theme.spacing(1),
        marginRight: 0,
      },
    },
    "&:hover": {
      backgroundColor: green[700],
    },
  },
  buttonNo: {
    backgroundColor: red[500],
    color: "#fff",
    "& .MuiButton-endIcon": {
      margin: 0,
      "& .MuiSvgIcon-root": {
        marginLeft: theme.spacing(1),
        marginRight: 0,
      },
    },
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  marginTopBottom: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

class PublishJobs extends React.Component {
  state = {
    jobId: -1,
    token: "",
    candidateId: -1,
    candidateFullname: "",
    authorized: false,
    isLoading: true,
    jobData: {},
    expand: false,
    declined: false,
    approved: false,
    completed: false,
    alreadyAnswered: false,
    error: null,
    filterQuestions: [],
    isJobClosed: false,
  };
  handleExpand = () => {
    this.setState((prevState, prevProps) => ({ expand: !prevState.expand }));
  };
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    for (let param of query.entries()) {
      this.setState({ token: param[1] });
      const tokenRequest = axios.get(
        `/wp-json/api/v2/candidateAuthentication/${param[1]}`,
        {
          params: {
            jobId: this.props.match.params.id,
            deviceInfo: {
              OSName: osName,
              browserName: browserName,
              DeviceType: deviceType,
              Mobile: mobileModel,
              mobileVendor,
            },
          },
        }
      );
      const candidateAlreadyAnswered = axios.get(
        `/wp-json/api/v2/already_answered/${this.props.match.params.id}`,
        {
          params: {
            token: param[1],
          },
        }
      );
      const jobIdRequest = axios.get(
        `/wp-json/api/v2/job/${this.props.match.params.id}`
      );

      axios
        .all([tokenRequest, jobIdRequest, candidateAlreadyAnswered])
        .then(
          axios.spread((...responses) => {
            let publishDate = moment(
              responses[1].data.published,
              "YYYY-MM-DD h:mm:ss a"
            );
            publishDate = moment(publishDate)
              .add(parseInt(responses[1].data.job_time_limit), "hours")
              .format("YYYY-MM-DD h:mm:ss a");
            const rawExperienceFQ = responses[1].data.filter_questions.filter(
              (fqItem) => fqItem.fq_type === "experience"
            );
            const rawAcademicFQ = responses[1].data.filter_questions.filter(
              (fqItem) => fqItem.fq_type === "academicDegree"
            );
            const rawLicensesFQ = responses[1].data.filter_questions.filter(
              (fqItem) => fqItem.fq_type === "license"
            );
            const rawTalentsFQ = responses[1].data.filter_questions.filter(
              (fqItem) => fqItem.fq_type === "talents"
            );
            const rawCertificatesFQ = responses[1].data.filter_questions.filter(
              (fqItem) => fqItem.fq_type === "certificate"
            );
            const rawLanguagesFQ = responses[1].data.filter_questions.filter(
              (fqItem) => fqItem.fq_type === "languages"
            );
            const rawDynamicFilterQuestions =
              responses[1].data.dynamic_filter_questions;
            console.log(
              "PB",
              publishDate,
              responses[1].data.server_time,
              moment(publishDate, "YYYY-MM-DD h:mm:ss a").isBefore(
                moment(responses[1].data.server_time, "YYYY-MM-DD h:mm:ss a")
              )
            );
            this.setState({
              isLoading: false,
              jobId: this.props.match.params.id,
              candidateId: responses[0].data.id,
              candidateFullname: responses[0].data.fullname,
              authorized: true,
              jobData: responses[1].data,
              alreadyAnswered: parseInt(responses[2].data) === 0 ? false : true,
              isJobClosed: moment(publishDate).isBefore(
                moment(responses[1].data.server_time, "YYYY-MM-DD h:mm:ss a")
              ),
              // check if the job is closed:
              filterQuestions: [
                {
                  experience: rawExperienceFQ.length
                    ? rebuildExperienceFQObject(rawExperienceFQ)
                    : [],
                },
                {
                  academicDegrees: rawAcademicFQ.length
                    ? rebuildAcademicDegreeFQObject(rawAcademicFQ)
                    : [],
                },
                {
                  licenses: rawLicensesFQ.length
                    ? rebuildLicensesFQObject(rawLicensesFQ)
                    : [],
                },
                { skills: rebuildTalentsFQObject(rawTalentsFQ) },
                {
                  certificates: rawCertificatesFQ.length
                    ? rebuildCertificateFQObject(rawCertificatesFQ)
                    : [],
                },
                {
                  languages: rawLanguagesFQ.length
                    ? rebuildLanguagesFQObject(rawLanguagesFQ)
                    : [],
                },
                {
                  dynamics: rawDynamicFilterQuestions.length
                    ? rebuildDynamicsFQObject(rawDynamicFilterQuestions)
                    : [],
                },
              ],
            });
          })
        )
        .catch((errors) => {
          // react on errors.
          this.setState({ isLoading: false, error: errors });
        });
    }
  }
  handleDeclinedAnswers = (declinedReason, declinedOther) => {
    //get all declined answers and send to db
    let profileUpdate = "";
    switch (declinedReason) {
      case "salary":
        profileUpdate = this.state.jobData.maximum_salary;
        break;
      case "location":
        profileUpdate = this.state.jobData.city;
        break;
      default:
        profileUpdate = "";
        break;
    }
    axios
      .get(`/wp-json/api/v2/declinedJob/${this.props.match.params.id}`, {
        params: {
          token: this.state.token,
          reason: declinedReason,
          other: declinedOther ? declinedOther : "",
          profile: profileUpdate,
        },
      })
      .then((res) => {
        this.setState({
          completed: true,
          alreadyAnswered: true,
          isLoading: false,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false, error: err });
      });
  };
  handleBackFromFullDetails = () => {
    this.setState({ expand: false });
  };

  handleCancelDeclineClick = () => {
    this.setState({ declined: false });
  };

  handleQuestionierCompleted = (questionier) => {
    axios
      .get(
        `/wp-json/api/v2/questionier_answers/${this.props.match.params.id}`,
        {
          params: {
            token: this.state.token,
            questionier,
          },
        }
      )
      .then((res) => console.log(res.data))
      .catch((err) => {
        this.setState({ isLoading: false, error: err });
      });
  };
  render() {
    const { classes } = this.props;
    let answersRender = null;
    if (this.state.declined && !this.state.completed) {
      answersRender = (
        <JobDeclinedReasons
          declined={this.state.declined}
          cancelDecline={this.handleCancelDeclineClick}
          answered={this.handleDeclinedAnswers}
          fullname={this.state.candidateFullname}
        />
      );
    } else if (this.state.declined && this.state.completed) {
      answersRender = (
        <QuestionierCompleted
          fullname={this.state.candidateFullname}
          declinedCompleted
        />
      );
    } else {
      answersRender = (
        <JobQuestionier
          filterQuestions={this.state.filterQuestions}
          jobData={this.state.jobData}
          candidateFullname={this.state.candidateFullname}
          professionsTranslation={this.state.jobData.professions_translation}
          candidateAnswers={this.handleQuestionierCompleted}
        />
      );
    }

    return (
      <React.Fragment>
        <Grid item xs={12} className={classes.root}>
          <AppBar />
          {!this.state.expand &&
          !this.state.declined &&
          !this.state.approved ? (
            <Fade in={true} mountOnEnter unmountOnExit>
              <Box>
                <img
                  src={ItMobile}
                  style={{ width: "100%" }}
                  alt="job-decoration"
                />
              </Box>
            </Fade>
          ) : null}
          {this.state.declined || this.state.approved ? (
            answersRender
          ) : (
            <div className={classes.content}>
              {this.state.isLoading ? (
                <Spinner open={this.state.isLoading} />
              ) : (
                <React.Fragment>
                  {this.state.error ? (
                    <Alert severity="error">
                      <AlertTitle>
                        <FormattedMessage
                          id="publishScreen.main.error"
                          defaultMessage="On no! we have an error!"
                        />
                      </AlertTitle>
                      <strong>
                        <FormattedMessage
                          id="common.error.details"
                          defaultMessage="Error Details"
                        />
                      </strong>
                      <Typography variant="body1">
                        {this.state.error.response ? (
                          this.state.error.response.status
                        ) : (
                          <FormattedMessage
                            id="common.error.unknown"
                            defaultMessage="Error Unknown"
                          />
                        )}
                      </Typography>
                    </Alert>
                  ) : null}
                  <Fade in={true} mountOnEnter unmountOnExit>
                    <div>
                      <Typography
                        variant="h5"
                        align="center"
                        style={{ color: blueGrey[900] }}
                      >
                        {this.state.jobData.title}
                      </Typography>

                      <div className={classes.meta}>
                        <Chip
                          icon={<PersonPinCircleRoundedIcon />}
                          label={this.state.jobData.city}
                          clickable={false}
                        />
                        <Chip
                          icon={<WorkOutlineRoundedIcon />}
                          label={this.state.jobData.job_extent.value}
                          clickable={false}
                        />
                        {!this.state.jobData.discrete_company ? (
                          <Chip
                            icon={<BusinessIcon />}
                            label={this.state.jobData.company.name}
                            clickable={false}
                          />
                        ) : null}
                        {!this.state.jobData.discrete_salary ? (
                          <Chip
                            icon={<CurrencySymbolIcon />}
                            label={this.state.jobData.maximum_salary}
                            clickable={false}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Fade>
                  {this.state.expand ? (
                    <FullJobDetails
                      back={this.handleExpand}
                      jobData={this.state.jobData}
                    />
                  ) : (
                    <Slide
                      direction="left"
                      in={!this.state.expand}
                      mountOnEnter
                      unmountOnExit
                    >
                      <div className={classes.marginTopBottom}>
                        <Box>
                          <Typography variant="body1" align="center">
                            <FormattedMessage
                              id="publishScreen.welcome"
                              defaultMessage="Welcome Back, <b>{fullname}</b>"
                              values={{
                                fullname: this.state.candidateFullname,
                              }}
                            />
                          </Typography>
                          <Typography variant="body1" align="center">
                            <FormattedMessage
                              id="publishScreen.suitableJobForYou"
                              defaultMessage="We thought this job might be excatly for you"
                            />
                          </Typography>
                        </Box>
                        <Box
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <Button
                            color="secondary"
                            endIcon={<ExpandMoreRoundedIcon />}
                            onClick={this.handleExpand}
                          >
                            <FormattedMessage
                              id="common.moreDetails"
                              defaultMessage="More Details"
                            />
                          </Button>
                        </Box>
                      </div>
                    </Slide>
                  )}
                  <Divider />
                  {!this.state.alreadyAnswered ? (
                    <React.Fragment>
                      <div className={classes.marginTopBottom}>
                        <Countdown
                          publishDate={this.state.jobData.published}
                          jobDuration={parseInt(
                            this.state.jobData.job_time_limit
                          )}
                        />
                      </div>
                      <Divider />
                    </React.Fragment>
                  ) : null}

                  {this.state.alreadyAnswered ? (
                    <React.Fragment>
                      <Typography variant="h5" align="center">
                        <FormattedMessage
                          id="publishScreen.alreadyAnsweredMessage"
                          defaultMessage="We already got your answers. If there is a match, we will contact you soon"
                        />
                      </Typography>
                      <div className={classes.moreJobs}>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() =>
                            window.open(
                              ACCOUNT_REDIRECT_WEBSITE.toString(),
                              "_blank"
                            )
                          }
                        >
                          <FormattedMessage
                            id="publishScreen.questionier.completed.seachMoreJobs"
                            defaultMessage="other jobs"
                          />
                        </Button>
                      </div>
                    </React.Fragment>
                  ) : (
                    <Container className={classes.footer} disableGutters={true}>
                      <Box className={classes.buttons}>
                        <Button
                          variant="contained"
                          className={classes.buttonYes}
                          disabled={
                            this.state.isJobClosed || this.state.alreadyAnswered
                          }
                          endIcon={<DoneRoundedIcon />}
                          onClick={() => this.setState({ approved: true })}
                        >
                          <FormattedMessage
                            id="publishScreen.interested"
                            defaultMessage="I'm Interested"
                          />
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.buttonNo}
                          disabled={
                            this.state.isJobClosed || this.state.alreadyAnswered
                          }
                          endIcon={<CloseRoundedIcon />}
                          onClick={() => this.setState({ declined: true })}
                        >
                          <FormattedMessage
                            id="publishScreen.notInterested"
                            defaultMessage="Less, thanks"
                          />
                        </Button>
                      </Box>
                    </Container>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
export default withStyles(styles, { withTheme: true })(withRouter(PublishJobs));
