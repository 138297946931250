import React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "@material-ui/core";

let StarIcon = ({ strokeColor, ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M11.7758 2.14891C11.8675 1.9631 12.1325 1.9631 12.2242 2.14891L14.6215 7.00638C14.8764 7.52288 15.3691 7.88088 15.9391 7.9637L21.2997 8.74263C21.5047 8.77243 21.5866 9.02443 21.4382 9.16906L17.5593 12.9501C17.1469 13.3521 16.9586 13.9314 17.056 14.4991L17.9717 19.8379C18.0067 20.0422 17.7924 20.1979 17.609 20.1015L12.8143 17.5808C12.3045 17.3128 11.6955 17.3128 11.1857 17.5808L6.39105 20.1015C6.20764 20.1979 5.99328 20.0422 6.02831 19.8379L6.94399 14.4991C7.04136 13.9314 6.85315 13.3521 6.4407 12.9501L2.56178 9.16906C2.4134 9.02442 2.49528 8.77243 2.70033 8.74263L8.06087 7.9637C8.63086 7.88088 9.1236 7.52288 9.37851 7.00638L11.7758 2.14891Z"
      stroke={strokeColor ? strokeColor : "#363740"}
      strokeWidth="1.5"
    />
  </SvgIcon>
);
StarIcon = pure(StarIcon);
StarIcon.displayName = "StarIcon";
StarIcon.muiName = "SvgIcon";

export default StarIcon;
