import React from "react";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
}));

const AddCancelButton = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div style={{ marginTop: theme.spacing(2) }}>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={props.addIcon ? props.addIcon : <Icon>add</Icon>}
        onClick={props.addClick}
      >
        {props.addCaption ? props.addCaption : "Add"}
      </Button>
      <Button
        variant="outlined"
        color="primary"
        className={classes.cancel}
        onClick={props.cancelClick}
      >
        {props.cancelCaption ? props.cancelCaption : "Cancel"}
      </Button>
    </div>
  );
};
export default AddCancelButton;
