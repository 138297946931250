import React from "react";
import EditRow from "../../CandidateComponents/EditRow/EditRow";
import AddingRow from "../../CandidateComponents/AddingRow/AddingRow";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  Container,
  CircularProgress,
} from "@material-ui/core";
import {
  GenderList,
  FamilyStatusList,
} from "../../../../config/candidatesConfigData";
import {
  AI_ACADEMIC_DGREE,
  AI_CERTIFICATES,
  AI_LANGUAGES,
  AI_LICENSES,
  DEGREE_TYPES,
  AI_SALARY_EXPECTATIONS,
} from "../../../../config/constants";
import {
  translateDegreeAndType,
  reverseDegreeTranslation,
} from "../../../../config/jobsConfigData";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useIntl, FormattedMessage } from "react-intl";

const degreeValues = () => {
  const degrees = Object.keys(DEGREE_TYPES);
  const degreeValues = degrees.map((deg) => translateDegreeAndType(deg));
  return degreeValues;
};

const styles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      borderRadius: 16,
    },
    "& .MuiTypography-h6": {
      marginBottom: theme.spacing(1),
    },
    marginTop: 66,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function getNextId(objArr, idField) {
  if (!objArr || objArr.length === 0) return 1;
  return (
    objArr.reduce(
      (max, p) => (p[idField] > max ? parseInt(p[idField]) : parseInt(max)),
      parseInt(objArr[0][idField])
    ) + 1
  );
}

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const AboutMe = ({ data, dataUpdate, dataRemove }) => {
  const theme = useTheme();
  const classes = styles();
  const intl = useIntl();
  const [firstName, setFirstName] = React.useState(data.firstName);
  const [lastName, setLastName] = React.useState(data.lastName);
  const [email, setEmail] = React.useState(data.email);
  const [identifierNumber, setIdentifierNumber] = React.useState(
    data.identifierNumber
  );
  const [mobile, setMobile] = React.useState(data.mobile);
  const [yearOfBirth, setYearOfBirth] = React.useState(data.yearOfBirth);
  const [address, setAddress] = React.useState(data.address);
  const [city, setCity] = React.useState(data.city);
  const [gender, setGender] = React.useState(data.gender);
  const [familyStatus, setFamilyStatus] = React.useState(data.family_status);
  const [fieldsUpdated, setFieldsUpdated] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [degrees, setDegrees] = React.useState([]);
  const [certificates, setCertificates] = React.useState([]);
  const [licenses, setLicenses] = React.useState([]);
  const [languages, setLanguages] = React.useState([]);
  const [salaryExpectations, setSalaryExpectations] = React.useState([]);

  React.useEffect(() => {
    const academicDegressIndex = data.additional_information.findIndex(
      (ai_data) => Object.keys(ai_data)[0] === "academicDegrees"
    );
    const certIndex = data.additional_information.findIndex(
      (ai_data) => Object.keys(ai_data)[0] === AI_CERTIFICATES
    );
    const licenseIndex = data.additional_information.findIndex(
      (ai_data) => Object.keys(ai_data)[0] === AI_LICENSES
    );

    const languageIndex = data.additional_information.findIndex(
      (ai_data) => Object.keys(ai_data)[0] === AI_LANGUAGES
    );

    const salaryExpectationsIndex = data.additional_information.findIndex(
      (ai_data) => Object.keys(ai_data)[0] === AI_SALARY_EXPECTATIONS
    );

    setDegrees(
      academicDegressIndex > -1
        ? data.additional_information[academicDegressIndex].academicDegrees
        : []
    );
    setCertificates(
      certIndex > -1 ? data.additional_information[certIndex].certificates : []
    );

    setLicenses(
      licenseIndex > -1
        ? data.additional_information[licenseIndex].licenses
        : []
    );

    setLanguages(
      languageIndex > -1
        ? data.additional_information[languageIndex].languages
        : []
    );

    setSalaryExpectations(
      salaryExpectationsIndex > -1
        ? data.additional_information[salaryExpectationsIndex]
            .salaryExpectations
        : []
    );
  }, [data]);

  React.useEffect(() => {
    if (fieldsUpdated.length > 0) {
      handeUpdateDetails();
      setFieldsUpdated([]);
    }
  }, [fieldsUpdated]);

  const handleUpdate = (field, value) => {
    switch (field) {
      case "fullname":
        //split by space:
        if (value.includes(" ")) {
          const index = value.indexOf(" ");
          setFirstName(value.substr(0, index));
          setLastName(value.substr(index + 1, value.length));
        } else {
          setFirstName(value);
        }
        break;
      case "email":
        setEmail(value);
        break;
      case "identifierNumber":
        setIdentifierNumber(value);
        break;
      case "mobile":
        setMobile(value);
        break;
      case "yearOfBirth":
        setYearOfBirth(value);
        break;
      case "address":
        setAddress(value);
        break;
      case "city":
        setCity(value);
        break;
      case "gender":
        setGender(getKeyByValue(GenderList, value));
        break;
      case "family_status":
        setFamilyStatus(getKeyByValue(FamilyStatusList, value));
        break;
      case "academic_degree":
        //check if this is an edit or new:
        if (value.id > -1) {
          const localDegreeIndex = degrees.findIndex(
            (deg) => deg.degreeId === value.id
          );
          const localDegrees = degrees;
          const editableDegree = {
            degreeId: parseInt(value.id),
            degreeType: reverseDegreeTranslation(value.type),
            degreeOn: value.value,
          };
          localDegrees[localDegreeIndex] = editableDegree;
          setDegrees(localDegrees);
        } else {
          //get the next id:
          const newDegree = {
            degreeId: getNextId(degrees, "degreeId"),
            degreeType: reverseDegreeTranslation(value.type),
            degreeOn: value.value,
          };
          setDegrees([...degrees, newDegree]);
        }
        //handeUpdateDetails();
        break;
      case "certificate":
        if (value.id > -1) {
          const localCertificateIndex = certificates.findIndex(
            (cert) => cert.certificateId === value.id
          );
          const localCertficates = certificates;
          const editableCertificate = {
            certificateId: parseInt(value.id),
            certificateName: value.value,
          };
          localCertficates[localCertificateIndex] = editableCertificate;
          setCertificates(localCertficates);
        } else {
          //get the next id:
          const newCertificate = {
            certificateId: getNextId(certificates, "certificateId"),
            certificateName: value.value,
          };
          setCertificates([...certificates, newCertificate]);
        }

        break;
      case "license":
        if (value.id > -1) {
          const localLicenseIndex = licenses.findIndex(
            (license) => license.licenseId === value.id
          );
          const localLicenses = licenses;
          const editableLicense = {
            licenseId: parseInt(value.id),
            licenseName: value.type,
            licenseYears: value.value,
          };
          localLicenses[localLicenseIndex] = editableLicense;
          setLicenses(localLicenses);
        } else {
          //get the next id:
          const newLicense = {
            licenseId: getNextId(licenses, "licenseId"),
            licenseName: value.type,
            licenseYears: value.value,
          };
          setLicenses([...licenses, newLicense]);
        }
        break;
      case "language":
        if (value.id > -1) {
          const localLanguageIndex = languages.findIndex(
            (lang) => lang.languageId === value.id
          );
          const localLanguage = languages;
          const editableLanguage = {
            languageId: parseInt(value.id),
            languageName: value.type,
            languageLevel: value.value,
          };
          localLanguage[localLanguageIndex] = editableLanguage;
          setLanguages(localLanguage);
        } else {
          //get the next id:
          const newLanguage = {
            languageId: getNextId(languages, "languageId"),
            languageName: value.type,
            languageLevel: value.value,
          };
          setLanguages([...languages, newLanguage]);
        }
        break;
      case "salaryExpectation":
        if (value.id > -1) {
          const localSEIndex = salaryExpectations.findIndex(
            (salary) => salary.seId === value.id
          );
          const localSE = salaryExpectations;
          const editableSE = {
            seId: parseInt(value.id),
            seMeasure: value.type,
            seForRole: "",
            seIsRange: "false",
            seRange: value.value,
          };
          localSE[localSEIndex] = editableSE;
          setSalaryExpectations(localSE);
        } else {
          //get the next id:
          const newSe = {
            seId: getNextId(salaryExpectations, "seId"),
            seMeasure: value.type,
            seForRole: "",
            seIsRange: "false",
            seRange: value.value,
          };
          setSalaryExpectations([...salaryExpectations, newSe]);
        }
        break;
      default:
        break;
    }
    if (!fieldsUpdated.includes(field)) {
      setFieldsUpdated([...fieldsUpdated, field]);
    }
  };

  const handeUpdateDetails = async () => {
    //generate field=value pairs for server update:
    let updates = fieldsUpdated.map((field) => {
      switch (field) {
        case "fullname":
          //split by space:
          return [{ firstName: firstName }, { lastName: lastName }];
        case "email":
          return { [field]: email };
        case "identifierNumber":
          return { [field]: identifierNumber };
        case "mobile":
          return { [field]: mobile };
        case "yearOfBirth":
          return { year_of_birth: yearOfBirth };
        case "address":
          return { [field]: address };
        case "city":
          return { [field]: city };
        case "gender":
          return { [field]: gender };
        case "family_status":
          return { [field]: familyStatus };
        case "academic_degree":
          return { [field]: degrees };
        case "certificate":
          return { [field]: certificates };
        case "license":
          return { [field]: licenses };
        case "language":
          return { [field]: languages };
        case "salaryExpectation":
          return { [field]: salaryExpectations };
        default:
          break;
      }
    });
    dataUpdate(updates);
  };

  return (
    <Container maxWidth="lg">
      <Grid container className={classes.root}>
        <Grid item xl={12} xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={theme.spacing(2)}
          >
            <Typography variant="h2">
              <FormattedMessage
                id="candidateProfile.updateDetails"
                defaultMessage="Update Details"
              />
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handeUpdateDetails}
              disabled={fieldsUpdated.length === 0 || isLoading}
              style={{ position: "relative" }}
            >
              <FormattedMessage id="general.update" defaultMessage="Update" />
              {isLoading && (
                <CircularProgress
                  color="inherit"
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="candidateProfile.aboutMe.personalDetails.title"
                defaultMessage="Personal Details"
              />
            </Typography>
            <Paper>
              <EditRow
                label={intl.formatMessage({
                  id: "candidateProfile.aboutMe.personalDetails.fullName",
                  defaultMessage: "Full Name",
                })}
                value={`${firstName} ${lastName}`}
                update={(value) => handleUpdate("fullname", value)}
              />
              <EditRow
                label={intl.formatMessage({
                  id: "candidates.add.email",
                  defaultMessage: "Email",
                })}
                value={email}
                type="email"
                update={(value) => handleUpdate("email", value)}
                validateType={"email"}
              />
              <EditRow
                label={intl.formatMessage({
                  id: "common.id",
                  defaultMessage: "ID",
                })}
                value={identifierNumber}
                type={"number"}
                update={(value) => handleUpdate("identifierNumber", value)}
                validateType={"id"}
              />
              <EditRow
                label={intl.formatMessage({
                  id: "candidates.add.mobileNumber",
                  defaultMessage: "Mobile Number",
                })}
                value={mobile}
                type="tel"
                update={(value) => handleUpdate("mobile", value)}
                validateType={"telephone_main"}
                noborder
              />
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="common.generalDetails"
                defaultMessage="General Details"
              />
            </Typography>
            <Paper>
              <EditRow
                label={intl.formatMessage({
                  id: "candidates.add.yearOfBirth",
                  defaultMessage: "Year Of Birth",
                })}
                value={`${yearOfBirth}`}
                type="number"
                update={(value) => handleUpdate("yearOfBirth", value)}
                validateType={"year"}
              />
              <EditRow
                label={intl.formatMessage({
                  id: "candidates.add.address",
                  defaultMessage: "Address",
                })}
                value={`${address}`}
                update={(value) => handleUpdate("address", value)}
              />
              <EditRow
                label={intl.formatMessage({
                  id: "candidates.add.city",
                  defaultMessage: "City",
                })}
                value={city}
                update={(value) => handleUpdate("city", value)}
              />
              <EditRow
                label={intl.formatMessage({
                  id: "common.gender",
                  defaultMessage: "Gender",
                })}
                value={GenderList[gender]}
                list={Object.values(GenderList)}
                update={(value) => handleUpdate("gender", value)}
              />
              <EditRow
                label={intl.formatMessage({
                  id: "candidates.add.familyStatus",
                  defaultMessage: "Family Status",
                })}
                value={FamilyStatusList[familyStatus]}
                list={Object.values(FamilyStatusList)}
                update={(value) => handleUpdate("family_status", value)}
                noborder
              />
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="candidateProfile.aboutMe.educationCertifications.title"
                defaultMessage="Education & Certificates"
              />
            </Typography>
            <Paper>
              {degrees.map((degree) => {
                return (
                  <AddingRow
                    placeholder={intl.formatMessage({
                      id: "candidateProfile.aboutMe.degree.placeholder",
                      defaultMessage: "My degree is in...",
                    })}
                    key={degree.degreeId.toString()}
                    label={intl.formatMessage({
                      id: "jobs.fq.academicDegree",
                      defaultMessage: "Academic Degree",
                    })}
                    editLabel={intl.formatMessage({
                      id: "jobs.fq.academicDegree",
                      defaultMessage: "Academic Degree",
                    })}
                    value={{
                      id: degree.degreeId,
                      type: translateDegreeAndType(degree.degreeType),
                      value: degree.degreeOn,
                    }}
                    list={degreeValues()}
                    update={(value) => handleUpdate("academic_degree", value)}
                    remove={(value) => dataRemove("academic_degree", value)}
                    rowType={AI_ACADEMIC_DGREE}
                  />
                );
              })}
              {certificates.map((cert) => {
                return (
                  <AddingRow
                    placeholder={intl.formatMessage({
                      id: "candidateProfile.aboutMe.certificate.add",
                      defaultMessage: "I have a certificate in...",
                    })}
                    key={cert.certificateId.toString()}
                    label={intl.formatMessage({
                      id: "jobs.fq.cert.add",
                      defaultMessage: "Add Certificate",
                    })}
                    editLabel={intl.formatMessage({
                      id: "jobs.fq.certificates.singular",
                      defaultMessage: "Certificate",
                    })}
                    value={{
                      id: cert.certificateId,
                      value: cert.certificateName,
                    }}
                    list={degreeValues()}
                    update={(value) => handleUpdate("certificate", value)}
                    remove={(value) => dataRemove(AI_CERTIFICATES, value)}
                    rowType={AI_CERTIFICATES}
                  />
                );
              })}
              <AddingRow
                placeholder={intl.formatMessage({
                  id: "candidateProfile.aboutMe.degree.placeholder",
                  defaultMessage: "My degree is in...",
                })}
                label={intl.formatMessage({
                  id: "jobs.fq.academicDegree.add.short",
                  defaultMessage: "Add Academic Degree",
                })}
                editLabel={intl.formatMessage({
                  id: "jobs.fq.academicDegree",
                  defaultMessage: "Academic Degree",
                })}
                list={degreeValues()}
                update={(value) => handleUpdate("academic_degree", value)}
                emptyMode
                rowType={AI_ACADEMIC_DGREE}
              />
              <AddingRow
                placeholder={intl.formatMessage({
                  id: "candidateProfile.aboutMe.certificate.add",
                  defaultMessage: "I have a certificate in...",
                })}
                label={intl.formatMessage({
                  id: "jobs.fq.cert.add",
                  defaultMessage: "Add Certificate",
                })}
                editLabel={intl.formatMessage({
                  id: "jobs.fq.certificates.singular",
                  defaultMessage: "Certificate",
                })}
                update={(value) => handleUpdate("certificate", value)}
                rowType={AI_CERTIFICATES}
                emptyMode
                noborder
              />
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="candidateProfile.aboutMe.salaryExpectations.title"
                defaultMessage="My Salary Expectations"
              />
            </Typography>
            <Paper>
              {salaryExpectations.map((salary) => {
                return (
                  <AddingRow
                    placeholder={intl.formatMessage({
                      id:
                        "candidateProfile.aboutMe.salaryExpectations.title.placeholder",
                      defaultMessage: "I'm expecting a salary of at least...",
                    })}
                    key={salary.seId.toString()}
                    label={intl.formatMessage({
                      id: "jobs.fq.salaryExpectations",
                      defaultMessage: "Salary Expectations",
                    })}
                    editLabel={intl.formatMessage({
                      id: "candidateProfile.aboutMe.salaryExpectations.title",
                      defaultMessage: "My Salary Expectations",
                    })}
                    value={{
                      id: salary.seId,
                      type: salary.seMeasure,
                      value: salary.seRange,
                    }}
                    update={(value) => handleUpdate("salaryExpectation", value)}
                    remove={(value) =>
                      dataRemove(AI_SALARY_EXPECTATIONS, value)
                    }
                    rowType={AI_SALARY_EXPECTATIONS}
                  />
                );
              })}
              {salaryExpectations.length === 0 && (
                <AddingRow
                  placeholder={intl.formatMessage({
                    id:
                      "candidateProfile.aboutMe.salaryExpectations.title.placeholder",
                    defaultMessage: "I'm expecting a salary of at least...",
                  })}
                  label={intl.formatMessage({
                    id: "jobs.fq.salaryExpectations",
                    defaultMessage: "Salary Expectations",
                  })}
                  editLabel={intl.formatMessage({
                    id: "candidateProfile.aboutMe.salaryExpectations.title",
                    defaultMessage: "My Salary Expectations",
                  })}
                  update={(value) => handleUpdate("salaryExpectation", value)}
                  rowType={AI_SALARY_EXPECTATIONS}
                  emptyMode
                  noborder
                />
              )}
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="jobs.fq.licenses"
                defaultMessage="Licenses"
              />
            </Typography>
            <Paper>
              {licenses.map((license) => {
                return (
                  <AddingRow
                    placeholder={intl.formatMessage({
                      id: "candidateProfile.aboutMe.license.placeholder",
                      defaultMessage: "I have a license for...",
                    })}
                    key={license.licenseId.toString()}
                    label={intl.formatMessage({
                      id: "jobs.fq.licenses.singular",
                      defaultMessage: "License",
                    })}
                    editLabel={intl.formatMessage({
                      id: "jobs.fq.licenses.singular",
                      defaultMessage: "License",
                    })}
                    value={{
                      id: license.licenseId,
                      type: license.licenseName,
                      value: license.licenseYears,
                    }}
                    update={(value) => handleUpdate("license", value)}
                    remove={(value) => dataRemove(AI_LICENSES, value)}
                    rowType={AI_LICENSES}
                  />
                );
              })}
              <AddingRow
                placeholder={intl.formatMessage({
                  id: "candidateProfile.aboutMe.license.placeholder",
                  defaultMessage: "I have a license for...",
                })}
                label={intl.formatMessage({
                  id: "jobs.fq.licenses.singular",
                  defaultMessage: "License",
                })}
                editLabel={intl.formatMessage({
                  id: "jobs.fq.licenses.singular",
                  defaultMessage: "License",
                })}
                update={(value) => handleUpdate("license", value)}
                rowType={AI_LICENSES}
                emptyMode
                noborder
              />
            </Paper>
          </Box>
          <Box my={theme.spacing(4)}>
            <Typography variant="h6" color="secondary">
              <FormattedMessage
                id="jobs.fq.languages"
                defaultMessage="Languages"
              />
            </Typography>
            <Paper>
              {languages.map((lang) => {
                return (
                  <AddingRow
                    placeholder={`${intl.formatMessage({
                      id: "jobs.fq.languages.singular",
                      defaultMessage: "Language",
                    })}...`}
                    key={lang.languageId.toString()}
                    label={intl.formatMessage({
                      id: "candidate.addLanguage",
                      defaultMessage: "Add Language",
                    })}
                    editLabel={intl.formatMessage({
                      id: "jobs.fq.languages.singular",
                      defaultMessage: "Language",
                    })}
                    value={{
                      id: lang.languageId,
                      type: lang.languageName,
                      value: lang.languageLevel,
                    }}
                    update={(value) => handleUpdate("language", value)}
                    remove={(value) => dataRemove(AI_LANGUAGES, value)}
                    rowType={AI_LANGUAGES}
                  />
                );
              })}
              <AddingRow
                placeholder={`${intl.formatMessage({
                  id: "jobs.fq.languages.singular",
                  defaultMessage: "Language",
                })}...`}
                label={intl.formatMessage({
                  id: "candidate.addLanguage",
                  defaultMessage: "Add Language",
                })}
                editLabel={intl.formatMessage({
                  id: "jobs.fq.languages.singular",
                  defaultMessage: "Language",
                })}
                update={(value) => handleUpdate("language", value)}
                rowType={AI_LANGUAGES}
                emptyMode
                noborder
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AboutMe;
