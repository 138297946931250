import React from "react";

export function profileAgeGender(year, gender, city, mobile) {
  let genderStr = "";
  if (gender !== null && gender !== "") {
    if (gender.trim().toLowerCase() === "female") genderStr = "Female ";
    else if (gender.trim().toLowerCase() === "male") genderStr = "Male ";
    else genderStr = "";
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: `${city ? city : ""} &bull; ${genderStr} ${(
          new Date().getFullYear() - parseInt(year ? year : 0)
        ).toString()} &bull; ${mobile ? mobile : ""}`,
      }}
    />
  );
}

export function profileAgeGenderNew(gender, year) {
  let genderStr = "";
  if (gender) {
    if (gender.trim().toLowerCase() === "female") genderStr = "Female ";
    else if (gender.trim().toLowerCase() === "male") genderStr = "Male ";
  }

  if (year && parseInt(year) > 1900)
    genderStr += " " + new Date().getFullYear() - parseInt(year);

  return genderStr;
}
