import React from "react";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import {
  Grid,
  Paper,
  Typography,
  Box,
  useTheme,
  Tooltip,
  Fab,
  Collapse,
  Container,
  Divider,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Button,
  Snackbar,
  Link,
  IconButton,
} from "@material-ui/core";
import ConfigurationListsContext from "../../context/configurationListsContext";
import DialogYesNo from "../../components/DialogYesNo/DialogYesNo";
import {
  FamilyStatusList,
  GenderList,
} from "../../config/candidatesConfigData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import SearchJob from "../../components/SearchJob/SearchJob";
import Tags from "../../components/Tags/Tags";
import AddIcon from "@material-ui/icons/Add";
import LibiExploreResults from "./LibiExploreResults";
import MyExploreSearches from "./MyExploreSearches";
import Spinner from "../../UI/Spinner/Spinner";
import PeriodSelection from "../../components/PeriodSelection/PeriodSelection";
import StyledRating from "../../components/StyledRating/StyledRating";
import {
  MenuJobsIcon,
  MenuCandidatesIcon,
  ArrowUpDownIcon,
  ContinueIcon,
} from "../../Assets/CustomIcons/CustomIconsList";
import StarIcon from "../../Assets/CustomIcons/StarIcon";
import axios from "axios";
import EditExploreSearch from "./EditExploreSearch";
import AxiosErrorHandling from "../../components/AxiosErrorHandling/AxiosErrorHandling";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
    },
    "& .MuiButton-text": {
      color: "#636575",
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(4),
  },
  gridRow: {
    marginTop: theme.spacing(2),
  },
  filterByButtons: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-contained": {
      backgroundColor: "#F5F6F8",
    },
  },
}));

const Explore = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [error, setError] = React.useState(null);
  const [familyStatusList, setFamilyStatusList] = React.useState([]);
  const [selectedArea, setSelectedArea] = React.useState([]);
  const [filterBy, setFilterBy] = React.useState("candidates"); //either candidates or jobs
  const [addSearch, setAddSearch] = React.useState(true);
  const [ageFrom, setAgeFrom] = React.useState(16);
  const [ageTo, setAgeTo] = React.useState(67);
  // const [includeWithoutRating, setIncludeWithoutRating] = React.useState(false);
  const [selectedFamilyStatus, setSelectedFamilyStatus] = React.useState("");
  const [selectedGender, setSelectedGender] = React.useState("");
  const [candidateTags, setCandidateTags] = React.useState([]);
  const [selectedProfessions, setSelectedProfessions] = React.useState([]);
  const [candidatesFound, setCandidatesFound] = React.useState([]);
  const [selectedRating, setSelectedRating] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [initialLoading, setInitialLoading] = React.useState(false);
  const [totalFound, setTotalFound] = React.useState(0);
  const [editSearch, setEditSearch] = React.useState(-1);
  const [savedSearches, setSavedSearches] = React.useState([]);
  const [searchUpdated, setSearchUpdated] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(-1); //if greater than 0 - show confirm dialog box to delete the id
  const [searchFromSave, setSearchFromSave] = React.useState(false);
  const [selectedPeriod, setSelectedPeriod] = React.useState("allTimes");
  const [page, setPage] = React.useState(1);
  const perPage = React.useRef(20);
  const [reportPeriod, setReportPeriod] = React.useState({
    from: moment().subtract(7, "days").format("YYYY/MM/DD"),
    to: moment().format("YYYY/MM/DD"),
  });
  const [customDateFrom, setCustomDateFrom] = React.useState(
    moment().subtract(1, "days").format("YYYY/MM/DD")
  );
  const [customDateTo, setCustomDateTo] = React.useState(
    moment().format("YYYY/MM/DD")
  );

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    let localPeriod = {
      from: moment().subtract(7, "days").format("YYYY/MM/DD"),
      to: moment().format("YYYY/MM/DD"),
    };
    switch (event.target.value) {
      case "today":
        localPeriod = {
          from: moment().format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "yesterday":
        localPeriod = {
          from: moment().subtract(1, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "last7":
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last28":
        localPeriod = {
          from: moment().subtract(28, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last90":
        localPeriod = {
          from: moment().subtract(90, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "thisYear":
        localPeriod = {
          from: moment(`${moment().year()}/1/1`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
          to: moment(`${moment().year()}/12/31`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
        };
        break;
      case "allTimes": {
        localPeriod = {
          from: moment(1900 / 1 / 1, "YYYY/MM/DD").format("YYYY/MM/DD"),
          to: moment(2099 / 1 / 1, "YYYY/MM/DD").format("YYYY/MM/DD"),
        };
        break;
      }
      default:
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
    }
    setReportPeriod(localPeriod);
  };

  const generateAgeList = (fromAge = 16, ageTo = 67) => {
    const ages = [];
    for (let i = fromAge; i <= ageTo; i++) {
      ages.push(
        <MenuItem key={i.toString()} value={i}>
          {i}
        </MenuItem>
      );
    }
    return ages;
  };

  React.useEffect(() => {
    setFamilyStatusList(
      Object.keys(FamilyStatusList).map((item) => {
        return { id: item, value: FamilyStatusList[item] };
      })
    );
    getSearches();
  }, []);

  React.useEffect(() => {
    if (page !== 1) findCandidates(buildFilterData());
  }, [page]);

  const getSearches = async () => {
    setInitialLoading(true);
    try {
      let response = await axios.get("/wp-json/api/v2/get_explore_searches");
      setInitialLoading(false);
      if (response.data) {
        setSavedSearches(response.data);
      }
    } catch (err) {
      setError(err.response);
      setInitialLoading(false);
    }
  };

  const handleRefreshList = () => {
    getSearches();
  };

  const handleAddNewtag = (tag) => {
    setCandidateTags([...candidateTags, tag.trim()]);
  };

  const handleRemoveTag = (tag) => {
    setCandidateTags(candidateTags.filter((t) => t !== tag));
  };

  const buildFilterData = (premade = null) => {
    return premade
      ? {}
      : {
          filterBy,
          area: selectedArea.length
            ? selectedArea.map((area) => parseInt(area.id))
            : undefined,
          tags:
            candidateTags && candidateTags.length
              ? candidateTags.join(",")
              : undefined,
          gender: selectedGender ? selectedGender : undefined,
          ageFrom,
          ageTo,
          rating: selectedRating > -1 ? selectedRating : undefined,
          //includeNoRating: includeWithoutRating,
          familyStatus: selectedFamilyStatus
            ? selectedFamilyStatus.id
            : undefined,
          professions: selectedProfessions.length
            ? selectedProfessions.map((professions) => professions.id)
            : undefined,
          period: selectedPeriod !== "allTimes" ? reportPeriod : undefined,
          periodTag: selectedPeriod,
          page,
          perPage,
        };
  };

  const handleSaveSearch = (searchData) => {
    setSavedSearches(searchData);
  };

  const handleEditSearch = (searchId) => {
    const index = savedSearches.findIndex(
      (item) => parseInt(item.id) === parseInt(searchId)
    );
    if (index > -1) {
      setEditSearch(parseInt(searchId));
    }
  };

  const handleOnSelect = (searchId) => {
    //locate the search:
    const index = locateSearchIndex(parseInt(searchId));
    if (index > -1) {
      setSearchFromSave(true);
      findCandidates(savedSearches[index].data);
    }
  };

  const handleOnRemove = (searchId) => {
    const index = locateSearchIndex(parseInt(searchId));
    if (index > -1) {
      setSearchFromSave(false);
      setConfirmDelete(parseInt(searchId));
    }
  };

  const handleRemoveSearch = async () => {
    const index = locateSearchIndex(confirmDelete);
    if (index > -1) {
      try {
        setIsLoading(true);
        const remove = await axios.delete(
          `/wp-json/api/v2/remove_explore_search/${confirmDelete}`
        );
        setIsLoading(false);
        setConfirmDelete(-1);
        //update the list - remove search from list by confirmDelete id
        const removeIndex = savedSearches.findIndex(
          (ss) => parseInt(ss.id) === confirmDelete
        );
        savedSearches.splice(removeIndex, 1);
      } catch (err) {
        setIsLoading(false);
        setConfirmDelete(-1);
        setError(err.response);
      }
    }
  };

  const locateSearchIndex = (searchId) => {
    return savedSearches.findIndex((item) => parseInt(item.id) === searchId);
  };

  const handleGetSearchData = () => {
    return savedSearches[locateSearchIndex(editSearch)];
  };

  const handleOnSave = async (data) => {
    const index = locateSearchIndex(editSearch);
    const localSearches = savedSearches;
    localSearches[index] = data;
    setSavedSearches(localSearches);
    setEditSearch(-1);
    try {
      const udpated = await axios.patch(
        `/wp-json/api/v2/edit_explore_search/${editSearch}`,
        { name: data.name }
      );
      setSearchUpdated(true);
    } catch (err) {
      setSearchUpdated(false);
      setError(err.response);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    const filterData = buildFilterData();
    console.log(filterData);
    //findCandidates(filterData);
  };

  const findCandidates = async (filterData) => {
    setIsLoading(true);
    try {
      const candidates = await axios.post(
        "/wp-json/api/v2/find_candidates_criteria",
        { filterData }
      );

      setIsLoading(false);
      if (candidates.data && candidates.data !== "") {
        setCandidatesFound(candidates.data);
        setTotalFound(parseInt(candidates.headers["x-wp-total"]));
      }
    } catch (err) {
      setError(err.response);
      setIsLoading(false);
    }
  };

  const handleCollectFilterCretria = () => {
    const filterData = buildFilterData();
    setSearchFromSave(false);
    findCandidates(filterData);
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.root}>
        {!candidatesFound ||
          (!candidatesFound.length && (
            <React.Fragment>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{ padding: theme.spacing(2) }}
                spacing={3}
              >
                <Grid item></Grid>
                <Grid item>
                  <Tooltip title="Search by criteria">
                    <span>
                      <Fab
                        size="medium"
                        color="primary"
                        aria-label="add"
                        disabled={addSearch}
                        onClick={() => setAddSearch(true)}
                      >
                        <AddIcon />
                      </Fab>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              <Collapse in={addSearch} style={{ position: "relative" }}>
                <Paper>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        my={theme.spacing(4)}
                      >
                        <Typography variant="h4">Find Candidates</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">
                          {filterBy === "candidates"
                            ? "Find by criteria"
                            : "Find by job parameters"}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box className={classes.filterByButtons}>
                        <Button
                          variant={
                            filterBy === "candidates" ? "contained" : "text"
                          }
                          endIcon={<MenuCandidatesIcon />}
                          onClick={() => setFilterBy("candidates")}
                          style={{ marginLeft: theme.spacing(2) }}
                          disabled={isLoading}
                          disableElevation={true}
                        >
                          Search by criteria
                        </Button>
                        <Button
                          variant={filterBy === "jobs" ? "contained" : "text"}
                          endIcon={<MenuJobsIcon />}
                          onClick={() => setFilterBy("jobs")}
                          disabled={isLoading}
                          disableElevation={true}
                        >
                          Search by job
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    {filterBy === "candidates" && (
                      <React.Fragment>
                        <Grid item xs={4}>
                          <ConfigurationListsContext.Consumer>
                            {(context) => (
                              <Autocomplete
                                options={context.areasList.sort(
                                  (a, b) => a.parent_id > b.parent_id
                                )}
                                getOptionLabel={(area) => area.value}
                                limitTags={2}
                                multiple
                                disabled={isLoading}
                                id="area"
                                value={
                                  selectedArea.length > 0
                                    ? selectedArea.map(
                                        (sp) =>
                                          context.areasList.filter(
                                            (item) => item.id === sp.id
                                          )[0]
                                      )
                                    : []
                                }
                                onChange={(e, value) => setSelectedArea(value)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    margin="normal"
                                    value={
                                      selectedArea !== null
                                        ? Object.values(selectedArea)
                                        : null
                                    }
                                    label="Working Area"
                                    variant="filled"
                                  />
                                )}
                              />
                            )}
                          </ConfigurationListsContext.Consumer>
                        </Grid>
                        <Grid item xs={4} className={classes.gridRow}>
                          <FormControl
                            variant="filled"
                            fullWidth
                            disabled={isLoading}
                          >
                            <InputLabel htmlFor="ageFrom">Gender</InputLabel>
                            <Select
                              value={selectedGender}
                              onChange={(event) =>
                                setSelectedGender(event.target.value)
                              }
                            >
                              <MenuItem value="">
                                <em>Without</em>
                              </MenuItem>
                              {Object.keys(GenderList).map((gender) => (
                                <MenuItem key={gender} value={gender}>
                                  {GenderList[gender]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={4} className={classes.gridRow}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <FormControl
                              variant="filled"
                              fullWidth
                              style={{ marginLeft: theme.spacing(2) }}
                              disabled={isLoading}
                            >
                              <InputLabel htmlFor="ageFrom">
                                Age From
                              </InputLabel>
                              <Select
                                value={ageFrom}
                                onChange={(event) =>
                                  setAgeFrom(event.target.value)
                                }
                                inputProps={{
                                  name: "age",
                                  id: "filled-age",
                                }}
                                IconComponent={(_props) => (
                                  <div
                                    className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled"
                                    style={{ top: 24 }}
                                  >
                                    <ArrowUpDownIcon />
                                  </div>
                                )}
                              >
                                {generateAgeList(16, ageTo)}
                              </Select>
                            </FormControl>
                            <FormControl
                              variant="filled"
                              fullWidth
                              disabled={isLoading}
                            >
                              <InputLabel htmlFor="ageFrom">Age To</InputLabel>
                              <Select
                                value={ageTo}
                                onChange={(event) =>
                                  setAgeTo(event.target.value)
                                }
                                inputProps={{
                                  name: "age",
                                  id: "filled-age-to",
                                }}
                                IconComponent={(_props) => (
                                  <div
                                    className="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconFilled"
                                    style={{ top: 24 }}
                                  >
                                    <ArrowUpDownIcon />
                                  </div>
                                )}
                              >
                                {generateAgeList(ageFrom, 67)}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Autocomplete
                            options={familyStatusList}
                            id="familyStatus"
                            fullWidth
                            getOptionLabel={(option) => option.value}
                            getOptionSelected={(option, value) =>
                              option.id === value.id
                            }
                            disabled={isLoading}
                            value={
                              selectedFamilyStatus &&
                              selectedFamilyStatus !== null &&
                              Object.keys(selectedFamilyStatus).length
                                ? familyStatusList.find(
                                    (item) =>
                                      selectedFamilyStatus.id === item.id
                                  )
                                : null
                            }
                            onChange={(e, value) => {
                              setSelectedFamilyStatus(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Family Status"
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <ConfigurationListsContext.Consumer>
                            {(context) => (
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                limitTags={4}
                                options={context.professionsList.sort(
                                  (a, b) => a.parent_id > b.parent_id
                                )}
                                disabled={isLoading}
                                getOptionLabel={(prof) => prof.name}
                                onChange={(e, value) =>
                                  setSelectedProfessions(value)
                                }
                                value={selectedProfessions.map(
                                  (sp) =>
                                    context.professionsList.filter(
                                      (item) => item.id === sp.id
                                    )[0]
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="filled"
                                    label="Select Professions"
                                    placeholder="Professions"
                                  />
                                )}
                              />
                            )}
                          </ConfigurationListsContext.Consumer>
                        </Grid>
                        <Grid item xs={4}>
                          <PeriodSelection
                            noMargin
                            fullWidth
                            label="Period"
                            filled
                            selectedPeriod={selectedPeriod}
                            onPeriodChange={handlePeriodChange}
                          />
                          <Collapse in={selectedPeriod === "custom"}>
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="space-beween"
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-from"
                                  label="From Date"
                                  maxDate={customDateTo}
                                  value={customDateFrom}
                                  onChange={(date) =>
                                    setCustomDateFrom(
                                      moment(date).format("YYYY/MM/DD")
                                    )
                                  }
                                  KeyboardButtonProps={{
                                    "aria-label": "fromDate",
                                  }}
                                  style={{
                                    marginTop: 8,
                                    marginRight: theme.spacing(2),
                                  }}
                                />
                                <KeyboardDatePicker
                                  disableToolbar
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-to"
                                  label="To Date"
                                  minDate={customDateFrom}
                                  value={customDateTo}
                                  onChange={(date) =>
                                    setCustomDateTo(
                                      moment(date).format("YYYY/MM/DD")
                                    )
                                  }
                                  KeyboardButtonProps={{
                                    "aria-label": "toDate",
                                  }}
                                  style={{
                                    marginTop: 8,
                                    marginRight: theme.spacing(2),
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </Box>
                          </Collapse>
                        </Grid>
                        <Grid item xs={4} className={classes.gridRow}>
                          <Box
                            display="flex"
                            alignItems="flex-end"
                            justifyContent="flex-start"
                          >
                            <div>
                              <Typography variant="body1" color="textSecondary">
                                Rating equal of higher then
                              </Typography>
                              <StyledRating
                                precision={0.5}
                                value={
                                  selectedRating === -1 ? null : selectedRating
                                }
                                name={"CandidateRating"}
                                icon={<StarIcon strokeColor={"#8593FF"} />}
                                onChange={(event, value) =>
                                  setSelectedRating(value)
                                }
                              />
                              {selectedRating > -1 && (
                                <div>
                                  <Link
                                    onClick={() => setSelectedRating(-1)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Clear
                                  </Link>
                                </div>
                              )}
                            </div>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Tags
                            AddTags={handleAddNewtag}
                            tags={candidateTags}
                            onChipDelete={handleRemoveTag}
                            disabled={isLoading}
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                    {filterBy === "jobs" && (
                      <Grid item xs={12}>
                        <SearchJob searchMode variant="filled" fullWidth />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ContinueIcon />}
                        style={{ float: "left" }}
                        onClick={handleCollectFilterCretria}
                        disabled={isLoading}
                      >
                        Apply
                      </Button>
                      <Button
                        style={{ float: "left", marginLeft: theme.spacing(2) }}
                        onClick={() => setAddSearch(false)}
                        disabled={isLoading}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Collapse>
            </React.Fragment>
          ))}
      </Container>
      {candidatesFound && candidatesFound.length > 0 && (
        <LibiExploreResults
          candidates={candidatesFound}
          total={totalFound}
          pages={Math.ceil(totalFound / 20)}
          disableSave={searchFromSave}
          searchData={buildFilterData()}
          onSaveSearch={handleSaveSearch}
          onBack={() => setCandidatesFound([])}
          onPageChange={handlePageChange}
          currentPage={page}
        />
      )}
      {initialLoading && <Spinner open={initialLoading} />}
      {(!candidatesFound || !candidatesFound.length) && (
        <Container maxWidth={"xl"}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            my={theme.spacing(4)}
          >
            <Typography variant="h4">My Searches</Typography>
            <Tooltip title="Refresh">
              <IconButton
                aria-label="back"
                onClick={handleRefreshList}
                color="primary"
                style={{ marginRight: theme.spacing(1) }}
              >
                <CachedRoundedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <MyExploreSearches
            results={savedSearches}
            onEdit={handleEditSearch}
            onSelect={handleOnSelect}
            onRemove={handleOnRemove}
            onPageChange={handlePageChange}
          />
          {searchUpdated && (
            <Snackbar
              open={searchUpdated}
              autoHideDuration={3000}
              onClose={() => setSearchUpdated(false)}
            >
              <Alert severity="success">Search saved successfully</Alert>
            </Snackbar>
          )}
        </Container>
      )}
      {editSearch > 0 && (
        <EditExploreSearch
          open={editSearch > 0}
          close={() => setEditSearch(-1)}
          searchData={handleGetSearchData()}
          onSave={handleOnSave}
        />
      )}
      {error && (
        <AxiosErrorHandling
          title={"An error occured while searching candidates"}
          error={error}
        />
      )}
      {isLoading && <Spinner open={isLoading} />}
      {confirmDelete > -1 && (
        <DialogYesNo
          open={confirmDelete > -1}
          onClose={() => setConfirmDelete(-1)}
          title="Remove search"
          description={`Are you sure you want to remove search # ${confirmDelete}?`}
          positiveButtonLabel={"Yes"}
          onConfirm={handleRemoveSearch}
          negativeButtonLabel="Cancel"
        />
      )}
    </React.Fragment>
  );
};
export default Explore;
