import React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "@material-ui/core";

let SendFill = (props) => (
  <SvgIcon {...props}>
    <path d="M3 10.9999H9L9 12.9999H3L3 22.1539C3.00001 22.2408 3.02267 22.3262 3.06573 22.4016C3.1088 22.4771 3.17078 22.54 3.24558 22.5842C3.32037 22.6284 3.4054 22.6524 3.49227 22.6537C3.57915 22.6551 3.66487 22.6338 3.741 22.5919L22.203 12.4379C22.2814 12.3947 22.3468 12.3313 22.3923 12.2543C22.4378 12.1772 22.4618 12.0894 22.4618 11.9999C22.4618 11.9104 22.4378 11.8226 22.3923 11.7455C22.3468 11.6685 22.2814 11.6051 22.203 11.5619L3.741 1.4079C3.66487 1.36602 3.57915 1.3447 3.49227 1.34604C3.4054 1.34738 3.32037 1.37134 3.24558 1.41555C3.17078 1.45976 3.1088 1.5227 3.06573 1.59817C3.02267 1.67363 3.00001 1.75901 3 1.8459L3 10.9999Z" />
  </SvgIcon>
);
SendFill = pure(SendFill);
SendFill.displayName = "SendFill";
SendFill.muiName = "SvgIcon";

export default SendFill;
