export const SCREEN_DASHBOARD = "Dashboard";
export const SCREEN_CANDIDATES = "Candidates";
export const SCREEN_COMPANIES = "Companies";
export const SCREEN_JOBS = "Jobs";
export const SCREEN_TRACERS = "Tracers";
export const SCREEN_SETTINGS = "Settings";
export const DEGREE_TYPES = {
  first: [
    "B.A",
    "B.Sc",
    "B.Ed",
    "B.SW",
    "LL.B.",
    "B.Sw",
    "B.Tech",
    "B.Des",
    "B.Arch.",
  ],
  second: ["M.A", "M.B.A", "L.L.M", "M.Sc", "M.E", "M.Ed"],
  doctor: ["Ph.D", "LL.D.", "M.D", "D.M.D", "D.V.M"],
  engineer: ["B.Sc"],
  practicalEngineer: [],
  technition: [],
};

export const AI_EXPERIENCES = "experiences";
export const AI_SKILLS = "skills";
export const AI_LICENSES = "licenses";
export const AI_ACADEMIC_DGREE = "academicDegree";
export const AI_CERTIFICATES = "certificates";
export const AI_LANGUAGES = "languages";
export const AI_SALARY_EXPECTATIONS = "salaryExpectations";
export const AI_DYNAMICS = "dynamics";

export const LOW_SMS_BALANCE = 500;
export const MED_SMS_BALANCE = 10000;
//export const HIGH_SMS_BALANCE IS NOT REQUIRED, as the value is MED_SMS_BALANCE + 1

let smsTemplateDefault =
  "Hi {{candidat FullName}},\n\rMy name is {{recruiter Fullname}} from Horeca Jobs human resources, and I have a job that may suits you.\n\rTo view job details and salary terms >> {{link}}\n\rGood luck,\n\r{{Recruiter First Name}}\n\rTo usubscribe >> {{unsubscribe_url}}";

let smsTemplate2 =
  "Γεια σας {{candidat FullName}},\n\rΤο όνομά μου είναι {{recruiter Fullname}} από το ανθρώπινο δυναμικό της Horeca Jobs και έχω μια δουλειά που μπορεί να σας ταιριάζει.\n\r Για να δείτε τις λεπτομέρειες εργασίας και τους όρους μισθού >> {{link}}\n\rΚαλή τύχη,\n\r{{Recruiter Όνομα}}Για να εγγραφείτε >> {{unsubscribe_url}}";

export const MAX_HOURLY_RATE = 200; //above this number, a salary would consider a monthly rate salary, up to this number - hourly rate

export const SMSTEMPLATES = [smsTemplateDefault, smsTemplate2];

export const ACCOUNT_REDIRECT_WEBSITE = "https://staffmatters.com";
export const ACCOUNT_CONTACT_EMAIL = "admin@smstaffmatters.com";
export const ACCOUNT_DISPLAY_NAME = "Staffmatters Jobs";

export const COUNTRY_CODE = "357";

export const trasformMobileToLocalFormat = (phoneNumber) => {
  if (!phoneNumber) return "";
  let mobile = phoneNumber
    .toString()
    .trim()
    .replace(/[-+\s]/g, ""); //remove dashes, whitespaces, +
  if (mobile.startsWith(COUNTRY_CODE)) {
    mobile = mobile.replace(COUNTRY_CODE, "");
  }

  return mobile;
};
