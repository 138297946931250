import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Typography, Box, CircularProgress, Button } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: 0,
    },
  },
}));

const VerificationStage = ({
  message,
  loading,
  foundDuplicate,
  foundDuplicateMessage,
  redirectLogin,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Typography variant="h4" align="center">
          {foundDuplicate ? foundDuplicateMessage : message}
        </Typography>

        <Box
          px={theme.spacing(2)}
          display="flex"
          alignItems="center"
          flexDirection="column"
          my={theme.spacing(5)}
        >
          {loading && <CircularProgress color="secondary" />}
        </Box>
        {foundDuplicate && (
          <Box
            px={theme.spacing(2)}
            display="flex"
            alignItems="center"
            flexDirection="column"
            my={theme.spacing(5)}
          >
            <Typography
              variant="body1"
              align="center"
              color="textSecondary"
              style={{
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
              }}
            >
              <FormattedMessage
                id="candidateProfile.signUp.step2.error.foundParameters"
                defaultMessage="One of the provided parameters already in our system"
              />
            </Typography>
            <Button color="secondary" onClick={redirectLogin}>
              <FormattedMessage id="login.signIn" defaultMessage="Sign In" />
            </Button>
          </Box>
        )}
      </div>
    </React.Fragment>
  );
};
export default VerificationStage;
