import React, { Component } from "react";
import {
  Button,
  IconButton,
  Container,
  Paper,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import ChevronLeftRounded from "@material-ui/icons/ChevronLeftRounded";
import VisibilityRoundedIcon from "@material-ui/icons/VisibilityRounded";
import EditIcon from "../../Assets/CustomIcons/EditIcon";
import SendFill from "../../Assets/CustomIcons/SendFill";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import AddJob from "./AddJob/AddJob";
import JobsList from "./JobsList/JobsList";
import MySearch from "../../components/MySearch/MySearch";
import MySort from "../../components/MySearch/MySort";
import { JobActionsMenuOptions } from "../../config/jobsConfigData";
import UploadCandidates from "../../components/UploadCandidates/UploadCandidates";
import JobDashbaord from "../../Screens/Jobs/JobsList/JobDashboard/JobDashboard";
import CloseJob from "../../components/CloseJob/CloseJob";
import AxiosErrorHandling from "../../components/AxiosErrorHandling/AxiosErrorHandling";
import axios from "axios";
import { withRouter } from "react-router-dom";
import DialogYesNo from "../../components/DialogYesNo/DialogYesNo";
import ViewType from "../../components/ViewType/ViewType";
import CachedRoundedIcon from "@material-ui/icons/CachedRounded";
import JobsMoreActions from "../../components/EntityMoreActions/EntitysMoreActions";
import CoordinatorsDropdownMultipleSelection from "../../components/CoordinatorsDropdown/CoordinatorsDropdownMultipleSelection";
import ClearAllIcon from "@material-ui/icons/ClearAll";

const DRAWER_WIDTH = 240;

const styles = (theme) => ({
  root: {},
  topSearchBar: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    position: "fixed",
    top: 0,
    zIndex: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    width: "calc(100% - " + DRAWER_WIDTH + "px)",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    minHeight: 240,
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  refreshButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  endButtons: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      marginLeft: theme.spacing(2),
    },
  },
});

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.handleSearchTitleChange = this.handleSearchTitleChange.bind(this);
    this._timeout = null;
    this.state = {
      addMode: false,
      editMode: -1, // will hold job id in case of edit mode
      showAlert: false,
      activeJobId: -1,
      activeJobData: {},
      action: "",
      error: null,
      jobsFilter: {
        searchTitle: "",
        closedOnly: false,
        showDrafts: false,
        selectedRecruiters: [],
        refreshList: false,
      },

      viewType: "list",
      viewPreviewDistribution: false,
      filterSingleJobItemCandidatesBy: "all",
      clearFilter: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.jobsFilter.refreshList === true) {
      this.setState({
        jobsFilter: { ...this.state.jobsFilter, refreshList: false },
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.jobsFilter.refreshList === true &&
      nextState.jobsFilter.refreshList === false
    ) {
      return false;
    }
    return true;
  }

  componentDidMount() {
    switch (this.props.innerpath) {
      case "jobs":
      case "drafts":
        this.setState((prevState) => ({
          isLoading: true,
          action: "jobs",
          jobsFilter: {
            ...prevState.jobsFilter,
            showDrafts: this.props.innerpath === "drafts",
          },
        }));
        //Check if we have user settings in cache:
        const cacheUserSettings = JSON.parse(localStorage.getItem("us"));
        if (cacheUserSettings) {
          this.setState((prevState) => ({
            jobsFilter: {
              ...prevState.jobsFilter,
              showDrafts:
                this.props.innerpath === "drafts"
                  ? true
                  : cacheUserSettings.show_drafts,
              selectedRecruiters: cacheUserSettings.recruiters
                ? cacheUserSettings.recruiters
                : [],
            },
          }));
          if (
            cacheUserSettings.show_drafts &&
            this.props.innerpath !== "/jobs/drafts"
          ) {
            this.props.history.push("/jobs/drafts");
          }
        } else {
          axios
            .get("/wp-json/api/v2/user_settings")
            .then((res) => {
              //store settings in cache
              localStorage.removeItem("us");
              localStorage.setItem("us", JSON.stringify(res.data));
              this.setState((prevState) => ({
                jobsFilter: {
                  ...prevState.jobsFilter,
                  selectedRecruiters: res.data.recruiters
                    ? res.data.recruiters
                    : [],
                  showDrafts:
                    this.props.location.pathname === "/jobs/drafts"
                      ? true
                      : res.data.show_drafts,
                },
                isLoading: false,
              }));
            })
            .catch(() => {
              this.setState({
                isLoading: false,
              });
            });
        }

        break;
      case "dashboard":
        if (
          this.props.match.params.jobid &&
          parseInt(this.props.match.params.jobid) > 0
        ) {
          this.setState({ action: "viewDashboard" });
        }
        axios
          .get("/wp-json/api/v2/jobs_summary", {
            params: {
              per_page: -1,
              allJobs: "",
              job_id: parseInt(this.props.match.params.jobid),
              showStats: true,
            },
          })
          .then((res) => {
            this.setState({
              activeJobData: res.data[0],
              activeJobId: parseInt(this.props.match.params.jobid),
              action: "viewDashboard",
              isLoading: false,
            });
          })
          .catch((err) => {
            this.setState({ error: err.response, isLoading: false });
          });
        break;
      // case "drafts":
      //   this.setState({ showDrafts: true, action: "jobs" });
      //   break;
      case "AddNewJob":
        this.setState({ addMode: true });
        break;
      case "uploads":
        this.setState({
          action: "uploadCandidates",
          activeJobData: parseInt(this.props.match.params.jobid),
        });
        break;
      default:
        break;
    }
  }

  handleRecruitersFilterChange = (value) => {
    this.setState({
      jobsFilter: {
        ...this.state.jobsFilter,
        selectedRecruiters: value ? value : [],
      },
      clearFilter: false,
    });
    //update the cache
    this.updateUserSettingsCache("recruiters", value);
  };

  handleClearFilter = () => {
    this.setState({
      jobsFilter: { ...this.state.jobsFilter, selectedRecruiters: [] },
      clearFilter: false,
    });
  };
  handleOpenedByMeOnly = (event) => {
    let localSelectedRecruiters = this.state.jobsFilter.selectedRecruiters;
    if (event.target.checked) {
      const sssr = JSON.parse(localStorage.getItem("us"));
      if (sssr && sssr.recruiters) {
        localSelectedRecruiters = [parseInt(localStorage.getItem("userId"))];
      } else {
        localSelectedRecruiters = {
          recruiters: [parseInt(localStorage.getItem("userId"))],
          show_drafts: localSelectedRecruiters.showDrafts,
        };
      }
    } else {
      //check if user id exists in array:
      const index = localSelectedRecruiters.indexOf(
        parseInt(localStorage.getItem("userId"))
      );
      if (index > -1) {
        localSelectedRecruiters.splice(index, 1);
      }
    }
    this.updateUserSettingsCache("recruiters", localSelectedRecruiters);
    this.removeLastJobPage();

    this.setState((prevState) => ({
      jobsFilter: {
        ...prevState.jobsFilter,
        selectedRecruiters: localSelectedRecruiters,
      },
    }));
  };
  handleShowClosedOnly = (event) => {
    this.removeLastJobPage();
    //this.setState({ jobsFilter: event.target.checked, refreshList: true });
    this.setState((prevState) => ({
      jobsFilter: { ...prevState.jobsFilter, closedOnly: event.target.checked },
    }));
  };

  handleEditJob = (jobId) =>
    this.setState({ editMode: jobId, addMode: true, activeJobId: jobId });

  handleViewJobDashboard = (jobDataObject) => {
    if (jobDataObject)
      this.setState({
        action: "viewDashboard",
        activeJobId: jobDataObject.id,
        activeJobData: jobDataObject,
      });
  };

  handleReopenJob = () => {
    axios
      .post(`/wp-json/api/v2/reopen_job/${this.state.activeJobId}`)
      .then(() => {
        this.setState({
          action: "jobs",
          activeJobId: -1,
          jobsFilter: { ...this.state.jobsFilter, refreshList: true },
          closedOnly: false,
        });
      })
      .catch((err) => {
        this.setState({ error: err.response });
      });
  };

  handleCloseJob = async (reason) => {
    const jobId = this.state.activeJobId;
    this.setState({ action: "jobs", activeJobId: -1 });
    try {
      await axios.post(`/wp-json/api/v2/close_job/${jobId}`, {
        reason: reason,
      });
      const jobscache = JSON.parse(sessionStorage.getItem("jobsCache"));
      const totalJobs = JSON.parse(sessionStorage.getItem("jobsHeadersCache"));
      totalJobs["x-wp-total"] = parseInt(totalJobs["x-wp-total"]) - 1;
      const jobIndex = jobscache.findIndex((job) => job.id === jobId);
      if (jobIndex > -1) {
        jobscache.splice(jobIndex, 1);
        sessionStorage.removeItem("jobsCache");
        sessionStorage.setItem("jobsCache", JSON.stringify(jobscache));
        sessionStorage.removeItem("jobsHeadersCache");
        sessionStorage.setItem("jobsHeadersCache", JSON.stringify(totalJobs));
      }
    } catch (err) {
      this.setState({ error: err.response });
    }
  };
  handleCloseMode = () => {
    this.setState({
      addMode: false,
      editMode: -1,
      activeJobId: -1,
      action: "jobs",
      activeJobData: {},
    });
    this.props.history.push("/jobs");
  };

  updateUserSettingsCache = (item, value) => {
    let userSettingsCache = JSON.parse(localStorage.getItem("us"));

    if (userSettingsCache) {
      userSettingsCache = {
        ...userSettingsCache,
        [item]: value,
      };
    }
    localStorage.removeItem("us");
    localStorage.setItem("us", JSON.stringify(userSettingsCache));
  };

  handleShowDraftsOnly = (event) => {
    //update the cache
    this.updateUserSettingsCache("show_drafts", event.target.checked);
    this.removeLastJobPage();
    this.setState((prevState) => ({
      jobsFilter: { ...prevState.jobsFilter, showDrafts: event.target.checked },
    }));
    event.target.checked
      ? this.props.history.replace("/jobs/drafts")
      : this.props.history.replace("/jobs");

    //getJobs(1, closedOnly, createdByMe, event.target.checked)
  };

  handleAddedSuccessfully = (newJobId) => {
    this.setState({
      showAlert: true,
      addMode: false,
      editMode: -1,
      activeJobId: parseInt(newJobId),
      action: "viewDashboard", //hold menuAction - determain active screen
    });
    this.props.history.push(`/jobs/dashboard/${parseInt(newJobId)}`);
  };
  handleCloseMyAlert = () => {
    this.setState({ showAlert: false });
  };
  handleSearchTitleChange = (search) => {
    if (this._timeout) {
      clearTimeout(this._timeout);
    }
    this._timeout = setTimeout(() => {
      this._timeout = null;
      if (search !== this.state.jobsFilter.searchTitle)
        this.setState({
          jobsFilter: { ...this.state.jobsFilter, searchTitle: search },
        });
    }, 2000);
  };
  handleJobMenuItemClick = (event, option, jobId) => {
    this.setState({ activeJobId: jobId });
    switch (option) {
      case "editJob": {
        this.setState({ editMode: jobId, addMode: true });
        break;
      }
      case "uploadCandidates": {
        this.setState({ action: option });
        this.props.history.push(`/jobs/uploads/${jobId}`);
        break;
      }
      case "closeJob": {
        //if current status is closed, this is a reopen job requst
        this.setState({ action: option });
        break;
      }
      case "jobReopen": {
        this.setState({ action: option });
        break;
      }
      default:
        this.setState({ activeJobId: -1 });
    }
  };

  handleAddCandidates = () => {
    //this.setState({ action: "uploadCandidates" });
    this.props.history.push(`/jobs/uploads/${this.state.activeJobId}`);
  };

  // handleSingleFilterJobItemBy = (filterBy) => {
  //   this.setState({ filterSingleJobItemCandidatesBy: filterBy });
  // };

  removeLastJobPage = () => sessionStorage.removeItem("ljp");

  handleOnEventDashboardStateUpdate = (event, candidateId) => {
    if (
      event.eventKey === "removeFromJob" &&
      parseInt(event.eventValue) === this.state.activeJobData.id
    ) {
      this.setState((prevState) => ({
        activeJobData: {
          ...prevState.activeJobData,
          total_assigned: this.state.activeJobData.total_assigned - 1,
        },
      }));
    }
  };

  handleRefreshList = () => {
    this.removeLastJobPage();
    //clearCache:
    sessionStorage.clear();
    this.setState({
      jobsFilter: { ...this.state.jobsFilter, refreshList: true },
    });
  };

  detectActiveRender = () => {
    const {
      jobsFilter,
      addMode,
      editMode,
      action,
      activeJobId,
      viewPreviewDistribution,
    } = this.state;
    if (addMode) {
      return (
        <AddJob
          editMode={editMode}
          addedSuccessfully={this.handleAddedSuccessfully}
          cancelNewJob={this.handleCloseMode}
        />
      );
    } else if (action === "uploadCandidates") {
      return (
        <UploadCandidates
          endUpload={this.handleCloseMode}
          jobId={this.state.activeJobId}
          cancel={() => {
            this.setState({ action: "", activeJobId: -1 });
            this.props.history.push(
              `/jobs/uploadCandidates/${this.state.activeJobId}`
            );
          }}
        />
      );
    } else if (
      activeJobId > 0 &&
      Object.keys(this.state.activeJobData).length
    ) {
      return (
        <JobDashbaord
          job={this.state.activeJobData}
          jobsMenuOptions={JobActionsMenuOptions}
          menuItemClick={this.handleJobMenuItemClick}
          searchFilter={jobsFilter.searchTitle}
          showDistributePreview={viewPreviewDistribution}
          filterBy={"all"}
          onPreviewDistribution={(showDP) =>
            this.setState({ viewPreviewDistribution: showDP })
          }
          onEventUpdate={this.handleOnEventDashboardStateUpdate}
        />
      );
    } else if (action === "jobs" || this.state.jobsFilter.showDrafts) {
      return (
        <JobsList
          jobsMenuOptions={JobActionsMenuOptions}
          menuItemClick={this.handleJobMenuItemClick}
          editJob={this.handleEditJob}
          viewJobDashboard={this.handleViewJobDashboard}
          filter={jobsFilter}
          clearFilter={this.state.clearFilter}
          viewType={this.state.viewType}
        />
      );
    }
  };

  render() {
    const { classes } = this.props;
    const { searchTitle, addMode, activeJobId, action } = this.state;
    return (
      <React.Fragment>
        {/* HEADER FOR JOBS LIST */}
        {!addMode && action === "jobs" && (
          <Paper
            width={1}
            elevation={0}
            square
            className={classes.topSearchBar}
          >
            <div className={classes.topHeader}>
              <Grid
                container
                spacing={3}
                justify="flex-start"
                alignItems="center"
                style={{ margin: 0 }}
              >
                <Grid item xs={4} style={{ paddingRight: 0 }}>
                  <MySearch
                    changeSearchTerm={this.handleSearchTitleChange}
                    searchLabel="Find job"
                    disabled={addMode}
                    helper={
                      "Find job by name, job id, company name, external id"
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <MySort />
                </Grid>
                <Grid item xs={1}>
                  <ViewType
                    selectedView={this.state.viewType}
                    onChange={(value) => this.setState({ viewType: value })}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={3}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    aria-label="refresh"
                    className={classes.refreshButton}
                    color="primary"
                    onClick={this.handleRefreshList}
                  >
                    <CachedRoundedIcon />
                  </IconButton>
                  <Button
                    variant="contained"
                    aria-label="add"
                    color="primary"
                    endIcon={<AddIcon />}
                    disabled={addMode}
                    onClick={() => {
                      this.setState({
                        addMode: true,
                      });
                      this.props.history.push("/jobs");
                    }}
                  >
                    Add Job
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          this.state.jobsFilter.showDrafts
                            ? this.state.jobsFilter.showDrafts
                            : false
                        }
                        onChange={this.handleShowDraftsOnly}
                        name="showDrafs"
                        color="primary"
                      />
                    }
                    label="Drafts"
                    //className={classes.checkbox}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.createdByMe}
                        onChange={this.handleOpenedByMeOnly}
                        name="allJobs"
                        color="primary"
                      />
                    }
                    label="Created by me only"
                    //className={classes.checkbox}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.jobsFilter.closedOnly}
                        onChange={this.handleShowClosedOnly}
                        name="allJobs"
                        color="primary"
                      />
                    }
                    label="Closed Only"
                    disabled={this.state.jobsFilter.showDrafts}
                    //className={classes.checkbox}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    {this.state.selectedRecruiters &&
                      this.state.selectedRecruiters.length > 0 && (
                        <Tooltip title={"Clear Filter"}>
                          <IconButton
                            aria-label="clear"
                            onClick={this.handleClearFilter}
                          >
                            <ClearAllIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    <CoordinatorsDropdownMultipleSelection
                      label="Filter by Recruiters"
                      onRecruiterChange={this.handleRecruitersFilterChange}
                      selectedRecruiters={
                        this.state.jobsFilter.selectedRecruiters
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        )}
        {/* HEADER FOR JOB DASHBOARD */}
        {action === "viewDashboard" && (
          <Paper
            width={1}
            elevation={0}
            square
            className={classes.topSearchBar}
          >
            <React.Fragment>
              <div className={classes.topHeader}>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  alignItems="center"
                  style={{ margin: 0 }}
                >
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: 0 }}
                    className={classes.topHeader}
                  >
                    <Tooltip title="Back to jobs list">
                      <IconButton
                        aria-label="ChevronLeft"
                        className={classes.topHeader}
                        onClick={this.handleCloseMode}
                        size="medium"
                      >
                        <ChevronLeftRounded />
                      </IconButton>
                    </Tooltip>

                    <MySearch
                      //searchTerm={searchTitle}
                      changeSearchTerm={this.handleSearchTitleChange}
                      searchLabel="Find Candidates"
                      disabled={addMode}
                      helper={"Find candidates by name, ID, profession, city"}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <MySort />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={4}>
                    <div className={classes.endButtons}>
                      <Tooltip title="Edit Job">
                        <IconButton
                          aria-label="refresh"
                          className={classes.refreshButton}
                          onClick={() =>
                            this.handleEditJob(this.props.match.params.jobid)
                          }
                        >
                          <EditIcon color={"primary"} />
                        </IconButton>
                      </Tooltip>
                      <Button
                        variant="outlined"
                        aria-label="add"
                        color="primary"
                        endIcon={<AddIcon />}
                        onClick={this.handleAddCandidates}
                      >
                        Add Candidates
                      </Button>
                      <Button
                        variant="contained"
                        aria-label="publish"
                        color="primary"
                        endIcon={<SendFill style={{ color: "#fff" }} />}
                        onClick={() =>
                          this.setState({ viewPreviewDistribution: true })
                        }
                      >
                        Publish Job
                      </Button>
                      <JobsMoreActions
                        menuItemClick={this.handleJobMenuItemClick}
                        options={JobActionsMenuOptions}
                        jobId={this.state.activeJobId}
                        color="primary"
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          </Paper>
        )}
        {/* HEADER FOR PUBLISH JOB */}
        {action === "viewDashboard" && this.state.viewPreviewDistribution && (
          <Paper
            width={1}
            elevation={0}
            square
            className={classes.topSearchBar}
          >
            <React.Fragment>
              <div className={classes.topHeader}>
                <Grid
                  container
                  spacing={3}
                  justify="space-between"
                  alignItems="center"
                  style={{ margin: 0 }}
                >
                  <Grid
                    item
                    xs={4}
                    style={{ paddingLeft: 0 }}
                    className={classes.topHeader}
                  >
                    <Tooltip title="Back to jobs list">
                      <IconButton
                        aria-label="ChevronLeft"
                        className={classes.topHeader}
                        onClick={this.handleCloseMode}
                        size="medium"
                      >
                        <ChevronLeftRounded />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={4}>
                    <div className={classes.endButtons}>
                      <Tooltip title="Preview">
                        <IconButton
                          aria-label="refresh"
                          className={classes.refreshButton}
                        >
                          <VisibilityRoundedIcon color={"primary"} />
                        </IconButton>
                      </Tooltip>
                      <Button
                        variant="contained"
                        aria-label="publish"
                        color="primary"
                        endIcon={<SendFill style={{ color: "#fff" }} />}
                        onClick={() =>
                          this.setState({ viewPreviewDistribution: true })
                        }
                      >
                        Publish Job
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          </Paper>
        )}
        <Container
          maxWidth={"xl"}
          style={{
            marginTop:
              action === "jobs" && !addMode
                ? 190
                : action === "viewDashboard"
                ? 100
                : "1.5rem",
          }}
        >
          {this.state.error && (
            <AxiosErrorHandling
              title="Error in jobs module"
              error={this.state.error}
            />
          )}
          {this.detectActiveRender()}
          {action === "closeJob" && activeJobId > 0 ? (
            <CloseJob
              jobId={activeJobId}
              open={this.state.action === "closeJob"}
              cancel={() => this.setState({ action: "jobs", activeJobId: -1 })}
              closeJob={this.handleCloseJob}
            />
          ) : null}
          {action === "jobReopen" && activeJobId > 0 ? (
            <DialogYesNo
              open={true}
              onClose={() => this.setState({ action: "jobs" })}
              title="Reopen Job"
              description={`Reopen job ${activeJobId}`}
              positiveButtonLabel={"Yes"}
              onConfirm={this.handleReopenJob}
              negativeButtonLabel="Cancel"
            />
          ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(withStyles(styles)(Jobs));
