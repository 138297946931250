import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "../../../../components/Logo/Logo";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#fff",
  },
  avatar: {
    width: 60,
    height: 60,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <IconButton color="secondary" onClick={onMobileNavOpen}>
          <MenuIcon />
        </IconButton>
        <Box flexGrow={1} />
        <Box display="flex" alignItems="center">
          <RouterLink to="/my-profile/login" style={{ display: "flex" }}>
            <Logo />
          </RouterLink>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
