import React from "react";
import { Typography, Link } from "@material-ui/core";

const CandidateNameLink = ({ name, onNameClick }) => {
  return (
    <Typography variant="body1">
      <Link
        style={{
          cursor: "pointer",
          textDecoration: "none",
          fontWeight: 500,
        }}
        color="textSecondary"
        onClick={onNameClick}
      >
        {name}
      </Link>
    </Typography>
  );
};
export default CandidateNameLink;
