import React, { useContext } from "react";
import PropTypes from "prop-types";
import Alert from "@material-ui/lab/Alert";
import ChevronLeftRounded from "@material-ui/icons/ChevronLeftRounded";
import {
  Grid,
  Paper,
  Button,
  makeStyles,
  Container,
  Typography,
  Box,
  Snackbar,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ConfigurationListsContext from "../../context/configurationListsContext";
import Pagination from "../../UI/Pagination/Pagination";
import { DashboardPeriod } from "../../config/dashboardConfigData";
import {
  FamilyStatusList,
  GenderList,
} from "../../config/candidatesConfigData";
import {
  LinkToJobIcon,
  SaveIcon,
  FilterIcon,
  UploadIcon,
} from "../../Assets/CustomIcons/CustomIconsList";
import Candidates from "../Candidates/Candidates";
import SaveSerach from "./SaveSearch";
import axios from "axios";
const DRAWER_WIDTH = 240;
const useStyles = makeStyles((theme) => ({
  root: {},
  topSearchBar: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "fixed",
    top: 0,
    zIndex: 10,
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    width: "calc(100% - " + DRAWER_WIDTH + "px)",
  },
  toolbarButtons: {
    "& .MuiButton-root": {
      marginRight: theme.spacing(2),
      background: "#F5F6F8",
      color: "#636575",
      "&:hover": {
        background: "#d5d5d5",
      },
    },
    "& .MuiIconButton-root": {
      marginRight: theme.spacing(2),
      background: "#F5F6F8",
      color: "#636575",
      "&:hover": {
        background: "#d5d5d5",
      },
    },
  },
}));

const ExploreResults = ({
  candidates,
  total = 1,
  pages = 1,
  searchData,
  disableSave,
  onSaveSearch,
  onBack,
  onPageChange,
  currentPage,
}) => {
  const classes = useStyles();
  const [showSaveSearch, setShowSaveSearch] = React.useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = React.useState(false);
  const [linkToJob, setLinkToJob] = React.useState(false);
  const [multipleSelectedCount, setMultipleSelectedCount] = React.useState(0);
  const context = useContext(ConfigurationListsContext);

  const buildTagsData = () => {
    let dataTags = [];
    dataTags =
      searchData.area && searchData.area.length
        ? searchData.area.map(
            (sa) =>
              context.areasList.find((item) => parseInt(item.id) === sa).value
          )
        : [];
    if (searchData.tags) dataTags = [...dataTags, searchData.tags];
    if (searchData.gender)
      dataTags = [...dataTags, GenderList[searchData.gender]];
    dataTags = [...dataTags, `Ages ${searchData.ageFrom}-${searchData.ageTo}`];
    if (searchData.familyStatus)
      dataTags = [...dataTags, FamilyStatusList[searchData.familyStatus]];
    if (searchData.rating) {
      dataTags = [...dataTags, `Rating < ${searchData.rating}`];
    }
    if (searchData.professions && searchData.professions.length) {
      searchData.professions.forEach((item) => {
        dataTags = [
          ...dataTags,
          context.professionsList.find((pl) => item === pl.id).name,
        ];
      });
    }
    if (searchData.periodTag) {
      dataTags = [
        ...dataTags,
        DashboardPeriod.find((item) => item.value === searchData.periodTag)
          .label,
      ];
    }
    return dataTags;
  };
  const handleOnSearch = async (data) => {
    setShowSaveSearch(false);
    const search = await axios.post("/wp-json/api/v2/save_explore_search", {
      ...data,
      searchData,
    });
    setSavedSuccessfully(true);
    onSaveSearch(search.data);
  };

  const showPagination = (
    <Pagination
      onChange={onPageChange}
      totalPages={pages}
      total={total}
      totalLabel={"Total Candidates:"}
      currentPage={currentPage}
      //reload={this.handleReloadPage}
    />
  );

  const handleMultipleLinkToJob = () => setLinkToJob(true);

  return (
    <React.Fragment>
      <Paper width={1} elevation={0} square className={classes.topSearchBar}>
        <div className={classes.topHeader}>
          <Grid container spacing={3} justify="flex-start" alignItems="center">
            <Grid item xs={9}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                className={classes.toolbarButtons}
              >
                <Tooltip title="Back to searches management" aria-label="back">
                  <span>
                    <IconButton aria-label="back" onClick={onBack}>
                      <ChevronLeftRounded fontSize="small" />
                    </IconButton>
                  </span>
                </Tooltip>
                <Button
                  variant="contained"
                  aria-label="filter"
                  endIcon={<FilterIcon />}
                  disableElevation
                >
                  Advanced Search
                </Button>
                <Button
                  variant="contained"
                  aria-label="filter"
                  endIcon={<UploadIcon />}
                  disableElevation
                >
                  Selection from My Searches
                </Button>
                <Button
                  variant="contained"
                  aria-label="filter"
                  endIcon={<SaveIcon />}
                  disableElevation
                  onClick={() => setShowSaveSearch(true)}
                  disabled={disableSave}
                >
                  Add to My Searches
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                aria-label="add"
                color="primary"
                endIcon={<LinkToJobIcon />}
                disabled={multipleSelectedCount === 0}
                onClick={handleMultipleLinkToJob}
              >
                Link to job
              </Button>
            </Grid>
          </Grid>
        </div>
      </Paper>

      <Container maxWidth={"xl"} style={{ marginTop: 90 }}>
        <Typography variant="h5">{`Found ${total.toLocaleString("en-GB", {
          minimumFractionDigits: 0,
        })} candidates `}</Typography>
        {multipleSelectedCount > 0 && (
          <Typography variant="body1">{`Selected ${multipleSelectedCount.toLocaleString(
            "en-GB",
            {
              minimumFractionDigits: 0,
            }
          )} candidates `}</Typography>
        )}

        {candidates.length > 0 && (
          <React.Fragment>
            {showPagination}
            <Candidates
              disableDefaultSearch
              hideMultipleLinkToJobButton
              linkMultipleSelectedClick={linkToJob}
              getSelectedCount={(count) => setMultipleSelectedCount(count)}
              candidates={{
                data: candidates,
              }}
            />
            {showPagination}
          </React.Fragment>
        )}
      </Container>
      {showSaveSearch && (
        <SaveSerach
          open={showSaveSearch}
          close={() => setShowSaveSearch(false)}
          searchData={buildTagsData()}
          onSearch={handleOnSearch}
        />
      )}
      {savedSuccessfully && (
        <Snackbar
          open={savedSuccessfully}
          autoHideDuration={3000}
          onClose={() => setSavedSuccessfully(false)}
        >
          <Alert severity="success">Search saved successfully</Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

ExploreResults.propTypes = {
  searchData: PropTypes.object.isRequired,
};
export default ExploreResults;
