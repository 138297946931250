import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPaper-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiSkeleton-root": {
      marginBottom: theme.spacing(1),
    },
  },
}));

const JobsSkeleton = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xl={2}>
        <Skeleton variant="text" />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={"100%"} height={90} />
      </Grid>
      <Grid item xl={2}>
        <Skeleton variant="text" />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={"100%"} height={90} />
      </Grid>
      <Grid item xl={2}>
        <Skeleton variant="text" />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={"100%"} height={90} />
      </Grid>
      <Grid item xl={2}>
        <Skeleton variant="text" />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={"100%"} height={90} />
      </Grid>
      <Grid item xl={2}>
        <Skeleton variant="text" />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={"100%"} height={90} />
      </Grid>
      <Grid item xl={2}>
        <Skeleton variant="text" />
        <Skeleton variant="circle" width={40} height={40} />
        <Skeleton variant="rect" width={"100%"} height={90} />
      </Grid>
    </Grid>
  );
};
export default JobsSkeleton;
