import React from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
  },
}));

export default function FormDialog(props) {
  const classes = useStyles();
  const [reason, setReason] = React.useState("");
  return (
    <div>
      <Dialog
        open={props.open}
        //onClose={props.cancel}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        className={classes.root}
      >
        <DialogTitle id="form-dialog-title">
          Close Job {props.jobId}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to close job id {props.jobId}. The job will be removed
            from jobs list. <br />
            To continue, type the closing reason:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Closing Reason"
            type="text"
            onChange={(event) => setReason(event.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.cancel} color="primary">
            Cancel
          </Button>
          <Button onClick={() => props.closeJob(reason)} color="primary">
            Close Job
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
