import React from "react";
import { Collapse, Button, CircularProgress } from "@material-ui/core";
//import axios from "../../utils/axiosInstance";
import axios from "axios";
import ListAltRoundedIcon from "@material-ui/icons/ListAltRounded";
import { makeStyles } from "@material-ui/core/styles";
import CandidateJobAnswersReport from "../CandidateJobAnswersReport/CandidateJobAnswersReport";
import AxiosErrorHandling from "../AxiosErrorHandling/AxiosErrorHandling";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-start",
    /*flexDirection: "column",*/
    alignItems: "flex-start",
  },
  collapse: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    justifyContent: "flex-start",
  },
  button: {
    marginLeft: theme.spacing(0),
    minWidth: 114,
  },
  busy: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const JobAcceptedQuestionierSummary = ({ initClosed = true, ...props }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [questionier, setQuestionier] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    if (!initClosed) {
      setExpanded(true);
      handleReportClick();
    }
  }, [initClosed]);

  const handleReportClick = () => {
    const currentState = expanded;
    if (!currentState) {
      setIsLoading(true);
      const candidateId = parseInt(props.candidateId);
      const jobId = parseInt(props.jobId);
      axios
        .get(`/wp-json/api/v2/questionier_report/${jobId}`, {
          params: {
            candidateId: parseInt(candidateId),
          },
        })
        .then((res) => {
          setExpanded(true);
          setQuestionier(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err.response);
        });
    } else {
      setExpanded(false);
    }
  };

  return (
    <div className={classes.root}>
      <React.Fragment>
        {initClosed && (
          <Button
            color="primary"
            onClick={handleReportClick}
            endIcon={<ListAltRoundedIcon />}
            className={classes.button}
            disabled={isLoading}
            size="small"
          >
            {expanded ? "Hide Questionnaire" : "Show Questionnaire"}
            {isLoading && (
              <CircularProgress size={24} className={classes.busy} />
            )}
          </Button>
        )}
        <Collapse in={expanded} className={classes.collapse}>
          <div>
            {!initClosed && expanded && (
              <Button
                color="secondary"
                onClick={() => {
                  setExpanded(false);
                  props.onClose();
                }}
              >
                Close
              </Button>
            )}
            <CandidateJobAnswersReport questions={questionier} />
          </div>
        </Collapse>
        {error && (
          <AxiosErrorHandling
            title="Error occured in questionnaire report"
            error={error}
          />
        )}
      </React.Fragment>
    </div>
  );
};
export default JobAcceptedQuestionierSummary;
