import React from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Container,
} from "@material-ui/core";
import { DashboardPeriod } from "../../config/dashboardConfigData";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "axios";
import DashboardSkeleton from "../../UI/Skeletons/DashboardSkeleton";
import AxiosErrorHandling from "../../components/AxiosErrorHandling/AxiosErrorHandling";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import Scorecard from "../../components/Scorecard/Scorecard";
import WithDashboardPaper from "../../hoc/WithDashboardPaper";
import PieChart from "../../components/Charts/PieChart";
import BarChart from "../../components/Charts/BarChart";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

function calcDifference(total, previous) {
  if (previous === 0 || total === 0) {
    return previous > total ? -100 : 100;
  }
  if (total === 0 && previous === 0) return 0;

  return parseFloat(((total - previous) / total) * 100).toFixed(2);
}

function formatPercentageTotals(totalsArr, fieldName, fieldTotal = "total") {
  let totals = 0;
  let i;
  for (i = 0; i < totalsArr.length; i++) {
    totals += parseInt(totalsArr[i][fieldTotal]);
  }
  return totalsArr.map((item) => {
    return {
      name: item[fieldName] ? item[fieldName] : "Undefined",
      value:
        parseInt(item[fieldTotal]) > 0
          ? parseFloat((parseInt(item[fieldTotal]) / totals) * 100)
          : 0,
    };
  });
}

function calcResponseRate(
  totalCandidates,
  passed,
  partially,
  autodisqualified,
  declined
) {
  const totalResponse = passed + partially + autodisqualified + declined;
  if (totalResponse === 0) return 0;
  return parseFloat(
    ((totalCandidates - totalResponse) / totalCandidates) * 100
  ).toFixed(2);
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 140,
  },
}));

const DashboardData = () => {
  const classes = useStyles({ color: "green" });
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(true);
  const [scorecardData, setScorecardData] = React.useState([]);
  const [genderTotals, setGenderTotals] = React.useState([]);
  const [declinedReasons, setDeclinedReason] = React.useState([]);
  const [selectedPeriod, setSelectedPeriod] = React.useState("yesterday");
  const [coordinatorData, setCoordinatorData] = React.useState([]);
  const [customDateFrom, setCustomDateFrom] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const [customDateTo, setCustomDateTo] = React.useState(
    moment().format("YYYY/MM/DD")
  );
  const [period, setPeriod] = React.useState({
    from: moment().format("YYYY/MM/DD"),
    to: moment().format("YYYY/MM/DD"),
  });
  const [
    scorecardDataPreviousPeriod,
    setScorecardDataPreviousPeriod,
  ] = React.useState([]);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    getDashboardData(
      moment().subtract(1, "days").format("YYYY/MM/DD"),
      moment().format("YYYY/MM/DD")
    );
  }, []);

  const getDashboardData = (fromDate, toDate) => {
    setIsLoading(true);
    axios
      .get("/wp-json/api/v2/dashboard_data", {
        params: {
          from: fromDate ? moment(fromDate).format("YYYY/MM/DD") : null,
          to: toDate ? moment(toDate).format("YYYY/MM/DD") : null,
        },
      })
      .then((res) => {
        setScorecardData(res.data.dashboard_data);
        setGenderTotals(
          formatPercentageTotals(res.data.gender_total, "Gender", "total")
        );
        setScorecardDataPreviousPeriod(res.data.dashboard_data_previous_period);
        setDeclinedReason(
          formatPercentageTotals(res.data.decline_reasons, "reason", "total")
        );
        setCoordinatorData(res.data.coordinator_totals);
        setIsLoading(false);
      })
      .catch((err) => {
        //since this is the first call, check for authetication error:
        setIsLoading(false);
        setError(err.response);
      });
  };

  const menuList = DashboardPeriod.map((item) => {
    return (
      <MenuItem key={item.value} value={item.value}>
        {item.label}
      </MenuItem>
    );
  });

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
    console.log("New perdio", event.target.value);
    let localPeriod = {
      from: moment().subtract(7, "days").format("YYYY/MM/DD"),
      to: moment().format("YYYY/MM/DD"),
    };
    switch (event.target.value) {
      case "today":
        localPeriod = {
          from: moment().format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "yesterday":
        localPeriod = {
          from: moment().subtract(1, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };

        break;
      case "last7":
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last28":
        localPeriod = {
          from: moment().subtract(28, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "last90":
        localPeriod = {
          from: moment().subtract(90, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
      case "thisYear":
        localPeriod = {
          from: moment(`${moment().year()}/1/1`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
          to: moment(`${moment().year()}/12/31`, "YYYY/MM/DD").format(
            "YYYY/MM/DD"
          ),
        };
        break;

      default:
        localPeriod = {
          from: moment().subtract(7, "days").format("YYYY/MM/DD"),
          to: moment().format("YYYY/MM/DD"),
        };
        break;
    }
    setPeriod(localPeriod);
    getDashboardData(localPeriod.from, localPeriod.to);
  };

  const handleCustomDateCollection = () => {
    setPeriod({
      from: customDateFrom,
      to: customDateTo,
    });
    getDashboardData(customDateFrom, customDateTo);
  };

  const handleCancelDateCollection = () => {
    setCustomDateFrom(moment().format("YYYY/MM/DD"));
    setCustomDateTo(moment().format("YYYY/MM/DD"));
    setSelectedPeriod("today");
  };

  //const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={2}>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                  Select Period
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedPeriod}
                  onChange={handlePeriodChange}
                >
                  {menuList}
                </Select>
              </FormControl>
            </Grid>
            {selectedPeriod === "custom" && (
              <React.Fragment>
                <Grid item xs={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="date-picker-from"
                      label="From Date"
                      minDate={moment()
                        .subtract(365, "days")
                        .format("YYYY/MM/DD")}
                      maxDate={moment().format("YYYY/MM/DD")}
                      value={customDateFrom}
                      onChange={(date) => setCustomDateFrom(date)}
                      KeyboardButtonProps={{
                        "aria-label": "fromDate",
                      }}
                      style={{ marginTop: 8, marginRight: theme.spacing(2) }}
                    />
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="yyyy/MM/dd"
                      margin="normal"
                      id="date-picker-from"
                      label="End Date"
                      minDate={moment(customDateFrom).format("YYYY/MM/DD")}
                      value={customDateTo}
                      onChange={(date) => setCustomDateTo(date)}
                      KeyboardButtonProps={{
                        "aria-label": "toDate",
                      }}
                      style={{ marginTop: 8, marginRight: theme.spacing(2) }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCustomDateCollection}
                  >
                    Apply
                  </Button>
                  <Button onClick={handleCancelDateCollection}>Cancel</Button>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
        {isLoading ? (
          <DashboardSkeleton open={true} />
        ) : (
          <React.Fragment>
            <Grid item xl={2}>
              <Scorecard
                title="Total Jobs"
                value={parseInt(scorecardData.total_jobs)}
                barColor="#ED5565"
                differenceValue={calcDifference(
                  parseInt(scorecardData.total_jobs),
                  parseInt(scorecardDataPreviousPeriod.total_jobs)
                )}
                differenceValuePrefix={"%"}
              />
            </Grid>
            <Grid item xl={2}>
              <Scorecard
                title="Total Candidates"
                value={parseInt(scorecardData.total_assigned)}
                barColor="#FFCE54"
                differenceValue={calcDifference(
                  parseInt(scorecardData.total_assigned),
                  parseInt(scorecardDataPreviousPeriod.total_assigned)
                )}
                differenceValuePrefix={"%"}
              />
            </Grid>
            <Grid item xl={2}>
              <Scorecard
                title="Total Response Rate"
                value={calcResponseRate(
                  scorecardData.total_assigned,
                  scorecardData.total_passed,
                  scorecardData.total_partially_passed,
                  scorecardData.total_auto_disqualifed,
                  scorecardData.total_declined
                )}
                barColor="#48CFAD"
                differenceValue={calcResponseRate(
                  scorecardDataPreviousPeriod.total_assigned,
                  scorecardDataPreviousPeriod.total_passed,
                  scorecardDataPreviousPeriod.total_partially_passed,
                  scorecardDataPreviousPeriod.total_auto_disqualifed,
                  scorecardDataPreviousPeriod.total_declined
                )}
                differenceValuePrefix={"%"}
              />
            </Grid>

            <Grid item xl={2}>
              <Scorecard
                title="CV Referrals"
                value={scorecardData.total_cv_referrals}
                barColor="#5D9CEC"
                //differenceValue={scorecardDataPreviousPeriod.total_cv_referrals}
                differenceValue={calcDifference(
                  scorecardData.total_cv_referrals,
                  scorecardDataPreviousPeriod.total_cv_referrals
                )}
                differenceValuePrefix={"%"}
              />
            </Grid>
            <Grid item xl={2}>
              <Scorecard
                title="Watch Not Responded"
                value={
                  scorecardData.total_watched_jobs -
                  (scorecardData.total_declined +
                    scorecardData.total_passed +
                    scorecardData.total_partially_passed +
                    scorecardData.total_auto_disqualifed)
                }
                barColor="#AC92EC"
                differenceValue={
                  scorecardDataPreviousPeriod.total_watched_jobs -
                  (scorecardDataPreviousPeriod.total_declined +
                    scorecardDataPreviousPeriod.total_passed +
                    scorecardDataPreviousPeriod.total_partially_passed +
                    scorecardDataPreviousPeriod.total_auto_disqualifed)
                }
                differenceValuePrefix={"%"}
              />
            </Grid>
            <Grid item xl={2}>
              <Scorecard
                title="Recruitments"
                value={scorecardData.total_recruitments}
                barColor="#EC87C0"
                differenceValue={scorecardDataPreviousPeriod.total_recruitments}
                differenceValuePrefix={"%"}
              />
            </Grid>
            <Grid item xl={4}>
              <WithDashboardPaper customHeight={300}>
                <Typography align="center" variant="h5">
                  Distribution By Gender
                </Typography>
                <div style={{ direction: "ltr" }}>
                  <PieChart chartData={genderTotals} />
                </div>
              </WithDashboardPaper>
            </Grid>
            <Grid item xl={4}>
              <WithDashboardPaper customHeight={300}>
                <Typography align="center" variant="h5">
                  Distribution by Reject reasons
                </Typography>
                <div style={{ direction: "ltr" }}>
                  <PieChart chartData={declinedReasons} />
                </div>
              </WithDashboardPaper>
            </Grid>
            <Grid item xl={4}>
              <WithDashboardPaper customHeight={300}>
                <Typography align="center" variant="h5">
                  Jobs for period
                </Typography>
                <div style={{ direction: "ltr" }}>
                  <BarChart chartData={coordinatorData} />
                </div>
              </WithDashboardPaper>
            </Grid>
          </React.Fragment>
        )}

        {error && (
          <AxiosErrorHandling
            title="An error occured while loading dashboard data"
            error={error}
          />
        )}
      </Grid>
    </Container>
  );
};
export default DashboardData;
