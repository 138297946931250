import React from "react";
//import questionierCompletedImg from "../../../Assets/Images/questionierCompleted.png";
import { Paper, Typography, Button, Slide, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { purple, indigo } from "@material-ui/core/colors";
import CompletedQustionnaireImg from "../../../Assets/Images/questionnaire_complete.png";
import { ACCOUNT_REDIRECT_WEBSITE } from "../../../config/constants";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .MuiButton-root": {
      width: 170,
      borderRadius: 30,
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

function extractFirstNameFromFullName(fullname) {
  const name = fullname.split(" ");
  return name[0];
}

const QuestionierCompleted = (props) => {
  const classes = useStyles();

  return (
    <Slide
      direction={"down"}
      timeout={500}
      in={true}
      className={classes.root}
      mountOnEnter
      unmountOnExit
    >
      <Grid container>
        <Grid item xs={12}>
          <Box display={"flex"} alignItems={"center"} justifyContent="Center">
            <img
              src={CompletedQustionnaireImg}
              alt="Questionnaire Completed Successfully"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.paper}>
            <Typography
              variant="h3"
              align="center"
              style={{ color: purple[400] }}
            >
              <FormattedMessage
                id="publishScreen.questionier.completed.fullname"
                defaultMessage={"Thank you {fullname}!"}
                values={{
                  fullname: extractFirstNameFromFullName(props.fullname),
                }}
              />
            </Typography>
            <Typography variant="h5" align="center" color="secondary">
              {props.declinedCompleted ? (
                <FormattedMessage
                  id="publishScreen.questionier.completed.submittedDecline"
                  defaultMessage={
                    "We will try finding you other jobs, and will be in touch soon"
                  }
                />
              ) : (
                <FormattedMessage
                  id={"publishScreen.questionier.completed.submitted"}
                  defaultMessage={
                    "I have submitted your application. If there is a match, we will contact you soon!"
                  }
                />
              )}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                window.open(ACCOUNT_REDIRECT_WEBSITE.toString(), "_blank")
              }
            >
              Find more jobs
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Slide>
  );
};

export default QuestionierCompleted;
