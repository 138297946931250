import React from "react";
import {
  Container,
  Grid,
  Paper,
  makeStyles,
  Box,
  useTheme,
  Hidden,
} from "@material-ui/core";
import Welcome from "./Welcome";
import ProfileMissing from "../../CandidateComponents/ProfileStrenghtMissing";
import CandidateJobs from "../../CandidateComponents/CandidateJobsParticipation/CandidateJobs";
import UploadCV from "./uploadCV";
import ProfileStregthContext from "../../../../context/profileStrengthContext";
import ProfileProgressLinear from "../../../../components/Profile Progress/ProfileProgress";
import ProfileStrenthMissing from "../../CandidateComponents/ProfileStrenghtMissing";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.background.dark,
    //minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    "& .MuiPaper-root": {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
      marginTop: 66,
    },
  },
}));

const DashboardElements = ({
  onCVUpload,
  cvs,
  personalName,
  removeCv,
  cvUploading,
  jobs,
  token,
  onJobRating,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        <Grid container spacing={4}>
          <Grid item lg={9} md={12}>
            <Paper>
              <ProfileStregthContext.Consumer>
                {(context) => {
                  return (
                    <Welcome
                      personalName={personalName}
                      profileFull={context.score > 90}
                    />
                  );
                }}
              </ProfileStregthContext.Consumer>
            </Paper>
            <Hidden lgUp>
              <Box my={theme.spacing(2)}>
                <Paper>
                  <ProfileStregthContext.Consumer>
                    {(context) => {
                      return (
                        <ProfileProgressLinear
                          value={context.score}
                          indicator={context.indicator}
                        />
                      );
                    }}
                  </ProfileStregthContext.Consumer>
                  <ProfileStrenthMissing />
                </Paper>
              </Box>
              <Box my={theme.spacing(2)}>
                <Paper>
                  <UploadCV
                    onCVUpload={onCVUpload}
                    candidateCVs={cvs}
                    removeCv={removeCv}
                    cvUploading={cvUploading}
                  />
                </Paper>
              </Box>
            </Hidden>
            <Box my={theme.spacing(4)}>
              <CandidateJobs
                jobs={jobs}
                token={token}
                onJobRating={onJobRating}
              />
            </Box>
          </Grid>
          <Grid item lg={3} md={12}>
            <Hidden mdDown>
              <Paper>
                <ProfileMissing />
              </Paper>
              <Box my={theme.spacing(4)}>
                <Paper>
                  <UploadCV
                    onCVUpload={onCVUpload}
                    candidateCVs={cvs}
                    removeCv={removeCv}
                    cvUploading={cvUploading}
                  />
                </Paper>
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default DashboardElements;
