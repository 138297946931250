import React from "react";
import { Container, Grid, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AxiosErrorHandling from "../../../components/AxiosErrorHandling/AxiosErrorHandling";
import RebrandlyServiceStatus from "../../../components/RebrandlyServiceStatus/RebrandlyServiceStatus";
import SMSServiceStatus from "../../../components/SMSServiceStatus/SMSServiceStatus";
import clsx from "clsx";
import ErrorBoundary from "../../../components/ErrorBoundary/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

const Settings = (props) => {
  const classes = useStyles();
  const [error, setError] = React.useState(null);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} className={classes.root}>
        <Grid item xl={4}>
          <Paper className={fixedHeightPaper} style={{ position: "relative" }}>
            <RebrandlyServiceStatus />
          </Paper>
        </Grid>
        <Grid item xl={4}>
          <Paper className={fixedHeightPaper} style={{ position: "relative" }}>
            <ErrorBoundary>
              <SMSServiceStatus />
            </ErrorBoundary>
          </Paper>
        </Grid>

        {error && (
          <AxiosErrorHandling
            title="Error occured while loading dashboard"
            error={error}
          />
        )}
      </Grid>
    </Container>
  );
};
export default Settings;
