import React from "react";
import {
  Grid,
  Box,
  Divider,
  Collapse,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { AllEvents } from "../../../../config/candidatesConfigData";
import UnderJobContext from "../../../../context/underJobContext";
import DateFilterMeun from "../../../../components/DateFilterMenu/DateFilterMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  box: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
}));

function getEventsList(events) {
  let translatedList = [];
  translatedList = events.map((event) => {
    const indx = translatedList.findIndex(
      (tl) => tl.key === event.event.event_key
    );
    if (indx === -1) {
      return {
        key: event.event.event_key,
        label: AllEvents.find(
          (alleventitem) => alleventitem.id === event.event.event_key
        )
          ? AllEvents.find(
              (alleventitem) => alleventitem.id === event.event.event_key
            ).label
          : "Unknown Event",
      };
    } else return [...translatedList];
  });
  return translatedList;
}

const EventsFilter = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseMenu = () => setAnchorEl(null);

  const handleSelectedMenuItemClick = (item) => {
    setAnchorEl(null);
    props.getFilterByDate(item);
  };
  const handleShowDateFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Collapse in={props.showFilter} className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <UnderJobContext.Consumer>
            {(context) => (
              <Box className={classes.box}>
                {context.job && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={props.currentJobOnly}
                        onChange={props.getCurrentJob}
                        name="currentJob"
                      />
                    }
                    label="Filter events for this job only"
                  />
                )}

                <Autocomplete
                  id="jobEventList"
                  size="small"
                  options={getEventsList(props.events)}
                  getOptionLabel={(option) => option.label}
                  getOptionSelected={(option, value) =>
                    option.key === value.key
                  }
                  style={{ width: 300 }}
                  onChange={(event, value, reason) => {
                    reason === "clear"
                      ? props.filterByEvent("")
                      : props.filterByEvent(value.key);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="By Event type"
                      variant="outlined"
                    />
                  )}
                />
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={handleShowDateFilter}
                >
                  By Date
                </Button>
                {anchorEl ? (
                  <DateFilterMeun
                    close={handleCloseMenu}
                    menuItemSelected={handleSelectedMenuItemClick}
                    anchorEl={anchorEl}
                    selected={props.filter.filterByDate}
                  />
                ) : null}
              </Box>
            )}
          </UnderJobContext.Consumer>
        </Grid>
      </Grid>
      <Divider />
    </Collapse>
  );
};
export default EventsFilter;
