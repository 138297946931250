import React from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import { green, red, grey } from "@material-ui/core/colors";
import NumberFormat from "react-number-format";
import clsx from "clsx";

const GreenArrow = () => {
  return <ArrowUpwardRoundedIcon style={{ color: green[500] }} />;
};

const RedArrow = () => {
  return <ArrowDownwardRoundedIcon style={{ color: red[500] }} />;
};

const YellowArrow = () => {
  return <LinearScaleIcon style={{ color: grey[500] }} />;
};

function getArrow(value) {
  if (value > 0) return <GreenArrow />;
  if (value < 0) return <RedArrow />;
  return <YellowArrow />;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 120,
  },
}));

const Scorecard = (props) => {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Paper
      className={fixedHeightPaper}
      style={{
        position: "relative",
        overflow: "hidden",
        color: "#333",
        maxWidth: props.maxWidth ? parseInt(props.maxWidth) : "auto",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          top: 0,
          left: 0,
          height: 4,
          background: props.barColor ? props.barColor : "#FFCE54",
        }}
      ></div>
      <Grid container row="true" justify="space-between">
        <Grid item>
          <Grid item xs container direction="row" spacing={2}>
            <Grid item style={{ paddingTop: 0 }}>
              <Typography variant="h3">
                <NumberFormat
                  value={props.value}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid item style={{ textAlign: "center" }}>
            {getArrow(props.differenceValue)}
            <Typography
              variant="body1"
              style={{
                lineHeight: 0.5,
                fontSize: 14,
                color: props.differenceValue >= 0 ? green[500] : red[500],
              }}
            >
              {props.differenceValue
                ? `${
                    props.differenceValuePrefix
                      ? props.differenceValuePrefix
                      : ""
                  }${props.differenceValue}${
                    props.differenceValueSuffix
                      ? props.differenceValueSuffix
                      : ""
                  }`
                : ""}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography gutterBottom variant="h6">
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};
export default Scorecard;
