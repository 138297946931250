import React from "react";
import axios from "axios";
import {
  Typography,
  CircularProgress,
  LinearProgress,
  Box,
  Tooltip,
  IconButton,
  Grid,
  Snackbar,
  Button,
} from "@material-ui/core";
import ReplayRoundedIcon from "@material-ui/icons/ReplayRounded";
import { Alert, AlertTitle, TimelineDot } from "@material-ui/lab";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { grey, green, red, yellow } from "@material-ui/core/colors";
import Title from "../Title/Title";

const useStyles = makeStyles((theme) => ({
  root: {},
  pair: {
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(1),
    },
    "& .MuiButtonBase-root": {
      marginTop: theme.spacing(1),
    },
  },
  "& .MuiTimelineDot-defaultGrey": {
    backgroundColor: red[500],
  },
  grey: {
    color: grey[700],
    fontSize: 14,
  },
  progress: {
    position: "absolute",
    left: "calc(50% - 15px)",
    top: "calc(50% - 15px)",
    color: grey[500],
  },
}));

const RedDot = () => {
  return <TimelineDot style={{ backgroundColor: red[500] }} />;
};

const GreenDot = () => {
  return <TimelineDot style={{ backgroundColor: green[500] }} />;
};

const YellowDot = () => {
  return <TimelineDot style={{ backgroundColor: yellow[500] }} />;
};

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: green[200],
  },
}))(LinearProgress);

function getDotStatus(value) {
  if (value < 0.6) return <GreenDot />;
  else if (value < 0.7) return <YellowDot />;
  else return <RedDot />;
}

const RebrandlyServiceStatus = () => {
  const classes = useStyles();
  const [quantity, setQuantity] = React.useState(0);
  const [used, setUsed] = React.useState(0);
  //const [rebrandlyLastCleanup, setRebrandlyLastCleanup] = React.useState("");
  const [lastUpdate, setLastUpdate] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [rebrandingCleanup, setRebrandingCleanup] = React.useState(false);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    axios
      .get("/wp-json/api/v2/last_rebrandly_cleanup")
      .then((res) => {
        //setRebrandlyLastCleanup(res.data);
        setLastUpdate(new Date(res.data).toDateString());
        setIsLoading(false);
        if (new Date(res.data) < new Date().setHours(0, 0, 0, 0)) {
          setRebrandingCleanup(true);
          axios.post("/wp-json/api/v2/daily_cleanup", {});
        }
      })
      .catch((err) => {
        setError(err);
        setIsLoading(false);
        setRebrandingCleanup(false);
      });

    get_rebrandly_usage();
  }, []);

  const get_rebrandly_usage = () => {
    setIsLoading(true);
    axios
      .get("/wp-json/api/v2/get_shortlink_status")
      .then((res) => {
        setIsLoading(false);
        setQuantity(parseInt(res.data.included));
        setUsed(parseInt(res.data.used));
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response);
      });
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Title>Rebrandly Service</Title>
        </Grid>
        <Grid item>{getDotStatus(parseFloat(used / quantity))}</Grid>
      </Grid>

      {isLoading ? (
        <CircularProgress className={classes.progress} size={20} />
      ) : (
        <Box className={classes.pair}>
          <Typography variant="body1">
            Used Links: {used} out of {quantity}
          </Typography>
          <BorderLinearProgress
            variant="determinate"
            value={used === 0 ? 0 : (used / quantity) * 100}
          />
          <Tooltip title="Reload" aria-label="add">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => get_rebrandly_usage()}
            >
              <ReplayRoundedIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography varaint="caption" className={classes.grey}>
            Last cleanup process: {new Date(lastUpdate).toDateString()}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" size="small">
            Clean now
          </Button>
        </Grid>
      </Grid>

      {error !== null ? (
        <Alert severity="error">
          <AlertTitle>Service is not available</AlertTitle>
          <Typography variant="body2">
            {error.code}
            {error.statusText}
          </Typography>
        </Alert>
      ) : null}
      {rebrandingCleanup ? (
        <Snackbar
          open={rebrandingCleanup}
          autoHideDuration={6000}
          onClose={() => setRebrandingCleanup(false)}
        >
          <Alert onClose={() => setRebrandingCleanup(false)} severity="info">
            Links cleanup process has started.
          </Alert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  );
};
export default RebrandlyServiceStatus;
