import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Icon, Button } from "@material-ui/core";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Chip,
  Collapse,
  TextField,
  FormControlLabel,
  Checkbox,
  Slider,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FaceIcon from "@material-ui/icons/Face";
import MyTooltip from "../../../../../UI/Tooltip/Tooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minWidth: 600,
    paddingTop: 30,
    paddingLeft: 0,
    paddingRight: 0,
    position: "relative",
    "& .MuiDivider-root": {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    "& .MuiChip-root": {
      marginRight: theme.spacing(1),
    },
  },

  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  modalTitle: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  radios: {
    width: "100%",
    "& .MuiFormGroup-root": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
    },
  },
}));

function salaryExpectationsLabel(value) {
  return `&euro; ${value}`;
}

const AISalaryExpectations = (props) => {
  const classes = useStyles();
  const [seForRole, setSEForRole] = React.useState("");
  const [useRange, setUseRange] = React.useState(true);
  const [useForAllRoles, setUseForAllRoles] = React.useState(false);
  const [updatedChip, setUpdatedChip] = React.useState(-1);
  const [salaryRange, setSalaryRange] = React.useState([6000, 8000]);
  const [salaryMeasure, setSalaryMeasure] = React.useState("global");
  const [
    salaryExpectationsAIObject,
    setSalaryExpectationsAIObject,
  ] = React.useState([]);
  const theme = useTheme();

  React.useEffect(() => {
    if (props.aIValue && props.aIValue.length) {
      const seTempAIObject = props.aIValue.map((aiItem) => {
        return (
          [...salaryExpectationsAIObject],
          {
            key: aiItem.seId,
            seId: aiItem.seId,
            seForRole: aiItem.seForRole,
            seIsRange: aiItem.seIsRange,
            seRange: aiItem.seIsRange
              ? aiItem.seRange.map((x) => parseFloat(x))
              : parseFloat(aiItem.seRange),
            seMeasure: aiItem.seMeasure,
          }
        );
      });
      setSalaryExpectationsAIObject(seTempAIObject);
    }
  }, [props.aIValue]);

  const handleChangeSalaryMeasure = (event) => {
    setSalaryMeasure(event.target.value);
    //set default values for global or hour:
    if (event.target.value === "hour") {
      setSalaryRange(38);
      setUseRange(false);
    } else {
      setSalaryRange([6000, 8000]);
      setUseRange(true);
    }
  };

  const handleForRoleChange = (event) => {
    setSEForRole(event.target.value);
  };

  const buildAISalaryExpectations = (event) => {
    props.addAI(salaryExpectationsAIObject);
  };

  /*
  Single SalaryExpectations Object: 
  {
      seId: id,
      seForRole: string,
      seIsRange: bool,      
      seRange: array,      
      seMeasure: string, default global. other options: hour
  }
  */
  const handleAddSingleSalaryExpectationAI = () => {
    if (salaryExpectationsAIObject.length <= 0 || useForAllRoles) {
      setSalaryExpectationsAIObject([
        {
          seId: 1,
          seForRole: useForAllRoles ? "" : seForRole,
          seIsRange: useRange,
          seRange: salaryRange,
          seMeasure: salaryMeasure,
        },
      ]);
    } else {
      setSalaryExpectationsAIObject([
        ...salaryExpectationsAIObject,
        {
          seId: salaryExpectationsAIObject.length + 1,
          seForRole: useForAllRoles ? "" : seForRole,
          seIsRange: useRange,
          seRange: salaryRange,
          seMeasure: salaryMeasure,
        },
      ]);
    }

    clearForm();
  };

  const updateSelectedChip = () => {
    const tempUpdatedChip = salaryExpectationsAIObject.find(
      (chip) => chip.seId === updatedChip
    );

    //remove the selected chip from the array:
    const tempSEAIObject = salaryExpectationsAIObject.filter(
      (se) => se.seId !== tempUpdatedChip.seId
    );

    //update the form:
    setSalaryExpectationsAIObject([
      ...tempSEAIObject,
      {
        seId: tempUpdatedChip.seId,
        seForRole: seForRole,
        seIsRange: useRange,
        seRange: salaryRange,
        seMeasure: salaryMeasure,
      },
    ]);
    clearForm();
  };

  const clearForm = () => {
    //clean the form values:
    setUpdatedChip(-1);
    setSEForRole("");
    setUseRange(true);
    setSalaryRange([6000, 8000]);
    setUseForAllRoles(false);
    setSalaryMeasure("global");
  };

  const handleRemoveSalaryExpectation = (seId) => {
    //locate index id
    const newSalaryExpectationObject = salaryExpectationsAIObject.filter(
      (seid) => seid.seId !== seId
    );
    setSalaryExpectationsAIObject(newSalaryExpectationObject);
    clearForm();
  };

  const handleChangeSliderRange = (event) => {
    setUseRange(!event.target.checked);
  };

  const handleReadChip = (id) => {
    if (updatedChip > 0) {
      //we now need to disable this mode:
      setUpdatedChip(-1);
      clearForm();
    } else {
      const singleSEObj = salaryExpectationsAIObject.find(
        (se) => se.seId === id
      );
      setUpdatedChip(id);
      setSEForRole(singleSEObj.seForRole);
      singleSEObj.seForRole === ""
        ? setUseForAllRoles(true)
        : setUseForAllRoles(false);
      setUseRange(singleSEObj.seIsRange);
      setSalaryRange(singleSEObj.seRange);
      setSalaryMeasure(singleSEObj.seMeasure);
    }
  };

  const handleChangeForAllRoles = (event) => {
    setUseForAllRoles(event.target.checked);
    setSEForRole("");
  };
  const handleSalaryRangeChange = (event, newValue) => {
    useRange ? setSalaryRange(newValue) : setSalaryRange(newValue);
  };

  const displayChips =
    salaryExpectationsAIObject.length > 0
      ? salaryExpectationsAIObject.map((se) => {
          return (
            <Chip
              key={se.seId}
              icon={<FaceIcon />}
              label={se.seForRole === "" ? "All Jobs" : se.seForRole}
              clickable
              color="primary"
              onClick={() => handleReadChip(se.seId)}
              onDelete={() => handleRemoveSalaryExpectation(se.seId)}
              deleteIcon={<CloseIcon />}
              variant={
                updatedChip <= 0 || updatedChip !== se.seId
                  ? "outlined"
                  : "default"
              }
            />
          );
        })
      : null;

  return (
    <Container maxWidth="lg" className={classes.root}>
      <CloseIcon onClick={props.closeModal} className={classes.closeIcon} />
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Typography
            align="center"
            color="primary"
            variant="h5"
            className={classes.modalTitle}
          >
            {props.candidateFullName !== "undefined" &&
            props.candidateFullName.length > 1
              ? `Add Salary expectations for ${props.candidateFullName}`
              : "Add Salary expectations"}
          </Typography>
          <Divider />
          <div>
            <Collapse in={salaryExpectationsAIObject !== null}>
              <Typography variant="h5" className={classes.modalTitle}>
                Salary Expectations
              </Typography>
              {displayChips}
              <Divider />
            </Collapse>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: theme.spacing(1),
            }}
          >
            <Typography
              style={{ fontWeight: 700 }}
              color="textPrimary"
              variant="subtitle1"
            >
              Add Salary Expectations
            </Typography>
            <MyTooltip
              marginRight
              arrow
              caption="Different salary expectations for different roles are possible. For all roles, select 'All Roles'"
            />
          </div>
          <TextField
            id="forRole"
            label={useForAllRoles ? "All Roles" : "For a role"}
            variant="outlined"
            disabled={useForAllRoles}
            value={seForRole}
            onChange={handleForRoleChange}
            style={{ width: "100%" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={useForAllRoles}
                onChange={handleChangeForAllRoles}
                name="forAllrole"
                color="secondary"
              />
            }
            label="This expectations are for all roles"
          />
          <div
            style={{
              marginRight: theme.spacing(1),
              marginTop: theme.spacing(2),
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">Salary Type:</FormLabel>
              <RadioGroup
                aria-label="useSliderOption"
                name="useSlider"
                value={salaryMeasure}
                onChange={handleChangeSalaryMeasure}
              >
                <FormControlLabel
                  value={"global"}
                  control={<Radio color="primary" />}
                  label="Global Salary"
                />
                <FormControlLabel
                  value={"hour"}
                  control={<Radio color="primary" />}
                  label="Salary per hour"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div
            style={{
              marginLeft: theme.spacing(1),
              marginTop: theme.spacing(2),
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={!useRange}
                  onChange={handleChangeSliderRange}
                  name="useRangeCheck"
                  color="secondary"
                />
              }
              disabled={salaryMeasure === "hour"}
              label="Salary From:"
            />
            <MyTooltip
              marginRight
              arrow
              caption="Switch to range (Starting from...)"
            />
          </div>
          <div className={classes.root}>
            <Typography id="range-slider" gutterBottom>
              Type expectations Range
            </Typography>
            <Slider
              value={salaryRange}
              onChange={handleSalaryRangeChange}
              min={salaryMeasure === "global" ? 500 : 5}
              max={salaryMeasure === "global" ? 20000 : 250}
              step={salaryMeasure === "global" ? 50 : 0.5}
              aria-labelledby="range-slider"
              getAriaValueText={salaryExpectationsLabel}
              valueLabelDisplay="on"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={salaryRange.length === 0}
              className={classes.button}
              endIcon={<Icon>add</Icon>}
              onClick={
                updatedChip <= 0
                  ? handleAddSingleSalaryExpectationAI
                  : updateSelectedChip
              } //add new Object with all values of this Component
            >
              {updatedChip > 0 ? "Update Expectations" : "Add Expectations"}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={
                Object.keys(salaryExpectationsAIObject).length <= 0 ||
                salaryExpectationsAIObject.length <= 0
              }
              className={classes.button}
              endIcon={<Icon>done</Icon>}
              onClick={buildAISalaryExpectations}
            >
              Done
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
export default React.memo(AISalaryExpectations);
