import React, { Component } from "react";
import { Typography } from "@material-ui/core";
//import libiTimer from "../../Assets/Images/libiTimer.png";
import moment from "moment";
import { FormattedMessage } from "react-intl";

export default class Timer extends Component {
  state = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  componentDidMount() {
    /*let publishDate = moment(this.props.publishDate, "DD/MM/YYYY HH:mm:ss");
    publishDate = moment(publishDate).add(this.props.jobDuration, "hours");*/

    let publishDate = moment(this.props.publishDate, "YYYY-MM-DD h:mm:ss a");
    publishDate = moment(publishDate)
      .add(parseInt(this.props.jobDuration), "hours")
      .format("YYYY-MM-DD h:mm:ss a");

    if (moment(publishDate).isAfter(moment.now())) {
      let diff = moment.duration(moment(publishDate).diff(moment.now()));
      this.setState({
        hours: diff.days() * 24 + diff.hours(),
        minutes: diff.minutes(),
        seconds: diff.seconds(),
      });
    } else {
      this.setState({
        hours: 0,
        minutes: 0,
        seconds: 0,
      });
    }

    this.myInterval = setInterval(() => {
      const { seconds, minutes, hours } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(this.myInterval);
          } else {
            //reduce hours:
            this.setState(({ hours }) => ({
              hours: hours - 1,
              minutes: 59,
              seconds: 59,
            }));
          }
        } else {
          //reduce minutes:
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  render() {
    const { minutes, seconds, hours } = this.state;

    return (
      <div style={{ position: "relative" }}>
        <Typography variant="body1" align="center">
          <FormattedMessage
            id="publishScreen.jobClosedIn"
            defaultMessage="The job will be closed in"
          />
          :
        </Typography>
        {hours === 0 && minutes === 0 && seconds === 0 ? (
          <Typography variant="h4" align="center">
            <FormattedMessage
              id="jobs.jobClosed"
              defaultMessage="Job is closed"
            />
            :
          </Typography>
        ) : (
          <React.Fragment>
            <Typography variant="h3" align="center">
              {hours}:{minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </Typography>
            {/* <img
              src={libiTimer}
              alt="Libi Timer"
              style={{
                position: "absolute",
                left: 0,
                bottom: 0,
                height: "100%",
              }}
            /> */}
          </React.Fragment>
        )}
      </div>
    );
  }
}
