import React from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { Primary } from "../../config/colors";
import { FilterBy } from "../../config/jobsConfigData";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
    "& .MuiSelect-root": {
      backgroundColor: Primary.light,
      color: Primary.dark,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FilterCandidates = ({ value, onSelect }) => {
  const classes = useStyles();
  return (
    <Box>
      <FormControl className={classes.formControl}>
        <Select
          labelId="filter-candidates"
          id="filter-candidates"
          variant="outlined"
          value={value ? value : "all"}
          onChange={onSelect}
        >
          {FilterBy.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
export default FilterCandidates;
