import React from "react";
import pure from "recompose/pure";
import { SvgIcon } from "@material-ui/core";
import { Primary } from "../../config/colors";

let AccountIcon = ({ color, ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"
      fill={color ? color : Primary.main}
    />
  </SvgIcon>
);
AccountIcon = pure(AccountIcon);
AccountIcon.displayName = "AccountIcon";
AccountIcon.muiName = "SvgIcon";

export default AccountIcon;
